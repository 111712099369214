import moment from 'moment';
import { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions/index';
import { sortBy } from '../../../utils/mlp-utils';

class SearchGridRowDetailsDocumentGrid extends Component {
    getRowData = (document) => {
        const data = [];
        document['metadata'].forEach((x) => {
            if (x.isVisibleOnGrid) {
                data.push({ ...x });
            }
        });
        return sortBy(data, 'gridOrder').map((item, i) => {
            if (item.dataType === 'DateTime') {
                item.value = moment(item.originalValue).utc().startOf('day').format('MM/DD/YYYY');
            }

            return <td key={i}>{item.value}</td>;
        });
    };

    getRows = (documents) => {
        return documents.map((doc, i) => {
            return (
                <tr key={i}>
                    <td>
                        <input
                            type='checkbox'
                            disabled={this.props.selectedLimitReached && !doc.isSelected}
                            onChange={(e) => this.selectDoc(i, e)}
                            checked={doc.isSelected}
                        />
                    </td>
                    {this.getRowData(doc)}
                    <td onClick={(e) => actions.downloadDocuments(false, this.props.groupIndex, i, this.props.gridModel, e)}>
                        <i className='fa fa-arrow-circle-o-down'></i>
                    </td>
                </tr>
            );
        });
    };

    getHeaderData = (metadata) => {
        return sortBy(
            metadata.filter((x) => x.isVisibleOnGrid),
            'gridOrder'
        ).map((item, i) => {
            return <th key={i}>{item.label}</th>;
        });
    };

    selectAll = (e) => {
        this.props.selectDocs(e.target.checked, this.props.groupIndex);
    };

    selectDoc = (docIndex, e) => {
        this.props.selectDocs(e.target.checked, this.props.groupIndex, docIndex);
    };

    render() {
        const rows = this.getRows(this.props.documents);
        const allSelected = this.props.documents.every((x) => x.isSelected);
        const headers = this.getHeaderData(this.props.documents[0].metadata);

        return (
            <div className='document-details-grid'>
                <div className='table-responsive'>
                    <table className='table'>
                        <thead>
                            <tr>
                                <th>
                                    <input
                                        type='checkbox'
                                        disabled={this.props.selectedLimitReached && !allSelected}
                                        onChange={this.selectAll}
                                        checked={allSelected}
                                    />
                                </th>
                                {headers}
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>{rows}</tbody>
                    </table>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        selectDocs: (checked, groupIndex, docIndex) => dispatch(actions.selectImageDocs(checked, groupIndex, docIndex))
    };
};
export default connect(null, mapDispatchToProps)(SearchGridRowDetailsDocumentGrid);
