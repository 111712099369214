import { faSearch, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ChangeEvent, FormEvent, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generateUUID } from '../../../../../common/functions/utils';
import useTranslation from '../../../../../common/hooks/useTranslation';
import { ClientSelectionSlice, HydrationSlice } from '../../../../../common/models/ReduxSlices';
import {
    updateMainSearchFilter,
    updateSearchKeywords,
    updateSubmittedSearchKeywords
} from '../../../redux/reducers/completeEntryDataSlice';
import { RootState } from '../../../redux/store';
import { CASSModel } from '../../models/completeEntryDataModels';
import SearchChip from './SearchChip';
import './styles.css';

const SEARCH_TERMS_LIMIT = 10;

const areArraysEqual = (a: { id: string; text: string }[], b: { id: string; text: string }[]) =>
    a.length === b.length && a.every((element, index) => element.id === b[index].id);

const CADSearchBar = () => {
    const { user } = useSelector<RootState, HydrationSlice>((state) => state.hydration);
    const { initialClientSelection } = useSelector<RootState, ClientSelectionSlice>((state) => state.clientSelection);
    const { searchKeywords: reduxSearchKeywords } = useSelector<RootState, CASSModel>((state) => state.completeEntryData);
    const dispatch = useDispatch();

    const translate = useTranslation();

    const inputRef = useRef<HTMLInputElement>(null);
    const [searchKeywords, setSearchKeywords] = useState<{ id: string; text: string }[]>([]);
    const [searchInput, setSearchInput] = useState('');

    const placeholderText = !searchKeywords.length ? translate('InputPlaceholder_Label') : '';
    const remainingSpace = user?.PortalCompleteEntryDataSearchBarTextLimit
        ? user.PortalCompleteEntryDataSearchBarTextLimit - searchKeywords.reduce((acc, curr) => acc + curr.text.length, 0)
        : 0;

    const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;

        const truncatedVal = value.replace(/[^a-zA-Z0-9,_\s-]/g, '').slice(0, remainingSpace);

        setSearchInput(truncatedVal);
    };

    const handleClearSearch = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
        e.stopPropagation();

        resetSearchAndFilters();
        if (inputRef.current) {
            inputRef.current.blur();
        }
    };

    const resetSearchAndFilters = () => {
        setSearchInput('');
        setSearchKeywords([]);
        inputRef.current && inputRef.current.focus();

        dispatch(updateSubmittedSearchKeywords([]));
        return dispatch(updateMainSearchFilter([]));
    };

    const updateKeywords = (nextVal?: string) => {
        const trimmedSearchInput = nextVal ? nextVal.trim() : searchInput.trim();

        if (trimmedSearchInput === '') {
            setSearchInput('');
            return;
        }
        let newSearchKeywords = [...searchKeywords];

        if (trimmedSearchInput.includes(' ') || trimmedSearchInput.includes(',') || trimmedSearchInput.includes('	')) {
            const keywordArr = trimmedSearchInput.split(/[\s,]/).filter((keyword) => keyword !== '');

            keywordArr.forEach((keyword) => {
                if (keyword !== '') {
                    newSearchKeywords.push({ id: `keyword-${generateUUID()}`, text: keyword });
                }
            });
        } else {
            newSearchKeywords.push({ id: `keyword-${generateUUID()}`, text: trimmedSearchInput });
        }

        if (newSearchKeywords.length > 10) {
            newSearchKeywords = newSearchKeywords.slice(0, SEARCH_TERMS_LIMIT);
        }

        setSearchKeywords(newSearchKeywords);
        setSearchInput('');
    };

    const removeKeyword = (keywordId: string) => {
        setSearchKeywords(searchKeywords.filter(({ id }) => id !== keywordId));

        if (searchKeywords.length <= 1) {
            resetSearchAndFilters();
        }
    };

    const handlePasteInput = (e: React.ClipboardEvent<HTMLInputElement>) => {
        e.preventDefault();

        const paste = e.clipboardData.getData('text');
        const cleanString = paste?.replace(/[^a-zA-Z0-9,_\s-]/g, '');

        const truncatedPaste = cleanString?.slice(0, user?.PortalCompleteEntryDataSearchBarTextLimit);

        updateKeywords(truncatedPaste);
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        // revert last keyword to text on backspace
        if (searchInput === '' && e.key === 'Backspace') {
            if (searchKeywords.length !== 0) {
                const lastElement = searchKeywords.slice(-1);
                setSearchKeywords(searchKeywords.slice(0, -1));
                setSearchInput(lastElement[0].text);
            }
        }
    };

    const handleInputBlur = (e: React.FocusEvent<HTMLInputElement, Element>) => {
        if (searchInput) updateKeywords();
    };

    const handleSubmit = (val: { id: string; text: string }[] | undefined, e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        updateKeywords();

        const payload = val?.length ? val : searchKeywords;

        dispatch(updateMainSearchFilter(payload));
        dispatch(updateSubmittedSearchKeywords(searchKeywords));
    };

    useEffect(() => {
        // only update after user adds a space or comma
        // handle copied sheet cells containing tab separators
        if (searchInput.includes(' ') || searchInput.includes(',') || searchInput.includes('	')) updateKeywords();
    }, [searchInput, dispatch]);

    useEffect(() => {
        dispatch(updateSearchKeywords(searchKeywords));
    }, [searchKeywords, dispatch]);

    // Map local state to redux????
    useEffect(() => {
        if (!areArraysEqual(searchKeywords, reduxSearchKeywords)) setSearchKeywords(reduxSearchKeywords);
    }, [reduxSearchKeywords]);

    // Clear search bar when client selection changes
    useEffect(() => {
        setSearchKeywords([]);
        dispatch(updateSearchKeywords([]));
        setSearchInput('');
    }, [initialClientSelection]);

    useEffect(() => {
        if (!searchKeywords.length && inputRef.current)
            inputRef.current.placeholder = searchInput || searchKeywords.length > 0 ? '' : placeholderText;
    }, [searchKeywords]);

    return (
        <form
            role='search'
            onSubmit={handleSubmit.bind(null, undefined)}
            className='input-group pb-3 pt-3 pl-3 pr-3 container-fluid border-bottom flex-nowrap bg-white'
        >
            <div className='simplified-search-bar simplified-search-bar-with-button documents_search_bar'>
                <div className='chips-input-wrapper'>
                    <div className='magnifying-glass-input-icon documents_search_bar_icon'>
                        <FontAwesomeIcon icon={faSearch} />
                    </div>
                    <div className='documents_search_chips chips'>
                        {searchKeywords.map(({ id, text }) => (
                            <SearchChip
                                key={id}
                                id={id}
                                text={text}
                                remainingSpace={remainingSpace}
                                setSearchKeywords={setSearchKeywords}
                                onRemove={removeKeyword}
                            />
                        ))}
                        <input
                            type='text'
                            ref={inputRef}
                            className='documents_search_input form-control preserve-borders'
                            placeholder={placeholderText}
                            value={searchInput}
                            onChange={handleChangeInput}
                            onKeyDown={handleKeyDown}
                            onBlur={handleInputBlur}
                            onPaste={handlePasteInput}
                        />
                    </div>
                    <div className='justify-content-end'>
                        <span
                            role='button'
                            onClick={handleClearSearch}
                            className={`form-clear-simplified-search form-clear ${searchKeywords.length ? '' : 'd-none'}`}
                        >
                            <FontAwesomeIcon icon={faTimesCircle} />
                        </span>
                    </div>
                </div>
            </div>
            <div className='documents_search_bar_btn input-group-append'>
                <button type='submit' className='btn btn-primary d-flex align-items-center'>
                    {translate('SearchSubmitButton_Label')}
                </button>
            </div>
        </form>
    );
};

export default CADSearchBar;
