import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../axios-portal';
import { imagingPackageStatusType } from '../../common/constants/constants-portal';
import { sendToast } from '../reducers/toastSlice';
import { CHECK_DOWNLOAD_LOBBY, GET_DOWNLOAD_LOBBY } from './actionTypes';

export const CheckDownloadLobby = createAsyncThunk(CHECK_DOWNLOAD_LOBBY, async (payload: string, { dispatch, rejectWithValue }) => {
    try {
        const response = await axios.get<string>(`/Imaging/DownloadLobby?packageId=${payload}`);

        if (response.data === '') {
            dispatch(sendToast({ message: 'HeaderAndLineInfoNoData_Label', isLabel: true, type: 'error' }));

            return { ImageUrl: '', ImagingPackageStatusTypeID: 0, PackageId: payload, PackageTypeID: 0 };
        }

        const resData = JSON.parse(response.data) as {
            ImageUrl: string;
            ImagingPackageStatusTypeID: number;
            PackageId: number;
            PackageTypeID: number;
        };

        // Add packageId if error
        if (resData.ImagingPackageStatusTypeID === imagingPackageStatusType['FaultTerminated']) {
            if (!resData.ImageUrl) dispatch(sendToast({ message: 'DownloadLobbyErrorTitle_Label', isLabel: true, type: 'error' }));

            return {
                ...resData,
                PackageId: payload
            };
        }

        // Send success toast
        if (resData.ImagingPackageStatusTypeID === imagingPackageStatusType['Completed']) {
            await dispatch(GetInitialDownloadLobby()); // Refetch download lobby data
            dispatch(sendToast({ message: 'DocumentsDownloadReady_Label', isLabel: true, type: 'success' }));
        }

        return resData;
    } catch (error: any) {
        return rejectWithValue(error.response.status);
    }
});

export const GetInitialDownloadLobby = createAsyncThunk(GET_DOWNLOAD_LOBBY, async (payload, { rejectWithValue }) => {
    try {
        const response = await axios.get<string>(`/Imaging/DownloadLobbyUserInfo`);

        return JSON.parse(response.data) as {
            ImageUrl: string;
            ImagingPackageStatusTypeID: number;
            PackageId: number;
            PackageTypeID: number;
            CreatedDate: string;
            CountOfDocuments: number;
            EntryNumbers: string[];
        }[];
    } catch (error: any) {
        return rejectWithValue(error.response.status);
    }
});
