import { createSlice } from '@reduxjs/toolkit';
import type { UserTeamSlice } from '../../common/models/ReduxSlices';
import { GetUserTeamData } from '../actions/teamManagement';

const initialState: UserTeamSlice = {
    team: null,
    isLoading: true,
    error: undefined,
    editMember: null
};

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setEditMember(state, action) {
            state.editMember = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(GetUserTeamData.pending, (state, action) => {
                state.isLoading = true;
                state.error = undefined;
            })
            .addCase(GetUserTeamData.fulfilled, (state, action) => {
                state.isLoading = false;
                state.team = action.payload;
            })
            .addCase(GetUserTeamData.rejected, (state, action) => {
                state.isLoading = false;
                if (typeof action.payload === 'number') {
                    state.error = action.payload;
                } else {
                    state.error = action.error.message;
                }
            });
    }
});

export const selectEditMember = (state: UserTeamSlice) => state.editMember;

export const { setEditMember } = userSlice.actions;

export default userSlice.reducer;
