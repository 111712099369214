import { faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { REACT_APP_ASSETS_URL } from '../../constants/constants-portal';
import { HydrationSlice } from '../../models/ReduxSlices';
import Button from '../Button';
import Tooltip from '../Tooltip/Tooltip';

interface EmptyStateProps {
    onAdd: () => void;
    descriptionLabel: string;
    addButtonLabel: React.ReactNode;
    disabled?: boolean;
    isForClearances?: boolean;
}

const EmptyState = React.forwardRef<HTMLButtonElement, EmptyStateProps>(
    ({ onAdd, descriptionLabel, addButtonLabel, disabled, isForClearances }, ref) => {
        const { user } = useSelector<RootState, HydrationSlice>((state) => state.hydration);

        return (
            <div className='container-fluid mt-4 mb-4 d-flex flex-column justify-content-center align-items-center flex-fill'>
                <img
                    className='image-150px'
                    src={`${REACT_APP_ASSETS_URL}/assets/images/illustrations/objects/object-inbox-zero.svg`}
                    alt='Empty state'
                />
                <h3>{descriptionLabel}</h3>
                {!user?.InternalUser || isForClearances ? (
                    <Button
                        variant='primary'
                        size='medium'
                        ref={ref}
                        disabled={disabled}
                        onClick={() => (!user?.InternalUser || isForClearances) && onAdd()}
                    >
                        {/* InternalUser validation should be removed in the future, for now it is removed only for Clearances page */}
                        <FontAwesomeIcon icon={faUserPlus} className='mr-1' /> {addButtonLabel}
                    </Button>
                ) : (
                    <Tooltip label='Disabled for LII users.'>
                        <Button
                            variant='primary'
                            size='medium'
                            ref={ref}
                            disabled={disabled}
                            onClick={() => (!user?.InternalUser || isForClearances) && onAdd()}
                        >
                            {/* InternalUser validation should be removed in the future, for now it is removed only for Clearances page */}
                            <FontAwesomeIcon icon={faUserPlus} className='mr-1' /> {addButtonLabel}
                        </Button>
                    </Tooltip>
                )}
            </div>
        );
    }
);

export default EmptyState;
