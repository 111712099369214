import { configureStore } from '@reduxjs/toolkit';
import alertsReducer from '../../../redux/reducers/alertsSlice';
import clientSelectionReducer from '../../../redux/reducers/clientSelectionReducer';
import hydrationReducer from '../../../redux/reducers/hydrationSlice';
import languageReducer from '../../../redux/reducers/languageSlice';
import noticesReducer from '../../../redux/reducers/noticesSlice';
import portalSolutionsReducers from '../../../redux/reducers/portalSolutionsSlice';
import toastReducer from '../../../redux/reducers/toastSlice';
import translationsReducer from '../../../redux/reducers/translationsSlice';
import dateRangeReducer from '../../documents/redux/reducers/dateRangeSlice';
import imagingReducer from '../../documents/redux/reducers/imagingSlice';
//import completeEntryDataReducer from './reducers/completeEntryDataSlice';
import ellipsisReducer from '../../../redux/reducers/ellipsisSlice';
import completeEntryDataGridPreferencesReducer from './reducers/completeEntryDataGridPreferenceSlice';
import completeEntryDataGridSettingsReducer from './reducers/completeEntryDataGridSettingsSlice';
import completeEntryDataReducer from './reducers/completeEntryDataSlice';
import dateFilterReducer from './reducers/dateFilterSlice';
import locationFilterReducer from './reducers/locationFilterSlice';
import motFilterReducer from './reducers/motFilterSlice';

const store = configureStore({
    reducer: {
        // common slices
        hydration: hydrationReducer,
        translations: translationsReducer,
        language: languageReducer,
        alerts: alertsReducer,
        notices: noticesReducer,
        clientSelection: clientSelectionReducer,
        toast: toastReducer,
        solutionsMenuItems: portalSolutionsReducers,
        dateFilter: dateFilterReducer,
        ellipsis: ellipsisReducer,

        // page specific slices
        imaging: imagingReducer,
        dateRange: dateRangeReducer,
        completeEntryDataGridPreferences: completeEntryDataGridPreferencesReducer,
        completeEntryDataGridSettings: completeEntryDataGridSettingsReducer,
        completeEntryData: completeEntryDataReducer,
        locationFilter: locationFilterReducer,
        motFilter: motFilterReducer
    }
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export default store;
