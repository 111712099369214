import { useEffect, useState } from 'react';
import ErrorPage from '../../../common/features/ErrorPage';
import Spinner from '../../../common/features/Spinner';
import { fetchHtmlFile } from '../../../common/functions/utils';
import useTranslation from '../../../common/hooks/useTranslation';
import CollapsableCard from '../common/components/CollapsableCard';
import { useFaqSelector } from '../redux/hooks';

const createQuestions = (id: string, total: number) =>
    Array.from({ length: total }, (_, index) => ({
        question: `${id}-${index + 1}q`,
        answer: `${id}-${index + 1}a`
    }));

const SECTIONS = [
    {
        id: 'user-management',
        label: 'UserManagement_Label',
        questions: createQuestions('user-management', 3)
    },
    {
        id: 'password-mfa',
        label: 'PasswordMFA_Label',
        questions: createQuestions('password-mfa', 1)
    },
    {
        id: 'payments',
        label: 'Payments_Label',
        questions: createQuestions('payments', 7)
    },
    {
        id: 'snow',
        label: 'SNOW_Label',
        questions: createQuestions('snow', 1)
    }
];

export default function FaqGuideContent() {
    const { languageSelected } = useFaqSelector((state) => state.language);
    const languageCode = languageSelected?.code;

    const translate = useTranslation();

    const [{ isFetching, data, error }, setRequest] = useState<{ isFetching: boolean; data: null | typeof SECTIONS; error: null | string }>(
        {
            isFetching: false,
            data: null,
            error: null
        }
    );

    useEffect(() => {
        if (!languageCode) return;

        (async function () {
            try {
                setRequest({ isFetching: true, data: null, error: null });

                const results = await Promise.all(
                    SECTIONS.map(async (section) => {
                        const questionsWithContent = await Promise.all(
                            section.questions.map(async ({ question, answer }) => {
                                const questionFilePath = `/data/faq/${languageCode}/${question}.html`;
                                const answerFilePath = `/data/faq/${languageCode}/${answer}.html`;

                                const [questionContent, answerContent] = await Promise.all([
                                    fetchHtmlFile(questionFilePath),
                                    fetchHtmlFile(answerFilePath)
                                ]);

                                return {
                                    question: questionContent,
                                    answer: answerContent
                                };
                            })
                        );

                        return {
                            ...section,
                            questions: questionsWithContent
                        };
                    })
                );

                setRequest((prevState) => ({ ...prevState, data: results }));
            } catch (error) {
                setRequest((prevState) => ({ ...prevState, error: String(error) }));
            } finally {
                setRequest((prevState) => ({ ...prevState, isFetching: false }));
            }
        })();
    }, [languageCode]);

    if (isFetching) {
        return (
            <div className='d-flex justify-content-center align-items-center'>
                <Spinner />
            </div>
        );
    }

    if (error) {
        return <ErrorPage errorCode={500} withTranslations />;
    }

    return (
        <>
            {data?.map(({ id, label, questions }) => (
                <section key={id} className='d-flex flex-column my-4' style={{ gap: '1rem' }}>
                    <h2 className='mt-4'>{translate(label)}</h2>
                    {questions.map(({ question, answer }, index) => (
                        <CollapsableCard key={index} title={question}>
                            <div dangerouslySetInnerHTML={{ __html: answer }} />
                        </CollapsableCard>
                    ))}
                </section>
            ))}
        </>
    );
}
