import { ActionReducerMapBuilder, createSlice, PayloadAction, SliceCaseReducers, ValidateSliceCaseReducers } from '@reduxjs/toolkit';
import { ColumnSettings } from '../../common/models/GridPreferences';
import { GridPreferencesSlice } from '../../common/models/ReduxSlices';

export const genericGridPreferencesInitialState: GridPreferencesSlice = {
    allColumns: null,
    visibleColumns: null,
    columnsWidths: null,
    isLoading: false,
    areColumnsLoading: false,
    error: undefined,
    hasOwnColumnsOrder: false,
    columnOrderSaveCompleted: false,
    coulumnActivated: false
};

export const createGenericGridPreferencesSlice = <Reducers extends SliceCaseReducers<GridPreferencesSlice>>({
    name = '',
    initialState,
    reducers,
    extraReducers
}: {
    name: string;
    initialState: GridPreferencesSlice;
    reducers: ValidateSliceCaseReducers<GridPreferencesSlice, Reducers>;
    extraReducers: (builder: ActionReducerMapBuilder<GridPreferencesSlice>) => void;
}) => {
    return createSlice({
        name,
        initialState,
        reducers: {
            updateColumnOrder(state, action: PayloadAction<ColumnSettings[]>) {
                if (state.allColumns && state.visibleColumns) {
                    state.allColumns = state.allColumns.map((column) => ({
                        ...column,
                        Order: action.payload.find((item) => item.Code === column.Code)?.Order || 0
                    }));
                    state.visibleColumns = state.visibleColumns.map((column) => ({
                        ...column,
                        Order: action.payload.find((item) => item.Code === column.Code)?.Order || 0
                    }));
                }
            },
            resetGridPreferences() {
                return { ...initialState };
            },
            ...reducers
        },
        extraReducers
    });
};
