import { useDrag, useDrop } from 'react-dnd';

interface WaffleDraggableItemProps {
    item: any;
    index: number;
    style: React.CSSProperties;
    moveItem: (fromIndex: number, toIndex: number) => void;
    focusIndex: number | null;
    setFocusIndex: (focusIndex: number | null) => void;
}

const WaffleDraggableItem: React.FC<WaffleDraggableItemProps> = ({ item, index, moveItem, focusIndex, setFocusIndex }) => {
    const [, drag] = useDrag({
        type: 'CUSTOM_ITEM_TYPE',
        item: { index },
        collect: (monitor) => ({
            isDragging: !!monitor.isDragging()
        })
    });

    const [, drop] = useDrop({
        accept: 'CUSTOM_ITEM_TYPE',
        hover: (draggedItem: { index: number }) => {
            if (draggedItem.index !== index) {
                moveItem(draggedItem.index, index);
                draggedItem.index = index;
            }
        },

        drop: () => {
            setFocusIndex(index);
        }
    });

    return <div ref={(node) => drag(drop(node))}>{item}</div>;
};

export default WaffleDraggableItem;
