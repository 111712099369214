import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import type { ClientsAndPermissionsSlice } from '../../common/models/ReduxSlices';
import { GetCloneMemberDetails, GetEDMClientServices, GetTeamMemberDetails, InitNewTeamMember } from '../actions/teamManagement';

const initialState: ClientsAndPermissionsSlice = {
    data: null,
    adminRole: null,
    isPending: false,
    isLoading: false,
    isLoadingClientStatuses: undefined,
    error: undefined
};

const clientsAndPermissionsSlice = createSlice({
    name: 'clientsAndPermissions',
    initialState,
    reducers: {
        updateClientServicesCheckbox(
            state,
            action: PayloadAction<{
                number: string;
                field: string;
                isChecked: boolean;
                title: string;
                clientServicesIdx: number;
            }>
        ) {
            if (state.data?.ParentServices) {
                const parentServiceIdx =
                    state.data.ParentServices.findIndex((service) => service.Number + ' - ' + service.Name === action.payload.title) || 0;
                const clientServiceIdx = action.payload.clientServicesIdx;

                const featureIdx =
                    state.data.ParentServices[parentServiceIdx]?.ClientServices?.[clientServiceIdx]?.Features?.findIndex(
                        (feature: any) => feature.Name === action.payload.field
                    ) || 0;
                // Check the selected checkbox
                state.data.ParentServices[parentServiceIdx].ClientServices[clientServiceIdx].Features[featureIdx].IsSelected =
                    !action.payload.isChecked;
                // If the selected checkbox is not 'My Livingston Portal' feature then automatically select it as well
                if (
                    action.payload.isChecked === false &&
                    state.data.ParentServices[parentServiceIdx].ClientServices[clientServiceIdx].Features[
                        featureIdx
                    ].Name?.toLowerCase() !== 'my livingston portal' &&
                    state.data.ParentServices[parentServiceIdx].ClientServices[clientServiceIdx].Features.length > 1
                ) {
                    for (const feature of state.data.ParentServices[parentServiceIdx].ClientServices[clientServiceIdx].Features) {
                        if (feature.Name?.toLowerCase() === 'my livingston portal') {
                            feature.IsSelected = true;
                        }
                    }
                }
            }
        },
        toggleSelectAllClientServices(state, action: PayloadAction<{ title: string; featureName: string }>) {
            if (state.data?.ParentServices) {
                state.data.ParentServices = state.data.ParentServices.map((parentService) => {
                    if (parentService.Number + ' - ' + parentService.Name === action.payload.title) {
                        return {
                            ...parentService,
                            ClientServices: parentService.ClientServices?.map((clientService) => {
                                return {
                                    ...clientService,
                                    Features: clientService.Features?.map((feature) => {
                                        // If the selected checkbox is not 'My Livingston Portal' feature then automatically select it as well
                                        const allWithName = clientService.Features.filter((f) => f.Name === action.payload.featureName);
                                        const isAllNameSelected = allWithName.filter((f) => f.IsSelected).length === allWithName.length;
                                        const allWithMLP = clientService.Features.filter(
                                            (f) => f.Name?.toLowerCase() === 'my livingston portal'
                                        );
                                        const isAllMLPSelected = allWithMLP.filter((f) => f.IsSelected).length === allWithMLP.length;

                                        if (
                                            action.payload.featureName?.toLowerCase() !== 'my livingston portal' &&
                                            feature.Name?.toLowerCase() === 'my livingston portal' &&
                                            !isAllNameSelected &&
                                            !isAllMLPSelected
                                        )
                                            return { ...feature, IsSelected: !isAllNameSelected, selectAll: !isAllNameSelected };

                                        // Check all checkboxes by selected feature name
                                        const isFeatureMatch = feature.Name === action.payload.featureName;
                                        const isMLPFeature = action.payload.featureName.toLowerCase() === 'my livingston portal';
                                        const isClearancesFeature = action.payload.featureName.toLowerCase() === 'clearances';
                                        const isStatusCodeNameValid =
                                            !clientService.StatusCodeName ||
                                            (clientService.StatusCodeName && (isMLPFeature || isClearancesFeature));

                                        return isFeatureMatch && isStatusCodeNameValid
                                            ? { ...feature, IsSelected: !isAllNameSelected, selectAll: !isAllNameSelected }
                                            : feature;
                                    })
                                };
                            }),
                            AvailableFeatures: parentService.AvailableFeatures?.map((availableFeature) => {
                                return { ...availableFeature };
                            })
                        };
                    } else {
                        return parentService;
                    }
                });
            }
        },
        resetClientsAndPermissions() {
            return { ...initialState };
        },
        setIsClientStatusesLoading(state, action: PayloadAction<boolean>) {
            state.isLoadingClientStatuses = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(InitNewTeamMember.pending, (state, action) => {
                state.isLoading = true;
                state.error = undefined;
            })
            .addCase(InitNewTeamMember.fulfilled, (state, action) => {
                state.isLoading = false;
                if (action.payload.ParentServices) {
                    state.data = { ParentServices: action.payload.ParentServices };
                }
                if (action.payload.Users?.length) {
                    state.isPending =
                        action.payload.Users?.[0]?.Status === 'Pending' ||
                        action.payload.Users?.[0]?.Status === 'PendingUserConfirmRequired';
                }
                if (action.payload.AdminRole) {
                    state.adminRole = action.payload.AdminRole;
                }
            })
            .addCase(InitNewTeamMember.rejected, (state, action) => {
                state.isLoading = false;
                state.isPending = initialState.isPending;
                state.adminRole = initialState.adminRole;
                if (typeof action.payload === 'number') {
                    state.error = action.payload;
                } else {
                    state.error = action.error.message;
                }
            })
            .addCase(GetCloneMemberDetails.pending, (state, action) => {
                state.isLoading = true;
                state.error = undefined;
            })
            .addCase(GetCloneMemberDetails.fulfilled, (state, action) => {
                state.isLoading = false;
                if (action.payload.ParentServices) {
                    state.data = { ParentServices: action.payload.ParentServices };
                }
                if (action.payload.Users?.length) {
                    state.isPending =
                        action.payload.Users?.[0]?.Status === 'Pending' ||
                        action.payload.Users?.[0]?.Status === 'PendingUserConfirmRequired';
                }
                if (action.payload.AdminRole) {
                    state.adminRole = action.payload.AdminRole;
                }
            })
            .addCase(GetCloneMemberDetails.rejected, (state, action) => {
                state.isLoading = false;
                state.isPending = initialState.isPending;
                state.adminRole = initialState.adminRole;
                if (typeof action.payload === 'number') {
                    state.error = action.payload;
                } else {
                    state.error = action.error.message;
                }
            })
            .addCase(GetTeamMemberDetails.pending, (state, action) => {
                state.isLoading = true;
                state.error = undefined;
            })
            .addCase(GetTeamMemberDetails.fulfilled, (state, action) => {
                state.isLoading = false;
                if (action.payload.ParentServices) {
                    state.data = { ParentServices: action.payload.ParentServices };
                }
                if (action.payload.Users?.length) {
                    state.isPending =
                        action.payload.Users?.[0]?.Status === 'Pending' ||
                        action.payload.Users?.[0]?.Status === 'PendingUserConfirmRequired';
                }
                if (action.payload.AdminRole) {
                    state.adminRole = action.payload.AdminRole;
                }
            })
            .addCase(GetTeamMemberDetails.rejected, (state, action) => {
                state.isLoading = false;
                state.isPending = initialState.isPending;
                state.adminRole = initialState.adminRole;
                if (typeof action.payload === 'number') {
                    state.error = action.payload;
                } else {
                    state.error = action.error.message;
                }
            })
            .addCase(GetEDMClientServices.pending, (state, action) => {
                state.isLoadingClientStatuses = true;
            })
            .addCase(GetEDMClientServices.fulfilled, (state, action) => {
                state.isLoadingClientStatuses = false;
                if (action.payload && state.data) {
                    const newParentServices = state.data.ParentServices;
                    newParentServices.forEach((parentService) => {
                        if (parentService.Number) {
                            const statusCodeName = action.payload[parentService.Number];
                            if (statusCodeName) {
                                parentService.ClientServices.forEach((clientService) => {
                                    clientService.StatusCodeName = statusCodeName;
                                });
                            }
                        }
                    });

                    state.data.ParentServices = newParentServices;
                }
            })
            .addCase(GetEDMClientServices.rejected, (state, action) => {
                if (typeof action.payload === 'number') {
                    state.error = action.payload;
                } else {
                    state.error = action.error.message;
                }
            });
    }
});

export const { updateClientServicesCheckbox, toggleSelectAllClientServices, resetClientsAndPermissions, setIsClientStatusesLoading } =
    clientsAndPermissionsSlice.actions;

export default clientsAndPermissionsSlice.reducer;
