import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useRef } from 'react';
import ReactDOM from 'react-dom';
import useGTMEventTracking from '../../../../common/hooks/useGTMEventTracking';

interface FixedFooterWhiteProps {
    feedbackText?: string;
    primaryLabel?: string;
    primaryIcon?: any;
    primaryDisabled?: boolean;
    secondaryLabel?: string;
    secondaryIcon?: any;
    message?: string;
    primaryOnClick?: () => void;
    secondaryOnClick?: () => void;
}

const FixedFooterWhite: React.FC<FixedFooterWhiteProps> = ({
    feedbackText,
    primaryLabel,
    primaryIcon,
    primaryOnClick,
    primaryDisabled = false,
    secondaryLabel,
    secondaryIcon,
    secondaryOnClick,
    message = ''
}) => {
    const addUserTeamConfirmRef = useRef(null);
    useGTMEventTracking('Confirm_User_Team_CPEvt', { location: 'Profile' }, addUserTeamConfirmRef);

    const content = (
        <div id='FixedFooter' className='bg-white shadow-lg'>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col'>
                        <div className='d-flex flex-column flex-md-row align-items-md-center align-items-stretch justify-content-md-end fixed-bar-without-sidebar py-3'>
                            {feedbackText && <div className='helper-text mr-md-auto mb-3 mb-md-0 mt-md-0'>{feedbackText}</div>}
                            <div className='d-flex flex-column flex-sm-row'>
                                {secondaryLabel && (
                                    <button type='button' className='btn btn-tertiary-blue btn-lg m-1 flex-fill' onClick={secondaryOnClick}>
                                        {secondaryIcon && <FontAwesomeIcon icon={secondaryIcon} className='mr-1' />}
                                        {secondaryLabel}
                                    </button>
                                )}
                                {message?.length! > 1 && (
                                    <div className='d-flex justify-content-center align-items-center text-danger mr-2 mb-2'>{message}</div>
                                )}

                                {primaryLabel && (
                                    <button
                                        type='button'
                                        className='btn btn-primary btn-lg m-1 flex-fill'
                                        ref={addUserTeamConfirmRef}
                                        onClick={primaryOnClick}
                                        disabled={primaryDisabled}
                                    >
                                        {primaryIcon && <FontAwesomeIcon icon={primaryIcon} className='mr-1' />}
                                        {primaryLabel}
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

    const fixedFooterContainingElement = document.querySelector('.navbar-and-stage-wrapper');

    if (fixedFooterContainingElement) return ReactDOM.createPortal(content, fixedFooterContainingElement);

    return content;
};

export default FixedFooterWhite;
