import { configureStore } from '@reduxjs/toolkit';
import alertsReducer from '../../../redux/reducers/alertsSlice';
import clientSelectionReducer from '../../../redux/reducers/clientSelectionReducer';
import hydrationReducer from '../../../redux/reducers/hydrationSlice';
import languageReducer from '../../../redux/reducers/languageSlice';
import noticesReducer from '../../../redux/reducers/noticesSlice';
import portalSolutionsReducers from '../../../redux/reducers/portalSolutionsSlice';
import toastReducer from '../../../redux/reducers/toastSlice';
import translationsReducer from '../../../redux/reducers/translationsSlice';
import profileGridSettingsReducer from './reducers/profileGridSettingsSlice';
import profileReducer from './reducers/profileSlice';
import requestAnUpdateReducer from './reducers/requestAnUpdateSlice';
import updateSubscriptionsByUserAndClientReducer from './reducers/subscriptionsByUserAndClientSlice';
import subscriptionsReducer from './reducers/subscriptionsSlice';
import ticketsReducer from './reducers/ticketsSlice';

const store = configureStore({
    reducer: {
        // common slices
        hydration: hydrationReducer,
        translations: translationsReducer,
        language: languageReducer,
        alerts: alertsReducer,
        notices: noticesReducer,
        clientSelection: clientSelectionReducer,
        toast: toastReducer,
        solutionsMenuItems: portalSolutionsReducers,

        // page specific slices
        profile: profileReducer,
        subscriptions: subscriptionsReducer,
        requestAnUpdate: requestAnUpdateReducer,
        profileGridSettings: profileGridSettingsReducer,
        tickets: ticketsReducer,
        updateSubscriptionsByUserAndClient: updateSubscriptionsByUserAndClientReducer
    }
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export default store;
