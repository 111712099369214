import { faUndo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { CSSProperties } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { REACT_APP_ASSETS_URL } from '../../../../common/constants/constants-portal';
import Button from '../../../../common/features/Button';
import useTranslation from '../../../../common/hooks/useTranslation';
import { ClientSelectionSlice } from '../../../../common/models/ReduxSlices';
import { caBullet, other } from '../../common/constants/complete-entry-data-constants';
import { LocationFilterSlice } from '../../common/models/ReduxSlices';
import { CASSModel } from '../../common/models/completeEntryDataModels';
import { resetShipmentGridSettings } from '../../redux/reducers/completeEntryDataGridSettingsSlice';
import {
    updateDateFilter,
    updateLocationTypeFilter,
    updateMOTFilter,
    updateMainSearchFilter,
    updateSearched,
    updateSelectLocationFilter,
    updateShouldSubmitSearch,
    updateSubmittedSearchKeywords
} from '../../redux/reducers/completeEntryDataSlice';
import { resetDateFilters, updateIsDateOpen } from '../../redux/reducers/dateFilterSlice';
import { resetLocationFilters, updatedLocationIsFiltered } from '../../redux/reducers/locationFilterSlice';
import { resetMotFilters } from '../../redux/reducers/motFilterSlice';
import { RootState } from '../../redux/store';

interface NoDataProps {
    countrySelected?: string | undefined;
    numbersSearched?: string[] | undefined;
}

const NoShipmentData = () => {
    const { clientSelection } = useSelector<RootState, ClientSelectionSlice>((state) => state.clientSelection);
    const { locationTypeSelected } = useSelector<RootState, LocationFilterSlice>((state) => state.locationFilter);
    const { submitedSearchKeywords } = useSelector<RootState, CASSModel>((state) => state.completeEntryData);

    const dispatch = useDispatch();
    const translate = useTranslation();
    const containerStyle: CSSProperties = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        width: '100%',
        marginLeft: '7%',
        marginRight: '5%'
        //marginTop: '2%'
    };

    const handleResetSearch = () => {
        dispatch(resetLocationFilters());
        dispatch(resetDateFilters());
        dispatch(
            updateDateFilter({
                attribute: 'Date',
                selectedOption: '',
                selectedValue: '',
                endDate: '',
                startDate: ''
            })
        );
        dispatch(resetMotFilters());
        dispatch(updateMOTFilter([]));
        dispatch(resetShipmentGridSettings());
        dispatch(updateSelectLocationFilter({ attribute: locationTypeSelected?.attribute, selectedArray: [] }));
        dispatch(updateLocationTypeFilter(''));
        dispatch(updateSubmittedSearchKeywords([]));
        dispatch(updateMainSearchFilter([]));
        dispatch(updateSearched(false));
        dispatch(updateIsDateOpen(false));
        dispatch(updatedLocationIsFiltered(false));
        dispatch(updateShouldSubmitSearch(true));
    };

    return (
        <>
            <img
                alt='Not found error'
                className='image-150px mt-5'
                src={`${REACT_APP_ASSETS_URL}/assets/images/illustrations/scenes/scene-error-404-page-not-found.svg`}
                style={{ marginBottom: '1%' }}
            />
            <div>
                <h2 className='mb-2'>
                    {`${translate('NoMatchingResults_Label')} ${submitedSearchKeywords.length > 0 ? translate('For_Label') : ''} `}
                    {submitedSearchKeywords?.map(({ id, text }) => (
                        <React.Fragment key={id}>
                            <span style={{ backgroundColor: 'rgb(242, 243, 185)' }}>{`‘${text}’`}</span>
                            <span> </span>
                        </React.Fragment>
                    ))}
                </h2>
            </div>
            {clientSelection?.ClientServices.length === 0 && (
                <h3 style={{ marginTop: '0.5%' }}>{`${translate('AdvancedSearchResultsBlankState_Label')} 
                ${translate('NoClientsSelected_Label')}`}</h3>
            )}
            {clientSelection?.ClientServices.length !== 0 && (
                <>
                    <h3 style={{ marginTop: '0.5%' }}>{translate('SearchTerms_Label')}</h3>
                    <ul style={{ marginBottom: '0' }}>
                        {caBullet.map((x) => (
                            <li key={translate(x)}>{translate(x)}</li>
                        ))}
                    </ul>
                </>
            )}
            <h3 style={{ marginTop: '0.5%' }}>{translate('OtherSuggestions_Label')}</h3>
            <ul style={{ marginBottom: '.7vh' }}>
                {other.map((x) => (
                    <li key={translate(x)}>{translate(x)}</li>
                ))}
            </ul>
            <Button variant='link' onClick={handleResetSearch}>
                <FontAwesomeIcon icon={faUndo} /> {translate('ClearSearch_Label')}
            </Button>
        </>
    );
};

export default NoShipmentData;
