import useTranslation from '../../../../common/hooks/useTranslation';
import classes from './ClientsAndPermissionsTableHeading.module.css';

interface ClientsAndPermissionsTableHeadingProps {
    adminRole: string | null | undefined;
    featureName: string;
    title: string;
    isDisabled?: boolean;
    onToggleSelectAll: (options: { title: string; featureName: string }) => void;
}

const ClientsAndPermissionsTableHeading: React.FC<ClientsAndPermissionsTableHeadingProps> = ({
    adminRole,
    featureName,
    title,
    isDisabled,
    onToggleSelectAll
}) => {
    const translate = useTranslation();

    return (
        <div className='d-flex flex-column align-items-center'>
            <div className='text-center pb-1'>
                {adminRole === 'CentralAdmin' && featureName === 'My Livingston Portal' ? 'Clearances' : featureName}
            </div>
            <button
                type='button'
                className={`btn p-0 table-column-select-all ${classes.btn}`}
                disabled={isDisabled}
                onClick={onToggleSelectAll.bind(null, { title, featureName })}
            >
                {translate('SelectDeselectAll_Label')}
            </button>
        </div>
    );
};

export default ClientsAndPermissionsTableHeading;
