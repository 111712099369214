export const GET_SHIPMENT_LIST = 'GET_SHIPMENT_LIST';
export const GET_CLEARANCE_DETAILS = 'GET_CLEARANCE_DETAILS';
export const GET_CLEARANCE_SUBSCRIBERS = 'GET_CLEARANCE_SUBSCRIBERS';
export const UPDATE_CLEARANCE_SUBSCRIBERS = 'UPDATE_CLEARANCE_SUBSCRIBERS';
export const GET_NOTIFICATIONS_SETTINGS = 'GET_NOTIFICATIONS_SETTINGS';
export const UPDATE_NOTIFICATIONS_SETTINGS = 'UPDATE_NOTIFICATIONS_SETTINGS';
export const GET_CAD_GRID_PREFERENCES = 'GET_CLERANCES_GRID_PREFERENCES';
export const SAVE_CAD_GRID_PREFERENCES = 'SAVE_CLERANCES_GRID_PREFERENCES';
export const GET_SHIPMENT_DATA_SOURCE = 'GET_SHIPMENT_DATA_SOURCE';
export const SET_SHIPMENT_DATA_SOURCE = 'SET_SHIPMENT_DATA_SOURCE';
export const GET_CASS_INDEX = 'GET_CASS_INDEX';
export const SUBMIT_SEARCH = 'SUBMIT_SEARCH';
export const DOWNLOAD_ENTRIES = 'DOWNLOAD_ENTRIES';
