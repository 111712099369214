import { configureStore } from '@reduxjs/toolkit';
import alertsReducer from '../../../redux/reducers/alertsSlice';
import hydrationReducer from '../../../redux/reducers/hydrationSlice';
import languageReducer from '../../../redux/reducers/languageSlice';
import noticesReducer from '../../../redux/reducers/noticesSlice';
import portalSolutionsReducers from '../../../redux/reducers/portalSolutionsSlice';
import toastReducer from '../../../redux/reducers/toastSlice';
import translationsReducer from '../../../redux/reducers/translationsSlice';
import clientsAndPermissionsReducer from './reducers/clientsAndPermissionsSlice';
import editTeamMemberReducer from './reducers/editTeamMemberSlice';
import statusUserReducer from './reducers/statusUserSlice';
import teamGridSettingsReducer from './reducers/teamGridSettingsSlice';
import teamSearchFilterReducer from './reducers/teamSearchFilterSlice';
import teamStatusFilterReducer from './reducers/teamStatusFilterSlice';
import userDetailsReducer from './reducers/userDetailsSlice';
import userReducer from './reducers/userSlice';

const store = configureStore({
    reducer: {
        // common slices
        hydration: hydrationReducer,
        translations: translationsReducer,
        language: languageReducer,
        alerts: alertsReducer,
        notices: noticesReducer,
        toast: toastReducer,
        solutionsMenuItems: portalSolutionsReducers,

        // page specific slices
        user: userReducer,
        teamStatusFilter: teamStatusFilterReducer,
        teamSearchFilter: teamSearchFilterReducer,
        teamGridSettings: teamGridSettingsReducer,
        statusUser: statusUserReducer,
        userDetails: userDetailsReducer,
        editTeamMember: editTeamMemberReducer,
        clientsAndPermissions: clientsAndPermissionsReducer
    }
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export default store;
