import type { GridCellProps } from '@progress/kendo-react-grid';
import { useHistory } from 'react-router-dom';
import ActionButtonElipsis from '../../../../../common/features/Grid/ActionButtonElipsis';
import useTranslation from '../../../../../common/hooks/useTranslation';
import { ActionButtonOptions } from '../../../common/constants/constants-team';
import { useTeamDispatch, useTeamSelector } from '../../../redux/hooks';
import { openStatusModal, setIsActive, setUserId } from '../../../redux/reducers/statusUserSlice';
import { setEditMember } from '../../../redux/reducers/userSlice';

type ActionButtonOption = Omit<(typeof ActionButtonOptions)[number], 'label'> & { label: string };

export const ActionButton: React.FC<GridCellProps> = ({ dataItem, ...rest }) => {
    const { isActive } = useTeamSelector((state) => state.statusUser);
    const translate = useTranslation();
    const dispatch = useTeamDispatch();
    const { push } = useHistory();

    const availableActionButtonOptions = ActionButtonOptions.reduce<ActionButtonOption[]>((acc, option) => {
        const shouldInclude = isActive ? option.id !== 'REACTIVATE_USER' : option.id !== 'DEACTIVATE_USER';

        if (shouldInclude) {
            acc.push({ ...option, label: translate(option.label) });
        }

        return acc;
    }, []);

    const handleToggleActionPanel = () => {
        dispatch(setUserId(dataItem?.UserId));
        dispatch(setIsActive(dataItem?.IsActive));
    };

    const handleAction = (value: string) => {
        const optionId = value as (typeof availableActionButtonOptions)[number]['id'];
        switch (optionId) {
            case 'EDIT_USER': {
                dispatch(setEditMember(dataItem));
                return push(`/Team/TeamMember?userId=${dataItem?.UserId}`);
            }
            case 'CLONE_USER': {
                return push(`/Team/CloneNewUser?userId=${dataItem?.UserId}`);
            }
            case 'DEACTIVATE_USER': {
                dispatch(openStatusModal());
                return push('/Team');
            }
            case 'REACTIVATE_USER': {
                dispatch(openStatusModal());
                return push('/Team');
            }
            default:
                break;
        }
    };

    return (
        <ActionButtonElipsis
            options={availableActionButtonOptions}
            dangerOption={'DEACTIVATE_USER'}
            onAction={handleAction}
            onToggleActionPanel={handleToggleActionPanel}
            dataItem={dataItem}
            {...rest}
        />
    );
};
