import { createSlice } from '@reduxjs/toolkit';
import { TranslationsSlice } from '../../common/models/ReduxSlices';
import { ChangeLanguage, GetTranslations } from '../actions/translations';

const initialState: TranslationsSlice = {
    translations: null,
    isLoading: true,
    error: undefined
};

const translationsSlice = createSlice({
    name: 'translations',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(GetTranslations.pending, (state, action) => {
                state.isLoading = true;
                state.error = undefined;
            })
            .addCase(GetTranslations.fulfilled, (state, action) => {
                state.isLoading = false;
                state.translations = action.payload;
            })
            .addCase(GetTranslations.rejected, (state, action) => {
                state.isLoading = false;
                if (typeof action.payload === 'number') {
                    state.error = action.payload;
                } else {
                    state.error = action.error.message;
                }
            })
            .addCase(ChangeLanguage.pending, (state, action) => {
                state.error = undefined;
            })
            .addCase(ChangeLanguage.fulfilled, (state, action) => {
                state.translations = action.payload.PhraseGroup;
            })
            .addCase(ChangeLanguage.rejected, (state, action) => {
                if (typeof action.payload === 'number') {
                    state.error = action.payload;
                } else {
                    state.error = action.error.message;
                }
            });
    }
});

export default translationsSlice.reducer;
