import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ViewDocumentsReduxDocument {
    _metadataFormat: string;
    _fileS3Path: string;
    _imageDate: string;
    secondaryDescription: string | undefined;
    version: string | undefined;
}

export interface ViewDocumentsRedux {
    entry: string;
    documents: ViewDocumentsReduxDocument[];
}

export interface ViewImagesSlice {
    viewImages: ViewDocumentsRedux[];
    hideMenu: boolean;
    openedLocation: string;
}

const initialState: ViewImagesSlice = {
    viewImages: [],
    hideMenu: false,
    openedLocation: ''
};

const viewImagesSlice = createSlice({
    name: 'viewImages',
    initialState,
    reducers: {
        addViewImagesData(state, action: PayloadAction<{ viewImages: ViewDocumentsRedux[]; openedLocation: string }>) {
            state.viewImages = action.payload.viewImages;
            state.openedLocation = action.payload.openedLocation;
        },
        removeViewImagesData(state) {
            state.viewImages = [];
        },
        hideMenuData(state, action: PayloadAction<boolean>) {
            state.hideMenu = action.payload;
        }
    }
});

export const { addViewImagesData, removeViewImagesData, hideMenuData } = viewImagesSlice.actions;

export default viewImagesSlice.reducer;
