import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import classes from './ExpandableAlertCard.module.css';

interface ExpandableAlertCardProps {
    title: React.ReactNode;
    children: React.ReactNode;
}

export default function ExpandableAlertCard({ title, children }: ExpandableAlertCardProps) {
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleExpand = () => {
        setIsExpanded((prevState) => !prevState);
    };

    return (
        <div className={`alert alert-danger my-2 ${classes.container}`}>
            <div className='row'>
                {title}
                <div className='d-sm-none d-block w-100' />
                <div className='col col-sm-2 d-flex justify-content-sm-end justify-content-center align-items-md-center align-items-top'>
                    <button
                        type='button'
                        className={`d-flex align-items-center text-link font-weight-normal p-0 m-0 ${classes['btn-expand']}`}
                        onClick={toggleExpand}
                    >
                        <span className='mr-2'>View Livingston hold info</span>
                        <FontAwesomeIcon icon={faCaretDown} className={isExpanded ? classes.expanded : undefined} />
                    </button>
                </div>
            </div>
            {isExpanded && <div className='text-lii-text mt-4 mb-0'>{children}</div>}
        </div>
    );
}
