import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Spinner from '../../../../../common/features/Spinner';
import { fetchHtmlFile } from '../../../../../common/functions/utils';
import { LanguageSlice } from '../../../../../common/models/ReduxSlices';
import ExpandableAlertCard from '../../../common/features/ExpandableAlertCard/ExpandableAlertCard';
import { parseDateTimeET } from '../../../common/functions/parser';
import { ShipmentDetailsSlice } from '../../../common/models/ReduxSlices';
import { RootState } from '../../../redux/store';

export default function BrokerHolds() {
    const { shipmentDetails } = useSelector<RootState, ShipmentDetailsSlice>((state) => state.shipmentDetails);

    const { languageSelected } = useSelector<RootState, LanguageSlice>((state) => state.language);
    const languageCode = languageSelected?.code;

    const [{ isFetching, data, error }, setRequest] = useState<{ isFetching: boolean; data: null | string[]; error: null | string }>({
        isFetching: false,
        data: null,
        error: null
    });

    useEffect(() => {
        if (!languageCode || !shipmentDetails?.BHs.length) return;

        (async function () {
            try {
                setRequest({ isFetching: true, data: null, error: null });

                const sanitizedHTMLFiles = await Promise.all(
                    shipmentDetails?.BHs.map(({ MessageFile }) => fetchHtmlFile(`/data/broker-holds/${languageCode}/${MessageFile}.html`))
                );

                setRequest((prevState) => ({ ...prevState, data: sanitizedHTMLFiles }));
            } catch (error) {
                setRequest((prevState) => ({ ...prevState, error: String(error) }));
            } finally {
                setRequest((prevState) => ({ ...prevState, isFetching: false }));
            }
        })();
    }, [languageCode, shipmentDetails?.BHs]);

    if (isFetching) {
        return (
            <div className='d-flex justify-content-center align-items-center'>
                <Spinner />
            </div>
        );
    }

    if (error) {
        console.error(error);
        return null;
    }

    return (
        <div>
            {data &&
                shipmentDetails?.BHs.map(({ CustomsInfoBrokerHoldID, MessageTitle, HeldOn }, index) => (
                    <ExpandableAlertCard
                        key={CustomsInfoBrokerHoldID}
                        title={
                            <>
                                <div className='col-sm-7 col-8 d-flex'>
                                    <FontAwesomeIcon icon={faInfoCircle} className='mr-3 mt-1' />
                                    <div>
                                        <p className='mb-0'>
                                            <strong className='mr-1'>Shipment on hold by Livingston:</strong>
                                            {MessageTitle}
                                        </p>
                                        <p className='mb-sm-0'>Expand for additional details and directions.</p>
                                    </div>
                                </div>
                                <p className='col-sm-3 col-4 d-flex align-items-md-center mb-0'>{parseDateTimeET(HeldOn)}</p>
                            </>
                        }
                    >
                        <div dangerouslySetInnerHTML={{ __html: data[index] }} />
                    </ExpandableAlertCard>
                ))}
        </div>
    );
}
