import { GridCellProps } from '@progress/kendo-react-grid';
import { Checkbox } from '@progress/kendo-react-inputs';
import React from 'react';
import useTranslation from '../../../../../common/hooks/useTranslation';
import { TransformedSubscriber } from '../../../common/models/Subscriber';
import { ActionButton } from './ActionButton';

interface DataItem extends TransformedSubscriber {
    selected?: boolean;
    isNew?: boolean;
}

interface FormattedGridCellProps extends GridCellProps {
    dataItem: DataItem;
    onRemoveSubscriber: (id?: null | number) => void;
    isLocked: boolean;
    onSelectRow: (subscriber: any) => void;
}

const FormattedGridCell: React.FC<FormattedGridCellProps> = ({
    field = '',
    dataItem,
    isLocked,
    selectionChange,
    className,
    onSelectRow,
    style,
    ...rest
}) => {
    let cellContent;
    const translate = useTranslation();

    switch (field) {
        case 'FullName':
            cellContent = dataItem?.FullName;
            break;
        case 'Actions':
            cellContent = dataItem.IsEditable ? <ActionButton dataItem={dataItem} {...rest} disabled={isLocked} /> : null;
            break;
        case 'Email':
            cellContent = dataItem?.Email;
            break;
        case 'Type':
            cellContent = dataItem?.Type;
            break;
        case 'Language':
            cellContent = dataItem?.Language;
            break;
        case 'Role':
            cellContent = dataItem.Role ? translate(dataItem?.Role + '_Label') : '';
            break;
        case 'MobileNumberOnlyNumbers':
            cellContent = dataItem?.MobileNumber;
            break;
        case 'selected':
            cellContent = <Checkbox value={!!dataItem.selected} onChange={selectionChange} disabled={!dataItem.IsEditable} />;
            break;
        default:
            cellContent = null;
    }

    return (
        <td
            onClick={() => {
                if (field !== 'selected' && field !== 'Actions') onSelectRow(dataItem);
            }}
            className={`${className} ${dataItem?.isNew ? 'new-row-highlight' : ''}`}
            style={{
                ...style,
                backgroundColor: dataItem?.selected ? '#DDE9F9' : '',
                cursor: `${field !== 'selected' ? 'pointer' : 'default'}`
            }}
        >
            {cellContent}
        </td>
    );
};

export default FormattedGridCell;
