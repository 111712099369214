import { ClientSelectionData } from '../../../models/ResponseData';
import { type ClientChooserRow, type ClientChooserRowOption } from '../ClientChooser';

export const convertSelectedClients = (clients: ClientSelectionData['ClientServiceSelection']['ClientServices']): ClientChooserRow[] => {
    if (!clients.length) return [];

    const uniqueArr = formatClients(clients) as ClientSelectionData['ClientServiceSelection']['ClientServices'];

    const convertedArr: ClientChooserRow[] = uniqueArr.map((c1) => ({
        name: c1.Name || 'Null',
        country: c1.CountryCode,
        number: c1?.Number,
        fullNumber: c1?.FullNumber,
        options: clients.reduce((acc, c2) => {
            if (c2.Number === c1.Number) {
                acc.push({
                    name: c2.Name || 'Null',
                    selected: c2.IsSelected,
                    caption: c2?.Caption,
                    fullNumber: c2.FullNumber
                });
            }
            return acc;
        }, [] as ClientChooserRowOption[])
    }));

    return convertedArr;
};

export const revertSelectedClients = (
    clients: ClientChooserRow[],
    reduxClients: ClientSelectionData['ClientServiceSelection']['ClientServices']
): ClientSelectionData['ClientServiceSelection']['ClientServices'] => {
    if (!clients.length) return [];

    const revertedArr: ClientSelectionData['ClientServiceSelection']['ClientServices'] = reduxClients.map((country) => ({
        ...country,
        IsSelected:
            clients.find(({ number }) => number === country.Number)?.options.find(({ caption }) => caption === country.Caption)?.selected ||
            false
    }));

    return revertedArr;
};

// Formats initial client services array that we get from API
export const formatClients = (
    clients: ClientSelectionData['ClientServiceSelection']['ClientServices']
): ClientSelectionData['ClientServiceSelection']['ClientServices'] => {
    const uniqueArr = [] as ClientSelectionData['ClientServiceSelection']['ClientServices'];

    clients
        // Remove any without data
        .filter(({ Number, Caption, CountryCode, FullNumber }) => Number && Caption && CountryCode && FullNumber)
        // Filter only unique
        .filter(function (item) {
            const i = uniqueArr.findIndex((x) => x.FullNumber === item.FullNumber || x.Number === item.Number);
            if (i <= -1) {
                uniqueArr.push(item);
            }
            return null;
        });

    return uniqueArr;
};
