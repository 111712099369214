import { faFolder, faFolderOpen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GridCellProps } from '@progress/kendo-react-grid';
import { Checkbox, CheckboxChangeEvent } from '@progress/kendo-react-inputs';
import { memo } from 'react';
import CarrierIcon from '../../../../common/features/CarrierIcon';
import Tooltip from '../../../../common/features/Tooltip/Tooltip';
import useTranslation from '../../../../common/hooks/useTranslation';
import { convertDateMDY } from '../../utils/imaging-utils';
import { MOTCodes, MOTMap } from '../constants/constants-documents';
import { GroupedDocuments } from '../models/ImagingModel';
import ActionButton from './ActionButton';

interface FormattedGridCellProps extends GridCellProps {
    dataItem: GroupedDocuments;
    checkboxChange?: (payload: CheckboxChangeEvent) => void;
    selectedEntries?: string[];
    country: string;
    modalView: null | string;
    setModalView: (data: null | string) => void;
    setEllipsisCompareView: () => void;
}

const FormattedGridCell = memo(function FormattedGridCell({
    field = '',
    dataItem,
    checkboxChange,
    selectionChange,
    selectedEntries,
    className,
    style,
    country,
    modalView,
    setModalView,
    setEllipsisCompareView,
    ...rest
}: FormattedGridCellProps) {
    const entryTransaction = Object.keys(dataItem)[0];
    const documentData = Object.values(dataItem)[0][0];
    const translate = useTranslation();
    let cellContent: any;

    if (country === 'us') {
        switch (field) {
            case 'EntryTransactionNo':
                cellContent = (
                    <>
                        <FontAwesomeIcon
                            icon={modalView === entryTransaction ? faFolderOpen : faFolder}
                            className={`${modalView === entryTransaction ? '' : 'text-lii-text-light'} mr-2`}
                        />
                        {entryTransaction}
                    </>
                );
                break;
            case 'InvoiceNo':
                cellContent = documentData._branchInvoiceNumber;
                break;
            case 'InvoiceDate':
                cellContent = convertDateMDY(documentData.USINVD);
                break;
            case 'EstimatedArrival':
                cellContent = convertDateMDY(documentData.USARVD);
                break;
            case 'MasterBOLNo':
                cellContent = documentData.USMBILL;
                break;
            case 'DocumentStatus':
                cellContent = translate(documentData?.USSTAT);
                break;
            case 'ClientNo':
                cellContent = documentData.USBILLTO;
                break;
            case 'ClientName':
                cellContent = documentData._clientName_edm ? (
                    <Tooltip label={documentData._clientName_edm}>
                        <span>{documentData._clientName_edm}</span>
                    </Tooltip>
                ) : null;
                break;
            case 'ContainerNo':
                cellContent = documentData.USCONTNO;
                break;
            case 'ReferenceNo':
                cellContent = documentData.USCUSTREF;
                break;
            case 'CarrierNo':
                cellContent = documentData.USSCAC;
                break;
            case 'FolderDate':
                cellContent = convertDateMDY(documentData._folderDate);
                break;
            case 'Port':
                cellContent = documentData.USPORT;
                break;
            case 'ModeOfTransport':
                cellContent = <CarrierIcon motDocuments={MOTCodes[documentData.USMOT as keyof typeof MOTCodes]} MOTMapDocuments={MOTMap} />;
                break;
            case 'selected':
                cellContent = (
                    <Checkbox
                        name={documentData._entryNumber ? documentData._entryNumber : field}
                        disabled={false}
                        value={documentData?._entryNumber && selectedEntries?.includes(documentData?._entryNumber)}
                        onChange={checkboxChange}
                    />
                );
                break;

            case 'Actions':
                cellContent = (
                    <ActionButton
                        placement='left-start'
                        dataItem={documentData}
                        disabled={selectedEntries?.includes(entryTransaction)!}
                        setModalView={setModalView}
                        setEllipsisCompareView={setEllipsisCompareView}
                        {...rest}
                    />
                );
                break;
            default:
            //cellContent = documentData[field as keyof FileData]?.toString();
        }
    } else if (country === 'ca') {
        switch (field) {
            case 'EntryTransactionNo':
                cellContent = (
                    <>
                        <FontAwesomeIcon
                            icon={modalView === entryTransaction ? faFolderOpen : faFolder}
                            className={`${modalView === entryTransaction ? '' : 'text-lii-text-light'} mr-2`}
                        />
                        {entryTransaction}
                    </>
                );
                break;
            case 'InvoiceNo':
                cellContent = documentData._branchInvoiceNumber;
                break;
            case 'InvoiceDate':
                cellContent = convertDateMDY(documentData.LIINDT);
                break;
            case 'EstimatedArrival':
                cellContent = convertDateMDY(documentData.LIETA);
                break;
            case 'CargoControlNo':
                cellContent = documentData.LICCD;
                break;
            case 'DocumentStatus':
                cellContent = translate(documentData?.LISTAT);
                break;
            case 'ClientNo':
                cellContent = documentData._clientNumberAndCaption;
                break;
            case 'ClientName':
                cellContent = documentData._clientName_edm ? (
                    <Tooltip label={documentData._clientName_edm}>
                        <span>{documentData._clientName_edm}</span>
                    </Tooltip>
                ) : null;
                break;
            case 'PO1':
                cellContent = documentData.LIPO1;
                break;
            case 'PO2':
                cellContent = documentData.LIPO2;
                break;
            case 'CarrierNo':
                cellContent = documentData.CarrierNo;
                break;
            case 'FolderDate':
                cellContent = convertDateMDY(documentData._folderDate);
                break;
            case 'Port':
                cellContent = documentData.LIPORT;
                break;
            case 'Vendor':
                cellContent = documentData.LIVEND;
                break;
            case 'CLVSID':
                cellContent = documentData.LICLVS;
                break;
            case 'SBRN':
                cellContent = documentData.LISBRN;
                break;
            case 'ConsistDate':
                cellContent = convertDateMDY(documentData.LICONS);
                break;
            case 'ContainerNo':
                cellContent = documentData.LICONT;
                break;

            case 'selected':
                cellContent = (
                    <Checkbox
                        name={documentData?._transactionNumber ? documentData._transactionNumber : field}
                        disabled={false}
                        value={documentData?._transactionNumber && selectedEntries?.includes(documentData?._transactionNumber)}
                        onChange={checkboxChange}
                    />
                );
                break;

            case 'Actions':
                cellContent = (
                    <ActionButton
                        placement='left-start'
                        dataItem={documentData}
                        disabled={selectedEntries?.includes(entryTransaction)!}
                        country={country}
                        setModalView={setModalView}
                        setEllipsisCompareView={setEllipsisCompareView}
                        {...rest}
                    />
                );
                break;
            default:
            //cellContent = dataItem[field as keyof FileData]?.toString();
        }
    }

    return (
        <td
            className={`${className} ${
                selectedEntries?.find(
                    (selected) => selected === (country === 'us' ? documentData._entryNumber : documentData?._transactionNumber)
                )
                    ? 'k-master-row k-state-selected'
                    : 'k-master-row'
            }`}
            style={{
                ...style,
                textAlign: field === 'selected' ? 'center' : 'left',
                whiteSpace: 'nowrap',
                paddingTop: '1.85vh',
                paddingBottom: '1.85vh',
                position: field === 'Actions' ? 'sticky' : 'static',
                backgroundColor: field === 'Actions' ? 'white' : 'transparent',
                cursor: 'pointer'
            }}
            onClick={() => (field !== 'Actions' ? setModalView(modalView === entryTransaction ? null : entryTransaction) : null)}
        >
            {cellContent}
        </td>
    );
});

export default FormattedGridCell;
