import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FunctionComponent } from 'react';
import { SpringboardCardProps } from '../../models/springboardCardProps';

const SpringboardCard: FunctionComponent<SpringboardCardProps> = ({ icon, title, description, onClick, className = '' }) => {
    return (
        <div className={`card cursor-pointer h-100 ${className}`} onClick={() => onClick && onClick()}>
            <div className='card-body d-flex align-items-center'>
                <div className='d-flex align-items-center'>
                    {icon && (
                        <span className='badge badge-circular badge-circular-lg badge-info mr-3'>
                            <FontAwesomeIcon icon={icon} />
                        </span>
                    )}
                    <div>
                        {title && <h5 className='mb-0'>{title}</h5>}
                        {description && <div>{description}</div>}
                    </div>
                </div>
            </div>
        </div>
    );
};
export default SpringboardCard;
