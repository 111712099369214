import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GridColumn as Column, Grid, GridNoRecords, type GridCellProps } from '@progress/kendo-react-grid';
import '@progress/kendo-theme-default/dist/all.css';
import { memo, useCallback, useEffect } from 'react';
import useTranslation from '../../../../common/hooks/useTranslation';
import type { User } from '../models/CreateNewUser';

const HIGHLIGHT_TIMEOUT = 5000;

interface AddNewUserTableProps {
    data: User[];
    onRemoveUser: (index: number) => void;
}

const AddNewUserTable = memo(function AddNewUserTableProps({ data, onRemoveUser }: AddNewUserTableProps) {
    const translate = useTranslation();

    useEffect(() => {
        if (!data.length) return;

        const element = document.querySelector('.add-new-user-table .k-master-row:first-child');

        element?.classList.add('new-row-highlight');

        const delay = setTimeout(
            (el) => {
                el?.classList.remove('new-row-highlight');
            },
            HIGHLIGHT_TIMEOUT,
            element
        );

        return () => {
            clearTimeout(delay);
        };
    }, [data.length]);

    const totalUsersAdded =
        data.length === 0 ? translate('AddUsersNoUsersMessage_Label') : translate('StatusAlertUsersAdded_Label', data.length);

    const addRemoveButton = useCallback(
        (props: GridCellProps) => {
            return (
                <td className={'text-center'}>
                    <button type='button' className='btn btn-outline-danger btn-sm' onClick={onRemoveUser.bind(null, props.dataIndex)}>
                        <FontAwesomeIcon icon={faTimes} />
                    </button>
                </td>
            );
        },
        [onRemoveUser]
    );

    return (
        <div className='row'>
            <div className='col-12'>
                <h5 className='border-top mt-4 mb-3 pt-3'>{totalUsersAdded}</h5>
                <Grid className='add-new-user-table' data={data}>
                    <GridNoRecords>{translate('AddUsersNoUsersMessage_Label')}</GridNoRecords>
                    <Column field='FirstName' title={translate('AddUsersColumnName_Label')} />
                    <Column field='Email' title={translate('AddUsersColumnEmail_Label')} />
                    <Column field='CompanyName' title={translate('AddUsersColumnCompany_Label')} />
                    <Column field='MobileNumber' title={translate('AddUsersColumnMobile_Label')} />
                    <Column field='UserRole' title={translate('AddUsersColumnUserRole_Label')} />
                    <Column field='RemoveButton' cell={addRemoveButton} locked />
                </Grid>
            </div>
        </div>
    );
});

export default AddNewUserTable;
