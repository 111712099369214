import ReactDOM from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import { DocumentsSlice } from '../../../pages/clearances/common/models/ReduxSlices';
import { setRenderCompareModal } from '../../../pages/clearances/redux/reducers/documentsSlice';
import { RootState } from '../../../pages/clearances/redux/store';
import { hideMenuData } from '../../../redux/reducers/viewImagesSlice';
import Button from '../Button';

interface DownloadFooterProps {
    amountLabel: string;
    amountSelected: number;
    primaryButtonLabel: string;
    disablePrimaryButton?: boolean;
    onPrimary: () => void;
    viewButtonLabel?: string;
    onView?: () => void;
    handleTagEvent?: () => void;
    compareButtonLabel?: string;
}

const DownloadFooter: React.FC<DownloadFooterProps> = ({
    amountLabel,
    amountSelected,
    primaryButtonLabel,
    disablePrimaryButton,
    onPrimary,
    viewButtonLabel,
    onView,
    handleTagEvent,
    compareButtonLabel
}) => {
    const parentContainer = document.querySelector('.navbar-and-stage-wrapper') ?? document.body;
    const dispatch = useDispatch();
    const { renderCompareModal } = useSelector<RootState, DocumentsSlice>((state) => state.clearanceDocuments);
    return ReactDOM.createPortal(
        <div id='FixedFooter' className='bg-white shadow-lg'>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col'>
                        <div className='d-flex flex-column flex-md-row align-items-md-center align-items-stretch justify-content-md-end fixed-bar-without-sidebar py-3'>
                            <div className='helper-text mr-md-auto mb-3 mb-md-0 mt-md-0'>{amountLabel}</div>
                            <div className='d-flex flex-column flex-sm-row flex-shrink-0'>
                                {viewButtonLabel && (
                                    <Button type='button' onClick={() => onView && onView()} variant='secondary'>
                                        {viewButtonLabel}
                                    </Button>
                                )}
                                {compareButtonLabel && amountSelected >= 2 && (
                                    <Button
                                        className='ml-2'
                                        variant='secondary'
                                        size='medium'
                                        onClick={() => {
                                            dispatch(setRenderCompareModal(!renderCompareModal));
                                            dispatch(hideMenuData(true));
                                            if (handleTagEvent) {
                                                handleTagEvent();
                                            }
                                        }}
                                    >
                                        {compareButtonLabel}
                                    </Button>
                                )}
                                <Button
                                    className='ml-2'
                                    variant='primary'
                                    size='medium'
                                    onClick={onPrimary}
                                    disabled={disablePrimaryButton}
                                >
                                    {primaryButtonLabel}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>,
        parentContainer
    );
};

export default DownloadFooter;
