import { ChangeEvent, RefObject, useState } from 'react';

interface BasicSearchProps {
    inputRef: RefObject<HTMLInputElement>;
}

const useBasicSearch = ({ inputRef }: BasicSearchProps) => {
    const [searchParam, setSearchParam] = useState<string>('');
    const [showClear, setShowClear] = useState<boolean>(false);

    const handleSearch = () => {
        setSearchParam(inputRef.current && inputRef.current.value ? inputRef.current.value : '');
    };

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.value.length) {
            setShowClear(true);
        } else {
            setSearchParam('');
            setShowClear(false);
        }
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            setSearchParam(inputRef.current && inputRef.current.value ? inputRef.current.value : '');
        }
    };

    const handleOnClearInput = () => {
        setSearchParam('');
        setShowClear(false);
        if (inputRef.current) inputRef.current.value = '';
    };

    return { searchParam, showClear, handleSearch, handleInputChange, handleKeyDown, handleOnClearInput };
};

export default useBasicSearch;
