import { memo, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { PortalRoutes } from '../../common/constants/constants-portal';
import { GetProfileData } from './redux/actions/profile';
import ProfileGrid from './screens/ProfileGrid/ProfileGrid';
import RequestSuccess from './screens/ProfileGrid/RequestSuccess';
import RequestUpdate from './screens/ProfileGrid/RequestUpdate';

const Profile = memo(function Profile() {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(GetProfileData());
    }, [dispatch]);

    return (
        <main className='main-stage-wrapper h-100 d-flex flex-column flex-fill position-relative'>
            <div className='d-flex flex-column flex-fill'>
                <Switch>
                    <Route path={PortalRoutes.profile} exact component={ProfileGrid} />
                    <Route path={`${PortalRoutes.profile}/RequestUpdate`} exact component={RequestUpdate} />
                    <Route path={`${PortalRoutes.profile}/RequestSuccess`} exact component={RequestSuccess} />
                </Switch>
            </div>
        </main>
    );
});

export default Profile;
