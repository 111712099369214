import React from 'react';

interface CloseButtonProps {
    type?: 'standard' | 'toast';
    onClick: () => void;
    style?: React.CSSProperties;
}

const CloseButton: React.FC<CloseButtonProps> = ({ type = 'standard', onClick, style }) => {
    // Return appropriate button based on the selected type
    if (type === 'standard') {
        return (
            <button type='button' className='close close-button' onClick={onClick} style={style}>
                <span>&times;</span>
            </button>
        );
    } else {
        return (
            <button type='button' className='close close-button' style={style} data-dismiss='toast' aria-label='Close' onClick={onClick}>
                <span aria-hidden='true'>&times;</span>
            </button>
        );
    }
};

export default CloseButton;
