import { PortalRoutes } from '../../../../common/constants/constants-portal';
import Breadcrumb from '../../../../common/features/Breadcrumb/Breadcrumb';
import BreadcrumbItem from '../../../../common/features/Breadcrumb/BreadcrumbItem';
import useTranslation from '../../../../common/hooks/useTranslation';
import { useTeamSelector } from '../../redux/hooks';

const EditTeamMemberBreadcrumb = () => {
    const { data: userDetailsData } = useTeamSelector((state) => state.editTeamMember);
    const translate = useTranslation();

    return (
        <Breadcrumb>
            <BreadcrumbItem url={PortalRoutes.team}>{translate('Team_Label')}</BreadcrumbItem>
            <BreadcrumbItem>{userDetailsData?.Users?.[0].FullName || translate('Loading_Label')}</BreadcrumbItem>
        </Breadcrumb>
    );
};

export default EditTeamMemberBreadcrumb;
