import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GridCellProps } from '@progress/kendo-react-grid';
import { Checkbox } from '@progress/kendo-react-inputs';
import { useDispatch, useSelector } from 'react-redux';
import useTranslation from '../../../../../common/hooks/useTranslation';
import { Document } from '../../../../../common/models/ClearanceDocuments';
import { ShipmentDetailsSlice } from '../../../common/models/ReduxSlices';
import { DownloadDocuments } from '../../../redux/actions/clearanceDocuments';
import { RootState } from '../../../redux/store';

interface DataItem extends Document {
    selected?: boolean;
}

interface FormattedGridCellProps extends GridCellProps {
    dataItem: DataItem;
    country: 'us' | 'ca';
    onCheckboxClick: (param: string) => void;
}

const FormattedGridCell: React.FC<FormattedGridCellProps> = ({
    field = '',
    dataItem,
    country,
    selectionChange,
    style,
    className,
    onCheckboxClick
}) => {
    const { shipmentDetails } = useSelector<RootState, ShipmentDetailsSlice>((state) => state.shipmentDetails);
    const translate = useTranslation();
    const dispatch = useDispatch();
    let cellContent;

    const downloadDocument = (document: any) => {
        dispatch(DownloadDocuments({ fileNames: { [shipmentDetails?.EntryNumber || 'document']: [document.path] } }));
    };

    const downloadButton = (document: any) => {
        return (
            <button
                className='btn btn-tertiary btn-sm'
                type='button'
                id='DownloadButton'
                aria-haspopup='true'
                aria-expanded='false'
                onClick={() => downloadDocument(document)}
                disabled={dataItem.selected}
            >
                <FontAwesomeIcon icon={faDownload} className={'mr-2'} />
                {translate('DownloadButton_Label')}
            </button>
        );
    };

    switch (field) {
        case 'Document type':
            if (isNaN(parseInt(dataItem.metadataFormat))) {
                cellContent = translate(dataItem.metadataFormat.trim().toUpperCase().replaceAll(' ', '') + '_Label');
            } else {
                cellContent = dataItem.metadataFormat;
            }
            if (dataItem?.USDOCUMENTTYPE) cellContent += ' - ' + dataItem.USDOCUMENTTYPE;
            if (dataItem?.USCICONSOL) cellContent += ' - ' + dataItem.USCICONSOL;
            break;
        case 'Version':
            if (country === 'ca') {
                cellContent = !dataItem.LIVERSION ? '-' : dataItem.LIVERSION;
            }
            break;
        case 'Image date':
            cellContent = dataItem.imageDate;
            break;
        case 'Actions':
            cellContent = downloadButton(dataItem);
            break;
        case 'selected':
            cellContent = (
                <Checkbox
                    value={dataItem.selected}
                    onChange={(e) => {
                        onCheckboxClick(dataItem.documentId);
                    }}
                />
            );
            break;
        default:
            cellContent = null;
            break;
    }
    return (
        <td className={className} style={{ ...style, backgroundColor: dataItem?.selected ? '#DDE9F9' : '', cursor: 'default' }}>
            <span style={{ whiteSpace: 'pre-wrap' }}>{cellContent}</span>
        </td>
    );
};

export default FormattedGridCell;
