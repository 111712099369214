import { createSlice } from '@reduxjs/toolkit';
import { PortalSolutionsSlice } from '../../common/models/ReduxSlices';
import { GetPortalSolutionsMenu } from '../actions/portalSolutionsMenu';

const initialState: PortalSolutionsSlice = {
    isLoading: false,
    error: undefined,
    isUserConfigured: false,
    solutionsMenuItems: [
        {
            Name: '',
            Label: '',
            Icon: '',
            Url: '',
            Order: 1
        }
    ]
};

const portalSolutionsSlice = createSlice({
    name: 'solutionsMenuItems',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(GetPortalSolutionsMenu.pending, (state, action) => {
                state.isLoading = true;
                state.error = undefined;
            })
            .addCase(GetPortalSolutionsMenu.fulfilled, (state, action) => {
                state.isLoading = false;
                state.solutionsMenuItems = action.payload.SolutionsMenuItems;
                state.isUserConfigured = action.payload.IsUserConfigured;
            })
            .addCase(GetPortalSolutionsMenu.rejected, (state, action) => {
                state.isLoading = false;
                if (typeof action.payload === 'number') {
                    state.error = action.payload;
                } else {
                    state.error = action.error.message;
                }
            });
    }
});
export default portalSolutionsSlice.reducer;
