import { process } from '@progress/kendo-data-query';
import { GridColumn as Column, Grid, type GridCellProps, type GridHeaderCellProps } from '@progress/kendo-react-grid';
import '@progress/kendo-theme-default/dist/all.css';
import { useCallback, useState } from 'react';
import useTranslation from '../../../../common/hooks/useTranslation';
import type { ClientServices } from '../models/TeamMember';
import ClientsAndPermissionsTableCheckbox from './ClientsAndPermissionsTableCheckbox';
import ClientsAndPermissionsTableHeading from './ClientsAndPermissionsTableHeading';
import ClientsAndPermissionsTableNumberCell from './ClientsAndPermissionsTableNumberCell';

interface ClientsAndPermissionsTableProps {
    title: string;
    clientServices: ClientServices[];
    adminRole: string | null | undefined;
    isDisabled?: boolean;
    onSelect: (options: { number: string; field: string; isChecked: boolean; title: string; clientServicesIdx: number }) => void;
    onToggleSelectAll: (options: { title: string; featureName: string }) => void;
}

const ClientsAndPermissionsTable: React.FC<ClientsAndPermissionsTableProps> = ({
    isDisabled,
    title,
    clientServices,
    adminRole,
    onSelect,
    onToggleSelectAll
}) => {
    const translate = useTranslation();

    const createDataState = (dataState: any) => {
        return {
            result: process(clientServices, dataState),
            dataState: dataState
        };
    };

    const initialState = createDataState({
        // take: 20,
        skip: 0
    });

    const [result, setResult] = useState(initialState.result);
    const [dataState, setDataState] = useState(initialState.dataState);

    const dataStateChange = (event: any) => {
        const updatedState = createDataState(event.dataState);

        setResult(updatedState.result);
        setDataState(updatedState.dataState);
    };

    const headerCell = useCallback(
        (props: GridHeaderCellProps) => (
            <ClientsAndPermissionsTableHeading
                adminRole={adminRole}
                featureName={props.title || ''}
                title={title}
                isDisabled={isDisabled}
                onToggleSelectAll={onToggleSelectAll}
            />
        ),
        [adminRole, isDisabled, onToggleSelectAll, title]
    );

    const numberCaptionCell = useCallback(
        (props: GridCellProps) => <ClientsAndPermissionsTableNumberCell {...props} clientServices={clientServices} />,
        [clientServices]
    );

    const checkboxCell = useCallback(
        (props: GridCellProps) => (
            <ClientsAndPermissionsTableCheckbox
                {...props}
                clientServices={clientServices}
                isDisabled={isDisabled}
                onSelect={onSelect}
                title={title}
            />
        ),
        [clientServices, isDisabled, onSelect, title]
    );

    return (
        <>
            <div className='table-heading py-3 px-2'>{title}</div>
            <Grid
                className='clients-and-permissions-table mb-4'
                data={clientServices}
                {...dataState}
                onDataStateChange={dataStateChange}
                total={clientServices.length}
            >
                <Column field='Number' title={translate('ClientsAndPermissionsNumber_Label')} cell={numberCaptionCell} />
                <Column field='Name' title={translate('ClientsAndPermissionsName_Label')} />
                {result?.data[0]?.Features?.map((feature: any) => {
                    return (
                        <Column key={feature.Name} field={feature.Name} title={feature.Name} headerCell={headerCell} cell={checkboxCell} />
                    );
                })}
            </Grid>
        </>
    );
};

export default ClientsAndPermissionsTable;
