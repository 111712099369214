import { getter, process } from '@progress/kendo-data-query';
import { GridColumn as Column, Grid, GridCellProps, GridDataStateChangeEvent, GridHeaderCellProps } from '@progress/kendo-react-grid';
import { Checkbox } from '@progress/kendo-react-inputs';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import '@progress/kendo-theme-default/dist/all.css';
import { useCallback, useEffect, useRef, useState } from 'react';
import TagManager from 'react-gtm-module';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import GridLoadingOverlay from '../../../../../common/features/Grid/GridLoadingOverlay';
import RowWithButton from '../../../../../common/features/Grid/RowWithButton';
import UnsubscribeFooter from '../../../../../common/features/Grid/UnsubscribeFooter';
import useToast from '../../../../../common/features/Toast/useToast';
import Tooltip from '../../../../../common/features/Tooltip/Tooltip';
import useGridLanguage from '../../../../../common/hooks/useGridLanguage';
import useGridSelection from '../../../../../common/hooks/useGridSelection';
import useTranslation from '../../../../../common/hooks/useTranslation';
import { GridSettingsSlice, HydrationSlice, LanguageSlice } from '../../../../../common/models/ReduxSlices';
import { SubscribersModals } from '../../../../../common/models/SubscribersModals';
import { SubscribersSlice } from '../../../common/models/ReduxSlices';
import { SelectedSubscriber, TransformedSubscriber } from '../../../common/models/Subscriber';
import { UnsubscribeUsers } from '../../../redux/actions/subscribersManagement';
import {
    resetDataState,
    resetSelectedState,
    setCurrentDataItems,
    setDataState,
    setHeaderCheckbox,
    setPageSelected,
    setSelectedState,
    setTotalItems
} from '../../../redux/reducers/subscribersGridSettingsSlice';
import { RootState } from '../../../redux/store';
import UnsubscribeModal from '../RemoveSubscribersModal/UnsubscribeModal';
import { ColumnMenuCheckboxFilter, ColumnMenuSearchText } from './ColumnFilters';
import FormattedGridCell from './FormattedGridCell';

interface SubscribersTableProps {
    toggleModal: (prop: keyof SubscribersModals, next?: boolean) => void;
    subscribers: TransformedSubscriber[];
    modal: SubscribersModals;
}

const DATA_ITEM_KEY: keyof SelectedSubscriber = 'RowId';
const SELECTED_FIELD: keyof SelectedSubscriber = 'selected';
const idGetter = getter(DATA_ITEM_KEY);

const SubscribersTable: React.FC<SubscribersTableProps> = ({ subscribers, toggleModal, modal }) => {
    const { pageable, dataState, totalItems, selectedState, headerCheckbox, currentDataItems } = useSelector<RootState, GridSettingsSlice>(
        (state) => state.subscribersGridSettings
    );
    const { languageSelected: selectedLanguage } = useSelector<RootState, LanguageSlice>((state) => state.language);
    const { isLoading, error, subscribe, unsubscribe, subscriptionUpdate } = useSelector<RootState, SubscribersSlice>(
        (state) => state.subscribers
    );
    const { user } = useSelector<RootState, HydrationSlice>((state) => state.hydration);
    const gridRef = useRef<any>();
    const [gridSubscribers, setGridSubscribers] = useState<any[]>([]); // changed structure of subscribers from props
    const [selectedSubscribers, setSelectedSubscribers] = useState<SelectedSubscriber[]>([]); // selected subscribers for bulk delete
    const dispatch = useDispatch();
    const translate = useTranslation();
    const toast = useToast();
    const { push } = useHistory();
    const gridLanguage = useGridLanguage(selectedLanguage);
    const { handleHeaderSelectionChange, handleSelectionChange } = useGridSelection({
        DATA_ITEM_KEY,
        SELECTED_FIELD,
        idGetter,
        selectedState,
        currentDataItems,
        onSelectionChange: (value) => dispatch(setSelectedState(value)),
        onHeaderSelectionChange: useCallback((value) => dispatch(setHeaderCheckbox(value)), [dispatch]),
        onReset: useCallback(() => dispatch(resetSelectedState()), [dispatch])
    });

    // Transform the "subscribers" prop just to have the "selected" key for each subscriber/row
    useEffect(() => {
        if (subscribers) {
            setGridSubscribers(subscribers.map((item) => ({ ...item, [SELECTED_FIELD]: false })));
        }
    }, [subscribers]);

    // Update the total items that are responsible to show/hide pagination
    useEffect(() => {
        if (!gridRef.current) return;
        dispatch(setTotalItems(gridRef.current.props.data.total));
        dispatch(setCurrentDataItems(gridRef.current.props.data.data));
    }, [dataState, gridSubscribers, dispatch]);

    // Reset the selection on every grid reload
    useEffect(() => {
        if (!isLoading && !error) {
            dispatch(resetSelectedState());
        }
    }, [isLoading, error, dispatch]);

    // Array of selected SubscriptionIds
    useEffect(() => {
        if (modal.remove) return;

        setSelectedSubscribers(
            Object.keys(selectedState)
                .filter((id) => selectedState[id])
                .map((id) => ({ ...gridSubscribers.find((sub) => sub[DATA_ITEM_KEY] === parseInt(id)) }))
        );
    }, [gridSubscribers, modal.remove, selectedState]);

    const handleRemoveSubscriber = useCallback(
        (id?: null | number) => {
            if (id) setSelectedSubscribers(gridSubscribers.filter((item) => item[DATA_ITEM_KEY] === id));
            toggleModal('remove');
        },
        [toggleModal, gridSubscribers]
    );

    const GridCell = useCallback(
        (props: GridCellProps) => {
            return (
                <FormattedGridCell
                    {...props}
                    onRemoveSubscriber={handleRemoveSubscriber}
                    isLocked={props.dataItem[SELECTED_FIELD] || false}
                    onSelectRow={redirectToUserDetails}
                />
            );
        },
        [handleRemoveSubscriber]
    );

    const HeaderCell = useCallback(
        (props: GridHeaderCellProps) => {
            return <Checkbox value={!user?.InternalUser ? headerCheckbox : false} onChange={props.selectionChange} />;
        },
        [headerCheckbox, user]
    );

    const dataStateChange = (e: GridDataStateChangeEvent) => {
        dispatch(setDataState(e.dataState));
    };

    const handleRemoveSubscribers = async () => {
        const unsubRes = (await dispatch(UnsubscribeUsers(selectedSubscribers))) as any;
        // Set the grid page to 1
        dispatch(setPageSelected(1));
        toggleModal('remove');

        // Success toast
        if (!unsubscribe.error && unsubRes) {
            toast.send({
                message: translate('SubscribersRemoved_Label', unsubRes?.meta?.arg?.length),
                type: 'success'
            });
        }
    };

    const redirectToUserDetails = (subscriber: any) => {
        dispatch(resetDataState());
        return push({
            pathname: `/Subscribers/${subscriber.RowId}`,
            state: {
                isEditable: subscriber.IsEditable,
                editEmail: subscriber.Email,
                editPhone: subscriber.MobileNumber,
                fullName: subscriber.FullName,
                role: subscriber.Role,
                language: subscriber.Language,
                userId: subscriber.UserId
            }
        });
    };
    const handleTagEvent = () => {
        TagManager.dataLayer({
            dataLayer: {
                event: 'Add_Subscriber_SUB_CPEvt',
                location: 'Subscribers'
            }
        });
    };

    return (
        <>
            <RowWithButton
                extraRowClasses='ml-2'
                element={'.k-widget.k-grid.subscriber-landing-table'}
                onClick={() => !user?.InternalUser && (toggleModal('add', true), handleTagEvent())}
            >
                {!user?.InternalUser ? (
                    translate('AddSubscribersButton_Label')
                ) : (
                    <Tooltip label='Disabled for LII users.'>
                        <span>{translate('AddSubscribersButton_Label')}</span>
                    </Tooltip>
                )}
            </RowWithButton>
            <LocalizationProvider language={gridLanguage.language}>
                <IntlProvider locale={gridLanguage.locale}>
                    <div className='d-flex position-relative flex-fill'>
                        <Grid
                            className='subscriber-landing-table flex-fill'
                            data={process(
                                gridSubscribers &&
                                    gridSubscribers.map((item: any) => ({
                                        ...item,
                                        [SELECTED_FIELD]: selectedState[idGetter(item)]
                                    })),
                                dataState
                            )}
                            ref={gridRef}
                            pageable={totalItems < 10 ? false : pageable}
                            onDataStateChange={dataStateChange}
                            selectedField={SELECTED_FIELD}
                            onHeaderSelectionChange={handleHeaderSelectionChange}
                            onSelectionChange={handleSelectionChange}
                            // onRowClick={(event) => redirectToUserDetails(event.dataItem)}
                            sortable={{
                                allowUnsort: false
                            }}
                            selectable={{
                                enabled: false,
                                drag: false,
                                cell: false,
                                mode: 'multiple'
                            }}
                            {...dataState}
                        >
                            <Column field={SELECTED_FIELD} width='50px' cell={GridCell} headerCell={HeaderCell} />
                            <Column field='FullName' title={translate('Name_Label')} cell={GridCell} columnMenu={ColumnMenuSearchText} />
                            <Column field='Email' title={translate('Email_Label')} columnMenu={ColumnMenuSearchText} cell={GridCell} />
                            <Column field='Role' title={translate('Role_Label')} columnMenu={ColumnMenuCheckboxFilter} cell={GridCell} />
                            <Column
                                field='MobileNumberOnlyNumbers'
                                title={translate('MobileNumber_Label')}
                                columnMenu={ColumnMenuSearchText}
                                cell={GridCell}
                            />
                            <Column
                                field='Language'
                                title={translate('Language_Label')}
                                columnMenu={ColumnMenuCheckboxFilter}
                                cell={GridCell}
                            />
                            <Column field='Type' title={translate('Type_Label')} columnMenu={ColumnMenuCheckboxFilter} cell={GridCell} />
                            <Column field='Actions' cell={GridCell} sortable={false} locked />
                        </Grid>
                        {(isLoading || subscribe.isLoading || unsubscribe.isLoading || subscriptionUpdate.isLoading) &&
                            subscribers &&
                            subscribers.length > 0 && <GridLoadingOverlay />}
                    </div>
                </IntlProvider>
            </LocalizationProvider>

            {modal.remove && (
                <UnsubscribeModal subscribers={selectedSubscribers} toggleModal={toggleModal} handleSubmit={handleRemoveSubscribers} />
            )}

            {/* Unsubscribe footer */}
            {Object.entries(selectedState).filter(([key, value]) => value === true).length > 0 &&
                !modal.remove &&
                !isLoading &&
                !subscribe.isLoading &&
                !unsubscribe.isLoading &&
                !subscriptionUpdate.isLoading && (
                    <UnsubscribeFooter
                        amountLabel={translate('NumberOfRemoveSelected_Label', selectedSubscribers.length)}
                        primaryButtonLabel={translate('RemoveSelected_Label')}
                        onPrimary={() => toggleModal('remove', true)}
                    />
                )}
        </>
    );
};

export default SubscribersTable;
