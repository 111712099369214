export const MilestoneTooltips = (params: string) => {
    const status = params;
    let tip;

    switch (status) {
        case 'ReleaseSubmitted_Label':
            tip = 'ReleaseSubmittedNotification_Label';
            break;
        case 'OneUsgReceived_Label':
            tip = 'OneUsgReceivedNotification_Label';
            break;
        case 'EntrySummaryAccepted_Label':
            tip = 'EntrySummaryAcceptedNotification_Label';
            break;
        case 'CustomsCertified_Label':
            tip = 'CustomsCertifiedNotification_Label';
            break;
        case 'DocumentsReceived_Label':
            tip = 'DocumentsReceivedNotification_Label';
            break;
        case 'CustomsAccepted_Label':
            tip = 'CustomsCertifiedNotification_Label';
            break;
        case 'CustomsReleased_Label':
            tip = 'CustomsReleasedUSNotification_Label';
            break;
    }

    return tip;
};

export const ADJUST_PADDING = 4;
export const COLUMN_MIN = 4;
