import { createAsyncThunk } from '@reduxjs/toolkit';
import { CANCEL_USER, REACTIVATE_TEAM_MEMBER } from '../../../../../store/actions/teamManagement/teamManagementActionTypes';
import axios from '../../../../axios-portal';
import { API_CLIENT_ADMIN_PATH, API_TEAM_PATH } from '../../../../common/constants/constants-portal';
import type { ChangeTeamMemberStatus, TeamData, TeamMemberData } from '../../common/models/ResponseData';
import { ADD_NEW_USER, CLONE_USER, EDIT_USER, GET_CLIENT_STATUS, REFRESH_USER_DETAILS, SET_TEAM_DATA } from './actionTypes';

export const GetUserTeamData = createAsyncThunk(SET_TEAM_DATA, async (payload, { rejectWithValue }) => {
    try {
        const response = await axios.get<TeamData>(`${API_TEAM_PATH}/IndexPortal`);

        return response.data;
    } catch (error: any) {
        return rejectWithValue(error.response.status);
    }
});

export const InitNewTeamMember = createAsyncThunk(ADD_NEW_USER, async (payload, { rejectWithValue }) => {
    try {
        const response = await axios.get<TeamMemberData>(`${API_TEAM_PATH}/NewTeamMember`);
        return response.data;
    } catch (error: any) {
        return rejectWithValue(error.response.status);
    }
});

export const GetCloneMemberDetails = createAsyncThunk(CLONE_USER, async (payload: any, { rejectWithValue }) => {
    try {
        const response = await axios.get<TeamMemberData>(`${API_TEAM_PATH}/CloneTeamMember?teamMemberId=${payload.toString()}`);
        return response.data;
    } catch (error: any) {
        return rejectWithValue(error.response.status);
    }
});

export const GetTeamMemberDetails = createAsyncThunk(EDIT_USER, async (payload: any, { rejectWithValue }) => {
    try {
        const response = await axios.get<TeamMemberData>(`${API_TEAM_PATH}/TeamMemberDetails?teamMemberId=${payload.toString()}`);

        // return {
        //     ...response.data,
        //     Users: [
        //         {
        //             ...response.data.Users?.[0]
        //             // Status: 'Deactivated'
        //         }
        //     ]
        // };

        return response.data;
    } catch (error: any) {
        return rejectWithValue(error.response.status);
    }
});

// Same as above but without loading
export const RefreshTeamMemberDetails = createAsyncThunk(REFRESH_USER_DETAILS, async (payload: any, { rejectWithValue }) => {
    try {
        const response = await axios.get<TeamMemberData>(`${API_TEAM_PATH}/TeamMemberDetails?teamMemberId=${payload.toString()}`);

        return response.data;
    } catch (error: any) {
        return rejectWithValue(error.response.status);
    }
});

export const GetEDMClientServices = createAsyncThunk(GET_CLIENT_STATUS, async (payload: any, { rejectWithValue }) => {
    try {
        const response = await axios.post<Record<string, string>>(`${API_CLIENT_ADMIN_PATH}/GetEDMClientAccountStatuses`, payload);
        return response.data;
    } catch (error: any) {
        return rejectWithValue(error.response.status);
    }
});

export const CancelStatusTeamMember = createAsyncThunk(CANCEL_USER, async (payload: any, { rejectWithValue }) => {
    try {
        const response = await axios.post<ChangeTeamMemberStatus>(`${API_TEAM_PATH}/CancelUser?userId=${payload.toString()}`);
        return response.data;
    } catch (error: any) {
        return rejectWithValue(error.response.status);
    }
});

export const ReactivateStatusTeamMember = createAsyncThunk(REACTIVATE_TEAM_MEMBER, async (payload: any, { rejectWithValue }) => {
    try {
        const response = await axios.post<ChangeTeamMemberStatus>(`${API_TEAM_PATH}/ReactivateUser?userId=${payload.toString()}`);
        return response.data;
    } catch (error: any) {
        return rejectWithValue(error.response.status);
    }
});
