import { useEffect, useState } from 'react';
import useLocalStorageItem from './useLocalStorageItem';

interface UseTabSelectionProps {
    id: string;
}

const useTabSelection = ({ id }: UseTabSelectionProps) => {
    const [active, setActive] = useState<boolean>(false);
    const { value: activeTab, handleLocalStorageSetItem } = useLocalStorageItem('activeTab');

    useEffect(() => {
        if (activeTab === id) {
            setActive(true);
        } else {
            setActive(false);
        }
    }, [id, activeTab]);

    const handleClick = (localId?: string) => {
        handleLocalStorageSetItem(localId || id);
    };

    return { active, handleClick };
};

export default useTabSelection;
