import { useMemo } from 'react';
import useTranslation from '../../../../common/hooks/useTranslation';
import TeamSectionWrapper from '../../common/features/TeamSectionWrapper';
import { useTeamSelector } from '../../redux/hooks';
import UserDetailsEditable from './UserDetails/UserDetailsEditable';
import UserDetailsNonEditable from './UserDetails/UserDetailsNonEditable';

const EditTeamMemberUserDetails = () => {
    const { user } = useTeamSelector((state) => state.hydration);
    const { data: userDetailsData, isLoading: userDetailsLoading } = useTeamSelector((state) => state.editTeamMember);

    const translate = useTranslation();

    const teamMember = userDetailsData?.Users?.[0];
    const isEditable = useMemo(() => user?.Roles?.includes('TeamManagement_Edit'), [user?.Roles]);
    const isPendingStatus =
        teamMember?.Status === 'PendingUserActivation' ||
        teamMember?.Status === 'PendingFeatureSetup' ||
        teamMember?.Status === 'EmailChangedConfirmRequired' ||
        teamMember?.Status === 'PendingUserConfirmRequired';
    const isInactiveStatus =
        teamMember?.Status === 'Cancelled' ||
        teamMember?.Status === 'Deactivated' ||
        teamMember?.Status === 'Expired' ||
        teamMember?.Status === 'Hold';

    return (
        <TeamSectionWrapper title={translate('TeamMemberUserDetailsHeader_Label')} isLoading={userDetailsLoading}>
            {isEditable && !isPendingStatus && !isInactiveStatus ? <UserDetailsEditable /> : <UserDetailsNonEditable />}
        </TeamSectionWrapper>
    );
};

export default EditTeamMemberUserDetails;
