import React from 'react';

interface StageWrapperProps {
    marginBottom: boolean;
    children: React.ReactNode;
}

const StageWrapper: React.FC<StageWrapperProps> = ({ marginBottom = false, children }) => {
    return <div className={`d-flex flex-column flex-fill ${marginBottom ? 'mb-5' : ''}`}>{children}</div>;
};

export default StageWrapper;
