import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import StatusAlert from '../../../../common/features/StatusAlert';
import useTranslation from '../../../../common/hooks/useTranslation';
import { HydrationSlice } from '../../../../common/models/ReduxSlices';
import { SubscribersSlice } from '../../common/models/ReduxSlices';
import { clearNewSubscribers, resetSubscribe, resetSubscriptionUpdate, resetUnsubscribe } from '../../redux/reducers/subscribersSlice';
import { RootState } from '../../redux/store';

const SubscriptionStatusAlerts = () => {
    const { isLoading, subscribe, subscriptionUpdate, unsubscribe, subscribers } = useSelector<RootState, SubscribersSlice>(
        (state) => state.subscribers
    );
    const { user } = useSelector<RootState, HydrationSlice>((state) => state.hydration);
    const translate = useTranslation();
    const dispatch = useDispatch();

    // Reset all action inside "subscribers" slice on unmount
    useEffect(() => {
        return () => {
            dispatch(resetSubscriptionUpdate());
            dispatch(resetSubscribe());
            dispatch(resetUnsubscribe());
        };
    }, [dispatch]);

    useEffect(() => {
        const newSubsDelay = setTimeout(() => {
            dispatch(clearNewSubscribers());
        }, 3000);
        return () => clearTimeout(newSubsDelay);
    }, [subscribers?.length, dispatch]);

    if (!isLoading && !subscriptionUpdate.isLoading && !subscribe.isLoading && !unsubscribe.isLoading) {
        return (
            <>
                {/* Status alert for subscription update */}
                {subscriptionUpdate.data &&
                    !subscriptionUpdate.error &&
                    subscriptionUpdate.alertMessage &&
                    subscriptionUpdate.alertType && (
                        <StatusAlert message={translate(subscriptionUpdate.alertMessage)} type={subscriptionUpdate.alertType} />
                    )}
            </>
        );
    } else {
        return null;
    }
};

export default SubscriptionStatusAlerts;
