import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../axios-portal';
import { API_HYDRATION_PATH } from '../../common/constants/constants-portal';
import { NoticesData } from '../../common/models/ResponseData';
import { GET_USER_NOTICES } from './actionTypes';

export const GetNotices = createAsyncThunk(GET_USER_NOTICES, async (payload, { rejectWithValue }) => {
    try {
        const response = await axios.get<NoticesData>(`${API_HYDRATION_PATH}/GetActiveNotices/`);
        return response.data;
    } catch (error: any) {
        return rejectWithValue(error.response.status);
    }
});
