import { useEffect, useState } from 'react';

const useLocalStorageItem = (key: string, delay: number | undefined = 50) => {
    const [value, setValue] = useState<string | null>();

    // Get item from the "localStorage"
    useEffect(() => {
        const getLocalStorageItem = setTimeout(() => {
            const localStorageItemValue = localStorage.getItem(key);
            setValue(localStorageItemValue);
        }, delay);

        return () => {
            clearTimeout(getLocalStorageItem);
        };
    }, [delay, key]);

    const handleLocalStorageSetItem = (value: string) => {
        localStorage.setItem(key, value);
    };

    return { value, handleLocalStorageSetItem };
};

export default useLocalStorageItem;
