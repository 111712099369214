import moment from 'moment';
import { Contact } from '../../pages/contacts/common/models/Contact';
import { Subscription } from '../models/ClearanceSubscriptions';
import { NewSubscriber } from '../models/NewSubscriber';

export const emailValidator = (value: string | null | undefined) => {
    return value ? /\S+@\S+\.\S+/.test(value) : false;
};

export const phoneValidator = (value: string | null | undefined) => {
    return value ? /^[0-9 ()+-]+$/.test(value) : false;
};

export const inputHasValueValidator = (value: string | null | undefined) => {
    return value ? !!value.trim().length : false;
};

export const hyphenValueValidator = (value: string | null | undefined) => {
    return !value || value === '-' ? false : !!value.trim().length;
};

export const dateFormatValidator = (value: string | null | undefined, dateFormat: string = 'MM-DD-YYYY') => {
    return value ? moment(value, dateFormat, true).isValid() : false;
};

export const dateIsAfterValidator = (value: string | null | undefined, dateFormat: string = 'MM-DD-YYYY') => {
    return value ? moment(value, dateFormat, true).isAfter(new Date()) : false;
};

export const alphaNumericAndCommaValidator = (value: string | null | undefined) => {
    return value ? /^[a-zA-Z0-9,\s]*$/.test(value) : false;
};

export const uniqueSubscriberEmail = (value: string, existingSubscribers: null | Subscription[], newSubscribers: NewSubscriber[]) => {
    return existingSubscribers
        ? existingSubscribers?.filter((existingSubscriber) => existingSubscriber.Email === value).length === 0 &&
              newSubscribers.filter((newSubscriber) => newSubscriber.email === value).length <= 1
        : false;
};

export const uniqueSubscriberFromGridEmail = (value: string, existingSubscribers: null | Subscription[]) => {
    return existingSubscribers
        ? existingSubscribers?.filter((existingSubscriber) => existingSubscriber.Email === value).length === 0
        : false;
};

export const uniqueNewSubscriberEmail = (value: string, newSubscribers: NewSubscriber[]) => {
    return newSubscribers.filter((newSubscriber) => newSubscriber.email === value).length <= 1;
};

export const uniqueSubscriberPhone = (value: string, existingSubscribers: null | Subscription[], newSubscribers: NewSubscriber[]) => {
    return existingSubscribers
        ? existingSubscribers?.filter((existingSubscriber) => existingSubscriber.Phone === value).length === 0 &&
              newSubscribers.filter((newSubscriber) => newSubscriber.phone === value).length <= 1
        : false;
};

export const uniqueSubscriberFromGridPhone = (value: string, existingSubscribers: null | Subscription[]) => {
    return existingSubscribers
        ? existingSubscribers?.filter((existingSubscriber) => existingSubscriber.Phone === value).length === 0
        : false;
};

export const uniqueNewSubscriberPhone = (value: string, newSubscribers: NewSubscriber[]) => {
    return newSubscribers.filter((newSubscriber) => newSubscriber.phone === value).length <= 1;
};

export const newSubscribersHasValidEmailOrAndPhone = (newSubscribers: NewSubscriber[]) => {
    return newSubscribers.every((subscriber) => {
        if (subscriber.email.length && subscriber.phone.length) {
            return subscriber.emailIsValid && subscriber.phoneIsValid;
        } else if (subscriber.email.length) {
            return subscriber.emailIsValid;
        } else {
            return subscriber.phoneIsValid;
        }
    });
};

export const newSubscribersHasValidForm = (newSubscribers: NewSubscriber[]) => {
    return newSubscribers.every((subscriber) => subscriber.fullName.length && subscriber.role.length && subscriber.language.length);
};

export const uniqueNewSubscriber = (newSubscriberId: string, existingSubscribersIds: string[] | undefined, newSubscribersIds: string[]) => {
    return (
        existingSubscribersIds &&
        !existingSubscribersIds.includes(newSubscriberId) &&
        (newSubscribersIds.filter((item) => item === newSubscriberId).length > 1 ? false : true)
    );
};

export const uniqueContactFromGridEmail = (value: string, existingContacts: null | Contact[]) => {
    return existingContacts ? existingContacts.filter((existingContact) => existingContact.Email === value).length === 0 : false;
};

export const uniqueNewContactEmail = (value: string, newContacts: Contact[]) => {
    return newContacts.filter((newContact) => newContact.Email === value).length <= 1;
};

export const uniqueContactFromGridMobile = (value: string, existingContacts: null | Contact[]) => {
    return existingContacts ? existingContacts.filter((existingContact) => existingContact.MobileNumber === value).length === 0 : false;
};

export const uniqueNewContactMobile = (value: string, newContacts: Contact[]) => {
    return newContacts.filter((newContact) => newContact.MobileNumber === value).length <= 1;
};

export const isAddContactFormValid = (newContacts: Contact[], isAddingContact?: boolean) => {
    return newContacts.every(
        (contact) =>
            contact.IsEmailValid &&
            contact.IsFullNameValid &&
            contact.IsLanguageValid &&
            contact.IsRoleLabelValid &&
            (contact.MobileNumber?.length ? contact.IsMobileNumberValid : true) &&
            (contact.RoleLabel.includes('Carrier') ? contact.IsCarrierCodesValid : true) &&
            (isAddingContact ? contact.TeamAdminUserCompanyName?.length : true)
    );
};
