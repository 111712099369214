import type { GridCellProps } from '@progress/kendo-react-grid';
import { useRef } from 'react';
import type { ClientServices } from '../models/TeamMember';

type SelectOptions = { number: string; field: string; isChecked: boolean; title: string; clientServicesIdx: number };

interface ClientsAndPermissionsTableCheckboxProps extends GridCellProps {
    clientServices: ClientServices[];
    isDisabled?: boolean;
    title: string;
    onSelect: (options: SelectOptions) => void;
}

export default function ClientsAndPermissionsTableCheckbox({
    clientServices,
    isDisabled: isDisabledProp,
    title,
    dataItem,
    field: fieldProp,
    dataIndex,
    onSelect
}: ClientsAndPermissionsTableCheckboxProps) {
    const lastChange = useRef<NodeJS.Timeout | null>(null);

    const number = dataItem.Number;
    const field = fieldProp || '';
    const clientServicesIdx = dataIndex;
    const id = clientServicesIdx + number + field;
    const status = clientServices[clientServicesIdx]?.StatusCodeName;

    const checkbox = dataItem?.Features?.find((feature: any) => feature?.Name === field);
    const isChecked = checkbox?.IsSelected as boolean;
    const isPending = checkbox?.IsPending as boolean;
    const isDisabled =
        isDisabledProp ||
        isPending ||
        (status !== undefined && status !== 'Active' && field !== 'My Livingston Portal' && field !== 'Clearances');

    const handleChange = ({ number, field, isChecked, title, clientServicesIdx }: SelectOptions) => {
        if (lastChange.current) {
            clearTimeout(lastChange.current);
        }

        lastChange.current = setTimeout(() => {
            lastChange.current = null;
            onSelect({ number, field, isChecked, title, clientServicesIdx });
        }, 200);
    };

    return (
        <td>
            <form className='d-flex justify-content-center'>
                <div className='custom-control custom-checkbox'>
                    <input
                        type='checkbox'
                        className='custom-control-input'
                        id={id}
                        defaultChecked={isChecked}
                        disabled={isDisabled}
                        onChange={handleChange.bind(null, { number, field, isChecked, title, clientServicesIdx })}
                    />
                    <label className='custom-control-label' htmlFor={id} />
                </div>
            </form>
        </td>
    );
}
