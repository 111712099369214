import React, { useEffect } from 'react';
import StatusAlert from '../../../../common/features/StatusAlert';
import useTranslation from '../../../../common/hooks/useTranslation';
import { useTeamDispatch, useTeamSelector } from '../../redux/hooks';
import { setStatusMessage } from '../../redux/reducers/statusUserSlice';

interface StatusAlertsProps {
    name: string;
}

const StatusAlerts: React.FC<StatusAlertsProps> = ({ name }) => {
    const { isActive } = useTeamSelector((state) => state.statusUser);
    const { newUsersAmount } = useTeamSelector((state) => state.userDetails);
    const { user } = useTeamSelector((state) => state.hydration);
    const dispatch = useTeamDispatch();
    const translate = useTranslation();

    const timeout = user?.PortalTimeoutShort || 5000;

    useEffect(() => {
        if (name === 'userStatus') {
            const alertDelay = setTimeout(() => dispatch(setStatusMessage(false)), timeout + 200);
            return () => {
                clearTimeout(alertDelay);
            };
        }
    }, [dispatch, name, timeout]);

    return (
        <>
            {name === 'userStatus' && (
                <StatusAlert message={!isActive ? translate('UserReactivate_Label') : translate('UserDeactivate_Label')} type='success' />
            )}
            {name === 'newUser' && <StatusAlert message={translate('StatusAlertUsersAdded_Label', newUsersAmount)} type='success' />}
        </>
    );
};

export default StatusAlerts;
