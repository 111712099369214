import { faInfo, faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isRejected } from '@reduxjs/toolkit';
import moment from 'moment';
import { useState } from 'react';
import Badge from '../../../../../common/features/Badge';
import Spinner from '../../../../../common/features/Spinner';
import useToast from '../../../../../common/features/Toast/useToast';
import Tooltip from '../../../../../common/features/Tooltip/Tooltip';
import { useSearchParams } from '../../../../../common/hooks/useSearchParams';
import useTranslation from '../../../../../common/hooks/useTranslation';
import { TeamMemberStatusLabels } from '../../../common/constants/constants-team';
import { ReactivateStatusTeamMember, RefreshTeamMemberDetails } from '../../../redux/actions/teamManagement';
import { useTeamDispatch, useTeamSelector } from '../../../redux/hooks';
import { setStatusIsLoading, statusChangeCheck } from '../../../redux/reducers/statusUserSlice';
import PendingModal from './PendingModal';

const UserDetailsNonEditable = () => {
    const translate = useTranslation();

    const { isStatusLoading } = useTeamSelector((state) => state.statusUser);
    const { data } = useTeamSelector((state) => state.editTeamMember);
    const teamMember = data?.Users?.[0];
    const isInactiveStatus =
        teamMember?.Status === 'Cancelled' ||
        teamMember?.Status === 'Deactivated' ||
        teamMember?.Status === 'Expired' ||
        teamMember?.Status === 'Hold';
    const dispatch = useTeamDispatch();

    const [searchParams] = useSearchParams();
    const userId = parseInt(searchParams.get('userId') || '');

    const [pendingModalIsVisible, setPendingModalIsVisible] = useState(false);

    const toast = useToast();

    const reactivateTeamMember = async (userId: number) => {
        dispatch(setStatusIsLoading(true));

        try {
            const action = await dispatch(ReactivateStatusTeamMember(userId));

            if (isRejected(action)) {
                throw new Error(translate('SomethingWentWrong_Label'));
            }
        } catch (error) {
            if (error instanceof Error) {
                toast.send({ type: 'error', message: error.message });
            } else {
                toast.send({ type: 'error', message: translate('SomethingWentWrong_Label') });
            }
        } finally {
            dispatch(statusChangeCheck());
            dispatch(setStatusIsLoading(false));
            dispatch(RefreshTeamMemberDetails(userId));
        }
    };

    if (!teamMember) return null;

    return (
        <div className='container-fluid p-0'>
            <div className='row row-cols-1 row-cols-md-2 row-cols-lg-3'>
                <div className='col order-md-3'>
                    <div className='form-group'>
                        <label>{translate('Status_Label')}</label>
                        <br />
                        <div className='d-flex align-items-center'>
                            {teamMember?.IsActive ? (
                                <Badge type='success'>{teamMember?.Status}</Badge>
                            ) : (
                                <>
                                    <Badge type='danger'>
                                        {!isInactiveStatus && teamMember?.Status !== 'EmailChangedConfirmRequired' ? (
                                            <Tooltip label={translate('PendingUserTooltip_Label')}>
                                                <button
                                                    type='button'
                                                    className='btn p-0 rounded-lg d-flex align-items-center'
                                                    style={{ fontSize: 'inherit', color: 'inherit' }}
                                                    onClick={() => setPendingModalIsVisible(true)}
                                                >
                                                    {translate(
                                                        TeamMemberStatusLabels[teamMember?.Status as keyof typeof TeamMemberStatusLabels]
                                                    )}
                                                    <FontAwesomeIcon icon={faInfo} className='ml-2' style={{ cursor: 'pointer' }} />
                                                </button>
                                            </Tooltip>
                                        ) : (
                                            <>
                                                {translate(
                                                    TeamMemberStatusLabels[teamMember?.Status as keyof typeof TeamMemberStatusLabels]
                                                )}
                                            </>
                                        )}
                                    </Badge>
                                    {(teamMember.Status === 'Cancelled' ||
                                        teamMember.Status === 'Deactivated' ||
                                        teamMember.Status === 'Expired') && (
                                        <button
                                            type='button'
                                            className='btn btn-outline-success btn-sm ml-2'
                                            onClick={reactivateTeamMember.bind(null, userId)}
                                            disabled={isStatusLoading}
                                        >
                                            {isStatusLoading ? (
                                                <Spinner size='small' color='inherit' className='mr-1' />
                                            ) : (
                                                <FontAwesomeIcon icon={faSyncAlt} className='mr-1' />
                                            )}
                                            {translate('ReactivateUser_Label')}
                                        </button>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                </div>
                <div className='col order-md-1'>
                    <div className='form-group'>
                        <label>{translate('FirstName_Label')}</label>
                        <p>{teamMember?.FirstName}</p>
                    </div>
                </div>
                <div className='col order-md-2'>
                    <div className='form-group'>
                        <label>{translate('LastName_Label')}</label>
                        <p>{teamMember?.LastName}</p>
                    </div>
                </div>
                <div className='col order-md-4'>
                    <div className='form-group'>
                        <label>{translate('Email_Label')}</label>
                        <p>{teamMember?.Email}</p>
                    </div>
                </div>
                <div className='col order-md-5'>
                    <div className='form-group'>
                        <label>{translate('PhoneNumber_Label')}</label>
                        <p>{teamMember?.Phone}</p>
                    </div>
                </div>
                <div className='col order-md-6'>
                    <div className='form-group'>
                        <label>{translate('MobileNumberWithoutOptional_Label')}</label>
                        <p>{teamMember?.MobileNumber}</p>
                    </div>
                </div>
                <div className='col order-md-7'>
                    <div className='form-group'>
                        <label>{translate('Company_Label')}</label>
                        <p>{teamMember?.CompanyName}</p>
                    </div>
                </div>
                <div className='col order-md-8'>
                    <div className='form-group'>
                        <label>{translate('UserRole_Label')}</label>
                        <p>{teamMember?.Roles?.find((role) => role.IsSelected)?.Text}</p>
                    </div>
                </div>
                <div className='col order-md-9'>
                    <div className='form-group'>
                        <label>{translate('ExpirationDateWithoutOptional_Label')}</label>
                        <p>
                            {teamMember?.ExpirationDate
                                ? moment(teamMember?.ExpirationDate).format('MM-DD-YYYY')
                                : translate('Never_Label')}
                        </p>
                    </div>
                </div>
            </div>
            <PendingModal isOpen={pendingModalIsVisible} setIsOpen={setPendingModalIsVisible} />
        </div>
    );
};

export default UserDetailsNonEditable;
