import { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ErrorPage from '../../../../common/features/ErrorPage';
import StatusFilter from '../../../../common/features/FilterBar/StatusFilter';
import EmptyState from '../../../../common/features/Grid/EmptyState';
import HeaderBar from '../../../../common/features/HeaderBar/HeaderBar';
import Spinner from '../../../../common/features/Spinner';
import useTranslation from '../../../../common/hooks/useTranslation';
import { StatusFilterOptions, TeamMemberStatusLabels } from '../../common/constants/constants-team';
import type { TransformedTeamMember } from '../../common/models/TeamMember';
import { GetUserTeamData } from '../../redux/actions/teamManagement';
import { useTeamDispatch, useTeamSelector } from '../../redux/hooks';
import { setPageSelected } from '../../redux/reducers/teamGridSettingsSlice';
import { setSelectedStatus } from '../../redux/reducers/teamStatusFilterSlice';
import { resetNewUsersAmount } from '../../redux/reducers/userDetailsSlice';
import TeamSearchBox from './SearchBox/TeamSearchBox';
import TeamGridCaption from './TeamGridCaption';
import TeamsTable from './TeamsTable/TeamsTable';

// Filter team members by Status (All, Active, Inactive)
const filterTeamMembersByStatus = (teamMembers: TransformedTeamMember[], status: string) => {
    if (status.toUpperCase() === 'ACTIVE') {
        return teamMembers.filter((member) => member.IsActive);
    } else if (status.toUpperCase() === 'INACTIVE') {
        return teamMembers.filter((member) => !member.IsActive);
    } else {
        return teamMembers;
    }
};

// Filter team members by Search term (name or email)
const searchTeamMembers = (teamMembers: TransformedTeamMember[], searchTerm: string) => {
    if (!searchTerm.trim()) return teamMembers;

    const lowerCaseSearchTerm = searchTerm.toLowerCase();

    const matchesSearchTerm = (text: string | null | undefined): boolean => text?.toLowerCase().includes(lowerCaseSearchTerm) ?? false;

    return teamMembers.filter(
        (member) => matchesSearchTerm(member.FullName) || matchesSearchTerm(member.Email) || matchesSearchTerm(member.statusTranslated)
    );
};

const TeamGrid = () => {
    const { team, isLoading, error } = useTeamSelector((state) => state.user);
    const { searchTerm } = useTeamSelector((state) => state.teamSearchFilter);
    const { isStatusLoading, isStatusChange } = useTeamSelector((state) => state.statusUser);
    const { selectedStatus } = useTeamSelector((state) => state.teamStatusFilter);

    const translate = useTranslation();
    const dispatch = useTeamDispatch();
    const { push } = useHistory();

    const transformedTeamMembers = useMemo(
        () =>
            team?.TeamMembers?.map((member) => ({
                ...member,
                statusTranslated: translate(TeamMemberStatusLabels[member.Status as keyof typeof TeamMemberStatusLabels])
            })),
        [team?.TeamMembers, translate]
    );
    const [filteredTeam, setFilteredTeam] = useState<TransformedTeamMember[] | []>([]);

    // On page load get the team data and translations
    useEffect(() => {
        dispatch(GetUserTeamData());
    }, [dispatch, isStatusChange]);

    // Filter team members
    useEffect(() => {
        if (!transformedTeamMembers?.length) return;

        let filteredTeamMembers = searchTeamMembers(transformedTeamMembers, searchTerm);
        filteredTeamMembers = filterTeamMembersByStatus(filteredTeamMembers, selectedStatus.id);
        setFilteredTeam(filteredTeamMembers);
    }, [searchTerm, selectedStatus.id, transformedTeamMembers]);

    // Reset "newUsersAmount" on unmount
    useEffect(() => {
        return () => {
            dispatch(resetNewUsersAmount());
        };
    }, [dispatch]);

    const handleStatusChange = (value: number) => {
        dispatch(setSelectedStatus(value));
        dispatch(setPageSelected(1));
    };

    return (
        <div className='d-flex flex-column flex-fill'>
            {/* Header bar */}
            <HeaderBar>
                <h1>{translate('Team_Label')}</h1>
            </HeaderBar>
            {/* Filter bar */}
            <div className='d-flex flex-column bg-white'>
                <div className='container-fluid pb-3 border-bottom'>
                    <TeamSearchBox />
                </div>
                <div className='px-md-2 py-md-1 border-bottom'>
                    <StatusFilter
                        id='status-filters'
                        options={StatusFilterOptions}
                        selectedStatus={selectedStatus}
                        onChange={handleStatusChange}
                    />
                </div>
            </div>
            {/* Status: pending (showing only on initial load) */}
            {(isLoading || isStatusLoading) && (!team || (team.TeamMembers && team.TeamMembers.length === 0)) && (
                <div className='w-100 h-100 d-flex align-items-center justify-content-center'>
                    <Spinner />
                </div>
            )}
            {/* Status: fulfilled */}
            {team && team.TeamMembers && team.TeamMembers.length > 0 && !error && (
                <>
                    <TeamGridCaption />
                    <TeamsTable teamMembers={filteredTeam} />
                </>
            )}
            {/* No team members */}
            {!isLoading && team && team.TeamMembers?.length === 0 && (
                <EmptyState
                    descriptionLabel={translate('NoTeamMembers_Label')}
                    addButtonLabel={translate('AddUser_Label')}
                    onAdd={() => push('/Team/CreateNewUser')}
                />
            )}
            {/* Status: rejected */}
            {error && <ErrorPage errorCode={error} withTranslations />}
        </div>
    );
};

export default TeamGrid;
