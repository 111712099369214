import { useEffect, useState } from 'react';
import GridCaption from '../../../../common/features/Grid/GridCaption';
import useTranslation from '../../../../common/hooks/useTranslation';
import { StatusFilterOptions } from '../../common/constants/constants-team';
import { useTeamDispatch, useTeamSelector } from '../../redux/hooks';
import { resetDataState, setPageSelected } from '../../redux/reducers/teamGridSettingsSlice';
import { setSearchFilter } from '../../redux/reducers/teamSearchFilterSlice';
import { setSelectedStatus } from '../../redux/reducers/teamStatusFilterSlice';

const TeamGridCaption = () => {
    const { selectedStatus } = useTeamSelector((state) => state.teamStatusFilter);
    const { searchTerm } = useTeamSelector((state) => state.teamSearchFilter);
    const { dataState, totalItems } = useTeamSelector((state) => state.teamGridSettings);
    const [showClear, setShowClear] = useState(false);
    const dispatch = useTeamDispatch();
    const translate = useTranslation();

    useEffect(() => {
        if (selectedStatus.id !== StatusFilterOptions[0].id || searchTerm.length > 0 || dataState.filter !== undefined) {
            setShowClear(true);
        } else {
            setShowClear(false);
        }
    }, [selectedStatus.id, searchTerm, dataState]);

    const clearFilters = () => {
        dispatch(setSelectedStatus(0));
        dispatch(setSearchFilter(''));
        dispatch(resetDataState({ take: 10, skip: 0, sort: [{ field: 'LastLogin', dir: 'desc' }] }));
        dispatch(setPageSelected(1));
    };

    const formatMessage = () => {
        switch (selectedStatus.id) {
            case 'ALL':
                return translate('AllMembers_Label', totalItems);
            case 'ACTIVE':
                return translate('ActiveMembers_Label', totalItems);
            case 'INACTIVE':
                return translate('InactiveMembers_Label', totalItems);
        }
    };

    return (
        <GridCaption clearLabel={translate('Clear_Label')} showClear={showClear} onClear={clearFilters} tableColumnsDropdown={false}>
            <span>{formatMessage()}</span>
        </GridCaption>
    );
};

export default TeamGridCaption;
