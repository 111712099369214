import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../../../../common/features/Button';
import ModalWrapper from '../../../../../../common/features/Wrappers/ModalWrapper';
import useTranslation from '../../../../../../common/hooks/useTranslation';
import { SubscribersModals } from '../../../../../../common/models/SubscribersModals';
import { ClearanceSubscribersSlice } from '../../../../common/models/ReduxSlices';
import { UnsubscribeUsers } from '../../../../redux/actions/clearanceSubscribers';
import { resetUnsubscribe } from '../../../../redux/reducers/clearanceSubscribersSlice';
import { RootState } from '../../../../redux/store';

interface UnsubscribeModalProps {
    subscriberIds: string[];
    clearanceNo: string | undefined | null;
    toggleModal: (prop: keyof SubscribersModals, next?: boolean) => void;
}

const UnsubscribeModal: React.FC<UnsubscribeModalProps> = ({ subscriberIds, clearanceNo, toggleModal }) => {
    const { unsubscribe } = useSelector<RootState, ClearanceSubscribersSlice>((state) => state.clearanceSubscribers);
    const dispatch = useDispatch();
    const translate = useTranslation();

    // On initial load reset "unsubscribe" state
    useEffect(() => {
        dispatch(resetUnsubscribe());
    }, [dispatch]);

    const handleClose = () => {
        toggleModal('remove');
    };

    const handleSubmit = () => {
        dispatch(UnsubscribeUsers(subscriberIds));
        handleClose();
    };

    const isMultiple = subscriberIds.length > 1;

    return (
        <ModalWrapper onEscape={handleClose}>
            <div className='modal-dialog modal-dialog-centered modal-dialog-scrollable'>
                <div className='modal-content'>
                    {/* Heading */}

                    {/* Body */}
                    <div className='modal-body'>
                        <h3 className='modal-title' id='staticBackdropLabel'>
                            {isMultiple
                                ? translate('RemoveMultipleSubscribers_Label', subscriberIds.length.toString(), clearanceNo)
                                : translate('RemoveSingleSubscriber_Label', clearanceNo)}
                        </h3>
                        <p className='note mb-0 notification-message' style={{ width: '100%' }}>
                            {isMultiple
                                ? translate('RemoveMultipleSubscribersMessages_Label')
                                : translate('RemoveSingleSubscriberMessage_Label')}
                        </p>
                    </div>

                    {/* Footer */}
                    <div className='modal-footer'>
                        <Button variant='secondary' size='medium' onClick={handleClose}>
                            {translate('Cancel_Label')}
                        </Button>

                        <Button variant='danger' size='medium' onClick={handleSubmit} disabled={unsubscribe.isLoading ? true : false}>
                            {isMultiple
                                ? translate('YesRemoveSubscribers_Label', subscriberIds.length.toString())
                                : translate('YesRemoveSubscriber_Label')}
                        </Button>
                    </div>
                </div>
            </div>
        </ModalWrapper>
    );
};

export default UnsubscribeModal;
