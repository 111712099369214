import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../../../common/features/Button';
import ModalWrapper from '../../../../../common/features/Wrappers/ModalWrapper';
import useTranslation from '../../../../../common/hooks/useTranslation';
import { ContactsModals } from '../../../../../common/models/ContactsModals';
import { SelectedContact } from '../../../common/models/Contact';
import { ContactsSlice } from '../../../common/models/ReduxSlices';
import { resetRemoveContacts } from '../../../redux/reducers/contactsSlice';
import { RootState } from '../../../redux/store';

interface DeleteContactModalProps {
    contacts: SelectedContact[] | null;
    toggleModal: (prop: keyof ContactsModals, next?: boolean) => void;
    handleSubmit: () => void;
}

const DeleteContactModal: React.FC<DeleteContactModalProps> = ({ contacts, toggleModal, handleSubmit }) => {
    const { removeContacts } = useSelector<RootState, ContactsSlice>((state) => state.contacts);
    const translate = useTranslation();
    const dispatch = useDispatch();

    // On initial load reset "removeContacts" state
    useEffect(() => {
        dispatch(resetRemoveContacts());
    }, [dispatch]);

    const handleClose = () => {
        toggleModal('remove');
    };

    const isMultiple = contacts != null ? contacts.length > 1 : null;

    return (
        <ModalWrapper onEscape={handleClose}>
            <div className='modal-dialog modal-dialog-centered modal-dialog-scrollable'>
                <div className='modal-content'>
                    {/* Heading */}

                    {/* Body */}
                    <div className='modal-body'>
                        <h3 className='modal-title' id='staticBackdropLabel'>
                            {isMultiple ? translate('DeleteContacts_Label') : translate('DeleteContact_Label')}
                        </h3>
                        <p className='note mb-0 notification-message' style={{ width: '100%' }}>
                            {isMultiple ? translate('DeleteContactsMessage_Label') : translate('DeleteContactMessage_Label')}
                        </p>
                    </div>

                    {/* Footer */}
                    <div className='modal-footer'>
                        <Button variant='secondary' size='medium' onClick={handleClose}>
                            {translate('Cancel_Label')}
                        </Button>

                        <Button variant='danger' size='medium' onClick={handleSubmit} disabled={removeContacts.isLoading ? true : false}>
                            {isMultiple
                                ? translate('YesDeleteContacts_Label', removeContacts.removedContactsAmount)
                                : translate('YesDeleteContact_Label')}
                        </Button>
                    </div>
                </div>
            </div>
        </ModalWrapper>
    );
};

export default DeleteContactModal;
