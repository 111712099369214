import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { useSelector } from 'react-redux';
import Spinner from '../../../../../common/features/Spinner';
import useTranslation from '../../../../../common/hooks/useTranslation';
import useTranslationHydration from '../../../../../common/hooks/useTranslationHydration';
import { ClientSelectionSlice } from '../../../../../common/models/ReduxSlices';
import { docTypeCAHashMap, docTypeHashMap } from '../../../common/constants/constants-documents';
import { FileData } from '../../../common/models/ImagingModel';
import { RootState } from '../../../redux/store';
import { getIsSingleEntry } from '../DocumentCompareModal';
import DropDownAndButton from './DropDownAndButton';
import './styles.css';

const pdfjsWorker = require('pdfjs-dist/build/pdf.worker');

interface DocumentLoadSuccess {
    numPages: number;
}
interface props {
    isMobile: boolean;
    arrayOfFiles: FileData[];
    fetchedLink: string;
    setIndex: (updateFunction: (index: number) => number) => void;
    indexOfDocument: number;
    isLoading: boolean;
    isClearances: boolean;
}
const HeaderAndIframe: React.FC<props> = ({ isMobile, arrayOfFiles, fetchedLink, setIndex, indexOfDocument, isLoading, isClearances }) => {
    pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;
    const translateHydration = useTranslationHydration();
    const translate = useTranslation();
    const { initialClientSelection } = useSelector<RootState, ClientSelectionSlice>((state) => state.clientSelection);
    const country = initialClientSelection?.Countries.find((c) => c.IsSelected)?.Code;
    const entryNumberIndex: string = country === 'us' ? '_entryNumber' : '_transactionNumber';
    const isSingleEntry = getIsSingleEntry(arrayOfFiles, entryNumberIndex);
    const [numPages, setNumPages] = useState<number | null>(null);
    const onDocumentLoadSuccess = ({ numPages }: DocumentLoadSuccess) => {
        setNumPages(numPages);
    };
    const formDocTypePartOfTitle = isClearances
        ? country === 'us'
            ? docTypeHashMap[arrayOfFiles[indexOfDocument]._metadataFormat ?? ''].replaceAll(' ', '')
            : docTypeCAHashMap[arrayOfFiles[indexOfDocument]._metadataFormat ?? ''].replaceAll(' ', '') + '_Label'
        : arrayOfFiles[indexOfDocument]._metadataFormat?.replaceAll(' ', '') + '_Label';

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    // backgroundColor: 'rgb(50, 80, 115)',
                    padding: '20px'
                }}
            >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    {!isSingleEntry && (
                        <DropDownAndButton
                            type='entry'
                            isClearances={isClearances}
                            entryNumberIndex={entryNumberIndex}
                            indexOfDocument={indexOfDocument}
                            arrayOfFiles={arrayOfFiles}
                            buttonText={translateHydration('ViewEntries_Label')}
                            isMobile={isMobile}
                            setIndex={setIndex}
                            titleToBeDisplayed={
                                country === 'us'
                                    ? arrayOfFiles[indexOfDocument]._entryNumber ?? ''
                                    : arrayOfFiles[indexOfDocument]._transactionNumber ?? ''
                            }
                            country={country ?? ''}
                        />
                    )}
                    <DropDownAndButton
                        type='document'
                        isClearances={isClearances}
                        entryNumberIndex={entryNumberIndex}
                        indexOfDocument={indexOfDocument}
                        arrayOfFiles={arrayOfFiles}
                        buttonText={translateHydration('ViewDocuments_Label')}
                        isMobile={isMobile}
                        setIndex={setIndex}
                        titleToBeDisplayed={`${translate(formDocTypePartOfTitle)} ${arrayOfFiles[indexOfDocument]?._imageDate?.replaceAll(
                            '-',
                            '/'
                        )}`}
                        country={country ?? ''}
                    />
                </div>

                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <button
                        onClick={() => {
                            if (indexOfDocument === 0) return;
                            if (isClearances)
                                setIndex((number) => {
                                    return number - 1;
                                });
                            for (let i = indexOfDocument - 1; i >= 0; i--) {
                                if (arrayOfFiles[i][entryNumberIndex] === arrayOfFiles[indexOfDocument][entryNumberIndex]) {
                                    setIndex(() => i);
                                    break;
                                }
                            }
                        }}
                        type='button'
                        className={`btn btn-tertiary-white border-0 ml-1 d-none d-xl-inline-block ${isMobile ? 'btn-sm' : ''}`}
                        data-toggle={'tooltip'}
                        title={'Release V1.0'}
                    >
                        <FontAwesomeIcon icon={faChevronLeft} />
                    </button>

                    <button
                        onClick={() => {
                            if (
                                indexOfDocument ===
                                arrayOfFiles.filter((x: FileData) => {
                                    if (x[entryNumberIndex] === arrayOfFiles[indexOfDocument][entryNumberIndex]) return x;
                                }).length -
                                    1
                            )
                                return;

                            if (isClearances)
                                setIndex((number) => {
                                    return number + 1;
                                });

                            for (let i = indexOfDocument + 1; i <= arrayOfFiles.length; i++) {
                                if (arrayOfFiles[i][entryNumberIndex] === arrayOfFiles[indexOfDocument][entryNumberIndex]) {
                                    setIndex(() => i);
                                    break;
                                }
                            }
                        }}
                        type='button'
                        className={`btn btn-tertiary-white border-0 ml-1 d-none d-xl-inline-block ${isMobile ? 'btn-sm' : ''}`}
                        data-toggle={'tooltip'}
                        title={'Release V2.0'}
                    >
                        <FontAwesomeIcon icon={faChevronRight} />
                    </button>
                </div>
            </div>
            {!isLoading && (
                <div
                    style={{
                        height: '700px',
                        overflowY: 'auto'
                    }}
                >
                    <div style={{ height: 'auto' }} className='text-light p-0 h-100'>
                        <Document className='compare-pdf-container' file={fetchedLink} onLoadSuccess={onDocumentLoadSuccess}>
                            {Array.from(new Array(numPages), (el, index) => (
                                <Page
                                    key={`page_${index + 1}`}
                                    pageNumber={index + 1}
                                    renderAnnotationLayer={false}
                                    renderTextLayer={false}
                                    className='compare-pdf-page'
                                />
                            ))}{' '}
                        </Document>
                    </div>
                </div>
            )}
            {isLoading && (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%',
                        flexDirection: 'column'
                    }}
                >
                    <Spinner />
                    <p>Loading documents...</p>
                </div>
            )}
        </>
    );
};

export default HeaderAndIframe;
