import { createSlice } from '@reduxjs/toolkit';
import { ShipmentListSlice } from '../../../clearances/common/models/ReduxSlices';
import { ShipmentListItem } from '../../../clearances/common/models/ShipmentList';
import { GetShipmentList } from '../../../clearances/redux/actions/shipmentList';

const initialState: ShipmentListSlice = {
    totalSelected: 0,
    totalInProgress: 0,
    totalOnHold: 0,
    totalCompleted: 0,
    shipmentList: [],
    allShipmentList: [],
    allSelectedEntries: [],
    documentsIndex: null,
    docsIndexIsLoading: false,
    isLoading: false,
    error: undefined
};
const clearancesSnapshotsListSlice = createSlice({
    name: 'clearancesSnapshotsListSlice',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(GetShipmentList.pending, (state, action) => {
                state.isLoading = true;
                state.error = undefined;
            })
            .addCase(GetShipmentList.fulfilled, (state, action) => {
                state.isLoading = false;
                state.totalInProgress = action.payload.totalInProgress ? action.payload.totalInProgress : 0;
                state.totalOnHold = action.payload.totalOnHold ? action.payload.totalOnHold : 0;
                state.totalCompleted = action.payload.totalCompleted ? action.payload.totalCompleted : 0;
                state.shipmentList = action.payload.shipmentList
                    ? action.payload.shipmentList.map((dataItem: ShipmentListItem) => Object.assign({ selected: false }, dataItem))
                    : [];
                const allShipmentListArray = action.payload.shipmentList
                    ? [
                          ...state.allShipmentList,
                          ...action.payload.shipmentList.map((dataItem: ShipmentListItem) => Object.assign({ selected: false }, dataItem))
                      ]
                    : [];
                if (allShipmentListArray) {
                    state.allShipmentList = allShipmentListArray.reduce((accumulator, current) => {
                        if (!accumulator.find((item: ShipmentListItem) => item.EntryNumber === current.EntryNumber)) {
                            //@ts-ignore
                            accumulator.push(current);
                        }
                        return accumulator;
                    }, []);
                }
            })
            .addCase(GetShipmentList.rejected, (state, action) => {
                state.isLoading = false;
                if (typeof action.payload === 'number') {
                    state.error = action.payload;
                } else {
                    state.error = action.error.message;
                }
            });
    }
});

export default clearancesSnapshotsListSlice.reducer;
