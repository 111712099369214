import 'jquery-mask-plugin';
import React, { Component } from 'react';
import { connect } from 'react-redux';

class Navigation extends Component {
    initESW = (gslbBaseURL) => {
        let embedded_svc = window.embedded_svc;
        embedded_svc.settings.displayHelpButton = true; //Or false
        embedded_svc.settings.language = ''; //For example, enter 'en' or 'en-US'
        embedded_svc.settings.enabledFeatures = ['LiveAgent'];
        embedded_svc.settings.entryFeature = 'LiveAgent';

        embedded_svc.init(
            this.props.menuModel.LiveChatSaleforceUrl,
            this.props.menuModel.LiveChatSecureForceUrl,
            gslbBaseURL,
            '00D60000000J7iW',
            'Live_Agent_MLP',
            {
                baseLiveAgentContentURL: this.props.menuModel.LiveChatAgentContentUrl,
                deploymentId: '5720d0000008OuC',
                buttonId: '5733g0000004C9v',
                baseLiveAgentURL: this.props.menuModel.LiveChatAgentUrl,
                eswLiveAgentDevName: 'EmbeddedServiceLiveAgent_Parent04I0d0000004C9DEAU_1697187103e',
                isOfflineSupportEnabled: true
            }
        );
    };
    Livingston_Live_Agent_Chat = () => {
        if (!window.embedded_svc) {
            let s = document.createElement('script');
            s.setAttribute('src', 'https://livingstonintl.my.salesforce.com/embeddedservice/5.0/esw.min.js');
            s.onload = () => {
                this.initESW(null);
            };
            document.body.appendChild(s);
        } else {
            this.initESW(this.props.menuModel.LiveChatServiceForceUrl);
        }
    };
    render() {
        let navigationItems = this.props.data.map((item, index) => {
            const itemId = item.id;
            const cssClass = this.props.cssClassListItem + ' lii_' + itemId;
            let navItem = '';
            if (item.newTab) {
                navItem = (
                    <a target='_blank' rel='noreferrer' href={item.url}>
                        {this.props.translations.Phrases[item.text]}
                    </a>
                );
            } else {
                if (item.id === 'menu-item-23650') {
                    navItem = (
                        <button className='btn btn-link' onClick={() => this.Livingston_Live_Agent_Chat()}>
                            {this.props.translations.Phrases[item.text]}
                        </button>
                    );
                } else {
                    navItem = <a href={item.url}>{item.text}</a>;
                }
            }
            return (
                <li key={index.toString()} id={itemId} className={cssClass}>
                    {navItem}
                </li>
            );
        });

        return (
            <ul className={this.props.cssClassList}>
                {navigationItems}
                {this.props.children}
            </ul>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        menuModel: state.menu.model,
        translations: state.translations.headerModel
    };
};

export default connect(mapStateToProps)(Navigation);
