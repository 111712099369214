import { faComments, faGraduationCap, faPhone } from '@fortawesome/free-solid-svg-icons';
import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import LiveChat from '../../../common/features/LiveChat';
import useTranslationHydration from '../../../common/hooks/useTranslationHydration';
import { HydrationSlice, LanguageSlice } from '../../../common/models/ReduxSlices';
import { RootState } from '../../../redux/store';
import useGTMEventTracking from '../../hooks/useGTMEventTracking';
import SidebarNavFooterHelpItem from './SidebarNavFooterHelpItem';
import SidebarNavLanguageSelector from './SidebarNavLanguageSelector';

interface SidebarNavFooterProps {
    onLanguageChange: (payload: number) => void;
}

const SidebarNavFooter: React.FC<SidebarNavFooterProps> = ({ onLanguageChange }) => {
    const { languageSelected } = useSelector<RootState, LanguageSlice>((state) => state.language);
    const { user } = useSelector<RootState, HydrationSlice>((state) => state.hydration);
    const resourceURL = languageSelected?.code === 'en' ? user?.ContactUs?.EnglishHelpUrl : user?.ContactUs?.FrenchHelpUrl;
    const translate = useTranslationHydration();

    const liveChatRef = useRef<HTMLAnchorElement>(null);
    const resourceCenterRef = useRef<HTMLAnchorElement>(null);
    useGTMEventTracking('Live_Chat_Main_CPEvt', { location: 'Need Help' }, liveChatRef);
    useGTMEventTracking('Resource_Center_Main_CPEvt', { location: 'Need Help' }, resourceCenterRef);

    const startLiveChat = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        LiveChat({ liveChatSettings: user?.ContactUs?.LiveChatSettings });
    };

    return (
        <div className='sidebar-footer p-4 mb-2'>
            {user?.ContactUs && (
                <>
                    <h5 className='mb-2'>{translate('NeedHelp_Label')}</h5>

                    <div className='list-group sidebar-list-group mt-0 mb-4'>
                        <SidebarNavFooterHelpItem
                            title={user.ContactUs.Phone ?? ''}
                            url={`tel: ${user.ContactUs.Phone ?? ''}`}
                            icon={faPhone}
                        />
                        <SidebarNavFooterHelpItem
                            ref={resourceCenterRef}
                            title={translate('ResourceCenter_Label')}
                            url={resourceURL}
                            target='_blank'
                            icon={faGraduationCap}
                        />
                        <SidebarNavFooterHelpItem
                            ref={resourceCenterRef}
                            title={translate('LiveChat_Label')}
                            icon={faComments}
                            onClick={startLiveChat}
                        />
                        {/* {user.InternalUser && (
                            <SidebarNavFooterHelpItem
                                title={translate('PortalFaqGuide_Label')}
                                icon={faQuestionCircle}
                                url={PortalRoutes.faq}
                            />
                        )} */}
                    </div>
                    <h5 className='mb-3'>{translate('PreferredLanguage_Label')}</h5>
                    <SidebarNavLanguageSelector onLanguageChange={onLanguageChange} />
                </>
            )}
        </div>
    );
};

export default SidebarNavFooter;
