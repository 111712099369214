import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { Alert } from '../models/Alert';
import { Notice } from '../models/Notice';
import { HydrationSlice, NoticesSlice } from '../models/ReduxSlices';

/**
 * Hook for getting alerts. There are two types of alerts:
 * - Status alerts ('on holds' and 'in progress')
 * - Notices
 * Status alert (on hold):
 * {
 *   id: 'a1',
 *   type: 'ON_HOLD',
 *   date: '2021-11-11T13:41:00+01:00',
 *   entryNumber: <EntryNumber>,
 *   agencyKey: <Agency>
 * }
 * Status alert (in progress):
 * {
 *   id: 'a1',
 *   type: 'IN_PROGRESS',
 *   date: '2021-11-11T13:41:00+01:00',
 *   entryNumber: <EntryNumber>,
 *   agencyKey: <Agency>
 * }
 * Notices:
 * {
 *   id: 'a1',
 *   type: 'NOTICE',
 *   date: '2021-11-11T13:41:00+01:00',
 *   title: <Title>
 *   description: <Content>
 * }
 * All data is already sorted by date in descending order (newest alerts are showing first).
 *
 */

const useAlerts = () => {
    const [result, setResult] = useState<null | { statusAlerts: Alert[]; notices: Notice[] }>(null);
    const { user } = useSelector<RootState, HydrationSlice>((state) => state.hydration);
    const { activeNotices } = useSelector<RootState, NoticesSlice>((state) => state.notices);

    useEffect(() => {
        // Store Alerts and Notices in separate arrays
        let userStatusAlerts: Alert[] = [];
        let userNotices: Notice[] = [];
        // Check first if user is defined before transforming each array
        // On-hold alerts
        if (user?.ClearanceAlerts?.length) {
            userStatusAlerts = user.ClearanceAlerts.map((alert) => {
                return {
                    id: `${Math.random()}${alert.EntryNumber ?? ''}`,
                    type: alert.AlertStatus ?? '',
                    date: alert.HoldEventDateTime ?? '',
                    entryNumber: alert.EntryNumber ?? '',
                    agencyKey: alert.AgencyKey ?? '',
                    customsInfoID: alert.CustomsInfoID ?? 0
                };
            });
        }
        // Notices
        if (activeNotices) {
            userNotices = activeNotices.map((notice) => ({
                id: Math.random() + notice.Id,
                type: 'NOTICE',
                date: notice.EffectiveDateFrom,
                title: notice.Title,
                description: notice.Content
            }));
        }
        // Create new array and show alerts before notices
        setResult({ statusAlerts: [...userStatusAlerts], notices: [...userNotices] });
    }, [user?.ClearanceAlerts, activeNotices]);

    return result;
};

export default useAlerts;
