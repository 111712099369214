import { useEffect, useMemo } from 'react';
import useTranslation from '../../../../common/hooks/useTranslation';
import { GetEDMClientServices } from '../../redux/actions/teamManagement';
import { useTeamDispatch, useTeamSelector } from '../../redux/hooks';
import {
    resetClientsAndPermissions,
    toggleSelectAllClientServices,
    updateClientServicesCheckbox
} from '../../redux/reducers/clientsAndPermissionsSlice';
import ClientsAndPermissionsTable from './ClientsAndPermissionsTable';
import TeamSectionWrapper from './TeamSectionWrapper';

const ClientsAndPermissions = () => {
    const { user } = useTeamSelector((state) => state.hydration);
    const { data, isPending, adminRole, isLoading, isLoadingClientStatuses } = useTeamSelector((state) => state.clientsAndPermissions);
    const isEditable = useMemo(() => user?.Roles?.includes('TeamManagement_Edit'), [user?.Roles]);
    const { data: userDetailsData } = useTeamSelector((state) => state.editTeamMember);

    const dispatch = useTeamDispatch();
    const translate = useTranslation();

    const teamMember = userDetailsData?.Users?.[0];
    const isPendingStatus =
        teamMember?.Status === 'PendingUserActivation' ||
        teamMember?.Status === 'PendingFeatureSetup' ||
        teamMember?.Status === 'EmailChangedConfirmRequired' ||
        teamMember?.Status === 'PendingUserConfirmRequired';
    const isInactiveStatus =
        teamMember?.Status === 'Cancelled' ||
        teamMember?.Status === 'Deactivated' ||
        teamMember?.Status === 'Expired' ||
        teamMember?.Status === 'Hold';
    const isDisabled = !isEditable || isPending || isPendingStatus || isInactiveStatus;

    useEffect(() => {
        if (data?.ParentServices.length && isLoadingClientStatuses === undefined) {
            dispatch(GetEDMClientServices(data.ParentServices.map((x) => x.Number)));
        }
    }, [data]);

    // Reset "clientsAndPermissions" slice on unmount
    useEffect(() => {
        return () => {
            dispatch(resetClientsAndPermissions());
        };
    }, [dispatch]);

    return (
        <TeamSectionWrapper title={translate('ClientsAndPermissionsHeader_Label')} isLoading={isLoading || isLoadingClientStatuses}>
            <>
                {data?.ParentServices.length &&
                    data?.ParentServices.map((service, index) => (
                        <ClientsAndPermissionsTable
                            key={index}
                            isDisabled={isDisabled}
                            title={service.Number + ' - ' + service.Name}
                            clientServices={service.ClientServices}
                            adminRole={adminRole}
                            onSelect={(options) => dispatch(updateClientServicesCheckbox(options))}
                            onToggleSelectAll={(options) => dispatch(toggleSelectAllClientServices(options))}
                        />
                    ))}
            </>
        </TeamSectionWrapper>
    );
};

export default ClientsAndPermissions;
