import { useEffect, useState } from 'react';

const usePageLoad = () => {
    const [pageLoading, setPageLoading] = useState<boolean>(true);

    // Wait until the page is fully loaded (css, images...) before showing it
    useEffect(() => {
        const onPageLoad = () => {
            setPageLoading(false);
        };
        // Check if the page has already loaded
        if (document.readyState === 'complete') {
            onPageLoad();
        } else {
            window.addEventListener('load', onPageLoad);
        }
        // Cleanup function
        return () => window.removeEventListener('load', onPageLoad);
    }, []);

    return pageLoading;
};

export default usePageLoad;
