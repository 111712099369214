import React from 'react';
import Loader from 'react-loader-spinner';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import styled from 'styled-components';
import BackDrop from './common/Backdrop/Backdrop';

const Fallback = () => {
    return (
        <BackDrop show={true}>
            <FallbackContainer>
                <Loader type='Watch' color='#A9A9A9' height={100} width={100} />
            </FallbackContainer>
        </BackDrop>
    );
};

const FallbackContainer = styled.div`
    position: fixed;
    top: 47%;
    left: 47%;
    transform: translate(-50% -50%);
`;

export default Fallback;
