import { createSlice } from '@reduxjs/toolkit';
import { OverviewDashboardSlice } from '../../comon/models/overviewDashboard';
import { GetShipmentList } from '../actions/shipmentList';

const initialState: OverviewDashboardSlice = {
    totalSelected: 0,
    totalInProgress: undefined,
    totalOnHold: undefined,
    totalCompleted: undefined,
    isLoading: false,
    error: undefined
};

const overviewDashboardSlice = createSlice({
    name: 'overviewDashboard',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(GetShipmentList.pending, (state, action) => {
                state.isLoading = true;
                state.error = undefined;
            })
            .addCase(GetShipmentList.fulfilled, (state, action) => {
                state.isLoading = false;
                state.totalInProgress = action.payload.totalInProgress ? action.payload.totalInProgress : 0;
                state.totalOnHold = action.payload.totalOnHold ? action.payload.totalOnHold : 0;
                state.totalCompleted = action.payload.totalCompleted ? action.payload.totalCompleted : 0;
            })
            .addCase(GetShipmentList.rejected, (state, action) => {
                state.isLoading = false;
                if (typeof action.payload === 'number') {
                    state.error = action.payload;
                } else {
                    state.error = action.error.message;
                }
            });
    }
});

//export const {} = overviewDashboardSlice.actions;
export default overviewDashboardSlice.reducer;
