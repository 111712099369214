import { CompositeFilterDescriptor, FilterDescriptor } from '@progress/kendo-data-query';
import React, { useReducer, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../../../../common/features/Button';
import CarrierIcon from '../../../../../../common/features/CarrierIcon';
import Checkbox from '../../../../../../common/features/Checkbox';
import ClearSearchInputButton from '../../../../../../common/features/FilterBar/SearchBox/ClearSearchInputButton';
import RadioButton from '../../../../../../common/features/RadioButton';
import useSearchTags from '../../../../../../common/hooks/useSearchTags';
import useTranslation from '../../../../../../common/hooks/useTranslation';
import { MOTFilter, MOTMap } from '../../../../common/constants/constants-clearances';
import DateComponent from '../../../../common/features/dateTimePicker/DateComponent';
import { AdvancedSearchDateRange, AdvancedSearchMOT, MOTKeys } from '../../../../common/models/AdvancedSearch';
import { AdvancedSearchSettingsSlice } from '../../../../common/models/ReduxSlices';
import { setAdvancedSearchSettings } from '../../../../redux/reducers/advancedSearchSettingsSlice';
import { setCustomDateRange, setDateRangeSelected } from '../../../../redux/reducers/dateRangeSlice';
import { setSearchFilter } from '../../../../redux/reducers/searchSlice';
import { setMOTFilter, setPageSelected } from '../../../../redux/reducers/shipmentGridSettingsSlice';
import { resetStatus } from '../../../../redux/reducers/shipmentStatusSlice';
import { RootState } from '../../../../redux/store';

interface AdvancedSearchProps {
    placeholder?: string;
    showMobileSearch: boolean;
    onToggleMobileSearch: () => void;
    onToggleAdvancedSearch?: () => void;
}

interface AdvancedSearchAction {
    type: 'UPDATE_MOT' | 'UPDATE_DATE_RANGE' | 'UPDATE_CUSTOM_DATE_RANGE_DATES';
    payload?: string;
    startDate?: string;
    endDate?: string;
}

const advancedSearchReducer = (state: AdvancedSearchSettingsSlice, action: AdvancedSearchAction) => {
    const { type, payload, startDate, endDate } = action;
    switch (type) {
        case 'UPDATE_MOT':
            if (payload !== undefined) {
                return {
                    ...state,
                    mot: state.mot.map((item) => (item.id === payload ? { ...item, isChecked: !item.isChecked } : item))
                };
            }
            return { ...state };
        case 'UPDATE_DATE_RANGE':
            if (payload !== undefined) {
                return {
                    ...state,
                    dateRange: state.dateRange.map((item) =>
                        item.itemId === payload ? { ...item, isChecked: true } : { ...item, isChecked: false }
                    )
                };
            }
            return { ...state };
        case 'UPDATE_CUSTOM_DATE_RANGE_DATES':
            if (payload !== undefined) {
                return {
                    ...state,
                    dateRange: state.dateRange.map((item) =>
                        item.itemId === payload ? { ...item, startDate: startDate, endDate: endDate } : { ...item }
                    )
                };
            }
            return { ...state };
        default:
            return { ...state };
    }
};

const AdvancedSearch: React.FC<AdvancedSearchProps> = ({ placeholder, showMobileSearch, onToggleAdvancedSearch, onToggleMobileSearch }) => {
    const advancedSearchSettings = useSelector<RootState, AdvancedSearchSettingsSlice>((state) => state.advancedSearchSettings);
    const [state, dispatch] = useReducer(advancedSearchReducer, advancedSearchSettings);
    const [showCustomDateRange, setShowCustomDateRange] = useState<boolean>(false);
    const dispatchRedux = useDispatch();
    const translate = useTranslation();
    const { value, handleBlur, handleChange, handleKeyDown, handleReset } = useSearchTags({
        inputValue: advancedSearchSettings.tagsInput,
        onEnterKey: (payload) => handleSubmit(payload)
    });

    const handleSubmit = (payload?: { filteredInput: string; filteredTags: string[] }) => {
        let searchTerm: { inputValue: string; tags: string[] };
        const selectedMOT: MOTKeys[] = state.mot.filter((item) => (item.isChecked ? item : null)).map((item) => item.id);
        const dataStateMOTFilters: FilterDescriptor[] = MOTFilter.filter((item) => selectedMOT.includes(item.id)).map((item) => {
            return { field: 'ModeOfTransport', operator: 'eq', value: translate(item.label) };
        });
        const dataStateMOTFilter: CompositeFilterDescriptor = { logic: 'or', filters: dataStateMOTFilters };

        if (payload?.filteredInput) {
            searchTerm = { inputValue: payload.filteredInput, tags: payload.filteredTags };
        } else {
            searchTerm = { inputValue: value, tags: value.split(', ') };
        }

        // Update the advancetSearchSettings slice
        dispatchRedux(
            setAdvancedSearchSettings({
                mot: state.mot,
                dateRange: state.dateRange,
                tagsInput: searchTerm.inputValue,
                tags: searchTerm.tags
            })
        );
        // Set dateRange dropdown filter to the selected dateRange
        const selectedDateRangeIndex = state.dateRange.findIndex((item) => item.isChecked);
        if (selectedDateRangeIndex !== -1) {
            if (selectedDateRangeIndex !== 6) {
                dispatchRedux(setDateRangeSelected(selectedDateRangeIndex));
            } else {
                dispatchRedux(setDateRangeSelected(selectedDateRangeIndex));

                var arr = [];
                if (state.dateRange[6].startDate?.length) {
                    arr.push({ type: 'startdate', date: state.dateRange[6].startDate });
                }
                if (state.dateRange[6].endDate?.length) {
                    arr.push({ type: 'enddate', date: state.dateRange[6].endDate });
                }

                dispatchRedux(setCustomDateRange(arr));
            }
        }
        // Set status radio buttons filter to "ALL"
        dispatchRedux(resetStatus());
        // Update searchFilter slice
        dispatchRedux(setSearchFilter(searchTerm.tags));
        // Update the MOT filter inside the Grid
        dispatchRedux(setMOTFilter({ selectedMOT, dataStateMOTFilter }));

        // Close Advanced Search form
        if (onToggleAdvancedSearch) {
            onToggleAdvancedSearch();
        }
        // If mobile search is open close it
        if (showMobileSearch) {
            onToggleMobileSearch();
        }
        // Set grid page to 1
        dispatchRedux(setPageSelected(1));
    };

    const renderAdvsncedSearchOptions = () => {
        return (
            <div className='dropdown-menu dropdown-menu-right advanced-search-container show'>
                <div className={!showMobileSearch ? 'd-flex flex-column justify-content-between inner-wrapper' : ''}>
                    <div className='advanced-search-heading'>
                        <h4 className='advanced-search-title'>{translate('SearchClearances_Label')}</h4>
                    </div>
                    <div className='advanced-search-filters'>
                        <div className='advanced-search-input'>
                            <div className='form-group position-relative mb-0'>
                                <input
                                    type='text'
                                    placeholder={placeholder}
                                    value={value}
                                    className='form-control text-truncate'
                                    onChange={handleChange}
                                    onKeyDown={handleKeyDown}
                                    onBlur={handleBlur}
                                />
                                <ClearSearchInputButton inputValue={value} onClick={() => handleReset()} />
                            </div>
                            <p>{translate('AdvancedSearchNote_Label')}</p>
                        </div>
                        {/* MOT checkboxes */}
                        <div className='advanced-search-mot'>
                            <h4 className='filter-title'>{translate('ModeOfTransport_Label')}</h4>
                            <form className='d-flex flex-column'>
                                {state.mot.map((item: AdvancedSearchMOT) => {
                                    return (
                                        <div
                                            className='form-check custom-control custom-checkbox d-flex justify-content-between align-items-center'
                                            key={`${item.id}-advanced-search-mot`}
                                        >
                                            <Checkbox
                                                id={`${item.id}-advanced-search-mot`}
                                                name={item.id}
                                                isChecked={item.isChecked}
                                                onChange={(key) => dispatch({ type: 'UPDATE_MOT', payload: key })}
                                            >
                                                {translate(item.label)}
                                            </Checkbox>
                                            <div className='lead-icon'>
                                                <CarrierIcon mot={item.id} MOTMap={MOTMap} />
                                            </div>
                                        </div>
                                    );
                                })}
                            </form>
                        </div>
                        {/* Date range radio buttons */}
                        <div className='advanced-search-date'>
                            <h4 className='filter-title mt-1'>{translate('LastModified_Label')}</h4>
                            <form>
                                {state.dateRange.map((date: AdvancedSearchDateRange) => {
                                    if (date.id !== 'CUSTOM') {
                                        return (
                                            <RadioButton
                                                key={date.itemId}
                                                id={date.itemId}
                                                name='searchDateRange'
                                                isChecked={date.isChecked}
                                                onChange={() => dispatch({ type: 'UPDATE_DATE_RANGE', payload: date.itemId })}
                                            >
                                                {translate(date.label)}
                                            </RadioButton>
                                        );
                                    } else {
                                        return (
                                            <RadioButton
                                                key={date.itemId}
                                                id={date.itemId}
                                                name='searchDateRange'
                                                isChecked={date.isChecked}
                                                onChange={() => {
                                                    dispatch({ type: 'UPDATE_DATE_RANGE', payload: date.itemId });
                                                }}
                                                onClick={() => setShowCustomDateRange(true)}
                                            >
                                                {translate('CustomDateRange_Label')}
                                            </RadioButton>
                                        );
                                    }
                                })}
                            </form>
                        </div>
                    </div>
                    <div className='advanced-search-button-container'>
                        <Button variant='ghost' size='large' onClick={onToggleAdvancedSearch}>
                            {translate('Cancel_Label')}
                        </Button>
                        <Button variant='primary' size='large' onClick={() => handleSubmit()}>
                            {translate('SearchClearances_Label')}
                        </Button>
                    </div>
                </div>
            </div>
        );
    };

    const renderCustomDateRange = () => {
        return (
            <div className='dropdown'>
                <div id='DateRangePicker' className='dropdown-menu  dropdown-menu-right show'>
                    <div className='card shadow-none border-none'>
                        <div className='card-header px-4 py-3'>
                            <h5 className='m-0'>{translate('SelectCustomDateRange_Label')}</h5>
                        </div>
                        <div className='card-body px-4 py-3'>
                            <DateComponent
                                placeholder={translate('SelectStartDate_Label')}
                                dateLabel={translate('StartDate_Label')}
                                valMsg='Invalid date message'
                                noBorder
                                noHeader
                                value={state.dateRange[6].startDate}
                                onDateChanged={(value: string) =>
                                    dispatch({
                                        type: 'UPDATE_CUSTOM_DATE_RANGE_DATES',
                                        payload: 'CUSTOM-advanced-search-date-range',
                                        startDate: value,
                                        endDate: state.dateRange[6].endDate
                                    })
                                }
                                medium
                                className='pb-3'
                            />
                            <DateComponent
                                placeholder={translate('SelectEndDate_Label')}
                                dateLabel={translate('EndDate_Label')}
                                valMsg='Invalid date message'
                                noBorder
                                noHeader
                                value={state.dateRange[6].endDate}
                                onDateChanged={(value: string) =>
                                    dispatch({
                                        type: 'UPDATE_CUSTOM_DATE_RANGE_DATES',
                                        payload: 'CUSTOM-advanced-search-date-range',
                                        endDate: value,
                                        startDate: state.dateRange[6].startDate
                                    })
                                }
                                medium
                            />
                        </div>
                        <div className='card-footer d-flex px-4 py-3'>
                            <button
                                type='button'
                                className='btn btn-secondary cancel-custom-date-range mr-1 flex-fill text-center'
                                onClick={(e) => {
                                    setShowCustomDateRange(false);
                                    $('#FilterButton').attr('data-toggle', 'dropdown');
                                    e.stopPropagation();
                                }}
                            >
                                {translate('Cancel_Label')}
                            </button>
                            <button
                                type='button'
                                className='btn btn-primary apply-custom-date-range ml-1 flex-fill text-center'
                                onClick={() => {
                                    $('#DateRangePicker').removeClass('show');
                                    setShowCustomDateRange(false);
                                    $('#FilterButton').attr('data-toggle', 'dropdown');
                                    if (state.dateRange[6].startDate?.length || state.dateRange[6].endDate?.length) {
                                        handleSubmit();
                                    }
                                }}
                            >
                                {translate('Apply_Label')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <>
            <>
                {!showCustomDateRange && renderAdvsncedSearchOptions()}
                {showCustomDateRange && renderCustomDateRange()}
            </>
            {/* Overlay div */}
            <div
                style={{ position: 'fixed', top: '0', left: '0', bottom: '0', right: '0', width: '100%', height: '100%' }}
                onClick={onToggleAdvancedSearch}
            ></div>
        </>
    );
};

export default AdvancedSearch;
