import axios from 'axios';
import $ from 'jquery';
import moment from 'moment';
import toastr from 'toastr';
import axiosInstance, { cancelRequests } from '../../../axios-mlp';
import { imaging } from '../../../constants/mlp-constants';
import { formatTranslated, getSearchTypesBySelectedServices, pluck, printPage } from '../../../utils/mlp-utils';
import * as actionTypes from './imagingActionTypes';

const CancelToken = axios.CancelToken;
let cancelRequest = null;

export const setImages = (viewModel) => {
    return {
        type: actionTypes.SET_IMAGES,
        model: viewModel
    };
};

export const dropRequests = () => {
    return () => {
        cancelRequests();
    };
};

export const initViewImages = () => {
    return (dispatch) => {
        $('#overlay').show();
        axiosInstance
            .get('/Imaging/Index')
            .then((response) => {
                let model = JSON.parse(response.data);
                dispatch(setImages(model));
            })
            .catch((error) => {
                if (error.response && error.response.status !== 401 && error.response.status !== 403) {
                    window.location.href = window.location.protocol + '//' + document.location.host + '/BasicError';
                }
            })
            .finally(() => {
                $('#overlay').hide();
            });
    };
};

export const initImageModel = (translations) => {
    return {
        type: actionTypes.INIT_IMAGE_MODEL,
        translations: translations
    };
};

export const submitImageSearch = (model) => {
    return (dispatch) => {
        dispatch(resetSubmitImageSearchFlag());
        if (getSearchTypesBySelectedServices(model).length > 0) {
            if (cancelRequest) {
                cancelRequests();
            }
            $('#grid-loader').show();
            let toPost = JSON.parse(JSON.stringify(model));
            toPost.SearchResults = [];
            toPost.GridModel = [];
            const config = {
                cancelToken: new CancelToken(function executor(c) {
                    cancelRequest = c;
                })
            };
            axiosInstance
                .post('/Imaging/SubmitImageSearch', toPost, config)
                .then((response) => {
                    const data = JSON.parse(response.data);
                    dispatch(afterSubmitImageSearch(data.Results, data.TotalHits, data.TotalEntries));
                })
                .catch((error) => {
                    if (axios.isCancel(error)) {
                        return;
                    } else if (error.response) {
                        if (error.response.status === 400) {
                            toastr.warning(error.response.data);
                        } else {
                            toastr.error(error.response.data);
                        }
                    }
                })
                .finally(() => {
                    $('#grid-loader').hide();
                    cancelRequest = null;
                });
        }
    };
};

export const resetSubmitImageSearchFlag = () => {
    return {
        type: actionTypes.RESET_SUBMIT_IMAGE_SEARCH_FLAG
    };
};

export const afterSubmitImageSearch = (results, totalResults, totalEntries) => {
    return {
        type: actionTypes.SUBMIT_IMAGE_SEARCH,
        payload: {
            results: results,
            totalResults: totalResults,
            totalEntries: totalEntries
        }
    };
};

export const updateGridModel = (model, translations, selectedPids) => {
    const gridModel = getGridModel(model, translations, selectedPids);
    return {
        type: actionTypes.UPDATE_GRID_MODEL,
        gridModel: gridModel
    };
};

export const getGridModel = (model, translations, selectedPids) => {
    if (model.SearchResults.length === 0) {
        return [];
    }

    const grouping = model.ImageGroupings.find((imageGrouping) => imageGrouping.IsSelected === true);
    const groupingAttr = grouping.GroupingAttribute;
    let grouped = [];

    model.SearchResults.forEach((x) => {
        const key = x[groupingAttr];
        let grouping = grouped.find((g) => g.key === key);
        if (grouping === undefined) {
            grouping = {
                key: key,
                group: []
            };
            grouped.push(grouping);
        }

        grouping.group.push(x);
    });

    let allPreviousSelectedItems = [];

    if (selectedPids && selectedPids.length > 0) {
        allPreviousSelectedItems = allPreviousSelectedItems.concat(selectedPids);
    } else {
        // filter by selected groups, then selected items within selected groups
        // gets all currently selected pids, used to recheck items after applying a new filter to the page
        if (model.GridModel !== undefined) {
            const previousSelectedGroupings = model.GridModel.filter((gridGrouping) => gridGrouping.isSelected === true);
            previousSelectedGroupings.forEach((x) => {
                const previousSelectedItems = x.documents.filter((gridGrouping) => gridGrouping.isSelected === true);
                const previousSelectedPids = pluck(previousSelectedItems, 'pid');
                allPreviousSelectedItems = allPreviousSelectedItems.concat(previousSelectedPids);
            });
        }
    }

    let gridModel = [];
    grouped.forEach((x) => {
        // create new result groupings, maintain checked items
        gridModel.push(createResultGrouping(x.group, allPreviousSelectedItems, grouping, translations));
    });

    return gridModel;
};

export const createResultGrouping = (rows, allPreviousItems, grouping, translations) => {
    const documents = rows.map((r) => {
        return {
            isSelected: allPreviousItems.indexOf(r._pid) !== -1,
            documentUrl: r._fileS3Path,
            pid: r._pid,
            discriminator: r[grouping.DocumentDiscriminatorAttribute],
            parentGroupId: r[grouping.GroupingAttribute],
            metadata: getRowMetadata(r, grouping, translations)
        };
    });

    return {
        isSelected: documents.some((document) => document.isSelected === true),
        groupId: rows[0][grouping.GroupingAttribute],
        metadata: getGroupMetadata(rows[0], grouping, translations),
        documents: documents
    };
};

export const getRowMetadata = (row, grouping, translations) => {
    const rowAttributes = grouping.DocumentAttributes;
    let metadata = [];

    for (let i in row) {
        if (row.hasOwnProperty(i)) {
            const attribute = rowAttributes.find((a) => a.Attribute === i);
            metadata.push(createAttribute(translations, i, attribute, row[i]));
        }
    }

    // add title attribute
    let titleLabels = [];
    let titleValues = [];
    const attributes = rowAttributes.filter((a) => a.IsTitleAttribute === true);
    attributes.forEach((x) => {
        let attr = metadata.find((md) => md.attribute === x.Attribute);
        titleLabels.push(formatTranslated(x.Name, translations.Phrases));
        if (attr) {
            attr.isTitleAttribute = false;
            titleValues.push(attr.value);
        } else {
            titleValues.push('');
        }
    });
    metadata.push({
        attribute: titleLabels.join(','),
        label: titleLabels.join(', '),
        value: titleValues.join(', '),
        isTitleAttribute: true
    });

    // for all attributes that should be here but are not
    rowAttributes.forEach((x) => {
        if (metadata.some((md) => md.attribute === x.Attribute)) return; // already in metadata
        metadata.push(createAttribute(translations, x.Attribute, x));
    });

    return metadata;
};

export const getGroupMetadata = (row, grouping, translations) => {
    const groupAttributes = grouping.GroupAttributes;
    let metadata = [];

    for (let i in row) {
        if (row.hasOwnProperty(i)) {
            const attribute = groupAttributes.find((a) => a.Attribute === i);
            metadata.push(createAttribute(translations, i, attribute, row[i]));
        }
    }

    // for all attributes that should be here but are not
    groupAttributes.forEach((x) => {
        if (metadata.some((md) => md.attribute === x.Attribute)) return; // already in metadata
        metadata.push(createAttribute(translations, x.Attribute, x));
    });

    return metadata;
};

export const createAttribute = (translations, key, attr, value) => {
    let isVisibleOnGrid = false;
    let isVisibleOnGridDetails = false;
    let isVisibleOnDetailsScreen = false;
    let isTitleAttribute = false;
    let gridOrder = 0;
    let gridDetailsOrder = 0;
    let detailsScreenOrder = 0;
    let isGridSortable = false;
    let gridSortLevel = 0;
    let gridSortDirection = 'ASC';
    let isPackageSortable = false;
    let packageSortLevel = 0;
    let packageSortDirection = 'ASC';
    let label = formatTranslated(key, translations.Phrases);
    let originalValue = value;
    let dataType = 'string';

    if (attr) {
        isVisibleOnGrid = attr.IsVisibleOnGrid;
        isVisibleOnGridDetails = attr.IsVisibleOnGridDetails;
        isVisibleOnDetailsScreen = attr.IsVisibleOnDetailsScreen;
        isTitleAttribute = attr.IsTitleAttribute;
        gridOrder = attr.GridOrder;
        gridDetailsOrder = attr.GridDetailsOrder;
        detailsScreenOrder = attr.DetailsScreenOrder;
        isPackageSortable = attr.IsPackageSortable;
        packageSortLevel = attr.PackageSortLevel;
        packageSortDirection = attr.PackageSortDirection;
        isGridSortable = attr.IsGridSortable;
        gridSortLevel = attr.GridSortLevel;
        gridSortDirection = attr.GridSortDirection;
        label = formatTranslated(attr.Name, translations.Phrases);
        dataType = attr.DataType;

        if (key === '_metadataFormat') {
            const v = value.toUpperCase();
            value = formatTranslated(v, translations.Phrases);
        }

        if (key === 'raw_LIDOCUMENTTYPE') {
            const v = 'cafta_' + value;
            value = formatTranslated(v, translations.Phrases);
        }

        if (attr.DataType === 'DateTime' && value) {
            const v = moment(value).utc().startOf('day').format('MM/DD/YYYY');

            if (v !== 'Invalid Date') {
                value = v;
            }
        }
    }

    return {
        attribute: key,
        label: label,
        value: value !== undefined ? value : '',
        isVisibleOnGrid: isVisibleOnGrid,
        isVisibleOnGridDetails: isVisibleOnGridDetails,
        isVisibleOnDetailsScreen: isVisibleOnDetailsScreen,
        isTitleAttribute: isTitleAttribute,
        gridOrder: gridOrder,
        gridDetailsOrder: gridDetailsOrder,
        detailsScreenOrder: detailsScreenOrder,
        gridSortLevel: gridSortLevel,
        gridSortDirection: gridSortDirection,
        isGridSortable: isGridSortable,
        packageSortLevel: packageSortLevel,
        packageSortDirection: packageSortDirection,
        isPackageSortable: isPackageSortable,
        originalValue: originalValue,
        dataType: dataType
    };
};

export const clearImageFilters = () => {
    return {
        type: actionTypes.CLEAR_IMAGE_FILTERS
    };
};

export const updateImageClientServices = (data) => {
    return {
        type: actionTypes.UPDATE_IMAGE_CLIENT_SERVICES,
        data: data
    };
};

export const clearImageClientServices = () => {
    return {
        type: actionTypes.CLEAR_IMAGE_CLIENT_SERVICES
    };
};

export const selectSearchType = (code) => {
    return {
        type: actionTypes.SELECT_IMAGE_SEARCH_TYPE,
        code: code
    };
};

export const selectImageDocs = (isSelected, groupIndex, docIndex) => {
    return {
        type: actionTypes.SELECT_IMAGE_DOCS,
        payload: { isSelected: isSelected, groupIndex: groupIndex, docIndex: docIndex }
    };
};

export const downloadDocuments = (print, groupIndex, docIndex, gridModel) => {
    let fileNames = {};
    if (print) {
        fileNames['allFiles'] = [];
    }
    gridModel.forEach((x, i) => {
        if ((groupIndex === 'selectedOnly' && x.isSelected) || groupIndex === i) {
            const groupTitleAttribute = x.metadata.find((x) => x.isTitleAttribute).value;

            if (fileNames[groupTitleAttribute] === undefined && !print) {
                fileNames[groupTitleAttribute] = [];
            }

            x.documents.forEach((y, j) => {
                if ((docIndex === 'selectedOnly' && y.isSelected) || docIndex === j || docIndex === '*') {
                    if (print) {
                        fileNames['allFiles'].push(y.documentUrl);
                    } else {
                        fileNames[groupTitleAttribute].push(y.documentUrl);
                    }
                }
            });
        }
    });
    const type = print ? imaging.PACKAGE_TYPE_MERGE : imaging.PACKAGE_TYPE_ZIP;
    const downloadModel = {
        S3ImageFileNames: fileNames,
        ImagePackage: {
            ImagingPackageTypeID: type
        },
        EntryNumbers: {}
    };

    $('#overlay').show();
    axiosInstance
        .post('/Imaging/Download', downloadModel)
        .then((response) => {
            if (print && response.data.ImagingPackageStatusTypeID === imaging.imagingPackageStatusType.Completed) {
                printPage(response.data.ImageUrl);
            } else {
                window.open(response.data.ImageUrl);
            }
        })
        .catch((error) => {
            if (error.response) {
                if (error.response.data && error.response.data.Message) {
                    if (error.response.status === 400) {
                        toastr.warning(error.response.data.Message);
                    } else {
                        toastr.error(error.response.data.Message);
                    }
                } else if (error.response.status !== 401 && error.response.status !== 403) {
                    window.location.href = window.location.protocol + '//' + document.location.host + '/BasicError';
                }
            }
        })
        .finally(() => {
            $('#overlay').hide();
        });
};

const groupDocuments = (sortedDocuments, downloadZip, groupFilesInZip, groupByColumn) => {
    let grouped = {};

    if (!downloadZip) {
        grouped['allFiles'] = sortedDocuments.map((x) => x.documentUrl);
    } else {
        sortedDocuments.forEach((x) => {
            let groupValue = x.documentUrl;
            if (groupFilesInZip) {
                let groupColumn = groupByColumn;
                let sortOrderIndex = groupByColumn.indexOf('SortOrder');
                if (sortOrderIndex > 0) {
                    groupColumn = groupColumn.substring(0, sortOrderIndex);
                }

                let groupAttr = x.metadata.find((el) => el['attribute'] === groupColumn);
                if (!groupAttr) {
                    groupAttr = x.metadata.find((el) => el['attribute'] === groupByColumn);
                }

                groupValue = groupAttr.value;

                // reformat date
                if (groupAttr.dataType === 'DateTime') {
                    groupValue = groupValue.replace(/\//g, '-');
                }
            }

            if (grouped[groupValue] === undefined) {
                grouped[groupValue] = [];
            }
            grouped[groupValue].push(x.documentUrl);
        });
    }

    return grouped;
};

export const downloadDocument = (print, sortedDocuments, downloadZip, groupFilesInZip, groupByColumn) => {
    return (dispatch) => {
        const docs = groupDocuments(sortedDocuments, downloadZip, groupFilesInZip, groupByColumn);
        const packageType = downloadZip ? imaging.PACKAGE_TYPE_ZIP : imaging.PACKAGE_TYPE_MERGE;
        const downloadModel = {
            S3ImageFileNames: docs,
            ImagePackage: {
                ImagingPackageTypeID: packageType
            },
            EntryNumbers: {}
        };

        $('#overlay').show();
        axiosInstance
            .post('/Imaging/download', downloadModel)
            .then((response) => {
                dispatch(setDownloadDocumentUrl(response.data));
                const data = response.data;
                if (print && data.ImagingPackageStatusTypeID === imaging.imagingPackageStatusType.Completed) {
                    printPage(data.ImageUrl);
                } else {
                    window.open(data.ImageUrl);
                }
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.data && error.response.data.Message) {
                        if (error.response.status === 400) {
                            toastr.warning(error.response.data.Message);
                        } else {
                            toastr.error(error.response.data.Message);
                        }
                    } else if (error.response.status !== 401 && error.response.status !== 403) {
                        window.location.href = window.location.protocol + '//' + document.location.host + '/BasicError';
                    }
                }
            })
            .finally(() => {
                $('#overlay').hide();
            });
    };
};

export const selectGroupByOption = (newIndex, oldIndex) => {
    return {
        type: actionTypes.SELECT_IMAGE_GROUP_BY_OPTION,
        payload: { newIndex: newIndex, oldIndex: oldIndex }
    };
};

export const viewDetails = (data, gridModel, history) => {
    return (dispatch) => {
        let toRedirect = [];

        if (data.index !== -1) {
            toRedirect.push(JSON.parse(JSON.stringify(gridModel[data.index])));
        } else {
            gridModel.forEach((entry) => {
                if (entry.isSelected) {
                    toRedirect.push(JSON.parse(JSON.stringify(entry)));
                }
            });
        }

        toRedirect.forEach((x) => {
            if (x.documents.some((document) => document.isSelected === true)) {
                x.documents = x.documents.filter((document) => document.isSelected === true);
            }

            x.metadata = cleanUpMetadata(x.metadata, (y) => {
                return y.isVisibleOnDetailsScreen || y.isTitleAttribute;
            });
            x.isSelected = false;

            x.documents.forEach((y) => {
                y.metadata = cleanUpMetadata(y.metadata, (z) => {
                    return z.isPackageSortable || z.isTitleAttribute;
                });
                y.isSelected = false;
            });
        });

        const redirectionModel = createImageDetailsModel(toRedirect);
        redirectionModel.ViewModel[0].isSelected = true;
        dispatch(setImageDetails(redirectionModel));

        history.push({
            pathname: '/Imaging/Imaging/SearchResultDetails' + (data.pageRoute || '/DetailsEntry')
        });
    };
};

export const cleanUpMetadata = (metadata, condition) => {
    let toInclude = [];
    metadata.forEach((x) => {
        if (condition(x)) {
            toInclude.push(x.attribute);

            // for sortOrder columns include the real value attr
            let sortOrderIndex = x.attribute.indexOf('SortOrder');
            if (sortOrderIndex >= 0) {
                const valueAttr = metadata.find((atr) => atr.attribute === x.attribute.substring(0, sortOrderIndex));
                if (valueAttr) {
                    toInclude.push(valueAttr.attribute);
                }
            }
        }
    });
    toInclude = new Set(toInclude);
    return metadata.filter((x) => toInclude.has(x.attribute));
};

export const createImageDetailsModel = (data) => {
    const flatDocumentModel = pluck(data, 'documents').flat();
    const model = {
        ViewModel: data,
        EntriesToCompareLeft: JSON.parse(JSON.stringify(data)),
        EntriesToCompareRight: JSON.parse(JSON.stringify(data)),
        FlatDocumentModel: flatDocumentModel,
        LevelOneSortOptions: getDocumentGroupingOptionsForLevel(flatDocumentModel, 1),
        LevelTwoSortOptions: getDocumentGroupingOptionsForLevel(flatDocumentModel, 2),
        SortedDocuments: flatDocumentModel
    };
    return model;
};

export const getDocumentGroupingOptionsForLevel = (documents, level) => {
    const result = documents[0].metadata
        .filter((x) => x.isPackageSortable === true)
        .map((item) => {
            return {
                Text: item.label,
                Attribute: item.attribute,
                IsSelected: item.packageSortLevel === level,
                Level: item.packageSortLevel,
                Direction: item.packageSortDirection,
                IsHidden: false
            };
        });
    return result;
};

export const setImageDetails = (model) => {
    return {
        type: actionTypes.SET_IMAGE_DETAILS,
        model: model
    };
};

export const imageFilterOptionSelect = (newIndex, oldIndex, name) => {
    const filterIndex = name.split('.')[1];
    return {
        type: actionTypes.IMAGE_FILTER_OPTION_SELECT,
        payload: { newIndex: newIndex, oldIndex: oldIndex, filterIndex: filterIndex }
    };
};

export const imageFilterValueChange = (property, value, filterIndex) => {
    return {
        type: actionTypes.IMAGE_FILTER_VALUE_CHANGE,
        payload: { property: property, value: value, filterIndex: filterIndex }
    };
};

export const imageDocTypeFilterValueChange = (property1, value, filterIndex, property2, optionIndex) => {
    return {
        type: actionTypes.IMAGE_DOC_TYPE_FILTER_VALUE_CHANGE,
        payload: { property1: property1, value: value, filterIndex: filterIndex, property2: property2, optionIndex: optionIndex }
    };
};

export const imageDocTypeFilterSelectAll = (value, filterIndex) => {
    return {
        type: actionTypes.IMAGE_DOC_TYPE_FILTER_SELECT_ALL,
        payload: { value: value, filterIndex: filterIndex }
    };
};

export const imageMotFilterValueChange = (value, filterIndex, optionIndex) => {
    return {
        type: actionTypes.IMAGE_MOT_FILTER_VALUE_CHANGE,
        payload: { value: value, filterIndex: filterIndex, optionIndex: optionIndex }
    };
};

export const motFilterSelectAll = (value, filterIndex) => {
    return {
        type: actionTypes.MOT_FILTER_SELECT_ALL,
        payload: { value: value, filterIndex: filterIndex }
    };
};

export const filterImages = () => {
    return {
        type: actionTypes.FILTER_IMAGES
    };
};

export const setDownloadDocumentUrl = (url) => {
    return {
        type: actionTypes.SET_DOWNLOAD_DOCUMENT_URL,
        url: url
    };
};

export const selectEntry = (newIndex, oldIndex, side) => {
    return {
        type: actionTypes.SELECT_ENTRY,
        payload: { newIndex: newIndex, oldIndex: oldIndex, side: side }
    };
};

export const sortOptionSelect = (newIndex, oldIndex, level) => {
    if (level === 1 || level === 2) {
        return {
            type: actionTypes.SORT_OPTION_SELECT,
            payload: { newIndex: newIndex, oldIndex: oldIndex, level: level }
        };
    } else {
        return null;
    }
};

export const selectDocument = (newIndex, oldIndex, entryIndex, side) => {
    return {
        type: actionTypes.SELECT_DOCUMENT,
        payload: { newIndex: newIndex, oldIndex: oldIndex, entryIndex: entryIndex, side: side }
    };
};

export const loadImages = () => {
    return {
        type: actionTypes.LOAD_IMAGES
    };
};

export const setDropdownYears = (years) => {
    return {
        type: actionTypes.SET_DROPDOWN_YEARS,
        payload: { years: years }
    };
};

export const resetImageState = () => {
    return {
        type: actionTypes.RESET_IMAGE_STATE
    };
};

export const resetImageDetailsState = () => {
    return {
        type: actionTypes.RESET_IMAGE_DETAILS_STATE
    };
};

export const setImageModel = (model) => {
    return {
        type: actionTypes.SET_IMAGE_MODEL,
        model: model
    };
};
