import type { AxiosResponse } from 'axios';
import { axiosPost } from '../../../axios-portal';
import type { TeamMemberData } from '../common/models/ResponseData';

export const setTeamMember = async (payload: TeamMemberData) => {
    return await axiosPost('/TeamManagement/NewTeamMember', payload);
};

export const editTeamMember = async (data: TeamMemberData, editData: TeamMemberData) => {
    const changes = Object.keys(data.Users?.[0] || {})
        ?.map((item) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            const initialVal = data.Users?.[0]?.[item];
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            const updatedVal = editData.Users?.[0]?.[item];

            if (updatedVal && initialVal !== updatedVal && item !== 'Roles' && item !== 'UserRole') {
                return `Users.0.${item}`;
            } else {
                return undefined;
            }
        })
        .filter((item) => item);

    return await axiosPost('/TeamManagement/EditTeamMember', {
        ...data,
        ChangedProperties: changes,
        Users: [
            {
                ...data.Users?.[0],
                ...editData.Users?.[0]
            }
        ]
    });
};

export const validateNewTeamMember = async (args: { Email: string }) => {
    if (!args.Email) return null;

    const res = (await axiosPost('/TeamManagement/NewTeamMemberValidate', {
        ChangedProperties: Object.keys(args).map((arg) => 'Users.0.' + arg),
        Users: [
            {
                Email: args.Email
            }
        ]
    })) as AxiosResponse<string>;

    return {
        emailExists: res.data.includes('EmailExists_ValidationMsg')
    };
};
