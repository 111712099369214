import { useMemo, useState } from 'react';
import useTranslation from '../../../../common/hooks/useTranslation';
import FixedFooterWhite from '../../common/features/FixedFooterWhite';
import { isRequiredFeatureSelected } from '../../common/functions/utils';
import type { TeamMemberData } from '../../common/models/ResponseData';
import { useTeamSelector } from '../../redux/hooks';

interface EditTeamMemberFooterProps {
    onSaveUser: (payload: { data: TeamMemberData; editData: TeamMemberData }) => void;
}

const EditTeamMemberFooter: React.FC<EditTeamMemberFooterProps> = ({ onSaveUser }) => {
    const { user } = useTeamSelector((state) => state.hydration);
    const {
        data: userDetailsData,
        editData,
        userDetailsIsValid,
        isLoading: userDetailsLoading,
        error: userDetailsError
    } = useTeamSelector((state) => state.editTeamMember);
    const {
        data: clientsAndPermissionsData,
        isPending,
        isLoading: clientsAndPermissionsLoading,
        error: clientsAndPermissionsError
    } = useTeamSelector((state) => state.clientsAndPermissions);

    const translate = useTranslation();

    const isEditable = useMemo(() => user?.Roles?.includes('TeamManagement_Edit'), [user?.Roles]);
    const requiredFeatureIsSelected = useMemo(
        () =>
            isRequiredFeatureSelected({
                parentServices: clientsAndPermissionsData?.ParentServices,
                featureName: 'my livingston portal'
            }),
        [clientsAndPermissionsData?.ParentServices]
    );
    const [formIsSubmitted, setFormIsSubmitted] = useState(false);

    const teamMember = userDetailsData?.Users?.[0];
    const isPendingStatus =
        teamMember?.Status === 'PendingUserActivation' ||
        teamMember?.Status === 'PendingFeatureSetup' ||
        teamMember?.Status === 'EmailChangedConfirmRequired' ||
        teamMember?.Status === 'PendingUserConfirmRequired';
    const isInactiveStatus =
        teamMember?.Status === 'Cancelled' ||
        teamMember?.Status === 'Deactivated' ||
        teamMember?.Status === 'Expired' ||
        teamMember?.Status === 'Hold';
    const isSaveDisabled = isPendingStatus || isInactiveStatus;

    let message = '';

    if (isPendingStatus) {
        message = translate('PendingFeatureSetupLocked_Label');
    } else if (isInactiveStatus) {
        message = translate('InactiveTeamMemberLocked_Label');
    } else if (formIsSubmitted) {
        if (!requiredFeatureIsSelected) {
            message =
                userDetailsData?.AdminRole === 'CentralAdmin'
                    ? translate('ClearancesFeatureMustBeSelected_Label')
                    : translate('MLPFeatureMustBeSelected_Label');
        } else if (!userDetailsIsValid) {
            translate('InvalidUserDetails_Label');
        }
    }

    const handleSavingUsers = () => {
        setFormIsSubmitted(true);

        if (!userDetailsData || !editData || !requiredFeatureIsSelected || !userDetailsIsValid) return;

        onSaveUser({
            data: { ...userDetailsData, ...clientsAndPermissionsData },
            editData: { ...editData, ...clientsAndPermissionsData }
        });
    };

    if (!isEditable || isPending || userDetailsLoading || userDetailsError || clientsAndPermissionsLoading || clientsAndPermissionsError) {
        return null;
    }

    return (
        <FixedFooterWhite
            primaryLabel={translate('TeamMemberSaveChanges_Label')}
            primaryOnClick={handleSavingUsers}
            primaryDisabled={isSaveDisabled}
            message={message}
        />
    );
};

export default EditTeamMemberFooter;
