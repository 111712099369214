import { useDispatch, useSelector } from 'react-redux';
import { clearancesGridSettingsConstants } from '../../../pages/clearances/common/constants/constants-clearances';
import { SaveUserClearancesGridSetting } from '../../../pages/clearances/redux/actions/clearancesGridPreferences';
import { updateColumnOrder } from '../../../pages/clearances/redux/reducers/clearancesGridPreferencesSlice';
import { RootState } from '../../../redux/store';
import useTranslation from '../../hooks/useTranslation';
import { ColumnSettings } from '../../models/GridPreferences';
import { ClientSelectionSlice } from '../../models/ReduxSlices';
import { ShipmentListItem } from '../../models/ShipmentList';
import Button from '../Button';
import ColumnSelectionDropdown from './ColumnSelectionDropdown';

interface GridCaptionProps {
    children: React.ReactNode;
    showClear: boolean;
    clearLabel: string;
    onClear: () => void;
    tableColumnsDropdown: boolean;
    gridDataState?: {
        shipmentList: ShipmentListItem[];
        isLoading: boolean;
        error: string | number | undefined;
    };
    allColumns?: ColumnSettings[] | null;
    visibleColumns?: ColumnSettings[] | null;
    areColumnsLoading?: boolean;
}

const GridCaption: React.FC<GridCaptionProps> = ({
    children,
    showClear,
    clearLabel,
    onClear,
    tableColumnsDropdown,
    gridDataState,
    allColumns,
    visibleColumns,
    areColumnsLoading
}) => {
    const translate = useTranslation();
    const dispatch = useDispatch();
    const { initialClientSelection } = useSelector<RootState, ClientSelectionSlice>((state) => state.clientSelection) || {};
    const country = initialClientSelection?.Countries.find((item) => item.IsSelected);

    const applyDropdownChanges = (payload: ColumnSettings[]) => {
        dispatch(updateColumnOrder(payload));
        dispatch(
            SaveUserClearancesGridSetting({
                GridColumns: payload
                    .map((item, i) => {
                        return { ...item, Order: i + 1 };
                    })
                    .filter((item: ColumnSettings) => {
                        if (item.Code !== 'selected' && item.Code !== 'Actions') {
                            return item;
                        }
                        return null;
                    }),
                area: country?.Code
                    ? country?.Code === 'us'
                        ? clearancesGridSettingsConstants['us']
                        : clearancesGridSettingsConstants['ca']
                    : clearancesGridSettingsConstants['us']
            })
        );
    };

    return (
        <div className='header-strip-small bg-grey-200 py-2'>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col d-flex'>
                        <div className='d-flex justify-content-between align-items-center'>
                            {children}
                            {showClear && (
                                <>
                                    <span className='ml-3 mr-2'>|</span>
                                    <Button variant='link' onClick={onClear}>
                                        {clearLabel}
                                    </Button>
                                </>
                            )}
                        </div>
                        {tableColumnsDropdown &&
                            gridDataState &&
                            gridDataState.shipmentList.length > 0 &&
                            !gridDataState.isLoading &&
                            !gridDataState.error && (
                                <ColumnSelectionDropdown
                                    allColumns={allColumns ?? null}
                                    visibleColumns={visibleColumns ?? null}
                                    areColumnsLoading={areColumnsLoading ?? false}
                                    applyDropdownChanges={applyDropdownChanges}
                                    descriptiveText={translate('TableColumnsButton_Label')}
                                />
                            )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GridCaption;
