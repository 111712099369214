import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../../../../common/features/Button';
import { newSubscribersHasValidForm, uniqueNewSubscriber } from '../../../../../../common/functions/validators';
import useTranslation from '../../../../../../common/hooks/useTranslation';
import { SubscribersSlice } from '../../../../common/models/ReduxSlices';
import { GetTeamMembersAndContacts } from '../../../../redux/actions/subscribersManagement';
import { addNewSubscriber } from '../../../../redux/reducers/subscribersSlice';
import { RootState } from '../../../../redux/store';
import Subscriber from './Subscriber';

interface Props {
    handleStep: (value: number) => void;
}

const SubscriberInfoForm: React.FC<Props> = ({ handleStep }) => {
    const { newSubscribers, subscribers } = useSelector<RootState, SubscribersSlice>((state) => state.subscribers);
    const [fieldError, setFieldError] = useState({ error: '', value: true });
    const [rowLimitError, setRowLimitError] = useState<boolean>(false);
    const translate = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(GetTeamMembersAndContacts());
    }, [dispatch]);

    useEffect(() => {
        if (newSubscribers.length! < 10) {
            setRowLimitError(false);
        }
    }, [newSubscribers.length]);

    useEffect(() => {
        let emptyRows = newSubscribers.filter(
            (subscriber) =>
                subscriber.email === '' &&
                subscriber.fullName === '' &&
                subscriber.language === '-' &&
                subscriber.phone === '' &&
                subscriber.role === ''
        );

        if (!emptyRows.length) {
            if (newSubscribersHasValidForm(newSubscribers)) {
                if (
                    uniqueNewSubscriber(
                        newSubscribers[newSubscribers.length - 1].userId!,
                        subscribers?.map((item) => item.UserId!),
                        newSubscribers.map((item) => item.userId!)
                    )
                ) {
                    if (newSubscribers.every((subscriber) => subscriber.emailIsValid || subscriber.phoneIsValid)) {
                        setFieldError({ error: '', value: false });
                    } else {
                        setFieldError({ error: 'EmailOrPhoneRequired_Label', value: true });
                    }
                } else {
                    setFieldError({ error: 'SubscriberAlreadySubscribed_Label', value: true });
                }
            } else {
                setFieldError({ error: 'NotValidForm_Label', value: true });
            }
        } else {
            setFieldError({ error: '', value: true });
        }
    }, [newSubscribers, subscribers]);

    const handleAddNewSubscriber = () => {
        if (!fieldError.value) {
            if (newSubscribers.length! < 10) {
                setFieldError({ error: '', value: false });
                dispatch(addNewSubscriber());
            } else {
                setRowLimitError(true);
            }
        }
    };

    return (
        <>
            <div className='modal-body'>
                <form>
                    <div className='d-flex flex-column align-items-end overflow-auto'>
                        <div className='table-responsive' id='scrollTable'>
                            <table className='table w-100 add-subscriber input-row-overflow'>
                                <thead>
                                    <tr>
                                        <th scope='col'>{translate('Name_Label')}</th>
                                        <th scope='col'>{translate('Role_Label')}</th>
                                        <th scope='col'>{translate('Language_Label')}</th>
                                        <th scope='col'>{translate('EmailNotify_Label')}</th>
                                        <th scope='col'>{translate('PhoneNotify_Label')}</th>
                                        <th scope='col' className='row-action bg-white'></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {newSubscribers.map((subscriber) => (
                                        <Subscriber
                                            key={subscriber.id}
                                            id={subscriber.id}
                                            email={subscriber.email}
                                            emailIsValid={subscriber.emailIsValid!}
                                            language={subscriber.language}
                                            phone={subscriber.phone}
                                            phoneIsValid={subscriber.phoneIsValid!}
                                            fullName={subscriber.fullName}
                                            role={subscriber.role}
                                        />
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        {fieldError.value && <div className='text-center error-message'>{translate(fieldError.error)}</div>}
                        {rowLimitError ? (
                            <div className='text-center error-message'>{translate('MaxRowLimit_Label')}</div>
                        ) : (
                            <div className='p-1 w-100'>
                                <button type='button' className='btn btn-tertiary-blue btn-block' onClick={() => handleAddNewSubscriber()}>
                                    <FontAwesomeIcon icon={faPlus} className='mr-1' />
                                    {translate('AddAnotherSubscriber_Label')}
                                </button>
                            </div>
                        )}
                    </div>
                </form>
            </div>
            <div className='modal-footer justify-content-end'>
                <Button
                    variant='primary'
                    size='large'
                    onClick={() => {
                        if (!fieldError.value) handleStep(2);
                    }}
                >
                    {translate('Next_Label')}
                </Button>
            </div>
        </>
    );
};

export default SubscriberInfoForm;
