import type { GridCellProps } from '@progress/kendo-react-grid';
import Badge from '../../../../../common/features/Badge';
import useTranslation from '../../../../../common/hooks/useTranslation';
import { createDateForTeamTable } from '../../../common/functions/parser';
import type { TransformedTeamMember } from '../../../common/models/TeamMember';
import { useTeamSelector } from '../../../redux/hooks';
import { ActionButton } from './ActionButton';

interface FormattedGridCellProps extends GridCellProps {
    dataItem: TransformedTeamMember;
    onRowClick: (event: GridCellProps['dataItem']) => void;
}

function ActionsWrapper({ children }: { children?: React.ReactNode }) {
    return (
        <div className='position-absolute d-flex align-items-center justify-content-center' style={{ inset: 0 }}>
            {children}
        </div>
    );
}

const FormattedGridCell: React.FC<FormattedGridCellProps> = ({ field = '', style, dataItem, className, onRowClick, ...rest }) => {
    const { user } = useTeamSelector((state) => state.hydration);
    const translate = useTranslation();
    let cellContent;

    switch (field) {
        case 'Status':
            cellContent = <Badge type={dataItem.IsActive ? 'success' : 'danger'}>{dataItem.statusTranslated}</Badge>;
            break;
        case 'LastLogin':
            cellContent =
                dataItem.IsPending === true
                    ? translate('Never_Label')
                    : dataItem.LastLogin
                    ? createDateForTeamTable(dataItem.LastLogin)
                    : translate('Never_Label');
            break;
        case 'ExpirationDate':
            cellContent = dataItem.ExpirationDate ? createDateForTeamTable(dataItem.ExpirationDate) : translate('Never_Label');
            break;
        case 'Actions':
            cellContent =
                user?.Roles?.includes('TeamManagement_Edit') &&
                (dataItem.Status === 'Active' ||
                    dataItem.Status === 'ActiveFeatureSetupInProgress' ||
                    dataItem.Status === 'Cancelled' ||
                    dataItem.Status === 'Deactivated' ||
                    dataItem.Status === 'Expired') ? (
                    <ActionsWrapper>
                        <ActionButton dataItem={dataItem} {...rest} />
                    </ActionsWrapper>
                ) : (
                    <ActionsWrapper />
                );
            break;
        default:
            cellContent = null;
    }

    return (
        <td
            className={field === 'Actions' ? 'p-0' : className}
            onClick={field !== 'Actions' ? onRowClick.bind(null, dataItem) : undefined}
            style={{
                ...style,
                position: field === 'Actions' ? 'sticky' : 'static',
                backgroundColor: field === 'Actions' ? 'white' : 'transparent',
                cursor: 'pointer'
            }}
        >
            {cellContent}
        </td>
    );
};

export default FormattedGridCell;
