import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Breakpoints } from '../../../../../common/constants/constants-portal';
import SearchBox from '../../../../../common/features/FilterBar/SearchBox/SearchBox';
import useMediaQuery from '../../../../../common/hooks/useMediaQuery';
import useSearchInput from '../../../../../common/hooks/useSearchInput';
import useTranslation from '../../../../../common/hooks/useTranslation';
import { SearchFilterSlice } from '../../../../../common/models/ReduxSlices';
import { setPageSelected } from '../../../redux/reducers/subscribersGridSettingsSlice';
import { clearSearchFilter, setSearchFilter } from '../../../redux/reducers/subscribersSearchFilterSlice';
import { setSelectedStatus } from '../../../redux/reducers/subscribersStatusFilterSlice';
import { RootState } from '../../../redux/store';

const SubscribersSearchBox = () => {
    const { searchTerm } = useSelector<RootState, SearchFilterSlice>((state) => state.subscribersSearchFilter);
    const [showMobileSearch, setShowMobileSearch] = useState<boolean>(false);
    const { value, handleBlur, handleChange, handleKeyDown, handleReset } = useSearchInput({
        inputValue: searchTerm,
        onEnterKey: () => handleSubmit()
    });
    const isDesktop = useMediaQuery(Breakpoints.Mobile);
    const translate = useTranslation();
    const dispatch = useDispatch();

    // Automatically hide mobile search when not on mobile resolution
    useEffect(() => {
        if (isDesktop) {
            setShowMobileSearch(false);
        }
    }, [isDesktop]);

    const toggleMobileSearchBox = () => {
        setShowMobileSearch((prevState) => !prevState);
    };

    const handleClearInputValue = () => {
        handleReset();
        dispatch(clearSearchFilter());
    };

    const handleSubmit = () => {
        // Update the searchFilter redux slice
        dispatch(setSearchFilter(value.trim()));
        // Reset all filters to defaults
        dispatch(setSelectedStatus(0));
        // Set the grid page to 1
        dispatch(setPageSelected(1));
        // If mobile search is open close it
        if (showMobileSearch) {
            toggleMobileSearchBox();
        }
    };

    return (
        <SearchBox
            inputValue={value}
            placeholder={translate('SearchPlaceholder_Label')}
            submitButtonText={translate('SearchSubscribersButton_Label')}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            onBlur={handleBlur}
            onToggleMobileSearch={toggleMobileSearchBox}
            onClearInputValue={handleClearInputValue}
            onSubmit={handleSubmit}
            showMobileSearch={showMobileSearch}
        />
    );
};

export default SubscribersSearchBox;
