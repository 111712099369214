import { CSSProperties, useEffect, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { useSelector } from 'react-redux';
import { ViewDocumentsReduxDocument, ViewImagesSlice } from '../../../../redux/reducers/viewImagesSlice';
import { fetchDocumentPresignedUrl } from '../../redux/actions/Imaging';
import { RootState } from '../../redux/store';
import ViewHeader from './ViewHeader';

import Spinner from '../../../../common/features/Spinner';
import './styles.css';

const pdfjsWorker = require('pdfjs-dist/build/pdf.worker');

interface DocumentLoadSuccess {
    numPages: number;
}

interface Props {
    setDownloadModalView: (value: string) => void;
    isClearances: boolean;
}

const DocumentViewOverlay: React.FC<Props> = ({ setDownloadModalView, isClearances }) => {
    const { viewImages } = useSelector<RootState, ViewImagesSlice>((state) => state.viewImages);
    const [selectedEntry, setSelectedEntry] = useState('');
    const [selectedDocument, setSelectedDocument] = useState<ViewDocumentsReduxDocument | null>(null);
    pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

    const [pdfUrl, setPdfUrl] = useState('');

    useEffect(() => {
        if (!selectedDocument) return;

        fetchDocumentPresignedUrl(selectedDocument._fileS3Path).then((res) => res && setPdfUrl(res.result));
    }, [selectedEntry, selectedDocument]);

    const isMobile = window.innerWidth < 994;

    const [numPages, setNumPages] = useState<number | null>(null);
    const onDocumentLoadSuccess = ({ numPages }: DocumentLoadSuccess) => {
        setNumPages(numPages);
    };

    const containerStyle: CSSProperties = {
        marginTop: isClearances ? '0px' : window.innerWidth <= 370 ? '-300px' : '-267px'
    };

    if (!viewImages.length) return null;

    return (
        <div className='view_documents_container' style={containerStyle}>
            <ViewHeader
                selectedEntry={selectedEntry}
                selectedDocument={selectedDocument}
                setSelectedEntry={setSelectedEntry}
                setSelectedDocument={setSelectedDocument}
                viewImages={viewImages}
                setDownloadModalView={setDownloadModalView}
                isClearances={isClearances}
            />

            <div className='bg-lii-text text-light p-2 h-100'>
                {/* {pdfUrl && (
                    <iframe
                        // src={`${pdfUrl}#view=FitH${isMobile ? '&scale=150' : ''}`}
                        src={pdfUrl}
                        title='pdfViewer'
                        height='100%'
                        width='100%'
                        className={'round-bottom'}
                    />
                )} */}

                {pdfUrl && (
                    <Document file={pdfUrl} onLoadSuccess={onDocumentLoadSuccess} className='view-pdf-container'>
                        {Array.from(new Array(numPages), (el, index) => (
                            <Page
                                key={`page_${index + 1}`}
                                pageNumber={index + 1}
                                renderAnnotationLayer={false}
                                renderTextLayer={false}
                                className='view-pdf-page'
                            />
                        ))}
                    </Document>
                )}
                {!pdfUrl && (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%',
                            flexDirection: 'column'
                        }}
                    >
                        <Spinner />
                        <p>Loading documents...</p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default DocumentViewOverlay;
