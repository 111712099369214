import moment from 'moment';

export const createAmPmDates = (noticesList) => {
    noticesList.forEach((element) => {
        let effectiveDateFromLocal = new Date(element.EffectiveDateFrom);
        let effectiveDateToLocal = new Date(element.EffectiveDateTo);

        element.EffectiveDatePartFrom =
            effectiveDateFromLocal.getFullYear() +
            '-' +
            ('0' + (effectiveDateFromLocal.getMonth() + 1)).slice(-2) +
            '-' +
            ('0' + effectiveDateFromLocal.getDate()).slice(-2);
        let hoursFrom = effectiveDateFromLocal.getHours();
        element.EffectiveTimePartFromAmPm = hoursFrom >= 12 ? 'PM' : 'AM';
        hoursFrom = hoursFrom % 12;
        hoursFrom = hoursFrom ? hoursFrom : 12; // the hour '0' should be '12'
        element.EffectiveTimePartFrom = ('0' + hoursFrom).slice(-2) + ':' + ('0' + effectiveDateFromLocal.getMinutes()).slice(-2);

        element.EffectiveDatePartTo =
            effectiveDateToLocal.getFullYear() +
            '-' +
            ('0' + (effectiveDateToLocal.getMonth() + 1)).slice(-2) +
            '-' +
            ('0' + effectiveDateToLocal.getDate()).slice(-2);
        let hoursTo = effectiveDateToLocal.getHours();
        element.EffectiveTimePartToAmPm = hoursTo >= 12 ? 'PM' : 'AM';
        hoursTo = hoursTo % 12;
        hoursTo = hoursTo ? hoursTo : 12; // the hour '0' should be '12'
        element.EffectiveTimePartTo = ('0' + hoursTo).slice(-2) + ':' + ('0' + effectiveDateToLocal.getMinutes()).slice(-2);
    });
};

export const formatDate = (datePart, timePart, amPmPart) => {
    let time = ('0' + timePart).slice(-5); //in case we have h instead of hh format
    const hourFrom = time.substring(0, 2);
    const minutesFrom = time.substring(3, 5);

    if (amPmPart === 'PM' && hourFrom < 12) {
        time = parseInt(hourFrom) + 12 + ':' + minutesFrom;
    } else if (amPmPart === 'AM' && hourFrom === '12') {
        time = '00:' + minutesFrom;
    }

    return new Date(datePart + 'T' + time);
};

/**
 * Generate a string for display purposes.
 * Pass in a string or Date object
 * Examples:
 * "2021-05-23T14:00:00Z" => utc=12pm & est=>10am (Summer)
 * "2021-12-23T14:00:00Z" => utc=12pm & est=>9am (Winter)
 * new Date() => current time
 *
 * @param {*} dateTime in UTC (eg: 2022-01-22T02:00:00)
 * @returns string with EST time (eg: 02 Nov 2021 02:25 PM EST)
 */
export const displayDateTimeInEST = (dateTime) => {
    let utcTime;
    if (dateTime instanceof Date) {
        utcTime = dateTime;
    } else {
        const parts = dateTime.split('T');
        const dateParts = parts[0].split('-');
        const timeParts = parts[1].split(':');
        utcTime = new Date(
            Date.UTC(
                parseInt(dateParts[0]),
                parseInt(dateParts[1]) - 1,
                parseInt(dateParts[2]),
                parseInt(timeParts[0]),
                parseInt(timeParts[1]),
                parseInt(timeParts[2])
            )
        );
    }
    const timeDateStr =
        utcTime.toLocaleString('en-US', {
            timeZone: 'America/New_York',
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true
        }) + ' EST'; // -5h from UTC (during Winter) and -4h from UTC (during Summer)
    const parts = timeDateStr.replaceAll(',', '').split(' '); // Example: 12 5 2021 2:00 AM EST
    return parts[0] + ' ' + parts[1] + ' ' + parts[2] + ' ' + parts[3];
};

/**
 * Get human readable string like: "just now"
 * @param {string} dateTime in UTC eg: 2021-05-23T14:00:00Z
 * @returns human friendly readable string in local time
 */
export const displayRelativeTime = (dateTime) => {
    var localTime = moment.utc(dateTime).toDate();
    return moment(localTime).fromNow();
};

/**
 * Add h hours to a Date
 * @param {Date} dateTime
 * @param {*} h
 * @returns
 */
export const dateTimeAddHours = (dateTime, h) => {
    let d = new Date(dateTime);
    d.setTime(dateTime.getTime() + h * 60 * 60 * 1000);
    return d;
};

/**
 * Compare datetimes
 * @param {*} d1
 * @param {*} d2
 * @returns
 */
export const compareDateTime = (d1, d2) => {
    const t1 = d1.getTime();
    const t2 = d2.getTime();

    if (t1 === t2) {
        return 0;
    }

    if (t1 > t2) {
        return 1;
    }

    return -1;
};

export const compareDateOnly = (d1, d2) => {
    const reset1 = new Date(d1.getYear(), d1.getMonth(), d1.getDate());
    const reset2 = new Date(d2.getYear(), d2.getMonth(), d2.getDate());
    return compareDateTime(reset1, reset2);
};

export const isToday = (date) => {
    const now = new Date();
    now.setHours(0, 0, 0, 0);
    date.setHours(0, 0, 0, 0);
    return compareDateTime(now, date) === 0 ? true : false;
};

export const getNowDateTimeEst = () => {
    var utcDateTime = new Date();
    return getDateTimeEst(utcDateTime);
};

export const getDateTimeEst = (dateTime) => {
    const estDateStr = dateTime.toLocaleDateString('en-US', {
        timeZone: 'America/New_York'
    });
    const dateParts = estDateStr.split('/');

    const estTimeStr = dateTime.toLocaleTimeString('en-US', {
        timeZone: 'America/New_York',
        hour12: false
    });
    const timeParts = estTimeStr.split(':');

    return {
        year: parseInt(dateParts[2]),
        month: parseInt(dateParts[0]),
        day: parseInt(dateParts[1]),
        hour: parseInt(timeParts[0]),
        min: parseInt(timeParts[1])
    };
};

export const extractTimeInAMPM = (dateTime) => {
    let hour = dateTime.getHours();
    const ampm = hour < 12 ? 'AM' : 'PM';
    if (hour > 12) {
        hour -= 12;
    }
    const hourString = hour < 10 ? '0' + hour : hour;
    const minute = dateTime.getMinutes();
    const minuteString = minute < 10 ? '0' + minute : minute;
    return hourString + ':' + minuteString + ' ' + ampm;
};

export const extractDate = (dateTime) => {
    const month = dateTime.getMonth() + 1;
    const day = dateTime.getDate();
    return dateTime.getFullYear() + '-' + (month < 10 ? '0' + month : month) + '-' + (day < 10 ? '0' + day : day) + 'T00:00:00';
};

/**
 * Format a datetime that is already in EST format
 * eg. 2022-02-04T19:46:21.58
 *     04 Feb 2022 07:46 PM EST
 */
export const displayESTDateTime = (dateTime) => {
    let d = new Date(dateTime);
    let hour = d.getHours();

    const ampm = hour < 12 ? 'AM' : 'PM';

    if (hour > 12) {
        hour -= 12;
    }

    const hourString = hour < 10 ? '0' + hour : hour;

    const minute = d.getMinutes();

    const minuteString = minute < 10 ? '0' + minute : minute;

    const day = d.getDate();
    const dayString = day < 10 ? '0' + day : day;
    const year = d.getFullYear();

    const month = d.toLocaleString('default', { month: '2-digit' });
    return month + '-' + dayString + '-' + year + ' ' + hourString + ':' + minuteString + ' ' + ampm + ' EST';
};
