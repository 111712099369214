import type { GridCellProps } from '@progress/kendo-react-grid';
import type { ClientServices } from '../models/TeamMember';

interface ClientsAndPermissionsTableNumberCellProps extends GridCellProps {
    clientServices: ClientServices[];
}

export default function ClientsAndPermissionsTableNumberCell({ dataIndex, clientServices }: ClientsAndPermissionsTableNumberCellProps) {
    const clientServicesIdx = dataIndex;

    return <td>{clientServices[clientServicesIdx].Number + '-' + clientServices[clientServicesIdx].CaptionNumber}</td>;
}
