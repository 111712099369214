import { faBan, faInfo, faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isRejected } from '@reduxjs/toolkit';
import { isValidPhoneNumber } from 'libphonenumber-js';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import Badge from '../../../../../common/features/Badge';
import FormDatePicker from '../../../../../common/features/FormDatePicker';
import FormInputField from '../../../../../common/features/FormInputField';
import Select from '../../../../../common/features/Select';
import Spinner from '../../../../../common/features/Spinner';
import useToast from '../../../../../common/features/Toast/useToast';
import Tooltip from '../../../../../common/features/Tooltip/Tooltip';
import { dateFormatValidator, dateIsAfterValidator, inputHasValueValidator } from '../../../../../common/functions/validators';
import useInputValidation from '../../../../../common/hooks/useInputValidation';
import useMaskedInputValidation from '../../../../../common/hooks/useMaskedInputValidation';
import { useSearchParams } from '../../../../../common/hooks/useSearchParams';
import useTranslation from '../../../../../common/hooks/useTranslation';
import { TeamMemberStatusLabels } from '../../../common/constants/constants-team';
import { CancelStatusTeamMember, ReactivateStatusTeamMember, RefreshTeamMemberDetails } from '../../../redux/actions/teamManagement';
import { useTeamDispatch, useTeamSelector } from '../../../redux/hooks';
import { setEditData, setUserDetailsIsValid } from '../../../redux/reducers/editTeamMemberSlice';
import { setStatusIsLoading, statusChangeCheck } from '../../../redux/reducers/statusUserSlice';
import ActiveModal from './ActiveModal';

const UserDetailsEditable = () => {
    const { data } = useTeamSelector((state) => state.editTeamMember);
    const { user } = useTeamSelector((state) => state.hydration);
    const { isStatusLoading } = useTeamSelector((state) => state.statusUser);

    const [searchParams] = useSearchParams();
    const userId = parseInt(searchParams.get('userId') || '');

    const dispatch = useTeamDispatch();
    const translate = useTranslation();

    const [selectedRole, setSelectedRole] = useState('');

    const teamMember = data?.Users?.[0];
    const email = teamMember?.Email || '';

    const roles = useMemo(() => {
        return data?.Roles?.map((item) => {
            if (item?.Text === selectedRole) {
                return { ...item, IsSelected: true };
            } else {
                return { ...item, IsSelected: false };
            }
        });
    }, [data?.Roles, selectedRole]);
    const allRoles = useMemo(() => {
        if (data?.Roles) {
            return data?.Roles?.map((item) => {
                return { value: item?.Text!, label: '' };
            });
        } else {
            return [{ value: '', label: '' }];
        }
    }, [data?.Roles]);

    const {
        value: firstName,
        handleChange: firstNameChangeHandler,
        handleBlur: firstNameBlurHandler,
        isValid: firstNameIsValid,
        hasError: firstNameHasError
    } = useInputValidation({ initial: teamMember?.FirstName, validators: [inputHasValueValidator] });
    const {
        value: lastName,
        handleChange: lastNameChangeHandler,
        handleBlur: lastNameBlurHandler,
        isValid: lastNameIsValid,
        hasError: lastNameHasError
    } = useInputValidation({ initial: teamMember?.LastName, validators: [inputHasValueValidator] });
    const {
        value: company,
        handleChange: companyChangeHandler,
        handleBlur: companyBlurHandler,
        isValid: companyIsValid,
        hasError: companyHasError
    } = useInputValidation({ initial: teamMember?.CompanyName, validators: [inputHasValueValidator] });
    const {
        value: phoneNumber,
        handleChange: phoneNumberChangeHandler,
        handleBlur: phoneNumberBlurHandler,
        hasError: phoneNumberHasError,
        isValid: phoneNumberIsValid,
        errorIndex: phoneNumberErrorIndex
    } = useMaskedInputValidation({
        initial: teamMember?.Phone,
        validators: [inputHasValueValidator, (value: string) => isValidPhoneNumber(value, 'US')],
        inputMask: '(###) ###-#### x######'
    });
    const {
        value: mobileNumber,
        handleChange: mobileNumberChangeHandler,
        handleBlur: mobileNumberBlurHandler,
        hasError: mobileNumberHasError,
        isValid: mobileNumberIsValid
    } = useMaskedInputValidation({
        initial: teamMember?.MobileNumber,
        validators: [(value: string) => isValidPhoneNumber(value, 'US')],
        required: false,
        inputMask: '(###) ###-####'
    });
    const {
        value: date,
        handleChange: dateChangeHandler,
        handleBlur: dateBlurHandler,
        handlePicker: datePickerHandler,
        hasError: dateHasError,
        isValid: dateIsValid
    } = useInputValidation({
        initial: teamMember?.ExpirationDate ? moment(teamMember?.ExpirationDate).format('MM-DD-YYYY') : '',
        validators: [dateFormatValidator, dateIsAfterValidator],
        required: false
    });

    useEffect(() => {
        const id = setTimeout(() => {
            dispatch(
                setEditData({
                    FirstName: firstName.trim(),
                    LastName: lastName.trim(),
                    Email: email.trim(),
                    CompanyName: company.trim(),
                    Phone: phoneNumber.trim(),
                    MobileNumber: mobileNumber.trim(),
                    UserRole: selectedRole,
                    Roles: roles,
                    ExpirationDate: date.trim()
                })
            );
        }, 500);

        return () => clearTimeout(id);
    }, [firstName, lastName, email, company, phoneNumber, mobileNumber, date, selectedRole, dispatch, roles]);

    // Select the initial role for the user
    useEffect(() => {
        const currentRole = teamMember?.Roles?.find((item) => item?.IsSelected);

        if (currentRole?.Text) {
            setSelectedRole(currentRole.Text);
        }
    }, [data?.Users]);

    // Update the validation of the "User details"
    useEffect(() => {
        // If all fields are valid then output "true", else output "false". Had to use "hasError" instead "isValid" because in some cases the phone number and the mobile number are coming as invalid from the database.
        if (firstNameIsValid && lastNameIsValid && companyIsValid && !phoneNumberHasError && !mobileNumberHasError && !dateHasError) {
            dispatch(setUserDetailsIsValid(true));
        } else {
            dispatch(setUserDetailsIsValid(false));
        }
    }, [dispatch, companyIsValid, firstNameIsValid, lastNameIsValid, dateHasError, mobileNumberHasError, phoneNumberHasError]);

    const toast = useToast();

    const changeTeamMemberStatus = async (userId: number) => {
        const CHANGE_TEAM_MEMBER_STATUS = teamMember?.IsActive ? CancelStatusTeamMember : ReactivateStatusTeamMember;

        dispatch(setStatusIsLoading(true));

        try {
            const action = await dispatch(CHANGE_TEAM_MEMBER_STATUS(userId));

            if (isRejected(action)) {
                throw new Error(translate('SomethingWentWrong_Label'));
            }
        } catch (error) {
            if (error instanceof Error) {
                toast.send({ type: 'error', message: error.message });
            } else {
                toast.send({ type: 'error', message: translate('SomethingWentWrong_Label') });
            }
        } finally {
            dispatch(statusChangeCheck());
            dispatch(setStatusIsLoading(false));
            dispatch(RefreshTeamMemberDetails(userId));
        }
    };

    const [activeModalIsVisible, setActiveModalIsVisible] = useState(false);

    let statusContent;

    if (teamMember?.IsActive) {
        statusContent = (
            <>
                <Badge type='success'>
                    {teamMember.Status === 'ActiveFeatureSetupInProgress' ? (
                        <Tooltip label={translate('ClickForMoreInformation_Label')}>
                            <button
                                type='button'
                                className='btn p-0 rounded-lg d-flex align-items-center'
                                style={{ fontSize: 'inherit', color: 'inherit' }}
                                onClick={() => setActiveModalIsVisible(true)}
                            >
                                {translate(TeamMemberStatusLabels[teamMember?.Status as keyof typeof TeamMemberStatusLabels])}
                                <FontAwesomeIcon className='mx-2' icon={faInfo} />
                            </button>
                        </Tooltip>
                    ) : (
                        translate(TeamMemberStatusLabels[teamMember?.Status as keyof typeof TeamMemberStatusLabels])
                    )}
                </Badge>
                {user?.Roles !== undefined &&
                    (user.Roles.includes('TeamManagement_Edit') || user.Roles.includes('TeamManagement_Create')) && (
                        <button
                            type='button'
                            className='btn btn-outline-danger btn-sm ml-2'
                            onClick={changeTeamMemberStatus.bind(null, userId)}
                            disabled={isStatusLoading}
                        >
                            {isStatusLoading ? (
                                <Spinner size='small' color='inherit' className='mr-1' />
                            ) : (
                                <FontAwesomeIcon icon={faBan} className='mr-1' />
                            )}
                            {translate('DeactivateUser_Label')}
                        </button>
                    )}
            </>
        );
    } else {
        statusContent = (
            <>
                <Badge type='danger'>{translate(TeamMemberStatusLabels[teamMember?.Status as keyof typeof TeamMemberStatusLabels])}</Badge>
                {user?.Roles !== undefined &&
                    (user.Roles.includes('TeamManagement_Edit') || user.Roles.includes('TeamManagement_Create')) && (
                        <button
                            type='button'
                            className='btn btn-outline-success btn-sm ml-2'
                            onClick={changeTeamMemberStatus.bind(null, userId)}
                            disabled={isStatusLoading}
                        >
                            {isStatusLoading ? (
                                <Spinner size='small' color='inherit' className='mr-1' />
                            ) : (
                                <FontAwesomeIcon icon={faSyncAlt} className='mr-1' />
                            )}
                            {translate('ReactivateUser_Label')}
                        </button>
                    )}
            </>
        );
    }

    return (
        <div className='container-fluid p-0'>
            <div className='row row-cols-1 row-cols-md-2 row-cols-lg-3'>
                <div className='col order-md-1'>
                    <div className='form-group'>
                        <FormInputField
                            label={translate('FirstName_Label')}
                            value={firstName}
                            onChange={firstNameChangeHandler}
                            onBlur={firstNameBlurHandler}
                            isValid={firstNameIsValid}
                            hasError={firstNameHasError}
                        />
                        {firstNameHasError && <div className='error-message'>{translate('RequiredFirstName_Label')}</div>}
                    </div>
                </div>
                <div className='col order-md-2'>
                    <div className='form-group'>
                        <FormInputField
                            label={translate('LastName_Label')}
                            value={lastName}
                            onChange={lastNameChangeHandler}
                            onBlur={lastNameBlurHandler}
                            isValid={lastNameIsValid}
                            hasError={lastNameHasError}
                        />
                        {lastNameHasError && <div className='error-message'>{translate('RequiredLastName_Label')}</div>}
                    </div>
                </div>
                <div className='col order-md-3'>
                    <div className='form-group'>
                        <label>{translate('Status_Label')}</label>
                        <div className='d-flex align-items-center'>{statusContent}</div>
                    </div>
                </div>
                <div className='col order-md-4'>
                    <div className='form-group'>
                        <label>{translate('Email_Label')}</label>
                        <p>{email}</p>
                    </div>
                </div>
                <div className='col order-md-5'>
                    <div className='form-group'>
                        <FormInputField
                            label={translate('PhoneNumber_Label')}
                            value={phoneNumber}
                            onChange={phoneNumberChangeHandler}
                            onBlur={phoneNumberBlurHandler}
                            isValid={phoneNumberIsValid}
                            hasError={phoneNumberHasError}
                        />
                        {phoneNumberHasError && phoneNumberErrorIndex === 0 && (
                            <div className='error-message'>{translate('RequiredPhoneNumber_Label')}</div>
                        )}
                        {phoneNumberHasError && phoneNumberErrorIndex === 1 && (
                            <div className='error-message'>{translate('InvalidPhoneNumber_Label')}</div>
                        )}
                    </div>
                </div>
                <div className='col order-md-6'>
                    <div className='form-group'>
                        <FormInputField
                            label={translate('MobileNumber_Label')}
                            value={mobileNumber}
                            onChange={mobileNumberChangeHandler}
                            onBlur={mobileNumberBlurHandler}
                            isValid={mobileNumberIsValid}
                            hasError={mobileNumberHasError}
                        />
                        {mobileNumberHasError && <div className='error-message'>{translate('InvalidMobileNumber_Label')}</div>}
                    </div>
                </div>
                <div className='col order-md-7'>
                    <div className='form-group'>
                        <FormInputField
                            label={translate('Company_Label')}
                            value={company}
                            onChange={companyChangeHandler}
                            onBlur={companyBlurHandler}
                            isValid={companyIsValid}
                            hasError={companyHasError}
                        />
                        {companyHasError && <div className='error-message'>{translate('RequiredCompany_Label')}</div>}
                    </div>
                </div>
                <div className='col order-md-8'>
                    <Select label={translate('UserRole_Label')} options={allRoles} value={selectedRole} onChange={setSelectedRole} />
                </div>
                <div className='col order-md-9'>
                    <div className='form-group'>
                        <FormDatePicker
                            label={translate('ExpirationDate_Label')}
                            noBorder
                            noHeader
                            value={date}
                            onChange={dateChangeHandler}
                            onDateChange={datePickerHandler}
                            onBlur={dateBlurHandler}
                            dateFormat='MM-DD-YYYY'
                            hasError={dateHasError}
                            isValid={dateIsValid}
                        />
                        {dateHasError && <div className='error-message'>{translate('InvalidDate_Label')}</div>}
                    </div>
                </div>
            </div>
            <ActiveModal isOpen={activeModalIsVisible} setIsOpen={setActiveModalIsVisible} />
        </div>
    );
};

export default UserDetailsEditable;
