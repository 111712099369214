import { faExternalLinkAlt, faThLarge } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { SaveUserPortalSolutionsMenu } from '../../../../redux/actions/portalSolutionsMenu';
import { appItems } from '../../../constants/constants-portal';
import useDropdownToggle from '../../../hooks/useDropdownToggle';
import useGTMEventTracking from '../../../hooks/useGTMEventTracking';
import useTranslationHydration from '../../../hooks/useTranslationHydration';
import { SolutionMenuItem } from '../../../models/ResponseData';
import DropdownMenuButton from '../../DropdownMenuButton';
import WaffleDraggableItem from './WaffleDraggableItem';

interface WaffleDraggableDropdownProps {
    waffleItems: SolutionMenuItem[] | null;
    isLoading: boolean;
    isUserConfigured: boolean;
}

const WaffleDraggableDropdown: React.FC<WaffleDraggableDropdownProps> = ({ waffleItems, isLoading, isUserConfigured }) => {
    const initValue = useMemo(() => {
        return waffleItems && waffleItems?.length > 0 ? waffleItems : [];
    }, [waffleItems]);

    const [items, setItems] = useState<SolutionMenuItem[]>(initValue);
    const [focusIndex, setFocusIndex] = useState<number | null>(null);
    const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
    const [isDroped, setIsDropped] = useState<boolean>(false);

    const dispatch = useDispatch();
    const token = localStorage.getItem('token');

    useEffect(() => {
        setItems(initValue);
    }, [isLoading, initValue]);

    useEffect(() => {
        if (isDroped) {
            const handler = setTimeout(() => {
                dispatch(SaveUserPortalSolutionsMenu({ SolutionsMenuItems: items, token, IsUserConfigured: true }));
            }, 3000);

            return () => {
                clearTimeout(handler);
            };
        }
    }, [items, isUserConfigured, isDroped, token, dispatch]);

    const dropdownWuffleMenuRef = useRef(null);
    const buttonRef = useRef(null);
    const { isOpen: isWaffleOpen, handleToggle: handleWaffleToggle } = useDropdownToggle(dropdownWuffleMenuRef);
    useGTMEventTracking('WaffleMenu_Main_CPEvt', { location: 'WaffleMenu' }, buttonRef);
    const translate = useTranslationHydration();

    const handleMouseOver = (index: number) => {
        setHoveredIndex(index);
        setFocusIndex(null);
    };

    const handleMouseOut = (index: number) => {
        setHoveredIndex(null);
    };

    const handleDropp = () => {
        setIsDropped(true);
    };

    const moveItem = (fromIndex: number, toIndex: number) => {
        const updatedItems = [...items];
        const [movedItem] = updatedItems.splice(fromIndex, 1);
        updatedItems.splice(toIndex, 0, movedItem);
        updatedItems.forEach((item, index) => {
            updatedItems[index] = { ...item, Order: index + 1 };
        });

        setItems(updatedItems);
    };

    return (
        <DndProvider backend={HTML5Backend}>
            <div style={{ display: 'flex' }}>
                <div className='d-flex align-items-center justify-center'>
                    <DropdownMenuButton
                        childrenDivClassName='dropdown waffle-menu-wrapper mx-1 pb-0'
                        id='WaffleMenuDropdown'
                        label={null}
                        isOpen={isWaffleOpen}
                        disabled={isLoading}
                        onClick={handleWaffleToggle}
                        icon={faThLarge}
                        btnClassNameSecondary='btn btn-tertiary rounded-circle image-40px position-relative mx-1'
                        ref={dropdownWuffleMenuRef}
                        buttonRef={buttonRef}
                        style={{ maxWidth: '590px' }}
                        waffleMenuItemsLength={waffleItems?.length}
                    >
                        <h4 className='dropdown-header border-bottom'>{translate('PortalApps_Label')}</h4>
                        {/* <div className='dropdown-menu dropdown-menu-left pb-0'> */}
                        <div
                            className={`waffle-menu bg-gray-200 border-top rounded-bottom ${
                                waffleItems && waffleItems?.length <= 3 ? 'single-column' : 'grid'
                            }`}
                            style={
                                waffleItems && waffleItems?.length <= 3
                                    ? {}
                                    : {
                                          gridTemplateRows: waffleItems ? `repeat(${Math.ceil(waffleItems?.length / 2)}, 50px)` : '',
                                          gridAutoFlow: 'column'
                                      }
                            }
                        >
                            {items?.map((item: SolutionMenuItem, index: number) => (
                                <WaffleDraggableItem
                                    key={index}
                                    index={index}
                                    focusIndex={focusIndex}
                                    setFocusIndex={setFocusIndex}
                                    item={
                                        <Link
                                            key={index}
                                            to={{ pathname: item.Url }}
                                            onMouseOver={() => handleMouseOver(index)}
                                            onMouseOut={() => handleMouseOut(index)}
                                            onDrop={handleDropp}
                                            className='app-link d-flex flex-row justify-content-between align-items-center rounded-sm flex-grow-1 p-2'
                                            target='_blank'
                                            style={{
                                                cursor: 'grab',
                                                backgroundColor: focusIndex === index || hoveredIndex === index ? '#e2e9f0' : 'transparent'
                                            }}
                                        >
                                            <div className='d-flex flex-row align-items-center'>
                                                <div className='app-icon d-flex flex-column justify-content-center align-items-center shadow-sm flex-shrink-0 mr-2'>
                                                    <FontAwesomeIcon
                                                        icon={appItems.filter((appItem) => appItem.Label === item.Label)[0]?.Icon}
                                                    />
                                                </div>
                                                <div className='app-name flex-shrink-0'>{translate(item.Label)}</div>
                                            </div>
                                            <div
                                                className='external-link-icon ml-3'
                                                style={{
                                                    visibility: focusIndex === index || hoveredIndex === index ? 'visible' : 'hidden'
                                                }}
                                            >
                                                <FontAwesomeIcon icon={faExternalLinkAlt} />
                                            </div>
                                        </Link>
                                    }
                                    moveItem={moveItem}
                                    style={{ border: 'none' }}
                                />
                            ))}
                        </div>
                        {/* </div> */}
                    </DropdownMenuButton>

                    {/* Header bell icon button */}

                    {/* Header navigation */}
                </div>
            </div>
        </DndProvider>
    );
};

export default WaffleDraggableDropdown;
