import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useTranslation from '../../../../../common/hooks/useTranslation';
import { ClientSelectionSlice, HydrationSlice } from '../../../../../common/models/ReduxSlices';
import { DateRangeDocumentsFilter } from '../../../common/constants/constants-documents';
import { DateRangeSlice, ImagingModelSlice } from '../../../common/models/ReduxSlices';
import { setCustomDateRange, setDateRangeSelected } from '../../../redux/reducers/dateRangeSlice';
import { setPageSelected } from '../../../redux/reducers/imagingGridSettingsSlice';
import { updateDateFilters } from '../../../redux/reducers/imagingSlice';
import { RootState } from '../../../redux/store';
import DateComponent from './DateComponent';

interface DateRangeFilterProps {
    btnClass?: string;
    isOpen: boolean;
    onClick: () => void;
    handleToggle: () => void;
}

const DateRangeFilter = React.forwardRef<HTMLDivElement, DateRangeFilterProps>(({ btnClass = '', onClick, isOpen, handleToggle }, ref) => {
    const { user } = useSelector<RootState, HydrationSlice>((state) => state.hydration);
    const { initialClientSelection } = useSelector<RootState, ClientSelectionSlice>((state) => state.clientSelection);
    const country = initialClientSelection?.Countries.find((c) => c.IsSelected);
    const endDateFilter = new Date().getFullYear();
    const startDateFilter = user?.PortalClearancesCreatedOnMinDate ?? `01/01/2022`;
    const [showDateRange, setShowDateRange] = useState(false);
    const [startDateUs, setStartDateUs] = useState('');
    const [endDateUs, setEndDateUs] = useState('');
    const [startDateCa, setStartDateCa] = useState('');
    const [endDateCa, setEndDateCa] = useState('');

    const { dateRangeSelected, customDateRange } = useSelector<RootState, DateRangeSlice>((state) => state.dateRange);
    const {
        dateRangeCaLabel,
        dateRangeUsLabel,
        customDateRangeCaEndLabel,
        customDateRangeCaStartLabel,
        customDateRangeUsEndLabel,
        customDateRangeUsStartLabel,
        searchTypeCode,
        model
    } = useSelector<RootState, ImagingModelSlice>((state) => state.imaging);
    const dispatch = useDispatch();
    const translate = useTranslation();

    const attribute = country?.Code === 'us' ? 'USARVD' : 'LIETA';

    useEffect(() => {
        if (customDateRange?.length === 1) {
            if (customDateRange[0].type === 'startdate') {
                if (country?.Code === 'us') {
                    setStartDateUs(customDateRange[0].dateUs);
                    setEndDateUs('');
                } else {
                    setStartDateCa(customDateRange[0].dateCa || '');
                    setEndDateCa('');
                }
            } else {
                if (country?.Code === 'us') {
                    setEndDateUs(customDateRange[0].dateUs);
                    setStartDateUs('');
                } else {
                    setEndDateCa(customDateRange[0].dateCa || '');
                    setStartDateCa('');
                }
            }
        } else if (customDateRange?.length === 2) {
            if (country?.Code === 'us') {
                setStartDateUs(customDateRange[0].dateUs);
                setEndDateUs(customDateRange[1].dateUs);
            } else {
                setStartDateCa(customDateRange[0].dateCa || '');
                setEndDateCa(customDateRange[1].dateCa || '');
            }
        } else {
            if (country?.Code === 'us') {
                setStartDateUs('');
                setEndDateUs('');
            } else {
                setStartDateCa('');
                setEndDateCa('');
            }
        }
    }, [customDateRange, country?.Code]);

    const handleSelection = (dateRangeIndex: number) => {
        dispatch(setDateRangeSelected(dateRangeIndex));
        dispatch(setPageSelected(1));
    };
    const handleCustomDateSelection = () => {
        var arr = [];

        if (startDateUs.length > 0 || startDateCa.length > 0) {
            arr.push({
                type: 'startdate',
                dateUs: startDateUs,
                dateCa: startDateCa
            });
        }
        if (endDateUs.length > 0 || endDateCa.length > 0) {
            arr.push({
                type: 'enddate',
                dateUs: endDateUs,
                dateCa: endDateCa
            });
        }

        if (arr.length) {
            const startDateFormated = startDateUs.replaceAll('-', '/');
            const endDateFormated = endDateUs.replaceAll('-', '/');

            const startDateCheck = startDateUs.length > 0 ? new Date(startDateFormated) : new Date(startDateFilter);

            const endDateCheck = endDateUs.length > 0 ? new Date(endDateFormated) : new Date(endDateFilter, 11, 31);

            const dateCheckMin = new Date(startDateFilter);
            const dateCheckMax = new Date(endDateFilter, 11, 31);

            if (startDateCheck >= dateCheckMin && endDateCheck <= dateCheckMax) {
                dispatch(setCustomDateRange(arr));
                handleSelection(8);
            }
        }
    };

    const renderDateRangeOptions = () => {
        return (
            <div>
                {/* Dropdown main-content */}
                {DateRangeDocumentsFilter.map((dateRange, dateRangeIndex) => {
                    const isActive =
                        country?.Code === 'us'
                            ? dateRange.label === dateRangeUsLabel
                                ? true
                                : false
                            : dateRange.label === dateRangeCaLabel
                            ? true
                            : false;
                    if (dateRangeIndex < 8) {
                        return (
                            <div
                                className={`dropdown-item d-flex justify-content-between align-items-stretch ${isActive ? 'active' : ''}`}
                                key={dateRange.id}
                                onClick={() => {
                                    handleSelection(dateRangeIndex);

                                    if (
                                        (model && model.Filters && dateRange && dateRange.startDate && dateRange.endDate) ||
                                        (model &&
                                            model.Filters &&
                                            dateRange &&
                                            dateRange.startDate !== undefined &&
                                            dateRange.endDate !== undefined &&
                                            dateRange.id === 'ALL_TIME')
                                    ) {
                                        dispatch(
                                            updateDateFilters({
                                                startDate: dateRange.startDate,
                                                endDate: dateRange.endDate,
                                                attribute: searchTypeCode === 'CACLVS' ? 'LICONS' : attribute,
                                                dateRangeLabel: dateRange.label
                                            })
                                        );
                                    }
                                    handleToggle();
                                }}
                                style={isActive ? { backgroundColor: '#fff' } : undefined}
                            >
                                <div className='name d-flex align-items-center mr-3'>{translate(dateRange.label)}</div>
                            </div>
                        );
                    } else if (dateRangeIndex === 8) {
                        return (
                            <div key={dateRange.id}>
                                <div className='dropdown-divider'></div>
                                <a
                                    href='#/'
                                    className={`dropdown-item custom-date-range-toggle d-flex justify-content-between align-items-stretch ${
                                        isActive ? 'active' : ''
                                    }`}
                                    style={isActive ? { backgroundColor: '#fff' } : undefined}
                                    onClick={(e) => {
                                        setShowDateRange(true);
                                        handleSelection(dateRangeIndex);

                                        if (model && model.Filters && dateRange && dateRange.startDate && dateRange.endDate) {
                                            dispatch(
                                                updateDateFilters({
                                                    startDate: dateRange.startDate,
                                                    endDate: dateRange.endDate,
                                                    attribute: searchTypeCode === 'CACLVS' ? 'LICONS' : attribute,
                                                    dateRangeLabel: dateRange.label
                                                })
                                            );
                                        }
                                    }}
                                >
                                    {translate('CustomDateRange_Label')}
                                </a>
                            </div>
                        );
                    } else {
                        return undefined;
                    }
                })}
            </div>
        );
    };

    const renderCustomDateRange = () => {
        return (
            <div className='card shadow-none border-none'>
                <div className='card-header px-4 py-3'>
                    <h5 className='m-0'>{translate('SelectCustomDateRange_Label')}</h5>
                </div>
                <div className='card-body px-4 py-3'>
                    <DateComponent
                        placeholder={translate('SelectStartDate_Label')}
                        dateLabel={translate('StartDate_Label')}
                        valMsg='Invalid date message'
                        noBorder
                        noHeader
                        value={
                            country?.Code === 'us' ? startDateUs || customDateRangeUsStartLabel : startDateCa || customDateRangeCaStartLabel
                        }
                        onDateChanged={country?.Code === 'us' ? setStartDateUs : setStartDateCa}
                        medium
                        dateCheckObj={{
                            comparationValueMin: new Date(startDateFilter),
                            comparationValueMax: new Date(endDateFilter, 11, 31)
                        }}
                    />
                    <DateComponent
                        placeholder={translate('SelectEndDate_Label')}
                        dateLabel={translate('EndDate_Label')}
                        valMsg='Invalid date message'
                        noBorder
                        noHeader
                        value={country?.Code === 'us' ? endDateUs || customDateRangeUsEndLabel : endDateCa || customDateRangeCaEndLabel}
                        onDateChanged={country?.Code === 'us' ? setEndDateUs : setEndDateCa}
                        medium
                        dateCheckObj={{
                            comparationValueMin: new Date(startDateFilter),
                            comparationValueMax: new Date(endDateFilter, 11, 31)
                        }}
                    />
                </div>
                <div className='card-footer d-flex px-4 py-3'>
                    <button
                        type='button'
                        className='btn btn-secondary cancel-custom-date-range mr-1 flex-fill text-center'
                        onClick={(e) => {
                            setShowDateRange(false);
                            e.stopPropagation();
                        }}
                    >
                        {translate('Cancel_Label')}
                    </button>
                    <button
                        type='button'
                        className='btn btn-primary apply-custom-date-range ml-1 flex-fill text-center'
                        onClick={() => {
                            setShowDateRange(false);
                            handleToggle();
                            handleCustomDateSelection();
                            if ((startDateUs && endDateUs) || (startDateCa && endDateCa)) {
                                dispatch(
                                    updateDateFilters({
                                        startDate:
                                            country?.Code === 'us'
                                                ? moment(startDateUs, 'MM-DD-YYYY').startOf('day').format('YYYY-MM-DDTHH:mm:ss')
                                                : moment(startDateCa, 'MM-DD-YYYY').startOf('day').format('YYYY-MM-DDTHH:mm:ss'),
                                        endDate:
                                            country?.Code === 'us'
                                                ? moment(endDateUs, 'MM-DD-YYYY').endOf('day').format('YYYY-MM-DDTHH:mm:ss')
                                                : moment(endDateCa, 'MM-DD-YYYY').endOf('day').format('YYYY-MM-DDTHH:mm:ss'),
                                        attribute: searchTypeCode === 'CACLVS' ? 'LICONS' : attribute,
                                        dateRangeLabel: dateRangeSelected.label
                                    })
                                );
                            }
                        }}
                    >
                        {translate('Apply_Label')}
                    </button>
                </div>
            </div>
        );
    };

    return (
        <div className={`dropdown ${isOpen ? 'show' : ''}`} ref={!showDateRange ? ref : null}>
            {/* Dropdown header */}
            <button
                className={`btn btn-tertiary ${btnClass} dropdown-toggle btn-sm`}
                type='button'
                onClick={onClick}
                disabled={model && model.Filters ? false : true}
            >
                {country?.Code === 'us'
                    ? customDateRangeUsStartLabel && customDateRangeUsEndLabel
                        ? translate(dateRangeUsLabel, customDateRangeUsStartLabel, customDateRangeUsEndLabel)
                        : dateRangeUsLabel
                        ? translate(dateRangeUsLabel)
                        : translate('SelectDateByRange_Label')
                    : customDateRangeCaStartLabel && customDateRangeCaEndLabel
                    ? translate(dateRangeCaLabel, customDateRangeCaStartLabel, customDateRangeCaEndLabel)
                    : dateRangeCaLabel
                    ? translate(dateRangeCaLabel)
                    : translate('SelectDateByRange_Label')}
            </button>

            {/* Dropdown menu */}
            <div id='DateRangePicker' className={`dropdown-menu ${isOpen ? 'show' : ''}`} aria-labelledby='Filter'>
                {isOpen && !showDateRange && renderDateRangeOptions()}
                {showDateRange && renderCustomDateRange()}
            </div>
        </div>
    );
});
export default DateRangeFilter;
