import { isRejected } from '@reduxjs/toolkit';
import React from 'react';
import useToast from '../../../../common/features/Toast/useToast';
import ModalWrapper from '../../../../common/features/Wrappers/ModalWrapper';
import useTranslation from '../../../../common/hooks/useTranslation';
import { CancelStatusTeamMember, ReactivateStatusTeamMember } from '../../redux/actions/teamManagement';
import { useTeamDispatch, useTeamSelector } from '../../redux/hooks';
import { closeStatusModal, setStatusIsLoading, setStatusMessage, statusChangeCheck } from '../../redux/reducers/statusUserSlice';

const ChangeUserStatusModal: React.FC = () => {
    const { statusUserId, isActive } = useTeamSelector((state) => state.statusUser);
    const dispatch = useTeamDispatch();

    const translate = useTranslation();

    const toast = useToast();

    const USER_CHANGE_STATUS_FUNCTION = isActive ? CancelStatusTeamMember : ReactivateStatusTeamMember;

    const deactivateUser = async (userId: number) => {
        try {
            dispatch(closeStatusModal());
            dispatch(setStatusIsLoading(true));

            const action = await dispatch(USER_CHANGE_STATUS_FUNCTION(userId));

            if (isRejected(action)) {
                throw new Error(translate('SomethingWentWrong_Label'));
            }

            dispatch(setStatusMessage(true));
            dispatch(statusChangeCheck());
        } catch (error) {
            if (error instanceof Error) {
                toast.send({ type: 'error', message: error.message });
            } else {
                toast.send({ type: 'error', message: translate('SomethingWentWrong_Label') });
            }
        } finally {
            dispatch(closeStatusModal());
            dispatch(setStatusIsLoading(false));
        }
    };

    return (
        <ModalWrapper onEscape={() => dispatch(closeStatusModal())}>
            <div className='modal-dialog modal-dialog-centered modal-dialog-scrollable'>
                <div className='modal-content' style={{ padding: '15px', maxWidth: '500px' }}>
                    <div className='modal-header' style={{ borderBottom: 'none', paddingBottom: '0' }}>
                        <h3 className='modal-title' style={{ fontSize: '20px' }}>
                            {isActive ? translate('DeactivateUserModal_Label') : translate('ReactivateUserModal_Label')}
                        </h3>
                    </div>
                    <div className='modal-body' style={{ paddingTop: '12px', paddingBottom: '40px' }}>
                        {isActive ? translate('DeactivateModalMessage_Label') : translate('ReactivateModalMessage_Label')}
                    </div>
                    <div className='modal-footer'>
                        <button
                            type='button'
                            className='btn btn-tertiary-blue btn-lg'
                            onClick={() => {
                                dispatch(closeStatusModal());
                            }}
                        >
                            {translate('Close_Label')}
                        </button>
                        <button type='button' className='btn btn-danger btn-lg' onClick={deactivateUser.bind(null, statusUserId)}>
                            {isActive ? translate('ConfirmUserDeactivation_Label') : translate('ConfirmUserReactivation_Label')}
                        </button>
                    </div>
                </div>
            </div>
        </ModalWrapper>
    );
};

export default ChangeUserStatusModal;
