import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Spinner from '../../../../common/features/Spinner';
import { useSearchParams } from '../../../../common/hooks/useSearchParams';
import useTranslation from '../../../../common/hooks/useTranslation';
import { editTeamMember } from '../../api/utils';
import ClientsAndPermissions from '../../common/features/ClientsAndPermissions';
import Header from '../../common/features/Header';
import type { TeamMemberData } from '../../common/models/ResponseData';
import { GetTeamMemberDetails } from '../../redux/actions/teamManagement';
import { useTeamDispatch } from '../../redux/hooks';
import { resetEditTeamMember } from '../../redux/reducers/editTeamMemberSlice';
import { statusChangeCheck } from '../../redux/reducers/statusUserSlice';
import TeamMemberBreadcrumb from './EditTeamMemberBreadcrumb';
import TeamMemberFooter from './EditTeamMemberFooter';
import EditTeamMemberUserDetails from './EditTeamMemberUserDetails';

const EditTeamMember = () => {
    const dispatch = useTeamDispatch();

    const [isLoading, setIsLoading] = useState(false);

    const { push } = useHistory();
    const [searchParams] = useSearchParams();
    const userId = parseInt(searchParams.get('userId') || '');

    const translate = useTranslation();

    // On initial load get the team member details and reset "editTeamMember" slice on unmount
    useEffect(() => {
        if (!userId) return;

        dispatch(GetTeamMemberDetails(userId));
        return () => {
            dispatch(resetEditTeamMember());
        };
    }, [dispatch, userId]);

    const handleSaveUser = (payload: { data: TeamMemberData; editData: TeamMemberData }) => {
        setIsLoading(true);

        editTeamMember(payload.data, payload.editData).then((res: any) => {
            setIsLoading(false);
            dispatch(statusChangeCheck());
            if (res.data === 'SuccessfulSubmit') {
                return push('/Team');
            }
        });
    };

    if (isLoading) {
        return (
            <div className='w-100 h-100 d-flex align-items-center justify-content-center'>
                <Spinner />
            </div>
        );
    }

    return (
        <>
            {/* Breadcrumb */}
            <TeamMemberBreadcrumb />
            {/* Header */}
            <Header title={translate('TeamMemberDetails_Label')} />
            {/* Content */}
            <div className='container-fluid py-3'>
                {/* 1. User details */}
                <EditTeamMemberUserDetails />
                {/* 2. Clients and permissions */}
                <ClientsAndPermissions />
            </div>
            {/* Footer */}
            <TeamMemberFooter onSaveUser={handleSaveUser} />
        </>
    );
};

export default EditTeamMember;
