import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../../../common/features/Button';
import ModalWrapper from '../../../../../common/features/Wrappers/ModalWrapper';
import useTranslation from '../../../../../common/hooks/useTranslation';
import { ProfileModals } from '../../../../../common/models/SubscribersModals';
import { SubscriptionsSlice } from '../../../common/models/ReduxSlices';
import { MultipleUnsubscribe } from '../../../redux/actions/subscriptions';
import { setCustomerNumbers } from '../../../redux/reducers/subscriptionsByUserAndClientSlice';
import { resetSubscriptionUpdate } from '../../../redux/reducers/subscriptionsSlice';
import { RootState } from '../../../redux/store';

interface UnsubscribeModalProps {
    subscribers: any[] | null;
    toggleModal: (prop: keyof ProfileModals) => void;
    gridSubscriptions: any;
    setGridSubscriptions: (value: any) => void;
}

const UnsubscribeModal: React.FC<UnsubscribeModalProps> = ({ subscribers, toggleModal, gridSubscriptions, setGridSubscriptions }) => {
    const dispatch = useDispatch();
    const translate = useTranslation();

    const handleCancel = () => {
        toggleModal('remove');
    };
    const { subscriptionUpdate } = useSelector<RootState, SubscriptionsSlice>((state) => state.subscriptions);

    const GetIds = (subscribers: any[]) => {
        let subscriberIds: number[] = [];
        subscribers.forEach((subscriber: any) => {
            if (subscriber.SubscriptionId) {
                subscriberIds.push(subscriber.SubscriptionId);
            }
        });
        return subscriberIds;
    };

    const CustomerNumbersArray = subscribers?.map((customerNumber: any) => customerNumber.CustomerNumber) ?? [];
    useEffect(() => {
        dispatch(setCustomerNumbers(CustomerNumbersArray));
    }, [dispatch]);

    const handleSubmit = () => {
        if (gridSubscriptions) {
            setGridSubscriptions(
                gridSubscriptions.map((item: any) => ({
                    ...item,

                    isNew: false
                }))
            );
        }
        if (subscriptionUpdate.data) {
            dispatch(resetSubscriptionUpdate());
        }
        if (subscribers != null) {
            let subscriptionIds: number[] = GetIds(subscribers);
            toggleModal('remove');
            dispatch(MultipleUnsubscribe({ subscriptionIds }));
        }
    };

    return (
        <ModalWrapper>
            <div className='modal-dialog modal-dialog-centered modal-dialog-scrollable'>
                <div className='modal-content'>
                    {/* Heading */}

                    {/* Body */}
                    <div className='modal-body'>
                        <h3 className='modal-title' id='staticBackdropLabel'>
                            {translate('RemoveMultipleSubscribers_Label', subscribers?.length.toString())}
                        </h3>
                        <p className='note mb-0 notification-message' style={{ width: '100%' }}>
                            {translate('UnsubscribeMultipleModal_Label', subscribers?.length.toString())}
                        </p>
                    </div>

                    {/* Footer */}
                    <div className='modal-footer'>
                        <Button variant='secondary' size='medium' onClick={handleCancel}>
                            {translate('Cancel_Label')}
                        </Button>

                        <Button variant='danger' size='medium' onClick={handleSubmit}>
                            {translate('YesUnsubscribe_Label', subscribers?.length.toString())}
                        </Button>
                    </div>
                </div>
            </div>
        </ModalWrapper>
    );
};

export default UnsubscribeModal;
