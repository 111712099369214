import { faBoxOpen, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { memo, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { shipmentDataSourceConstants } from '../../../../common/constants/constants-portal';
import Button from '../../../../common/features/Button';
import ClientChooser from '../../../../common/features/ClientChooser/ClientChooser';
import ColumnSelectionDropdown from '../../../../common/features/Grid/ColumnSelectionDropdown';
import GridHeader from '../../../../common/features/GridHeader';
import HeaderBar from '../../../../common/features/HeaderBar/HeaderBar';
import { ShipmentDataSource } from '../../../../common/features/ShipmentDataSource';
import useClientDimensions from '../../../../common/hooks/useClientDimensions';
import useMediaQuery from '../../../../common/hooks/useMediaQuery';
import useTranslation from '../../../../common/hooks/useTranslation';
import { ColumnSettings } from '../../../../common/models/GridPreferences';
import { ClientSelectionSlice, GridPreferencesSlice, HydrationSlice } from '../../../../common/models/ReduxSlices';
import { SaveShipmentDataSource } from '../../../../redux/actions/shipmentDataSource';
import hasFilterBarFixedWidth from '../../../documents/utils/hasFilterBarFixedWidth';
import CADSearchBar from '../../common/components/Search/CADSearchBar';
import { gridSettingsConstants } from '../../common/constants/complete-entry-data-constants';
import { DateFilterSlice, LocationFilterSlice, MOTFilterSlice } from '../../common/models/ReduxSlices';
import { CASSModel } from '../../common/models/completeEntryDataModels';
import { SaveUserCADGridSetting } from '../../redux/actions/completeEntryDataGridPreferences';
import { updateColumnOrder, updateCoulumnActivated } from '../../redux/reducers/completeEntryDataGridPreferenceSlice';
import { resetShipmentGridSettings } from '../../redux/reducers/completeEntryDataGridSettingsSlice';
import {
    updateDateFilter,
    updateLocationTypeFilter,
    updateMOTFilter,
    updateSelectLocationFilter,
    updateShouldSubmitSearch
} from '../../redux/reducers/completeEntryDataSlice';
import {
    resetDateFilters,
    setAppliedDate,
    setAppliedType,
    setDate,
    setDateDropdownLabel,
    setEndDateCa,
    setStartDateCa,
    setType,
    updateIsDateOpen
} from '../../redux/reducers/dateFilterSlice';
import { resetApplylocationFilter, resetLocationFilters, updatedLocationIsFiltered } from '../../redux/reducers/locationFilterSlice';
import { resetMotFilters } from '../../redux/reducers/motFilterSlice';
import { RootState } from '../../redux/store';
import DateDocumentsFilter from '../Filters/date/DateDocumentsFilter';
import LocationFilter from '../Filters/location/LocationFilter';
import MOTFilter from '../Filters/mot/MOTFilter';
import CompleteEntryDataGrid from './CompleteEntryDataGrid';

const CompleteEntryDataPage = memo(function CompleteEntryDataPage() {
    const translate = useTranslation();
    const scrollableDivRef = useRef<HTMLDivElement>(null);
    const scrollableDivItems = scrollableDivRef.current && Array.from(scrollableDivRef.current['children']);
    const direction = {
        RIGHT: scrollableDivItems ? scrollableDivItems.length - 1 : 0,
        LEFT: 0
    };
    const [scrollRight, setScrollRight] = useState(true);
    const resize = useClientDimensions();
    const mounted = useRef(false);

    // Fix filter bar width once it grows to a certain length
    const [filterBarHasFixedWidth, setFilterBarHasFixedWidth] = useState<boolean>(); //, setFilterBarHasFixedWidth
    const [filtersHaveSelected, setFiltersHaveSelected] = useState(false);

    const dispatch = useDispatch();
    const history = useHistory();
    const mobileView = useMediaQuery(0, 515);

    const { allColumns, visibleColumns, areColumnsLoading } = useSelector<RootState, GridPreferencesSlice>(
        (state) => state.completeEntryDataGridPreferences
    );

    const { model } = useSelector<RootState, CASSModel>((state) => state.completeEntryData);
    const { date, type, dateDropdownLabel, endDateCa, startDateCa, appliedDate, appliedType } = useSelector<RootState, DateFilterSlice>(
        (state) => state.dateFilter
    );
    const { selectedStringArray } = useSelector<RootState, MOTFilterSlice>((state) => state.motFilter);

    const { locationTypeSelected, selectedLocationLabels } = useSelector<RootState, LocationFilterSlice>((state) => state.locationFilter);
    const gridHeaderRef = useRef<HTMLDivElement>(null);

    const dataSourceTranslations = {
        RealTimeData: translate('RealTimeData_Label'),
        MonitorClearanceStatuses: translate('MonitorClearanceStatuses_Label'),
        UpdatedRealTime: translate('UpdatedRealTime_Label'),
        CompleteEntryData: translate('CompleteEntryData_Label'),
        ComprehensiveClearanceData: translate('ComprehensiveClearanceData_Label'),
        UpdatedPeriodically: translate('UpdatedPeriodically_Label')
    };

    const { initialClientSelection, clientSelection, isLoadingUpdate, isLoadingGet } = useSelector<RootState, ClientSelectionSlice>(
        (state) => state.clientSelection
    );
    const country = initialClientSelection?.Countries.find((item) => item.IsSelected);
    const { shipmentDataSource, isSaveDataSourceLoading, isShipmentDataSourceLoading } = useSelector<RootState, HydrationSlice>(
        (state) => state.hydration
    );

    // Detect if mounted and prevent making changes on an unmounted component
    useEffect(() => {
        mounted.current = true;
        return () => {
            mounted.current = false;
        };
    }, []);

    useEffect(() => {
        if (mounted.current && scrollableDivRef.current) {
            setFilterBarHasFixedWidth(hasFilterBarFixedWidth({ scrollableDivRef, filtersHaveSelected }));
            appliedDate.length || appliedType.length || selectedLocationLabels.length || selectedStringArray.length
                ? setFiltersHaveSelected(true)
                : setFiltersHaveSelected(false);
        }
    }, [appliedDate, appliedType, selectedStringArray, selectedLocationLabels, resize, filtersHaveSelected]);

    useEffect(() => {
        if (country?.Code === 'us') {
            dispatch(SaveShipmentDataSource({ PreferenceValue: shipmentDataSourceConstants.us, Country: 'ca' }));
        }
    }, [clientSelection, country?.Code, dispatch]);

    // Redirect to Clearances
    useEffect(() => {
        if (
            shipmentDataSource === shipmentDataSourceConstants.us &&
            !isLoadingUpdate &&
            !isLoadingGet &&
            !isSaveDataSourceLoading &&
            !isShipmentDataSourceLoading
        ) {
            history.push('/Clearances');
        }
    }, [shipmentDataSource, clientSelection?.ClientTableData, isSaveDataSourceLoading, history, isLoadingUpdate, isLoadingGet, isShipmentDataSourceLoading, dispatch]);

    const handleDataSourceSelection = (index: number) => {
        if (index === 0) {
            if (country?.Code) dispatch(SaveShipmentDataSource({ PreferenceValue: shipmentDataSourceConstants.us, Country: 'ca' }));
        } else if (index === 1) {
            if (country?.Code) dispatch(SaveShipmentDataSource({ PreferenceValue: shipmentDataSourceConstants.ca, Country: 'ca' }));
        }
    };

    const applyDropdownChanges = (payload: ColumnSettings[]) => {
        dispatch(updateColumnOrder(payload));
        dispatch(
            SaveUserCADGridSetting({
                GridColumns: payload
                    .map((item, i) => {
                        return { ...item, Order: i + 1 };
                    })
                    .filter((item: ColumnSettings) => {
                        if (item.Code !== 'selected' && item.Code !== 'Actions') {
                            return item;
                        }
                        return null;
                    }),
                area: gridSettingsConstants['ca']
            })
        );
    };

    const applyButtonFunction = (attribute: string, selectedOption: string, selectedValue: string, endDate: string, startDate: string) => {
        dispatch(
            updateDateFilter({
                attribute,
                selectedOption,
                selectedValue,
                endDate,
                startDate
            })
        );
    };

    const handleColumnActivated = (activated: boolean) => {
        dispatch(updateCoulumnActivated(activated));
    };
    const clearAllFilters = () => {
        setFilterBarHasFixedWidth(hasFilterBarFixedWidth({ scrollableDivRef, filtersHaveSelected }));
        dispatch(resetLocationFilters());
        dispatch(resetDateFilters());
        dispatch(
            updateDateFilter({
                attribute: 'Date',
                selectedOption: '',
                selectedValue: '',
                endDate: '',
                startDate: ''
            })
        );
        dispatch(resetMotFilters());
        dispatch(updateMOTFilter([]));
        dispatch(resetShipmentGridSettings());
        dispatch(updateSelectLocationFilter({ attribute: locationTypeSelected?.attribute, selectedArray: [] }));
        dispatch(updateLocationTypeFilter(''));
        dispatch(updateIsDateOpen(false));
        dispatch(updatedLocationIsFiltered(false));
        dispatch(resetApplylocationFilter());
        dispatch(updateShouldSubmitSearch(true));
    };

    const propDateValue = (dateValue: string) => {
        dispatch(setDate(dateValue));
    };
    const propDateType = (dateType: string) => {
        dispatch(setType(dateType));
    };
    const propDateLabel = (dateValue: string) => {
        dispatch(setDateDropdownLabel(dateValue));
    };
    const propStartDate = (dateValue: string) => {
        dispatch(setStartDateCa(dateValue));
    };
    const propEndDate = (dateValue: string) => {
        dispatch(setEndDateCa(dateValue));
    };
    const setappliedDate = (dateValue: string) => {
        dispatch(setAppliedDate(dateValue));
    };
    const setappliedTypeFunction = (dateValue: string) => {
        dispatch(setAppliedType(dateValue));
    };
    return (
        <div className='d-flex flex-column flex-fill'>
            {/* Header bar */}
            <HeaderBar columnsLayout mobileView={mobileView}>
                <div className={'d-flex align-items-center'}>
                    <span className='badge badge-circular badge-info mr-3'>
                        <FontAwesomeIcon icon={faBoxOpen} />
                    </span>
                    <h1 className={'m-0 mr-2'}>{translate('CompleteEntryDataTitle_Label')}</h1>
                </div>
                <div className={`d-flex ${mobileView ? 'dropdown-examples' : ''}`} style={{ alignItems: 'unset' }}>
                    {country && country.Code === 'ca' && (
                        <ShipmentDataSource
                            translations={dataSourceTranslations}
                            dataSource={shipmentDataSource}
                            handleDataSource={handleDataSourceSelection}
                            shipmentDataSourceConstants={shipmentDataSourceConstants}
                        />
                    )}
                    <ClientChooser />
                </div>
            </HeaderBar>

            <CADSearchBar />

            <nav className='bg-white border-bottom' id='FilterBarWithDropdowns'>
                <div className='d-flex justify-content-start'>
                    <div
                        className={`filters-and-nav-arrows-wrapper ${filterBarHasFixedWidth ? 'fixed-width' : ''} ${
                            filtersHaveSelected ? 'clear-all-showing' : ''
                        }`}
                    >
                        <div className='scrollable-filter-area d-flex flex-nowrap align-items-center py-3 pr-3' ref={scrollableDivRef}>
                            <div className='font-weight-medium pl-3 pr-2'>{translate('Filters_Label')}</div>
                            <DateDocumentsFilter
                                setappliedDate={setappliedDate}
                                setappliedType={setappliedTypeFunction}
                                appliedDate={appliedDate}
                                appliedType={appliedType}
                                date={date}
                                type={type}
                                dateDropdownLabel={dateDropdownLabel}
                                setDate={propDateValue}
                                setType={propDateType}
                                applyButtonFunction={applyButtonFunction}
                                propDateLabel={propDateLabel}
                                setEndDateCa={propEndDate}
                                setStartDateCa={propStartDate}
                                startDateCa={startDateCa}
                                endDateCa={endDateCa}
                            />
                            <LocationFilter />
                            <MOTFilter />
                        </div>
                        <Button
                            className='btn btn-tertiary btn-sm btn-scroll-left shadow'
                            onClick={() => {
                                scrollableDivItems && scrollableDivItems[direction.LEFT].scrollIntoView(false);
                                setScrollRight(true);
                            }}
                            style={{
                                display:
                                    filterBarHasFixedWidth &&
                                    scrollableDivRef.current &&
                                    scrollableDivRef.current.offsetWidth <= scrollableDivRef.current.scrollWidth &&
                                    !scrollRight
                                        ? 'block'
                                        : 'none'
                            }}
                            variant={'link'}
                        >
                            <FontAwesomeIcon icon={faChevronLeft} />
                        </Button>
                        <Button
                            className='btn btn-tertiary btn-sm btn-scroll-right shadow'
                            onClick={() => {
                                scrollableDivItems && scrollableDivItems[direction.RIGHT].scrollIntoView(false);
                                setScrollRight(false);
                            }}
                            style={{
                                display:
                                    filterBarHasFixedWidth &&
                                    scrollableDivRef.current &&
                                    scrollableDivRef.current.offsetWidth <= scrollableDivRef.current.scrollWidth &&
                                    scrollRight
                                        ? 'block'
                                        : 'none'
                            }}
                            variant={'link'}
                        >
                            <FontAwesomeIcon icon={faChevronRight} />
                        </Button>
                        <div
                            className={`clear-all-filters d-flex flex-row align-items-center ${'fixed-width text-center border-left shadow-sm justify-content-center justify-content-start'}`}
                        ></div>
                    </div>
                    {filtersHaveSelected && (
                        <div
                            className={`clear-all-filters d-flex flex-row align-items-center ${
                                filterBarHasFixedWidth
                                    ? 'fixed-width text-center border-left shadow-sm justify-content-center'
                                    : 'justify-content-start'
                            }`}
                        >
                            <Button
                                className={`no-bold-underline ${filterBarHasFixedWidth ? '' : 'text-decoration-none'}`}
                                variant={'link'}
                                onClick={() => clearAllFilters()}
                            >
                                {translate('ClearAll_Label')}
                            </Button>
                        </div>
                    )}
                </div>
            </nav>

            <GridHeader
                ref={gridHeaderRef}
                columnsDropdown={
                    !!model?.Shipments?.length && (
                        <ColumnSelectionDropdown
                            allColumns={allColumns ?? allColumns}
                            visibleColumns={visibleColumns ?? visibleColumns}
                            areColumnsLoading={areColumnsLoading}
                            applyDropdownChanges={applyDropdownChanges}
                            descriptiveText={translate('TableColumnsButton_Label')}
                            columnActivatedChange={handleColumnActivated}
                        />
                    )
                }
            />
            <CompleteEntryDataGrid />
        </div>
    );
});

export default CompleteEntryDataPage;
