import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import GridCaption from '../../../../common/features/Grid/GridCaption';
import useTranslation from '../../../../common/hooks/useTranslation';
import { GridSettingsSlice, SearchFilterSlice } from '../../../../common/models/ReduxSlices';
import { resetDataState, setPageSelected } from '../../redux/reducers/contactsGridSettingsSlice';
import { setSearchFilter } from '../../redux/reducers/contactsSearchFilterSlice';
import { RootState } from '../../redux/store';

const ContactsGridCaption = () => {
    const { searchTerm } = useSelector<RootState, SearchFilterSlice>((state) => state.contactsSearchFilter);
    const { dataState, totalItems } = useSelector<RootState, GridSettingsSlice>((state) => state.contactsGridSettings);
    const [showClear, setShowClear] = useState(false);
    const dispatch = useDispatch();
    const translate = useTranslation();

    useEffect(() => {
        if (searchTerm.length > 0 || dataState.filter !== undefined) {
            setShowClear(true);
        } else {
            setShowClear(false);
        }
    }, [searchTerm, dataState]);

    const clearFilters = () => {
        dispatch(setSearchFilter(''));
        dispatch(resetDataState({ take: 10, skip: 0, sort: [{ field: 'LastLogin', dir: 'desc' }] }));
        dispatch(setPageSelected(1));
    };

    return (
        <GridCaption clearLabel={translate('Clear_Label')} showClear={showClear} onClear={clearFilters} tableColumnsDropdown={false}>
            <span>{translate('AllContacts_Label', totalItems)}</span>
        </GridCaption>
    );
};

export default ContactsGridCaption;
