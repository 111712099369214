import type { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { forwardRef, type MouseEvent } from 'react';
import { Link, type LinkProps } from 'react-router-dom';

interface SidebarNavFooterHelpItemProps extends Pick<LinkProps, 'target'> {
    icon: IconDefinition;
    url?: string;
    title: string;
    onClick?: (event: MouseEvent<HTMLElement>) => void;
}

const SidebarNavFooterHelpItem = forwardRef<HTMLAnchorElement, SidebarNavFooterHelpItemProps>(function SidebarNavFooterHelpItem(
    { icon, url = '', target, title, onClick },
    ref
) {
    const content = (
        <>
            <div className='d-flex align-items-center justify-content-center mr-3'>
                <FontAwesomeIcon icon={icon} className='globe' style={{ width: '1.25rem' }} />
            </div>
            <div className='list-group-item sidebar-list-group-item py-2'>{title}</div>
        </>
    );

    if (target === '_blank' || url.startsWith('tel:') || url.startsWith('mailto:')) {
        return (
            <a className='help-items d-flex' ref={ref} href={url} target={target} rel='noreferrer'>
                {content}
            </a>
        );
    }

    return (
        <Link className='help-items d-flex' ref={ref} to={url} target={target} onClick={onClick}>
            {content}
        </Link>
    );
});

export default SidebarNavFooterHelpItem;
