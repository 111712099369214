import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TabNavigationIds } from '../../../../common/constants/constants-portal';
import ErrorPage from '../../../../common/features/ErrorPage';
import Spinner from '../../../../common/features/Spinner';
import TabPanel from '../../../../common/features/Tabs/TabPanel';
import TabPanelItem from '../../../../common/features/Tabs/TabPanelItem';
import { formatDateMMDDYY } from '../../../../common/functions/parser';
import useTranslation from '../../../../common/hooks/useTranslation';
import { HydrationSlice } from '../../../../common/models/ReduxSlices';
import { setRequestAnUpdateModel } from '../../redux/reducers/requestAnUpdateSlice';
import { RootState as ProfileRootState, RootState } from '../../redux/store';
import ProfileSubscriptionTable from '../../screens/ProfileGrid/ProfileSubscriptionTable/ProfileSubscriptionTable';
import { UserProfileSlice, UsersTicketsSlice } from '../models/ReduxSlices.js';
import RequestUpdateDropdown from './RequestUpdateDropdown';

const TabContent = () => {
    const { profile } = useSelector<RootState, UserProfileSlice>((state) => state.profile);
    const { usersTickets, isLoading, error } = useSelector<ProfileRootState, UsersTicketsSlice>((state) => state.tickets);
    const { user } = useSelector<RootState, HydrationSlice>((state) => state.hydration);
    const dispatch = useDispatch();
    const translate = useTranslation();
    useEffect(() => {
        if (profile) {
            dispatch(
                setRequestAnUpdateModel({
                    RequestType: '',
                    Description: '',
                    FirstName: profile.FirstName,
                    LastName: profile.LastName,
                    Email: profile.Email,
                    PhoneNumber: profile.Phone,
                    ClientType: ''
                })
            );
        }
    }, [dispatch, profile]);

    const contentArray = [
        translate('StatusProfile_Label'),
        translate('PersonalProfile_Label'),
        translate('CompanyProfile_Label'),
        translate('ContactProfile_Label'),
        translate('RoleProfile_Label'),
        translate('AccessExpiration_Label')
    ];
    const values = [
        { Status: profile?.UserStatus?.Name },
        { Personal: { name: profile?.FirstName, lastName: profile?.LastName } },
        { Company: profile?.CompanyName },
        { Contact: { email: profile?.Email, phoneNumber: profile?.Phone } },
        { Role: { User: profile?.UserRoles?.[0]?.Role?.DisplayName } },
        { AccessExpiration: { date: profile?.ExpirationDate } }
    ];

    const renderContent = () => {
        let type: String = '';
        let content: JSX.Element;
        let result: Array<JSX.Element> = [];
        contentArray.map((item, index) => {
            type = item;
            switch (item) {
                case translate('StatusProfile_Label'):
                    content = (
                        <div className='col-md-12'>
                            <span className='badge badge-pill badge-success'>{values[index]?.Status}</span>
                        </div>
                    );
                    break;
                case translate('PersonalProfile_Label'):
                    content = (
                        <>
                            <div className='col-md-6 mb-3'>
                                <h5>{translate('FirstName_Label')}</h5>
                                <div>{values[index]?.Personal?.name}</div>
                            </div>
                            <div className='col-md-6'>
                                <h5>{translate('LastName_Label')}</h5>
                                <div>{values[index]?.Personal?.lastName}</div>
                            </div>
                        </>
                    );
                    break;
                case translate('CompanyProfile_Label'):
                    content = (
                        <>
                            <div className='col-md-12'>
                                <h5>{translate('CompanyNameProfile_Label')}</h5>
                                <div>{values[index]?.Company}</div>
                            </div>
                        </>
                    );
                    break;
                case translate('ContactProfile_Label'):
                    content = (
                        <>
                            <div className='col-md-6 mb-3'>
                                <h5>{translate('EmailAddress_Label')}</h5>
                                <div>{values[index]?.Contact?.email}</div>
                            </div>
                            <div className='col-md-6'>
                                <h5>{translate('PhoneNumber_Label')}</h5>
                                <div>{values[index]?.Contact?.phoneNumber}</div>
                            </div>
                        </>
                    );
                    break;
                case translate('RoleProfile_Label'):
                    content = (
                        <>
                            <div className='col-md-12'>
                                <h5>{translate('UserRole_Label')}</h5>
                                <div>{values[index]?.Role?.User}</div>
                            </div>
                        </>
                    );
                    break;
                case translate('AccessExpiration_Label'):
                    content = (
                        <>
                            <div className='col-md-12'>
                                <h5>{translate('ExpirationDate_Label')}</h5>
                                <div>
                                    {values[index]?.AccessExpiration?.date
                                        ? formatDateMMDDYY(values[index]?.AccessExpiration?.date)
                                        : translate('NoExpirationDate_Label')}
                                </div>
                            </div>
                        </>
                    );
                    break;
                default:
            }
            return result.push(
                <div
                    key={index}
                    className={`row profile-content-block ${
                        type === translate('StatusProfile_Label') ? 'pb-4 pt-2 pb-md-5 pt-md-3' : 'py-4 py-md-5'
                    }`}
                >
                    <h4 className='col-12 col-md-3 mb-4 mb-md-0'>{type}</h4>
                    <div className='col-12 col-md-9 '>
                        <div className='row info-block'>{content}</div>
                    </div>
                </div>
            );
        });
        return result;
    };

    //only TeamAdmin have request an update button
    const headerArea = () => {
        if (profile?.UserRoles?.some((role) => role.Role?.Name === 'ClientAdmin' || role.Role?.Name === 'CentralAdmin')) {
            //check if team admin have some case tickets
            if (usersTickets && usersTickets.length > 0 && !usersTickets.every((ticket) => ticket.Status === 'Closed')) {
                return (
                    <>
                        <div>
                            {usersTickets
                                .filter((ticket) => ticket.Status !== 'Closed')
                                .map((ticket) => (
                                    <div className='d-flex mb-2 mb-md-1' key={ticket.CaseNumber}>
                                        <div className='alert-icon'>
                                            <FontAwesomeIcon icon={faInfoCircle} className='mt-1' />
                                        </div>
                                        {translate('YourCaseNumberSubmitted_Label')} &nbsp;<b>#{ticket.CaseNumber}</b>&nbsp;{' '}
                                        {translate('YourCaseSubmittedOn_Label', ticket.CreatedDate, ticket.Status)}
                                    </div>
                                ))}
                        </div>
                        {!user?.InternalUser && <RequestUpdateDropdown />}
                    </>
                );
            } else {
                return (
                    <>
                        <div className='d-flex mb-2 mb-md-0'>
                            <div className='alert-icon'>
                                <FontAwesomeIcon icon={faInfoCircle} className='mt-1' />
                            </div>
                            <div>{translate('UpdateProfileAlert_Label')}</div>
                        </div>
                        {!user?.InternalUser && <RequestUpdateDropdown />}
                    </>
                );
            }
        } else {
            return <div>{translate('ToMakeChangesToYourProfile_Label')}</div>;
        }
    };

    if (isLoading) {
        return (
            <div className='w-100 h-100 d-flex align-items-center justify-content-center'>
                <Spinner />
            </div>
        );
    }

    if (error === 500) {
        return <ErrorPage errorCode={500} withTranslations />;
    }

    return (
        <TabPanel>
            <TabPanelItem id={TabNavigationIds.Profile[0]} className='bg-white'>
                <div className='container-fluid-nopadding'>
                    <div className='d-flex flex-column flex-md-row justify-content-md-between alert alert-global alert-info ' role='alert'>
                        {headerArea()}
                    </div>
                </div>
                <div className='container-fluid'>{renderContent()}</div>
            </TabPanelItem>
            <TabPanelItem id={TabNavigationIds.Profile[1]}>
                <ProfileSubscriptionTable />
            </TabPanelItem>
        </TabPanel>
    );
};

export default TabContent;
