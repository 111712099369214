import { useCallback, useEffect, useState } from 'react';

const useDropdownToggle = (ref: React.RefObject<HTMLElement>) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [isOutside, setIsOutside] = useState<boolean>(false);

    // Detect if the user clicked inside or outside of the element
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (ref.current && !ref.current.contains(event.target as HTMLDivElement)) {
                setIsOutside(true);
            } else {
                setIsOutside(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref]);

    // If click is outside of the element close the dropdown
    useEffect(() => {
        if (isOutside) {
            setIsOpen(false);
        }
    }, [isOutside]);

    // Toggle the dropdown's visibility
    const handleToggle = useCallback(() => {
        if (isOutside) {
            setIsOpen(false);
        } else {
            setIsOpen((prevState) => !prevState);
        }
    }, [isOutside]);

    // Close the dropdown when "Enter" key is pressed on the input field inside the dropdown
    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            if (isOpen === true) {
                setIsOpen(false);
            }
        }
    };

    return { isOpen, isOutside, handleToggle, handleKeyDown, setIsOpen };
};

export default useDropdownToggle;
