import { useEffect, useRef, useState } from 'react';
import useDropdownToggle from '../hooks/useDropdownToggle';
import useSelectionChangeFilterDropdown from '../hooks/useSelectionChangeFilterDropdown';
import useTranslation from '../hooks/useTranslation';
import Button from './Button';
import Checkbox from './Checkbox';
import DropdownMenuButton from './DropdownMenuButton';

interface Status {
    id: string;
    label: string;
    amount?: number;
}

interface MOT {
    id: string;
    label: string;
    isChecked: boolean;
}

interface Milestones {
    label: string;
    value: number;
    destination: string;
}

interface SubscriptionType {
    label: string;
    value: string;
}

interface FilterProps {
    id: string;
    name: string;
    options: readonly Status[] | readonly MOT[] | readonly Milestones[] | readonly SubscriptionType[];
    optionsLabel: string;
    selection: null | number[];
    selectAllEnabled?: boolean;
    handleSelection: (id: string, selectedArray: number[]) => void;
    conditionallyTranslate?: boolean;
}

const FilterDropdown: React.FC<FilterProps> = ({
    id,
    name,
    options,
    optionsLabel,
    selection,
    handleSelection,
    conditionallyTranslate = false,
    selectAllEnabled = true
}) => {
    const dropdownMenuRef = useRef(null);
    const { isOpen, handleToggle } = useDropdownToggle(dropdownMenuRef);
    const [selectedValues, setSelectedValues] = useState<number[]>([]);
    const [selectedLabels, setSelectedLabels] = useState<string>();
    const [selectedAll, setSelectedAll] = useState<boolean>(false);
    const optionsLength = options.length;
    const translate = useTranslation();
    // Detect changes in selection to enable/disable apply
    const { hasChanged } = useSelectionChangeFilterDropdown({ selectedValues, selection });

    useEffect(() => {
        optionsLength === selectedValues?.length ? setSelectedAll(true) : setSelectedAll(false);
    }, [selectedValues, optionsLength]);

    useEffect(() => {
        selection && setSelectedValues(selection);
    }, [isOpen, selection]);

    // Creating label with selected options
    useEffect(() => {
        selection?.length
            ? setSelectedLabels(
                  `${name}: ${selection
                      .map((item) => (id === 'Document Status' ? options[item]?.label : translate(options[item]?.label)))
                      .toString()
                      .replace(/,/g, ', ')}`
              )
            : setSelectedLabels(name);
    }, [selection, name, options, translate]);

    const handleChange = (index: number) => {
        const newValues = [...selectedValues];
        const indx = newValues.indexOf(index);
        indx > -1 ? newValues.splice(indx, 1) : newValues.push(index);
        setSelectedValues(newValues);
    };

    const handleSelectAll = () => {
        if (selectedAll) {
            setSelectedValues([]);
        } else {
            const selectedAllArray: number[] = [];
            options.forEach((item, i) => selectedAllArray.push(i));
            setSelectedValues(selectedAllArray);
        }
    };

    return (
        <>
            <DropdownMenuButton
                label={selectedLabels}
                isOpen={isOpen}
                onClick={handleToggle}
                ref={dropdownMenuRef}
                btnClassName={selection && selection.length ? 'btn-sm dropdown-toggle items-selected ml-1' : 'btn-sm dropdown-toggle ml-1'}
            >
                <div className='card shadow-none'>
                    <div className='card-body px-4 py-3 border-bottom font-weight-bold'>{optionsLabel}</div>
                </div>
                <div className='max-height-280'>
                    <div className='card-body px-4 py-3'>
                        {/* Select all */}
                        {selectAllEnabled && (
                            <label className='d-flex justify-content-between align-items-stretch mb-2'>
                                <Button className='pl-0' variant={'link'} onClick={handleSelectAll}>
                                    {selectedAll
                                        ? translate('NotificationsDeselectAllNotifications_Label')
                                        : translate('NotificationsSelectAllNotifications_Label')}
                                </Button>
                            </label>
                        )}
                        {/* Provided dropdown options */}
                        {options.map((option, index) => (
                            <label
                                key={Object.values(option)[0]}
                                className='d-flex justify-content-between align-items-stretch mb-2'
                                htmlFor={Object.values(option)[0]}
                            >
                                <Checkbox
                                    id={`${id.replace(/ /g, '-')}-${Object.values(option)[0]}`}
                                    name={option.label}
                                    inputClass='custom-control-input mr-3'
                                    labelClass='custom-control-label text-lii-text font-weight-normal'
                                    divClass='custom-control custom-checkbox'
                                    isChecked={selectedValues.includes(index)}
                                    onChange={() => handleChange(index)}
                                >
                                    {conditionallyTranslate ? option.label : translate(option.label)}
                                </Checkbox>
                            </label>
                        ))}
                    </div>
                    {/* Cancel and Apply */}
                </div>
                <div className='card shadow-none'>
                    <div className='card-body px-4 py-3 border-top'>
                        <div className='font-weight-medium mb-3'>
                            {`${selectedValues.length} `}
                            {translate('SelectedItems_Label')}
                        </div>
                        <div className='d-flex align-items-center'>
                            <Button
                                variant='tertiary-blue'
                                className='mr-1 flex-fill'
                                children={translate('Cancel_Label')}
                                onClick={handleToggle}
                            />
                            <Button
                                variant='primary'
                                className='ml-1 flex-fill'
                                disabled={hasChanged}
                                onClick={() => {
                                    handleToggle();
                                    handleSelection(id, selectedValues);
                                }}
                                children={translate('Apply_Label')}
                            />
                        </div>
                    </div>
                </div>
            </DropdownMenuButton>
        </>
    );
};

export default FilterDropdown;
