import $ from 'jquery';
import moment from 'moment';
import { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import axios from '../../../axios-mlp';
import * as actionCreators from '../../../store/actions/index';
import { formatTranslated } from '../../../utils/mlp-utils';
import BatchCancelModal from '../BatchCancelModal';
import BatchEditGridHeader from './BatchEditGridHeader';
import BatchEditParentGrid from './BatchEditParentGrid';

class BatchEditForm extends Component {
    downloadMismatches = () => {
        $('#overlay').show();
        let params = new URLSearchParams();
        params.append('mismatches', JSON.stringify(this.props.model.BatchEditModel.Mismatches));
        axios
            .post('/BatchUser/DownloadMismatches', null, { params: params })
            .then((response) => {
                const fileName = 'failed_matches_' + moment().format('YYYYMMDDHHmmss') + '.csv';
                const a = document.createElement('a');
                document.body.appendChild(a);
                a.style.cssText = 'display: none';
                a.download = fileName;
                if (window.navigator.msSaveOrOpenBlob) {
                    //IE support
                    const fileData = [response.data];
                    const blobObject = new Blob(fileData);
                    $(a).on(() => {
                        window.navigator.msSaveOrOpenBlob(blobObject, fileName);
                    });
                    a.click();
                } else {
                    const blob = new Blob([response.data], { type: 'octet/stream' }),
                        url = window.URL.createObjectURL(blob);
                    a.href = url;
                    a.click();
                    window.URL.revokeObjectURL(url);
                }
                if (a.remove) {
                    a.remove();
                }
            })
            .catch((error) => {
                if (error.response && error.response.status !== 401 && error.response.status !== 403) {
                    this.props.history.push('/BasicError');
                }
            })
            .finally(() => {
                $('#overlay').hide();
            });
    };

    submit = () => {
        this.props.onBatchJobSubmitEdit(this.props.model);
    };

    undo = () => {
        this.props.onUndo(this.props.model.BatchJobId);
    };
    batchJobCancelled = () => {
        this.props.onBatchJobCancelled(this.props.model.BatchJobId);
    };

    render() {
        const parentUsersList = this.props.model.BatchEditModel.ParentUsers;
        const mismatches = this.props.model.BatchEditModel.Mismatches;
        const roles = this.props.model.BatchEditModel.Roles;
        const errMsg = this.props.model.BatchEditModel.ErrorMessage;

        let errorMessage = '';
        if (errMsg) {
            errorMessage = (
                <div className='error message'>
                    {formatTranslated(this.props.model.BatchEditModel.ErrorMessage, this.props.translations.Phrases)}
                </div>
            );
        }

        const parentGrids = parentUsersList.map(function (p, index) {
            return <BatchEditParentGrid key={'parent-user' + index} user={p} parentIndex={index} />;
        });

        let mismatchesElement =
            mismatches.length > 0 ? (
                <button onClick={this.downloadMismatches} className='btn btn-link'>
                    {mismatches.length + ' ' + this.props.translations.Phrases['MismatchesOccurred_Label']}
                </button>
            ) : (
                ''
            );

        return (
            <div className='col-sm-12'>
                <div className='row'>
                    <div className='col-sm-12'>{errorMessage}</div>
                </div>
                <div className='row'>
                    <div className='text-right col-sm-12'>
                        <span className='table-header-info'>
                            {this.props.model.BatchEditModel.UsersFoundCount + ' ' + this.props.translations.Phrases['UsersFound_Label']}
                        </span>
                        <span>{mismatchesElement}</span>
                    </div>
                </div>
                <div className='row batch-edit-grid'>
                    <div className='col-sm-12'>
                        <table className='table table-striped'>
                            <BatchEditGridHeader vm={roles} statuses={this.props.model.BatchEditModel.Statuses} />
                            {parentGrids}
                        </table>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-sm-12'>
                        <div className='pull-right'>
                            <button type='button' className='button small blue' onClick={this.submit}>
                                {this.props.translations.Phrases['Save_Button']}
                            </button>
                            <button type='button' className='button small blue' onClick={this.undo}>
                                {this.props.translations.Phrases['Undo_Button']}
                            </button>
                            <BatchCancelModal
                                action=''
                                okButtonText={this.props.translations.Phrases['YesCancel_Label']}
                                cancelButtonText={this.props.translations.Phrases['No_Label']}
                                title={this.props.translations.Phrases['CancelBatchEdit_Label']}
                                btnText={this.props.translations.Phrases['Cancel_Button']}
                                batchJobId={this.props.model.BatchJobId}
                                cancelAction={this.batchJobCancelled}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        translations: state.translations.model,
        model: state.batchEditUsers.model
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onUndo: (batchJobId) => dispatch(actionCreators.getBatchJobEdit(batchJobId)),
        onBatchJobSubmitEdit: (model) => dispatch(actionCreators.submitBatchEdit(model)),
        onBatchJobCancelled: (batchJobId) => dispatch(actionCreators.cancelBatchJobEdit(batchJobId))
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BatchEditForm));
