import { GridColumnReorderEvent } from '@progress/kendo-react-grid';
import { useEffect, useState } from 'react';
// import json from '../../common/grid-preferences/grid-preferences.json';
import { ColumnSettings } from '../models/GridPreferences';
import { ColumnsWidths } from '../models/ReduxSlices';
import useGridColumnMinWidth from './useGridColumnMinWidth';

interface UseGridPreferencesProps {
    allColumns: null | ColumnSettings[];
    visibleColumns: null | ColumnSettings[];
    columnsWidths?: null | ColumnsWidths[];
    onColumnReorder: (payload: ColumnSettings[]) => void;
    saveColumnReorder: (payload: ColumnSettings[]) => void;
}

const useGridPreferences = ({ allColumns, columnsWidths, onColumnReorder, saveColumnReorder }: UseGridPreferencesProps) => {
    const [columns, setColumns] = useState<ColumnSettings[]>();
    const [trrigerSaveGridSettings, setTrrigerSaveGridSettings] = useState<boolean>(false);
    const { setWidth } = useGridColumnMinWidth({ columns: allColumns });

    // Update columns widths depending on the screen and grid width size
    useEffect(() => {
        setColumns(allColumns?.map((column) => ({ ...column, Width: setWidth(column.Width) })));
    }, [setWidth, allColumns]);

    useEffect(() => {
        if (columns && trrigerSaveGridSettings) {
            saveColumnReorder(columns);
            setTrrigerSaveGridSettings(false);
        }
    }, [columns, trrigerSaveGridSettings, saveColumnReorder]);

    // Update columns order
    const handleColumnReorder = (event: GridColumnReorderEvent) => {
        let reorderedColumns = event.columns.map(({ field, orderIndex, width, sortable, id }) => ({
            GridSettingColumnID: allColumns?.find((item) => item.Code === field)?.GridSettingColumnID,
            Code: field ?? '',
            FieldName: field ?? '',
            HeaderLabel: allColumns?.find((item) => item.Code === field)?.HeaderLabel ?? '',
            Order: Number(orderIndex),
            Width: Number(columnsWidths ? columnsWidths?.find((item) => item.Code === field)?.Width : width),
            IsSortable: !!sortable,
            IsVisibleAlways: !!allColumns?.find((item) => item.Code === field)?.IsVisibleAlways,
            IsVisible: !!allColumns?.find((item) => item.Code === field)?.IsVisible
        }));
        reorderedColumns = [...reorderedColumns].sort((a: ColumnSettings, b: ColumnSettings) => a.Order - b.Order);
        setColumns(reorderedColumns);
        setTrrigerSaveGridSettings(true);
        onColumnReorder(reorderedColumns);
    };

    return { columns, handleColumnReorder };
};

export default useGridPreferences;
