import React, { useCallback, useState } from 'react';
import useMaskedInput from './useMaskedInput';

interface MaskedInputValidationProps {
    validators: ((payload: string) => boolean)[];
    required?: boolean;
    validateOnSubmit?: boolean;
    additionalValidatorArg?: any;
    inputMask: string;
    initial?: null | string;
}

const useMaskedInputValidation = ({ validators, required = true, validateOnSubmit, inputMask, initial }: MaskedInputValidationProps) => {
    const { value, handleChange: handleMaskedInputChange, handleReset: handleMaskedInputReset } = useMaskedInput(inputMask, initial);
    const [isTouched, setIsTouched] = useState<boolean>(false);
    const validatorFunctions = validators.map((validator) => validator(value));

    const isValid = validators.length ? validatorFunctions.every((validator) => validator === true) : null;
    const hasError = isValid !== null ? (!isValid && isTouched) || (!isValid && validateOnSubmit) : null;
    const errorIndex = validatorFunctions.indexOf(false);
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!e.target.value && required === false) {
            setIsTouched(false);
        }
        handleMaskedInputChange(e.target.value);
    };

    const handleBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
        handleMaskedInputChange(e.target.value.trim());
        if (required === false && !e.target.value.trim().length) {
            return;
        }
        if (e.target.value !== initial) {
            setIsTouched(true);
        }
    };

    const handleReset = useCallback(
        (value?: null | string) => {
            handleMaskedInputReset(value);
            setIsTouched(false);
        },
        [handleMaskedInputReset]
    );

    return {
        value,
        handleChange,
        handleBlur,
        handleReset,
        isValid,
        hasError,
        errorIndex
    };
};

export default useMaskedInputValidation;
