import { useEffect, useState } from 'react';

interface SearchInputProps {
    inputValue: string;
    onEnterKey?: () => void;
}

const useSearchInput = ({ inputValue, onEnterKey }: SearchInputProps) => {
    const [value, setValue] = useState<string>(inputValue);

    useEffect(() => {
        setValue(inputValue);
    }, [inputValue]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.value);
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter' && onEnterKey) {
            onEnterKey();
        }
    };

    const handleBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
        setValue(e.currentTarget.value.trim());
    };

    const handleReset = () => {
        setValue('');
    };

    return { value, handleChange, handleBlur, handleKeyDown, handleReset };
};

export default useSearchInput;
