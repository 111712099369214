import moment from 'moment';
import 'moment/locale/fr';
import useTranslation from '../../../common/hooks/useTranslation';
import { useFaqSelector } from '../redux/hooks';

const LAST_UPDATED = '2024-10-01T00:00:00Z';
const DATE_FORMAT = 'MMMM YYYY';

export default function FaqGuideLastUpdated() {
    const { languageSelected } = useFaqSelector((state) => state.language);
    const languageCode = languageSelected?.code;

    const translate = useTranslation();

    const lastUpdated = moment(LAST_UPDATED)
        .locale(languageCode || 'en')
        .format(DATE_FORMAT);

    return (
        <p className='text-right'>
            {translate('LastUpdated_Label')} <i>{lastUpdated}</i>
        </p>
    );
}
