import React from 'react';
import { useSelector } from 'react-redux';
import BasicSearchBox from '../../../../../common/features/BasicSearchBox';
import Button from '../../../../../common/features/Button';
import useBasicSearch from '../../../../../common/hooks/useBasicSearch';
import useTranslation from '../../../../../common/hooks/useTranslation';
import { SubscribersSlice } from '../../../common/models/ReduxSlices';
import { RootState } from '../../../redux/store';
import ClientTable from './ClientTable/ClientTable';

interface Props {
    handleStep: (value: number) => void;
}

const SelectClientNumbers: React.FC<Props> = ({ handleStep }) => {
    const translate = useTranslation();
    const inputRef = React.createRef<HTMLInputElement>();
    const { selectedClients } = useSelector<RootState, SubscribersSlice>((state) => state.subscribers);
    const { searchParam, showClear, handleSearch, handleInputChange, handleKeyDown, handleOnClearInput } = useBasicSearch({ inputRef });

    return (
        <>
            <div className='modal-body'>
                <p>{translate('SubscribersClientNumberChange_Label')}</p>
                <BasicSearchBox
                    ref={inputRef}
                    inputPlaceHolder={translate('SearchClientNumbersPlaceholder_Label')}
                    buttonVariant={'tertiary'}
                    buttonText={translate('SearchClearances_Label')}
                    handleSearch={handleSearch}
                    handleInputChange={handleInputChange}
                    handleKeyDown={handleKeyDown}
                    onClearInputValue={handleOnClearInput}
                    showClear={showClear}
                    leftRightPadding={false}
                />
                <ClientTable searchParam={searchParam} />
            </div>
            <div className='modal-footer justify-content-between'>
                <Button type='button' variant='tertiary-blue' className='btn-lg' onClick={() => handleStep(1)}>
                    {translate('Back_Label')}
                </Button>
                <Button
                    type='button'
                    variant='primary'
                    className='btn-lg'
                    disabled={selectedClients ? Object.keys(selectedClients).length === 0 : true}
                    onClick={() => handleStep(3)}
                >
                    {translate('Next_Label')}
                </Button>
            </div>
        </>
    );
};

export default SelectClientNumbers;
