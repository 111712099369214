import $ from 'jquery';
import moment from 'moment';
import { Component } from 'react';
import ReactGA from 'react-ga';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import axios from '../../axios-mlp';
import * as actions from '../../store/actions/index';
import { closeMenuModal } from '../../utils/menuModalHandler.js';

class MenuItem extends Component {
    beforeRoutingAction = () => {
        this.props.onCloseModal();
        const itemText = this.props.translations.Phrases[this.props.item.Text] || this.props.item.Text;
        ReactGA.event({
            category: 'Outbound Link',
            action: 'click',
            label: itemText
        });
    };

    getUserReport = () => {
        $('#overlay').show();
        axios
            .get('/UserReport/Index', {
                responseType: 'arraybuffer',
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then((response) => {
                const fileName = 'UserReport_' + moment().format('YYYYMMDDHHmmss') + '.zip';
                const a = document.createElement('a');
                document.body.appendChild(a);
                a.style.cssText = 'display: none';
                a.download = fileName;
                if (window.navigator.msSaveOrOpenBlob) {
                    //IE support
                    const fileData = [response.data];
                    const blobObject = new Blob(fileData);
                    $(a).on(() => {
                        window.navigator.msSaveOrOpenBlob(blobObject, fileName);
                    });
                    a.click();
                } else {
                    const blob = new Blob([response.data], { type: 'application/zip' }),
                        url = window.URL.createObjectURL(blob);
                    a.href = url;
                    a.click();
                    window.URL.revokeObjectURL(url);
                }
                if (a.remove) {
                    a.remove();
                }
            })
            .catch((error) => {
                if (error.response && error.response.status !== 401 && error.response.status !== 403) {
                    this.props.history.push('/BasicError');
                }
            })
            .finally(() => {
                $('#overlay').hide();
            });
        this.props.onCloseModal();
    };

    resetState = () => {
        this.props.onResetStateUserSearch();
        this.props.onCloseModal();
        const itemText = this.props.translations.Phrases[this.props.item.Text] || this.props.item.Text;
        ReactGA.event({
            category: 'Outbound Link',
            action: 'click',
            label: itemText
        });
    };
    render() {
        const target = this.props.item.OpenInNewWindow ? '_blank' : '_self';

        const itemText = this.props.translations.Phrases[this.props.item.Text] || this.props.item.Text;

        const noticeBell = this.props.item.Text === 'Notice_Label' && this.props.notices === true ? <i className='fa fa-bell'></i> : null;

        let menuItem = null;
        if (this.props.item.Text === 'EditClientAdmin_Label') {
            menuItem = (
                <Link
                    to={this.props.item.Url}
                    target={target}
                    onClick={() => {
                        this.resetState();
                    }}
                >
                    {itemText}
                </Link>
            );
        } else if (this.props.item.Text === 'GetUserReport_Label') {
            menuItem = (
                <button
                    onClick={() => {
                        this.getUserReport();
                    }}
                >
                    {itemText}
                </button>
            );
        } else {
            menuItem =
                target === '_blank' ? (
                    <a
                        href={this.props.item.Url}
                        target={target}
                        onClick={() => {
                            this.beforeRoutingAction();
                        }}
                    >
                        {itemText}
                    </a>
                ) : (
                    <Link
                        to={this.props.item.Url}
                        target={target}
                        onClick={() => {
                            this.beforeRoutingAction(itemText);
                        }}
                    >
                        {itemText}
                        {'  '}
                        {noticeBell}
                    </Link>
                );
        }

        return <li className={itemText === '' ? 'menu-pointer-none' : 'menu-pointer-auto'}>{menuItem}</li>;
    }
}

const mapStateToProps = (state) => {
    return {
        translations: state.translations.model
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onCloseModal: () => {
            closeMenuModal();
            dispatch(actions.closeModal());
        },
        onResetStateUserSearch: () => dispatch(actions.resetStateUserSearch())
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MenuItem));
