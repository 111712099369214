import { configureStore } from '@reduxjs/toolkit';
import alertsReducer from '../../../redux/reducers/alertsSlice';
import clientSelectionReducer from '../../../redux/reducers/clientSelectionReducer';
import ellipsisReducer from '../../../redux/reducers/ellipsisSlice';
import hydrationReducer from '../../../redux/reducers/hydrationSlice';
import languageReducer from '../../../redux/reducers/languageSlice';
import noticesReducer from '../../../redux/reducers/noticesSlice';
import portalSolutionsReducers from '../../../redux/reducers/portalSolutionsSlice';
import toastReducer from '../../../redux/reducers/toastSlice';
import translationsReducer from '../../../redux/reducers/translationsSlice';
import viewImagesSlice from '../../../redux/reducers/viewImagesSlice';
import advancedSearchSettingsReducer from './reducers/advancedSearchSettingsSlice';
import authReducer from './reducers/authSlice';
import cadDetailsReducer from './reducers/cadDetailsSlice';
import clearancesGridPreferencesReducer from './reducers/clearancesGridPreferencesSlice';
import clearancesGridSettingsReducer from './reducers/clearancesGridSettingsSlice';
import clearanceSubscribersGridSettingsReducer from './reducers/clearanceSubscribersGridSettingsSlice';
import clearanceSubscribersReducer from './reducers/clearanceSubscribersSlice';
import dateRangeReducer from './reducers/dateRangeSlice';
import documentsReducer from './reducers/documentsSlice';
import searchFilterReducer from './reducers/searchSlice';
import shipmentDetailsReducer from './reducers/shipmentDetailsSlice';
import shipmentGridSettingsReducer from './reducers/shipmentGridSettingsSlice';
import shipmentListReducer from './reducers/shipmentListSlice';
import shipmentStatusReducer from './reducers/shipmentStatusSlice';

const store = configureStore({
    reducer: {
        // common slices
        hydration: hydrationReducer,
        translations: translationsReducer,
        language: languageReducer,
        alerts: alertsReducer,
        notices: noticesReducer,
        clientSelection: clientSelectionReducer,
        toast: toastReducer,
        solutionsMenuItems: portalSolutionsReducers,
        viewImages: viewImagesSlice,
        ellipsis: ellipsisReducer,

        // page specific slices
        auth: authReducer,
        shipmentDetails: shipmentDetailsReducer,
        clearanceSubscribers: clearanceSubscribersReducer,
        shipmentsWithTotals: shipmentListReducer,
        shipmentStatus: shipmentStatusReducer,
        dateRange: dateRangeReducer,
        shipmentGridSettings: shipmentGridSettingsReducer,
        advancedSearchSettings: advancedSearchSettingsReducer,
        searchFilter: searchFilterReducer,
        clearanceSubscribersGridSettings: clearanceSubscribersGridSettingsReducer,
        clearancesGridPreferences: clearancesGridPreferencesReducer,
        clearanceDocuments: documentsReducer,
        clearancesGridSettings: clearancesGridSettingsReducer,
        cadDetails: cadDetailsReducer
    }
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export default store;
