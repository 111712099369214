import { faCalendarDay } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { usePopper } from 'react-popper';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import useDropdownToggle from '../hooks/useDropdownToggle';
import { LanguageSlice } from '../models/ReduxSlices';
import Calendar from './DatePicker/Calendar';
import InputBase from './DatePicker/InputBase';
import DropdownWrapper from './Wrappers/DropdownWrapper';

interface FormDatePickerProps {
    label: string;
    value: string;
    placeholder?: string;
    today?: any;
    name?: string;
    disabled?: boolean;
    readonly?: boolean;
    noBorder?: boolean;
    noHeader?: boolean;
    valMsg?: string;
    size?: 'small' | 'medium' | 'large';
    dateFormat: 'MM-DD-YYYY';
    hasError?: null | boolean;
    isValid?: null | boolean;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onDateChange: (value: string) => void;
    onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const FormDatePicker: React.FC<FormDatePickerProps> = (props) => {
    const { languageSelected } = useSelector<RootState, LanguageSlice>((state) => state.language);
    const [referenceElement, setReferenceElement] = useState<any>(null);
    const [popperElement, setPopperElement] = useState<any>(null);
    const calendarRef = useRef(null);
    const { styles, attributes, update } = usePopper(referenceElement, popperElement, {
        placement: 'bottom-start',
        modifiers: [{ name: 'offset', options: { offset: [0, 2] } }]
    });
    const { isOpen, handleToggle, handleKeyDown } = useDropdownToggle(calendarRef);

    useEffect(() => {
        if (update) update();
    }, [update]);

    const calcInputSize = () => {
        let sizeClass = '';

        switch (props.size) {
            case 'small':
                sizeClass = 'form-control-sm';
                break;
            case 'medium':
                sizeClass = '';
                break;
            case 'large':
                sizeClass = 'form-control-lg';
                break;
            default:
                break;
        }

        return sizeClass;
    };

    const calcInputIconSize = () => {
        let iconSizeClass = 'input-icon-md';

        switch (props.size) {
            case 'small':
                iconSizeClass = 'input-icon-sm';
                break;
            case 'medium':
                iconSizeClass = 'input-icon-md';
                break;
            case 'large':
                iconSizeClass = 'input-icon-lg';
                break;
            default:
                break;
        }

        return iconSizeClass;
    };

    return (
        <div className={`needs-validation ${props.isValid ? 'was-validated' : ''}`}>
            <InputBase {...props}>
                <label htmlFor='dateInput'>{props.label}</label>
                <input
                    id='dateInput'
                    ref={setReferenceElement}
                    placeholder={props.placeholder}
                    type='text'
                    value={props.value}
                    className={`form-control ${calcInputSize()} dropdown-toggle ${props.hasError ? 'is-invalid' : ''} ${
                        props.isValid ? 'is-valid' : ''
                    }`}
                    onChange={props.onChange}
                    onBlur={props.onBlur}
                    autoComplete='off'
                    name={props.name}
                    disabled={props.disabled}
                    onKeyDown={handleKeyDown}
                    onClick={handleToggle}
                />
                {!props.hasError && !props.isValid && (
                    <div className={calcInputIconSize()}>
                        <FontAwesomeIcon icon={faCalendarDay} className='mr-3' />
                    </div>
                )}
                {isOpen && (
                    <DropdownWrapper>
                        <div
                            className='dropdown-menu show'
                            ref={setPopperElement}
                            style={{ margin: 0, ...styles.popper }}
                            {...attributes.popper}
                        >
                            <div ref={calendarRef}>
                                <Calendar
                                    customFormat={(x: string) => {
                                        return moment(x).startOf('day').format(props.dateFormat);
                                    }}
                                    today={props.today}
                                    selectedDate={props.value}
                                    onDateChanged={props.onDateChange}
                                    locale={languageSelected?.locale}
                                    language={languageSelected?.code}
                                    onClick={handleToggle}
                                    callingSection = {"team"}
                                />
                            </div>
                        </div>
                    </DropdownWrapper>
                )}
            </InputBase>
        </div>
    );
};

export default FormDatePicker;
