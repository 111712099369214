import useTranslationHydration from '../../../common/hooks/useTranslationHydration';
import FaqGuideContent from './FaqGuideContent';
import FaqGuideLastUpdated from './FaqGuideLastUpdated';

export default function FaqGuide() {
    const translate = useTranslationHydration();

    return (
        <div className='flex-1 overflow-auto p-4'>
            <div className='page-top-row'>
                <div className='page-header-section'>
                    <h1 style={{ color: '#004e9c' }} className='bold-title text-uppercase my-2 ml-n2'>
                        {translate('PortalFaqGuide_Label')}
                    </h1>
                </div>
            </div>
            <FaqGuideLastUpdated />
            <div className='content-wrapper pt-2'>
                <FaqGuideContent />
            </div>
        </div>
    );
}
