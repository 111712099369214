import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { AgencyList, TabNavigationIds } from '../../../../../common/constants/constants-portal';
import { getCountryFromClientNumber } from '../../../../../common/functions/utils';
import useTranslation from '../../../../../common/hooks/useTranslation';
import { ShipmentStatusFilter } from '../../../common/constants/constants-clearances';
import Alert from '../../../common/features/Alert';
import { parseDateTimeET } from '../../../common/functions/parser';
import { ShipmentDetailsSlice } from '../../../common/models/ReduxSlices';
import { RootState } from '../../../redux/store';

const ShipmentAlerts = () => {
    const { shipmentDetails } = useSelector<RootState, ShipmentDetailsSlice>((state) => state.shipmentDetails);
    const elementId = useRef<NodeJS.Timeout>();
    const translate = useTranslation();

    useEffect(() => {
        return () => {
            clearTimeout(elementId.current);
        };
    });

    const customsInfoHold =
        shipmentDetails?.ClientCaptionNumber && getCountryFromClientNumber(shipmentDetails?.ClientCaptionNumber) === 'us'
            ? AgencyList['Customs_US']
            : AgencyList['Customs_CA'];

    const sortByNewestDate = (
        array: {
            Key: string;
            Date: string | null | undefined;
            AgencyKey: string | null | undefined;
            Type: string;
        }[]
    ) => {
        for (let i = 0; i < array.length; i++) {
            for (let j = 0; j < array.length - i - 1; j++) {
                if (!array[j].Date) {
                    array[j].Date = new Date(0).toISOString();
                }
                if (!array[j + 1].Date) {
                    array[j + 1].Date = new Date(0).toISOString();
                }
                if (Date.parse(array[j].Date!) / 1000 < Date.parse(array[j + 1].Date!) / 1000) {
                    let temp = array[j];
                    array[j] = array[j + 1];
                    array[j + 1] = temp;
                }
            }
        }
        return array;
    };

    const getEventsByName = (name: string) => {
        const result: any[] = [];
        shipmentDetails?.PGAs?.forEach((pga) =>
            pga?.Events?.forEach((event) => {
                if (event.Key?.toLowerCase().includes(name)) {
                    result.push({ Key: event.Key, Date: event.Date, AgencyKey: pga.Header, Type: name });
                }
            })
        );
        return result;
    };

    const handleClick = (e: React.MouseEvent<HTMLAnchorElement>, cardId: string) => {
        e.preventDefault();
        const detailsTab = document.getElementById(`${TabNavigationIds.Clearances[0]}-tab`);
        const customsInfoCardId = document.getElementById(cardId);
        if (detailsTab && customsInfoCardId) {
            detailsTab.click();
            elementId.current = setTimeout(() => customsInfoCardId.scrollIntoView(), 250);
        }
    };

    return (
        <>
            {(shipmentDetails?.ClearanceStatus === ShipmentStatusFilter[1].id ||
                shipmentDetails?.ClearanceStatus === ShipmentStatusFilter[2].id) &&
                sortByNewestDate([...getEventsByName('hold'), ...getEventsByName('rejected')]).map((item, index) => {
                    if (item.Type === 'hold') {
                        // ClearanceStatus: ON_HOLD
                        return (
                            <Alert
                                key={index}
                                type='danger'
                                icon={<FontAwesomeIcon icon={faExclamationCircle} className='mt-1' />}
                                title={`${translate('ShipmentOnHold_Label')}`}
                                date={parseDateTimeET(item.Date)}
                                infoLink={{
                                    href: AgencyList[item.AgencyKey as keyof typeof AgencyList].label,
                                    label: AgencyList[item.AgencyKey as keyof typeof AgencyList].viewInfoLabel
                                }}
                                onClick={(e: React.MouseEvent<HTMLAnchorElement>) =>
                                    handleClick(e, AgencyList[item.AgencyKey as keyof typeof AgencyList].label)
                                }
                            >
                                {translate(
                                    'AlertsOnHold_Label',
                                    '',
                                    translate(
                                        AgencyList[item.AgencyKey as keyof typeof AgencyList].label === 'CusInfoCustomsHold'
                                            ? customsInfoHold.label
                                            : AgencyList[item.AgencyKey as keyof typeof AgencyList].label
                                    )
                                )}
                                .
                                <br />
                                {translate('HoldAlert_Label')}
                            </Alert>
                        );
                    } else {
                        // ClearanceStatus: IN_PROGRESS (UNDER REVIEW / REJECTED)
                        return (
                            <Alert
                                key={index}
                                type='warning'
                                icon={<FontAwesomeIcon icon={faExclamationCircle} className='mt-1' />}
                                title={`${translate('ShipmentUnderReview_Label')}`}
                                date={parseDateTimeET(item.Date)}
                                infoLink={{
                                    href: AgencyList[item.AgencyKey as keyof typeof AgencyList].label,
                                    label: AgencyList[item.AgencyKey as keyof typeof AgencyList].viewInfoLabel
                                }}
                                onClick={(e) => handleClick(e, AgencyList[item.AgencyKey as keyof typeof AgencyList].label)}
                            >
                                {translate(
                                    'AlertsInProgress_Label',
                                    '',
                                    translate(
                                        AgencyList[item.AgencyKey as keyof typeof AgencyList].label === 'CusInfoCustomsHold'
                                            ? customsInfoHold.label
                                            : AgencyList[item.AgencyKey as keyof typeof AgencyList].label
                                    )
                                )}
                                .
                                <br />
                                {translate('AlertsReviewMessage_Label')}
                            </Alert>
                        );
                    }
                })}
        </>
    );
};

export default ShipmentAlerts;
