import { faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { useMemo, useState } from 'react';
import useTranslation from '../../../../common/hooks/useTranslation';
import { useTeamSelector } from '../../redux/hooks';
import { isRequiredFeatureSelected } from '../functions/utils';
import type { TeamMemberData } from '../models/ResponseData';
import FixedFooterWhite from './FixedFooterWhite';

interface TeamMemberFooterProps {
    onAddUsers: (payload: { data: TeamMemberData; amount: number }) => void;
}

const TeamMemberFooter: React.FC<TeamMemberFooterProps> = ({ onAddUsers }) => {
    const {
        data: userDetailsData,
        newUsers,
        isLoading: userDetailsLoading,
        error: userDetailsError
    } = useTeamSelector((state) => state.userDetails);
    const {
        data: clientsAndPermissionsData,
        isLoading: clientsAndPermissionsLoading,
        error: clientsAndPermissionsError
    } = useTeamSelector((state) => state.clientsAndPermissions);

    const translate = useTranslation();

    const [formIsSubmitted, setFormIsSubmitted] = useState(false);
    const requiredFeatureIsSelected = useMemo(
        () =>
            isRequiredFeatureSelected({
                parentServices: clientsAndPermissionsData?.ParentServices,
                featureName: 'my livingston portal'
            }),
        [clientsAndPermissionsData?.ParentServices]
    );

    let message = '';

    if (formIsSubmitted) {
        if (newUsers.length === 0) {
            message = translate('AddAtLeastOneUser_Label');
        } else if (!requiredFeatureIsSelected) {
            message =
                userDetailsData?.AdminRole === 'CentralAdmin'
                    ? translate('ClearancesFeatureMustBeSelected_Label')
                    : translate('MLPFeatureMustBeSelected_Label');
        }
    }

    const handleAddingUsers = () => {
        setFormIsSubmitted(true);
        if (userDetailsData && newUsers.length > 0 && requiredFeatureIsSelected) {
            onAddUsers({ data: { ...userDetailsData, ...clientsAndPermissionsData }, amount: newUsers.length });
        }
    };

    return (
        <>
            {!userDetailsLoading && !userDetailsError && !clientsAndPermissionsLoading && !clientsAndPermissionsError && (
                <FixedFooterWhite
                    feedbackText={
                        newUsers.length === 0 ? translate('NoUsersCreated_Label') : translate('CountUsersCreated_Label', newUsers.length)
                    }
                    primaryLabel={newUsers.length > 0 ? translate('SaveUsers_Label') : translate('AddUsers_Label')}
                    primaryIcon={faUserPlus}
                    primaryOnClick={handleAddingUsers}
                    message={message}
                />
            )}
        </>
    );
};

export default TeamMemberFooter;
