import React, { useCallback, useState } from 'react';
import useInput from './useInput';

interface InputValidationProps {
    validators: ((payload: string) => boolean)[];
    required?: boolean;
    validateOnSubmit?: boolean;
    initial?: null | string;
}

const useInputValidation = ({ validators, required = true, validateOnSubmit, initial }: InputValidationProps) => {
    const { value, handleChange: handleInputChange, handleReset: handleInputReset } = useInput(initial);
    const [isTouched, setIsTouched] = useState<boolean>(false);
    const validatorFunctions = validators.map((validator) => validator(value));
    const isValid = validators.length ? validatorFunctions.every((validator) => validator === true) : null;
    const hasError = isValid !== null ? (!isValid && isTouched) || (!isValid && validateOnSubmit) : null;
    const errorIndex = validatorFunctions.indexOf(false);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!e.target.value && required === false) {
            setIsTouched(false);
        }
        handleInputChange(e.target.value);
    };

    const handlePicker = (value: string) => {
        if (!value) {
            setIsTouched(false);
        } else {
            setIsTouched(true);
        }
        handleInputChange(value);
    };

    const handleBlur = (e: React.ChangeEvent<HTMLInputElement> | string) => {
        handleInputChange(typeof e === 'string' ? e : e.target.value.trim());
        if (required === false && (typeof e === 'string' ? !e.trim().length : !e.target.value.trim().length)) {
            return;
        }
        setIsTouched(true);
    };

    const handleReset = useCallback(
        (value?: null | string) => {
            handleInputReset(value);
            setIsTouched(false);
        },
        [handleInputReset]
    );

    return {
        value,
        handleChange,
        handleInputChange,
        handleBlur,
        handleReset,
        handlePicker,
        setIsTouched,
        isValid,
        hasError,
        errorIndex
    };
};

export default useInputValidation;
