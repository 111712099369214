import { useEffect, useState } from 'react';

interface useInputToggleCheckProps {
    isChecked?: boolean;
    onChange?: (key: string, value: boolean) => void;
}

const useInputToggleCheck = ({ isChecked, onChange }: useInputToggleCheckProps) => {
    const [checked, setChecked] = useState<boolean>(isChecked !== undefined ? isChecked : false);

    useEffect(() => {
        if (isChecked !== undefined) {
            setChecked(isChecked);
        }
    }, [isChecked]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!onChange && isChecked === undefined) {
            setChecked((prevState) => !prevState);
        }
        if (onChange) {
            onChange(e.currentTarget.name, e.currentTarget.checked);
        }
    };

    return { checked, handleChange };
};

export default useInputToggleCheck;
