import { getter, process } from '@progress/kendo-data-query';
import { GridColumn as Column, Grid, GridCellProps, GridDataStateChangeEvent, GridHeaderCellProps } from '@progress/kendo-react-grid';
import { Checkbox } from '@progress/kendo-react-inputs';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import '@progress/kendo-theme-default/dist/all.css';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MILESTONES_CA, MILESTONES_US } from '../../../../../common/constants/constants-portal';
import ErrorPage from '../../../../../common/features/ErrorPage';
import GridLoadingOverlay from '../../../../../common/features/Grid/GridLoadingOverlay';
import UnsubscribeFooter from '../../../../../common/features/Grid/UnsubscribeFooter';
import Spinner from '../../../../../common/features/Spinner';
import StatusAlert from '../../../../../common/features/StatusAlert';
import ModalWrapper from '../../../../../common/features/Wrappers/ModalWrapper';
import useGridLanguage from '../../../../../common/hooks/useGridLanguage';
import useGridPreferences from '../../../../../common/hooks/useGridPreferences';
import useGridSelection from '../../../../../common/hooks/useGridSelection';
import useTranslation from '../../../../../common/hooks/useTranslation';
import {
    ClientSelectionSlice,
    GridSettingsSlice,
    HydrationSlice,
    LanguageSlice,
    ToastSlice
} from '../../../../../common/models/ReduxSlices';
import { ProfileModals } from '../../../../../common/models/SubscribersModals';
import { updateColumnOrder } from '../../../../clearances/redux/reducers/clearancesGridPreferencesSlice';
import { GridSubscriptions } from '../../../common/constants/constants-profile';
import { SubscriptionsByUserAndClientSlice, SubscriptionsSlice } from '../../../common/models/ReduxSlices';
import { GetSubscriptionsWithClientNumbers, GetUserWidthGridSetting } from '../../../redux/actions/subscriptions';
import {
    resetSelectedState,
    setCurrentDataItems,
    setDataState,
    setHeaderCheckbox,
    setSelectedState,
    setTotalItems
} from '../../../redux/reducers/profileGridSettingsSlice';
import { resetSubscriptionUpdate, resetUnsubscribe } from '../../../redux/reducers/subscriptionsSlice';
import { RootState } from '../../../redux/store';
import FormattedGridCell from './FormattedGridCell';
import { MultiSelectModal } from './MultiSelectModal';
import UnsubscribeModal from './UnsubscribeModal';

const initialModals: ProfileModals = {
    remove: false
};

const SELECTED_FIELD = 'selected';
const DATA_ITEM_KEY = 'RowId';
const idGetter = getter(DATA_ITEM_KEY);

const ProfileSubscriptionTable = () => {
    const { subscriptions, filteredSubscriptions, unsubscribe, isLoading, error, subscriptionUpdate, allColumns, visibleColumns } =
        useSelector<RootState, SubscriptionsSlice>((state) => state.subscriptions);
    const { subscriptionsByUserAndClient, customerNumbers } = useSelector<RootState, SubscriptionsByUserAndClientSlice>(
        (state) => state.updateSubscriptionsByUserAndClient
    );
    const { dataState, currentDataItems, selectedState, headerCheckbox, totalItems } = useSelector<RootState, GridSettingsSlice>(
        (state) => state.profileGridSettings
    );
    const [notificationsUS, setNotificationsUS] = useState<any>(MILESTONES_US);
    const [notificationsCA, setNotificationsCA] = useState<any>(MILESTONES_CA);
    const [loadingUpdateGrid, setLoadingUpdatedGrid] = useState<boolean>(false);
    const { user } = useSelector<RootState, HydrationSlice>((state) => state.hydration);
    const { languageSelected: selectedLanguage } = useSelector<RootState, LanguageSlice>((state) => state.language);

    const { toast } = useSelector<RootState, ToastSlice>((state) => state.toast);
    const { applyClicked } = useSelector<RootState, ClientSelectionSlice>((state) => state.clientSelection);
    const [gridSubscriptions, setGridSubscriptions] = useState<GridSubscriptions[]>([]);
    const [selectedSubscriptions, setSelectedSubscriptions] = useState<any[]>([]); // selected subscribers for bulk delete
    const [modal, setModal] = useState(false);
    const [unsubscribeModal, setUnsubscribeModal] = useState<ProfileModals>(initialModals);

    const gridRef = useRef<any>();
    const dispatch = useDispatch();
    const translate = useTranslation();
    const gridLanguage = useGridLanguage(selectedLanguage);
    const { handleHeaderSelectionChange, handleSelectionChange } = useGridSelection({
        DATA_ITEM_KEY,
        SELECTED_FIELD,
        idGetter,
        selectedState,
        currentDataItems,
        onSelectionChange: (value) => dispatch(setSelectedState(value)),
        onHeaderSelectionChange: useCallback((value) => dispatch(setHeaderCheckbox(value)), [dispatch]),
        onReset: useCallback(() => dispatch(resetSelectedState()), [dispatch])
    });

    const { columns } = useGridPreferences({
        allColumns,
        visibleColumns,
        onColumnReorder: (value) => dispatch(updateColumnOrder(value)),
        //@ts-ignore
        saveColumnReorder: (value) => dispatch(SaveUserClearancesGridSetting({ GridColumns: value }))
    });

    // Set data state for the grid
    useEffect(() => {
        if (subscriptions) {
            setGridSubscriptions(
                subscriptions.map((item) => ({
                    ...item,
                    [SELECTED_FIELD]: false,
                    isNew:
                        Object.keys(selectedState).length !== 0 ? customerNumbers?.some((custom) => custom === item.CustomerNumber) : false
                }))
            );
        }
    }, [subscriptions, customerNumbers, selectedState]);

    useEffect(() => {
        dispatch(GetUserWidthGridSetting());
    }, [dispatch]);

    // Update the total items that are responsible to show/hide pagination
    useEffect(() => {
        if (!gridRef.current) return;
        dispatch(setTotalItems(gridRef.current.props.data.total));
        dispatch(setCurrentDataItems(gridRef.current.props.data.data));
    }, [dataState, gridSubscriptions, filteredSubscriptions, dispatch]);

    // Reset the selection on every grid reload
    useEffect(() => {
        if (!isLoading && !error) {
            dispatch(resetSelectedState());
        }
    }, [isLoading, error, dispatch]);

    // Array of selected SubscriptionIds
    useEffect(() => {
        setSelectedSubscriptions(
            Object.keys(selectedState)
                .filter((id) => selectedState[id])
                .map((id) => ({ ...gridSubscriptions.find((sub: any) => sub[DATA_ITEM_KEY] === parseInt(id)) }))
        );
    }, [gridSubscriptions, selectedState]);

    useEffect(() => {
        dispatch(resetSubscriptionUpdate());
        dispatch(resetUnsubscribe());
    }, [dispatch, toast?.message]);

    // SubscriptionUpdate refreshes the grid on each update
    useEffect(() => {
        if (
            !subscriptionUpdate.isLoading &&
            !subscriptionUpdate.error &&
            !unsubscribe.error &&
            !unsubscribe.isLoading &&
            applyClicked !== false
        ) {
            dispatch(GetSubscriptionsWithClientNumbers({ ProfileEmail: user?.Email, ProfileMobileNumber: user?.MobileNumber }));
        }
    }, [
        applyClicked,
        subscriptionUpdate.isLoading,
        subscriptionUpdate.error,
        dispatch,
        user?.Email,
        user?.MobileNumber,
        unsubscribe.error,
        unsubscribe.isLoading
    ]);

    // Reset subscription update on unmount
    useEffect(() => {
        return () => {
            dispatch(resetSubscriptionUpdate());
        };
    }, [dispatch]);

    const GridCell = useCallback((props: GridCellProps) => {
        return <FormattedGridCell {...props} isLocked={props.dataItem[SELECTED_FIELD] || false} />;
    }, []);

    const HeaderCell = useCallback(
        (props: GridHeaderCellProps) => {
            return (
                <div style={{ textAlign: 'center' }}>
                    <Checkbox value={headerCheckbox} onChange={props.selectionChange} />
                </div>
            );
        },
        [headerCheckbox]
    );

    const toggleModal = useCallback(
        (prop: keyof ProfileModals | Array<keyof ProfileModals>, next?: boolean) => {
            if (typeof prop === 'string') {
                setUnsubscribeModal({ ...unsubscribeModal, [prop]: next !== undefined ? next : !unsubscribeModal[prop] });
            } else {
                setUnsubscribeModal(Object.assign({}, unsubscribeModal, ...prop.map((key) => ({ [key]: next }))));
            }
        },
        [unsubscribeModal]
    );

    const dataStateChange = (e: GridDataStateChangeEvent) => {
        dispatch(setDataState(e.dataState));
    };

    return (
        <>
            {/* Status: pending (only showing on initial load) */}
            {isLoading ||
                (!subscriptions && (
                    <div className='w-100 h-100 d-flex align-items-center justify-content-center'>
                        <Spinner />
                    </div>
                ))}
            {/* Status: fulfilled */}
            {subscriptions && !error && (
                <LocalizationProvider language={gridLanguage.language}>
                    <IntlProvider locale={gridLanguage.locale}>
                        <div className='d-flex position-relative flex-fill h-100'>
                            <Grid
                                className='profile-subscription-tables flex-fill h-100'
                                data={process(
                                    (filteredSubscriptions ? filteredSubscriptions : gridSubscriptions && gridSubscriptions).map(
                                        (item) => ({
                                            ...item,
                                            [SELECTED_FIELD]: selectedState[idGetter(item)]
                                        })
                                    ),
                                    dataState
                                )}
                                ref={gridRef}
                                total={subscriptions ? subscriptions.length : 0}
                                pageable={
                                    totalItems < 10
                                        ? false
                                        : {
                                              buttonCount: 4,
                                              info: true,
                                              type: 'numeric',
                                              pageSizes: [5, 10, 20],
                                              previousNext: true
                                          }
                                }
                                style={{ zIndex: 0 }}
                                selectedField={SELECTED_FIELD}
                                onHeaderSelectionChange={handleHeaderSelectionChange}
                                onSelectionChange={handleSelectionChange}
                                onDataStateChange={dataStateChange}
                                selectable={{
                                    enabled: false,
                                    drag: false,
                                    cell: false,
                                    mode: 'multiple'
                                }}
                                {...dataState}
                            >
                                {columns?.map((column) => {
                                    return (
                                        <Column
                                            key={column.Code}
                                            field={column.Code}
                                            title={translate(column.HeaderLabel)}
                                            cell={GridCell}
                                            headerCell={column.Code === 'selected' ? HeaderCell : undefined}
                                            sortable={column.IsSortable}
                                            width={column.Width}
                                            orderIndex={column.Order}
                                        />
                                    );
                                })}
                            </Grid>
                            {(isLoading || subscriptionUpdate.isLoading || unsubscribe.isLoading) &&
                                subscriptions &&
                                subscriptions.length > 0 &&
                                columns && <GridLoadingOverlay />}
                        </div>
                    </IntlProvider>
                </LocalizationProvider>
            )}
            {modal && (
                <ModalWrapper onEscape={() => setModal(false)}>
                    <MultiSelectModal
                        email={user?.Email}
                        selectedClientsNumber={selectedSubscriptions?.length}
                        setModal={setModal}
                        notificationsCA={notificationsCA}
                        notificationsUS={notificationsUS}
                        setNotificationsCA={setNotificationsCA}
                        setNotificationsUS={setNotificationsUS}
                        selectedSubscriptions={selectedSubscriptions}
                        setLoadingUpdatedGrid={setLoadingUpdatedGrid}
                        setGridSubscriptions={setGridSubscriptions}
                        gridSubscriptions={gridSubscriptions}
                    />
                </ModalWrapper>
            )}

            {!isLoading && !unsubscribe.isLoading && unsubscribe.removedSubscribersAmount && (
                <StatusAlert message={translate('UnsubscribedFromClients_Label', unsubscribe.removedSubscribersAmount)} type={'success'} />
            )}

            {/* Alert toast when update process is finished  */}
            {!isLoading &&
                !subscriptionUpdate.isLoading &&
                subscriptionUpdate.data &&
                subscriptionUpdate.alertMessage &&
                subscriptionUpdate.alertType && (
                    <StatusAlert
                        message={
                            subscriptionUpdate.requestedAction && subscriptionUpdate.requestedAction.toLowerCase() === 'unsubscribe'
                                ? translate(subscriptionUpdate.alertMessage, subscriptionUpdate.customerNumber)
                                : translate(subscriptionUpdate.alertMessage)
                        }
                        type={subscriptionUpdate.alertType}
                    />
                )}
            {!subscriptionsByUserAndClient.isLoading &&
                loadingUpdateGrid &&
                subscriptionsByUserAndClient.ResponseCode === 200 &&
                subscriptionsByUserAndClient.ResponseMessage === 'PASS' &&
                (subscriptionsByUserAndClient.SubscriptionStatusCode === 'SUBSCRIBED' ||
                    subscriptionsByUserAndClient.SubscriptionStatusCode === 'SUBSCRIBED-UPDATED') && (
                    <StatusAlert message={translate('ClearanceNotificationsUpdated_Label')} type='success' />
                )}

            {/* Unsubscribe footer */}
            {Object.entries(selectedState).filter(([key, value]) => value === true).length > 0 &&
                //!modal.remove &&
                !isLoading &&
                // !subscribe.isLoading &&
                !unsubscribe.isLoading &&
                !subscriptionUpdate.isLoading && (
                    <UnsubscribeFooter
                        amountLabel={translate('NumberOfRemoveSelected_Label', selectedSubscriptions.length)}
                        primaryButtonLabel={translate('RemoveSelected_Label')}
                        onPrimary={() => toggleModal('remove')}
                        secondaryButtonLabel={translate('ManageNotifications_Label')}
                        disablePrimaryButton={selectedSubscriptions.every((item: any) => item.SubscriptionId === null)}
                        onSecondary={() => setModal(true)}
                        selectedSubscribers={selectedSubscriptions.length}
                    />
                )}

            {/* Status: rejected */}
            {error && <ErrorPage errorCode={error} withTranslations />}

            {unsubscribeModal.remove && (
                <UnsubscribeModal
                    subscribers={selectedSubscriptions}
                    toggleModal={toggleModal}
                    gridSubscriptions={gridSubscriptions}
                    setGridSubscriptions={setGridSubscriptions}
                />
            )}
        </>
    );
};
export default ProfileSubscriptionTable;
