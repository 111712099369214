import { createSlice } from '@reduxjs/toolkit';
import { NoticesSlice } from '../../common/models/ReduxSlices';
import { GetNotices } from '../actions/notices';

const initialState: NoticesSlice = {
    activeNotices: null,
    isLoading: false,
    error: undefined
};

const noticesSlice = createSlice({
    name: 'notices',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(GetNotices.pending, (state, action) => {
                state.isLoading = true;
                state.error = undefined;
            })
            .addCase(GetNotices.fulfilled, (state, action) => {
                state.isLoading = false;
                state.activeNotices = action.payload.Notices;
            })
            .addCase(GetNotices.rejected, (state, action) => {
                state.isLoading = false;
                if (typeof action.payload === 'number') {
                    state.error = action.payload;
                } else {
                    state.error = action.error.message;
                }
            });
    }
});

export default noticesSlice.reducer;
