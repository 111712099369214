import { FunctionComponent } from 'react';
import { CounterCardProps } from '../../models/counterCardProps';

const CounterCard: FunctionComponent<CounterCardProps> = ({
    title,
    status,
    count,
    dangerText = false,
    button,
    className,
    spinner,
    loading
}) => {
    return (
        <div className={`card h-100${className ?? ''}`}>
            <div className='card-body'>
                <small className='text-uppercase text-lii-text-light font-weight-bold'>{title}</small>
                <h5 className={`${dangerText ? 'text-danger-dark' : ''} ${spinner && loading ? 'mb-2' : 'mb-0'}`}>{status}</h5>
                {spinner && loading ? (
                    spinner
                ) : (
                    <h1 className={`${dangerText ? 'text-danger-dark' : ''} display-3 mb-0`}>{spinner && loading ? spinner : count}</h1>
                )}
            </div>
            {button && (
                <div className='card-footer'>
                    <button className='btn btn-tertiary' onClick={() => button.onClick && button.onClick()}>
                        {button.label}
                    </button>
                </div>
            )}
        </div>
    );
};
export default CounterCard;
