import { faFileDownload } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { CSSProperties } from 'react';
import { Document } from '../../../../common/models/ClearanceDocuments';
import { FileData } from '../models/ImagingModel';

export const DocumentsTypeFilterCA = [
    { id: 'B2', label: 'B2PostEntry_Label', amount: 0 },
    { id: 'B3', label: 'B3Entry_Label', amount: 0 },
    { id: 'RECAP', label: 'Recap_Label', amount: 0 },
    { id: 'INV', label: 'BillingInvoice_Label', amount: 0 },
    { id: 'CCI', label: 'CanadaCustomsInvoice_Label', amount: 0 },
    { id: 'CCD', label: 'CargoControlDocument_Label', amount: 0 },
    { id: 'ENTRY SUMMARY', label: 'EntrySummary_Label', amount: 0 },
    { id: 'SOURCE', label: 'Release_Label', amount: 0 },
    { id: 'CAD', label: 'CAD_Label', amount: 0 },
    { id: 'UNCATEGORIZED', label: 'Uncategorized_Label', amount: 0 }
];

export const DocumentsTypeFilterUS = [
    { id: '3311', label: '3311_Label', amount: 0 },
    { id: '3461', label: '3461_Label', amount: 0 },
    { id: '7501', label: '7501_Label', amount: 0 },
    { id: '7512', label: '7512_Label', amount: 0 },
    { id: '7523', label: '7523_Label', amount: 0 },
    { id: '7533', label: '7533_Label', amount: 0 },
    { id: 'IARN', label: 'ArrivalNotice_Label', amount: 0 },
    { id: 'INV', label: 'BillingInvoice_Label', amount: 0 },
    { id: 'SUMP', label: 'ClassificationSummaryReport_Label', amount: 0 },
    { id: 'CONM', label: 'ContainerStatusReport_Label', amount: 0 },
    { id: 'IDEO', label: 'DeliveryOrder_Label', amount: 0 },
    { id: 'IEDI', label: 'EDIInvoice_Label', amount: 0 },
    { id: 'CB214', label: 'FTZEntry_Label', amount: 0 },
    { id: 'ISFPR', label: 'ImporterSecurityFiling_Label', amount: 0 },
    { id: 'DETP', label: 'InvoiceDetailReport_Label', amount: 0 },
    { id: 'LCIN', label: 'LandedCostInvoice_Label', amount: 0 },
    { id: 'PROINV', label: 'ProformaInvoice_Label', amount: 0 },
    { id: 'PROOF', label: 'ProofLiterReport_Label', amount: 0 },
    { id: 'REASR', label: 'ReconciliationAssociationReport_Label', amount: 0 },
    { id: 'REDER', label: 'ReconciliationDetail_Label', amount: 0 },
    { id: 'REHER', label: 'ReconciliationHeader_Label', amount: 0 },
    { id: 'SOURCE', label: 'Release_Label', amount: 0 },
    { id: 'SEBP', label: 'SingleEntryBond_Label', amount: 0 },
    { id: 'STEEL', label: 'SteelLicenseReport_Label', amount: 0 },
    { id: 'UNCATEGORIZED', label: 'Uncategorized_Label', amount: 0 }
];
export const DocumentsStatusFilterUniversal = [
    { id: 'Clearance canceled', label: 'ClearanceCanceled_Label', icon: null, value: ['J'] },
    { id: 'Clearance completed', label: 'ClearanceCompleted_Label', icon: null, value: ['A', 'C'] },
    { id: 'Clearance in progress', label: 'ClearanceInProgress_Label', icon: null, value: ['D', 'N', 'T', 'H', 'R'] },
    { id: 'Invoice issued', label: 'InvoiceIssued_Label', icon: null, value: ['B'] }
] as const;
export const DocumentsActionButtonOptions = [
    { id: 'VIEW_DETAILS', label: 'ViewClearanceDetails_Label', icon: null },
    { id: 'HEADER_LINE', label: 'HeaderAndLineInfo_Label', icon: null, rightIcon: faFileDownload },
    { id: 'DOCUMENTS', label: 'Documents_Label', icon: null, rightIcon: faFileDownload },
    { id: 'VIEW_DOCUMENT', label: 'ViewDocuments_Label', icon: null },
    { id: 'COMPARE_DOCUMENT', label: 'CompareDocuments_Label', icon: null }
] as const;
export const DocumentsActionButtonOptionsCa = [
    { id: 'VIEW_DETAILS', label: 'ViewClearanceDetails_Label', icon: null },
    { id: 'DOCUMENTS', label: 'Documents_Label', icon: null, rightIcon: faFileDownload },
    { id: 'VIEW_DOCUMENT', label: 'ViewDocuments_Label', icon: null },
    { id: 'COMPARE_DOCUMENT', label: 'CompareDocuments_Label', icon: null }
] as const;
export const caBullet = [
    'EntryTransactionNo_Label',
    'InvoiceNo_Label',
    'CargoControlNo_Label',
    'PO1_Label',
    'PO2_Label',
    'CarrierNo_Label',
    'Port_Label',
    'Vendor_Label',
    'CLVSID_Label',
    'SBRN_Label',
    'Container_Label',
    'BOLNo_Label'
];

export const usBullet = [
    'EntryTransactionNo_Label',
    'InvoiceNo_Label',
    'MasterBOLNo_Label',
    'Container_Label',
    'Reference_Label',
    'CarrierNo_Label',
    'Port_Label',
    'HouseBOLNo_Label',
    'SubHouseBOLNo_Label',
    'CommercialInvoiceNo_Label',
    'DocumentReferenceNo_Label'
];

export const other = ['CheckTypos_Label', 'ClearSearchStartOver_Label'];
export function CheckArraysFunction(arrayOfStrings: ReadonlyArray<ReadonlyArray<string>>, compareValue: string): boolean {
    for (const subArray of arrayOfStrings) {
        for (const str of subArray) {
            if (str === compareValue) {
                return true;
            }
        }
    }
    return false;
}

export type DocumentsTypePayload = {
    optionsArray: string[];
    country?: {
        Code: 'us' | 'ca';
        Name: 'United States' | 'Canada';
        IsSelected: boolean;
    };
};

export type DocumentStatusPayload = {
    optionsArray: ReadonlyArray<ReadonlyArray<string>>;
    country?: {
        Code: 'us' | 'ca';
        Name: 'United States' | 'Canada';
        IsSelected: boolean;
    };
};
export type SortOption = {
    Text: string;
    Attribute: string;
    IsSelected: boolean;
    Level: number;
    Direction: 'ASC' | 'DESC' | '';
    IsHidden: boolean;
};

export type Metadata = {
    attribute: string;
    label: string;
    value: string;
    isVisibleOnGrid: boolean;
    isVisibleOnGridDetails: boolean;
    isVisibleOnDetailsScreen: boolean;
    isTitleAttribute: boolean;
    gridOrder: number;
    gridDetailsOrder: number;
    detailsScreenOrder: number;
    gridSortLevel: number;
    gridSortDirection: string;
    isGridSortable: boolean;
    packageSortLevel: number;
    packageSortDirection: string;
    isPackageSortable: boolean;
    originalValue: string;
    dataType: string;
};

export const MOTMap = {
    AIR: { label: 'Air_Label', icon: 'airplane', allMots: ['Air, Container', 'Air, Non-container'] },
    TRUCK: { label: 'Truck_Label', icon: 'truck', allMots: ['Truck, Container', 'Truck, Non-container'] },
    SHIP: { label: 'Ocean_Label', icon: 'ship', allMots: ['Vessel, Container', 'Vessel, Non-container', 'Border Water-borne'] },
    TRAIN: { label: 'Rail_Label', icon: 'train', allMots: ['Rail, Container', 'Rail, Non-container'] },
    HAND_CARRY: { label: 'HandCarry_Label', icon: 'person carrying goods', allMots: ['Pedestrian', 'Passenger, hand carried goods'] },
    PIPELINE: {
        label: 'Pipeline_Label',
        icon: 'pipeline',
        allMots: ['Fixed Transport Installations']
    },
    OTHER: { label: 'Other_Label', icon: '', allMots: ['Mail', 'Road other', 'Auto', 'Mode Unknown', 'No Mode'] }
};

export const MOTCodes = {
    '01': Object.keys(MOTMap)[0] as keyof typeof MOTMap,
    '1': Object.keys(MOTMap)[0] as keyof typeof MOTMap,
    '40': Object.keys(MOTMap)[0] as keyof typeof MOTMap,
    '41': Object.keys(MOTMap)[0] as keyof typeof MOTMap,
    '2': Object.keys(MOTMap)[1] as keyof typeof MOTMap,
    '02': Object.keys(MOTMap)[1] as keyof typeof MOTMap,
    '30': Object.keys(MOTMap)[1] as keyof typeof MOTMap,
    '31': Object.keys(MOTMap)[1] as keyof typeof MOTMap,
    '9': Object.keys(MOTMap)[2] as keyof typeof MOTMap,
    '09': Object.keys(MOTMap)[2] as keyof typeof MOTMap,
    '10': Object.keys(MOTMap)[2] as keyof typeof MOTMap,
    '11': Object.keys(MOTMap)[2] as keyof typeof MOTMap,
    '12': Object.keys(MOTMap)[2] as keyof typeof MOTMap,
    '6': Object.keys(MOTMap)[3] as keyof typeof MOTMap,
    '06': Object.keys(MOTMap)[3] as keyof typeof MOTMap,
    '20': Object.keys(MOTMap)[3] as keyof typeof MOTMap,
    '21': Object.keys(MOTMap)[3] as keyof typeof MOTMap,
    '8': Object.keys(MOTMap)[4] as keyof typeof MOTMap,
    '08': Object.keys(MOTMap)[4] as keyof typeof MOTMap,
    '33': Object.keys(MOTMap)[4] as keyof typeof MOTMap,
    '60': Object.keys(MOTMap)[4] as keyof typeof MOTMap,
    '7': Object.keys(MOTMap)[5] as keyof typeof MOTMap,
    '07': Object.keys(MOTMap)[5] as keyof typeof MOTMap,
    '70': Object.keys(MOTMap)[5] as keyof typeof MOTMap,
    '0': Object.keys(MOTMap)[6] as keyof typeof MOTMap,
    '90': Object.keys(MOTMap)[6] as keyof typeof MOTMap,
    null: Object.keys(MOTMap)[6] as keyof typeof MOTMap,
    '': Object.keys(MOTMap)[6] as keyof typeof MOTMap,
    '32': Object.keys(MOTMap)[6] as keyof typeof MOTMap,
    '34': Object.keys(MOTMap)[6] as keyof typeof MOTMap,
    '50': Object.keys(MOTMap)[6] as keyof typeof MOTMap
};

export const MOTFilter = [
    {
        id: Object.keys(MOTMap)[0] as keyof typeof MOTMap,
        label: MOTMap.AIR.label,
        isChecked: true
    },
    {
        id: Object.keys(MOTMap)[1] as keyof typeof MOTMap,
        label: MOTMap.TRUCK.label,
        isChecked: true
    },
    {
        id: Object.keys(MOTMap)[2] as keyof typeof MOTMap,
        label: MOTMap.SHIP.label,
        isChecked: true
    },
    {
        id: Object.keys(MOTMap)[3] as keyof typeof MOTMap,
        label: MOTMap.TRAIN.label,
        isChecked: true
    },
    {
        id: Object.keys(MOTMap)[4] as keyof typeof MOTMap,
        label: MOTMap.HAND_CARRY.label,
        isChecked: true
    },
    {
        id: Object.keys(MOTMap)[5] as keyof typeof MOTMap,
        label: MOTMap.PIPELINE.label,
        isChecked: true
    },
    {
        id: Object.keys(MOTMap)[6] as keyof typeof MOTMap,
        label: MOTMap.OTHER.label,
        isChecked: true
    }
];

export const DateRangeDocumentsFilter = [
    {
        id: 'LAST_2',
        label: 'DateRangeTwoDays_Label',
        startDate: moment().startOf('day').subtract(2, 'days').format('YYYY-MM-DD'),
        endDate: moment().endOf('day').format('YYYY-MM-DDTHH:mm:ss')
    },
    {
        id: 'LAST_7',
        label: 'DateRangeSevenDays_Label',
        startDate: moment().startOf('day').subtract(7, 'days').format('YYYY-MM-DD'),
        endDate: moment().endOf('day').format('YYYY-MM-DDTHH:mm:ss')
    },
    {
        id: 'LAST_30',
        label: 'DateRangeThirtyDays_Label',
        startDate: moment().startOf('day').subtract(30, 'days').format('YYYY-MM-DD'),
        endDate: moment().endOf('day').format('YYYY-MM-DDTHH:mm:ss')
    },
    {
        id: 'LAST_60',
        label: 'DateRangeSixtyDays_Label',
        startDate: moment().startOf('day').subtract(60, 'days').format('YYYY-MM-DD'),
        endDate: moment().endOf('day').format('YYYY-MM-DDTHH:mm:ss')
    },
    {
        id: 'LAST_90',
        label: 'DateRangeNinetyDays_Label',
        startDate: moment().startOf('day').subtract(90, 'days').format('YYYY-MM-DD'),
        endDate: moment().endOf('day').format('YYYY-MM-DDTHH:mm:ss')
    },
    {
        id: 'THIS_YEAR',
        label: 'DateRangeThisYear_Label',
        startDate: moment().startOf('year').format('YYYY-MM-DD'),
        endDate: moment().endOf('day').format('YYYY-MM-DDTHH:mm:ss')
    },
    {
        id: 'LAST_YEAR',
        label: 'DateRangeLastYear_Label',
        startDate: moment().subtract(1, 'years').startOf('year').format('YYYY-MM-DD'),
        endDate: moment().subtract(1, 'years').startOf('year').endOf('year').format('YYYY-MM-DDTHH:mm:ss')
    },
    {
        id: 'ALL_TIME',
        label: 'DateRangeAllTime_Label',
        startDate: null,
        endDate: moment().endOf('day').format('YYYY-MM-DDTHH:mm:ss')
    },
    { id: 'CUSTOM', label: 'DateRangeCustom_Label' }
];

export const DateTypesUsLabels = [
    { label: 'InvoiceIssued_Label', name: 'USINVD', searchTypeCode: 'USSHP', attribute: 'USARVD' },
    { label: 'EstimatedArrival_Label', name: 'USARVD', searchTypeCode: 'USSHP', attribute: 'USARVD' },
    { label: 'ShipmentCreated_Label', name: '_folderDate', searchTypeCode: 'USSHP', attribute: 'USARVD' }
];
export const DateTypesCaLabels = [
    { label: 'InvoiceIssued_Label', name: 'LIINDT', searchTypeCode: 'CASHP', attribute: 'LIETA' },
    { label: 'EstimatedArrival_Label', name: 'LIETA', searchTypeCode: 'CASHP', attribute: 'LIETA' },
    { label: 'ShipmentCreated_Label', name: '_folderDate', searchTypeCode: 'CASHP', attribute: 'LIETA' },
    { label: 'ConsistDate_Label', name: 'LICONS', searchTypeCode: 'CACLVS', attribute: 'LICONS' }
];
export const DateTypeCompleteEntryDataPage = [
    {
        name: 'release_dt',
        label: 'ReleaseDate_Label',
        searchTypeCode: 'CASHP',
        attribute: 'LIETA'
    },
    { name: 'acceptance_dt', label: 'CadAcceptDtTime_Label', searchTypeCode: 'CASHP', attribute: 'LIETA' },
    { name: 'acctg_date', label: 'AccountingDate_Label', searchTypeCode: 'CASHP', attribute: 'LIETA' },
    { name: 'pay_due_dt', label: 'PayDueDate_Label', searchTypeCode: 'CASHP', attribute: 'LIETA' }
];
export const dateTypesLabels = ['InvoiceIssued_Label', 'EstimatedArrival_Label', 'ShipmentCreated_Label', 'ConsistDate_Label'];

export const ButtonArray = [
    {
        buttonText: 'View documents',
        buttonFunction: () => {},
        buttonStyle: { marginRight: '10px', backgroundColor: 'RGB(255, 255, 255)', color: 'RGB(0, 112, 224)' }
    },
    {
        buttonText: 'Compare documents',
        buttonFunction: () => {},
        buttonStyle: { marginRight: '10px', backgroundColor: 'RGB(255, 255, 255)', color: 'RGB(0, 112, 224)' }
    }
];

export const menuItems = ['None', 'Entry no.', 'Document type', 'Document date', 'Client name', 'Client no.'];
export const hashMap: { [key: string]: string } = {
    'Document type': '_metadataFormat',
    'Client name': '_clientName_edm',
    'Document date': '_imageDate',
    'Client no.': 'USBILLTO',
    'Entry no.': '_entryNumber'
};

export const hashMapCA: { [key: string]: string } = {
    'Document type': '_metadataFormat',
    'Client name': '_clientName_edm',
    'Document date': '_imageDate',
    'Client no.': '_clientNumberAndCaption',
    'Entry no.': '_transactionNumber'
};

export const docTypeHashMap: { [key: string]: string } = {
    '3311': '3311',
    '3461': '3461',
    '7501': '7501',
    '7512': '7512',
    '7523': '7523',
    '7533': '7533',
    iarn: 'Arrival Notice',
    inv: 'Billing Invoice',
    sump: 'Classification Summary Report',
    conm: 'Container Status Report',
    ideo: 'Delivery Order',
    iedi: 'EDI Invoice',
    cb214: 'FTZ Entry',
    isfpr: 'Importer Security Filing',
    detp: 'Invoice Detail Report',
    lcin: 'Landed Cost Invoice',
    proinv: 'Proforma Invoice',
    proof: 'Proof Liter Report',
    reasr: 'Reconciliation Association Report',
    reder: 'Reconciliation Detail',
    reher: 'Reconciliation Header',
    source: 'Release',
    sebp: 'Single Entry Bond',
    steel: 'Steel License Report',
    uncategorized: 'Uncategorized'
};

export const docTypeCAHashMap: { [key: string]: string } = {
    b2: 'B2 Post-Entry',
    b3: 'B3 Entry',
    recap: 'B3 Recap',
    inv: 'Billing Invoice',
    cci: 'Canada Customs Invoice',
    ccd: 'Cargo Control Document',
    cad: 'CAD',
    'entry summary': 'Entry Summary',
    source: 'Release',
    uncategorized: 'Uncategorized'
};
export const OldMlpMap = [
    {
        Text: 'Document Type',
        Attribute: '_metadataFormatSortOrder',
        IsSelected: false,
        Level: 2,
        Direction: 'ASC',
        IsHidden: false
    },
    {
        Text: 'Client No',
        Attribute: 'USBILLTO',
        IsSelected: false,
        Level: 0,
        Direction: '',
        IsHidden: false
    },
    {
        Text: 'Entry No',
        Attribute: '_entryNumber',
        IsSelected: false,
        Level: 1,
        Direction: 'DESC',
        IsHidden: false
    },
    {
        Text: 'Image Date',
        Attribute: '_imageDate',
        IsSelected: false,
        Level: 3,
        Direction: 'DESC',
        IsHidden: false
    },
    {
        Text: 'Client Name',
        Attribute: '_clientName_edm',
        IsSelected: true,
        Level: 0,
        Direction: '',
        IsHidden: false
    }
];

export const componentStyle: CSSProperties = {
    flex: 1,
    margin: '10px',
    borderRadius: '15px',
    overflow: 'hidden',
    height: '100%'
};

export const DocumentsReturnFields = [
    '_filename',
    '_metadataFormat_raw',
    '_metadataFormat',
    '_pid',
    'USDOCUMENTTYPE',
    'USBILLTO',
    'USPORT',
    'USSCAC',
    'USMBILL',
    'USSTAT',
    'USARVD',
    'USCONTNO',
    'USCUSTREF',
    'USINVD',
    'USMOT',
    'USCICONSOL',
    '_folderDate',
    '_entryNumber',
    '_clientNumberAndCaption',
    '_imageDate',
    '_branchInvoiceNumber',
    '_fileS3Path',
    '_clientName_edm',
    'LISTAT',
    'LIETA',
    'LICCD',
    'LIPORT',
    'LIVEND',
    'LICLVS',
    'LIPO1',
    'LIPO2',
    'LIINDT',
    'LISBRN',
    'LICONS',
    '_transactionNumber',
    'CarrierNo',
    'LICONT',
    'LIDOCUMENTID',
    'LIVERSION'
];

export function documentToFileData(documents: ({ ['selected']: boolean } & Document)[]): FileData[] {
    const fileData: FileData[] = [];
    documents
        .filter((item) => item.selected)
        .forEach((document: Document) => {
            fileData.push({
                _metadataFormat: document.metadataFormat,
                _imageDate: document.imageDate,
                _fileS3Path: document.path,
                USDOCUMENTTYPE: '',
                USPORT: 123,
                _entryNumber: document.entryNumber,
                _transactionNumber: document.transactionNumber,
                _clientName_edm: document.clientName,
                USBILLTO: Number(document.USBILLTO)
            });
        });

    return fileData;
}

export const googleDocs = 'https://docs.google.com/gview?url=';

export function compareDates(dateStr1: string, dateStr2: string) {
    const [month1, day1, year1] = dateStr1.split('/').map(Number);
    const [month2, day2, year2] = dateStr2.split('/').map(Number);

    const date1: Date = new Date(year1, month1 - 1, day1);
    const date2: Date = new Date(year2, month2 - 1, day2);

    return date1.getTime() - date2.getTime();
}

export const calculateDateRange = (optionValue: string, originalValue: string) => {
    const today = moment().utc().startOf('day');
    let startDate = moment().utc().startOf('day');
    let endDate = moment().utc().startOf('day');

    switch (optionValue) {
        case 'DateRangeTwoDays_Label':
            startDate = today.subtract(2, 'days');
            break;
        case 'DateRangeSevenDays_Label':
            startDate = today.subtract(1, 'week');
            break;
        case 'DateRangeThirtyDays_Label':
            startDate = today.subtract(30, 'days');
            break;
        case 'DateRangeSixtyDays_Label':
            startDate = today.subtract(60, 'days');
            break;
        case 'DateRangeNinetyDays_Label':
            startDate = today.subtract(90, 'days');
            break;
        case 'DateRangeThisYear_Label':
            startDate = moment().utc().startOf('year');
            break;
        case 'DateRangeLastYear_Label':
            startDate = moment().utc().subtract(1, 'year').startOf('year');
            endDate = moment().utc().subtract(1, 'year').endOf('year');
            if (moment(originalValue).utc().isBetween(startDate, endDate)) return true;
            else return false;
        case 'DateRangeAllTime_Label':
            startDate = moment(0).utc().startOf('day');
            break;
    }
    return moment(originalValue).utc().isAfter(startDate);
};
