import { useCallback, useEffect, useState } from 'react';
import { maskInput } from '../functions/maskInput';

const useMaskedInput = (mask: string, initial?: null | string) => {
    const [value, setValue] = useState<string>(initial || '');

    useEffect(() => {
        if (initial) {
            setValue(initial);
        } else {
            setValue('');
        }
    }, [initial]);

    const handleChange = (value: string) => {
        setValue((prevState) => (prevState.length > value.length ? value : maskInput(value, mask)));
    };

    const handleReset = useCallback((value?: null | string) => {
        setValue(value || '');
    }, []);

    return {
        value,
        handleChange,
        handleReset
    };
};

export default useMaskedInput;
