import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import GridCaption from '../../../../common/features/Grid/GridCaption';
import useTranslation from '../../../../common/hooks/useTranslation';
import { GridSettingsSlice, SearchFilterSlice, StatusFilterSlice } from '../../../../common/models/ReduxSlices';
import { StatusFilterOptions } from '../../common/constants/constants-subscribers';
import { resetDataState, resetSelectedState, setPageSelected } from '../../redux/reducers/subscribersGridSettingsSlice';
import { setSearchFilter } from '../../redux/reducers/subscribersSearchFilterSlice';
import { setSelectedStatus } from '../../redux/reducers/subscribersStatusFilterSlice';
import { RootState } from '../../redux/store';

const SubscribersGridCaption = () => {
    const { dataState, totalItems } = useSelector<RootState, GridSettingsSlice>((state) => state.subscribersGridSettings);
    const { selectedStatus } = useSelector<RootState, StatusFilterSlice>((state) => state.subscribersStatusFilter);
    const { searchTerm } = useSelector<RootState, SearchFilterSlice>((state) => state.subscribersSearchFilter);
    const [showClear, setShowClear] = useState(false);
    const dispatch = useDispatch();
    const translate = useTranslation();

    useEffect(() => {
        if (selectedStatus.id !== StatusFilterOptions[0].id || searchTerm.length > 0 || dataState.filter !== undefined) {
            setShowClear(true);
        } else {
            setShowClear(false);
        }
    }, [selectedStatus.id, searchTerm, dataState]);

    const clearFilters = () => {
        dispatch(setSelectedStatus(0));
        dispatch(resetSelectedState());
        dispatch(setSearchFilter(''));
        dispatch(resetDataState());
        dispatch(setPageSelected(1));
    };

    return (
        <GridCaption clearLabel={translate('Reset_Label')} showClear={showClear} onClear={clearFilters} tableColumnsDropdown={false}>
            <span>{translate('Results_Label', totalItems)}</span>
        </GridCaption>
    );
};

export default SubscribersGridCaption;
