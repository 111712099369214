import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../axios-portal';
import { SolutionMenuItem } from '../../common/models/ResponseData';
import { GET_PORTAL_SOLUTIONS_MENU, SAVE_PORTAL_SOLUTIONS_MENU } from './actionTypes';

export const GetPortalSolutionsMenu = createAsyncThunk(GET_PORTAL_SOLUTIONS_MENU, async (payload: string | null, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/Menu/GetPortalSolutionsMenu?token=` + payload);
        return response.data;
    } catch (error: any) {
        return rejectWithValue(error.response.status);
    }
});

export const SaveUserPortalSolutionsMenu = createAsyncThunk(
    SAVE_PORTAL_SOLUTIONS_MENU,
    async (
        payload: { SolutionsMenuItems: null | SolutionMenuItem[]; token: string | null; IsUserConfigured: boolean },
        { rejectWithValue }
    ) => {
        try {
            const response = await axios.post(`/Menu/SaveUserPortalSolutionsMenu?token=` + payload.token, {
                SolutionsMenuItems: payload.SolutionsMenuItems,
                IsUserConfigured: payload.IsUserConfigured
            });

            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.status);
        }
    }
);
