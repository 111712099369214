import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';
import Badge from '../../../../../common/features/Badge';
import useTranslation from '../../../../../common/hooks/useTranslation';
import { ShipmentMilestones } from '../../../common/constants/constants-clearances';
import { parseDateTimeET } from '../../../common/functions/parser';
import { ShipmentDetailsSlice } from '../../../common/models/ReduxSlices';
import { RootState } from '../../../redux/store';

interface MilestoneCardProps {
    setShowModal?: (value: boolean) => void;
}

const MilestoneCard: React.FC<MilestoneCardProps> = ({ setShowModal }) => {
    const { shipmentDetails } = useSelector<RootState, ShipmentDetailsSlice>((state) => state.shipmentDetails);
    const shipmentMilestones = shipmentDetails?.Destination === 'USA' ? ShipmentMilestones.USA : ShipmentMilestones.Canada;
    const translate = useTranslation();

    const milestones = () => {
        const allStages = Object.values(shipmentMilestones);
        const completedStages = shipmentDetails?.Stage;

        return allStages.map((item, index) => {
            const isCompleted = completedStages?.includes(index + 1);
            let dateTime = '';

            if (isCompleted && shipmentDetails) {
                dateTime = parseDateTimeET(shipmentDetails[item?.milestoneEventKey]);
            }
            return (
                <div
                    key={index}
                    className='milestone-entry d-flex flex-column flex-sm-row justify-content-sm-between justify-content-start align-items-start align-items-sm-center'
                >
                    <div className='status d-flex flex-row justify-content-start align-items-start'>
                        <div className='status-icon mr-3 mt-1'>
                            {isCompleted ? (
                                <Badge variant='circular' type='success' size='extra-small'>
                                    <FontAwesomeIcon icon={faCheck} />
                                </Badge>
                            ) : (
                                <Badge variant='circular' type='neutral' size='extra-small'>
                                    &nbsp;
                                </Badge>
                            )}
                        </div>
                        <div className='milestone-text-and-time-wrapper d-flex flex-column align-items-start'>
                            <div className='milestone-text'>{translate(item.label)}</div>
                            <div className='milestone-date text-muted'>{dateTime ? dateTime : translate('NoActivity_Label')}</div>
                        </div>
                    </div>
                </div>
            );
        });
    };

    return (
        <div className='card mb-4'>
            <div className='card-header d-flex flex-column align-items-start flex-sm-row justify-content-sm-between align-items-sm-center'>
                <h4 className='mb-2 mb-md-0'>{translate('ClearanceMilestones_Label')}</h4>
            </div>
            <div className='card-body py-4'>{milestones()}</div>
        </div>
    );
};

export default MilestoneCard;
