import produce from 'immer';
import moment from 'moment';
import { formatTranslated, getSearchTypesBySelectedServices, sortBy } from '../../utils/mlp-utils';
import * as actionTypes from '../actions/searchShipment/searchShipmentActionTypes';
import { filters as Constants } from './../../constants/mlp-constants';

const initialState = {
    model: {
        ClientServiceSelection: {
            ClientServices: [],
            Countries: [],
            HasUnrestrictedAccess: false,
            MaxSelectedServicesPerCountry: 0,
            ServicesLimitReached: false
        },
        ColumnWidth: {},
        DetailColumnWidth: {},
        EntryNumberUrl: '',
        Filters: [],
        ImageGroupings: [],
        LineDetailGridOrderFields: '',
        MaxSelectedDocuments: 0,
        NumberOfResultsToLoad: 0,
        OnlyShowLatest: true,
        QuickSearchValue: null,
        ReturnFields: [],
        SearchTypes: [],
        ShipmentModels: [],
        Shipments: [],
        SearchResults: null,
        TotalHits: 0,
        TotalLoadedResults: 0,
        User: null,
        UserShipmentGridColumns: []
    },
    shouldInitSearchShipment: false,
    shouldInitModel: false,
    shouldSubmitSearchShipment: false,
    shouldUpdateDataSource: false,
    type: 'load'
};

const setSearchShipment = (state, action) => {
    return produce(state, (draft) => {
        draft.model = action.model;
        if (!draft.model.SearchResults) {
            draft.model.SearchResults = [];
        }
        draft.shouldInitModel = true;
    });
};

const initSearchShipmentModel = (state, action) => {
    return produce(state, (draft) => {
        draft.model.Filters.forEach((f, i) => {
            f.FilterIndex = i;
            if (f.Options) {
                f.Options.forEach((y) => {
                    y.TranslatedName = action.translations.Phrases[y.Name] || y.Name;
                });
                if (f.Options[0] && f.Options[0].SortValue) {
                    sortBy(f.Options, 'SortValue');
                } else {
                    sortBy(f.Options, 'TranslatedName');
                }
            }
            initMainSearchFilter(f);
            initOtherFilters(f);
        });
        let availableSearchTypes = getSearchTypesBySelectedServices(draft.model);
        if (availableSearchTypes !== undefined && availableSearchTypes.length > 0) {
            availableSearchTypes[0].IsSelected = true;

            draft.model.ImageGroupings.forEach((x) => {
                x.IsSelected = false;
                x.IsHidden = x.SearchTypeCode !== availableSearchTypes[0].Code;
                x.CalculatedValue = x.SearchTypeCode + '-' + x.Name;
                x.TranslatedName = formatTranslated(x.Name, action.translations.Phrases);
            });
            let imageGrouping = draft.model.ImageGroupings.find((x) => x.SearchTypeCode === availableSearchTypes[0].Code);
            imageGrouping.IsSelected = true;
        }
        draft.shouldInitModel = false;
        draft.shouldSubmitSearchShipment = true;
    });
};

const resetSubmitSearchShipmentFlag = (state) => {
    return produce(state, (draft) => {
        draft.shouldSubmitSearchShipment = false;
    });
};

const afterSubmitSearchShipment = (state, action) => {
    return produce(state, (draft) => {
        draft.model.TotalHits = action.payload.totalHits;
        action.payload.shipments.forEach((s) => {
            if (s.CustomsReleaseDt === 'Cancelled') {
                s.CustomsReleaseDtCancelled = true;
            } else {
                s.CustomsReleaseDtCancelled = false;
            }
        });
        draft.model.Shipments = action.payload.shipments;
        if (draft.type === 'filter') {
            draft.model.SearchResults = draft.model.Shipments;
        } else {
            Array.prototype.push.apply(draft.model.SearchResults, draft.model.Shipments);
        }
        draft.model.TotalLoadedResults = draft.model.SearchResults.length;
        draft.shouldUpdateDataSource = true;
    });
};

const resetUpdateDataSourceFlag = (state) => {
    return produce(state, (draft) => {
        draft.shouldUpdateDataSource = false;
    });
};

const resetInitSearchShipmentFlag = (state) => {
    return produce(state, (draft) => {
        draft.shouldInitSearchShipment = false;
    });
};

const updateShipmentClientServices = (state, action) => {
    return produce(state, (draft) => {
        draft.model.TotalLoadedResults = 0;
        draft.model.Shipments = [];
        draft.model.SearchResults = [];
        draft.type = 'filter';

        const oldSelectedCountry = state.model.ClientServiceSelection.Countries.find((country) => country.IsSelected);
        draft.model.ClientServiceSelection = action.data;
        const newSelectedCountry = draft.model.ClientServiceSelection.Countries.find((country) => country.IsSelected);        
        if (oldSelectedCountry.Code !== newSelectedCountry.Code) {
            draft.model.SearchTypes.forEach((type) => {
                type.IsSelected = false;
            });            
            let availableSearchTypes = getSearchTypesBySelectedServices(draft.model);
            if(availableSearchTypes !== undefined && availableSearchTypes.length > 0)
            {
                availableSearchTypes[0].IsSelected = true;
                draft.model.ImageGroupings.forEach((x) => {
                    x.IsSelected = false;
                    x.IsHidden = x.SearchTypeCode !== availableSearchTypes[0].Code;
                });
                let grouping = draft.model.ImageGroupings.find((x) => x.SearchTypeCode === availableSearchTypes[0].Code);
                grouping.IsSelected = true;
                draft.shouldInitSearchShipment = true;
            }
            
            
        } else {
            draft.shouldSubmitSearchShipment = true;
        }
    });
};

const clearShipmentClientServices = (state) => {
    return produce(state, (draft) => {
        draft.model.ClientServiceSelection = {
            ClientServices: [],
            Countries: [],
            HasUnrestrictedAccess: false,
            MaxSelectedServicesPerCountry: 0,
            ServicesLimitReached: false
        };
        draft.model.SearchResults = null;
    });
};

const clearShipmentFilters = (state) => {
    return produce(state, (draft) => {
        draft.model.Filters.forEach((f) => {
            if (f.Level !== 'grid') {
                f.Value = undefined;
                f.RangeStart = undefined;
                f.RangeEnd = undefined;
                f.Options.forEach((o) => {
                    o.IsSelected = false;
                });
                initMainSearchFilter(f);
                initOtherFilters(f);
            }
        });
        draft.model.TotalLoadedResults = 0;
        draft.model.SearchResults = [];
        draft.model.Shipments = [];
        draft.type = 'filter';
        draft.shouldSubmitSearchShipment = true;
    });
};

const clearShipmentDateFilters = (state) => {
    return produce(state, (draft) => {
        draft.model.Filters.forEach((f) => {
            if (f.Level === 'date') {
                f.Value = undefined;
                f.RangeStart = undefined;
                f.RangeEnd = undefined;
                f.Options.forEach((o) => {
                    o.IsSelected = false;
                });
                initMainSearchFilter(f);
                initOtherFilters(f);
            }
        });
        draft.model.TotalLoadedResults = 0;
        draft.model.SearchResults = [];
        draft.model.Shipments = [];
        draft.type = 'filter';
        draft.shouldSubmitSearchShipment = true;
    });
};

const initMainSearchFilter = (filter) => {
    if (filter.ControlName === 'MainSearchFilter' && filter.Options.length > 0) {
        filter.Attribute = Constants.ALL_TYPES;
        filter.AttributeDataType = 'string';
    }
};

const initOtherFilters = (filter) => {
    if (
        (filter.ControlName === 'PrimaryDateFilter' ||
            filter.ControlName === 'LocationFilter' ||
            filter.ControlName === 'PartiesFilter' ||
            filter.ControlName === 'PartsFilter' ||
            filter.ControlName === 'MultipleWildcardFilter' ||
            filter.ControlName === 'MeasuresFilter') &&
        filter.Options.length > 0
    ) {
        filter.Options[0].IsSelected = true;
        filter.Attribute = filter.Options[0].Value;
        filter.AttributeDataType = filter.Options[0].DataType;
    }
};

const shipmentFilterOptionSelect = (state, action) => {
    return produce(state, (draft) => {
        if (action.payload.oldIndex !== -1) {
            draft.model.Filters[action.payload.filterIndex].Options[action.payload.oldIndex].IsSelected = false;
        }
        if (action.payload.newIndex !== -1) {
            draft.model.Filters[action.payload.filterIndex].Options[action.payload.newIndex].IsSelected = true;
        } else {
            draft.model.Filters[action.payload.filterIndex].Options.forEach((x) => {
                x.IsSelected = false;
            });
            initMainSearchFilter(draft.model.Filters[action.payload.filterIndex]);
        }
    });
};

const shipmentFilterValueChange = (state, action) => {
    return produce(state, (draft) => {
        draft.model.Filters[action.payload.filterIndex][action.payload.property] = action.payload.value;
    });
};

const filterShipments = (state) => {
    return produce(state, (draft) => {
        draft.model.TotalLoadedResults = 0;
        draft.model.Shipments = [];
        draft.model.SearchResults = [];
        draft.type = 'filter';
        draft.shouldSubmitSearchShipment = true;
    });
};

const resetSortParams = (rowAttributes) => {
    rowAttributes.forEach((attribute) => {
        attribute.IsGridSortable = false;
        attribute.GridSortLevel = 0;
        attribute.GridSortDirection = null;
    });
};

const setSortParams = (state, action) => {
    return produce(state, (draft) => {
        draft.model.ImageGroupings.forEach((x) => {
            const selectedSearchType = draft.model.SearchTypes.find((type) => type.IsSelected);

            if (x.SearchTypeCode === selectedSearchType.Code) {
                const rowAttributes = x.GroupAttributes;
                resetSortParams(rowAttributes);
                if (action.payload.sortParams) {
                    action.payload.sortParams.forEach((param, index) => {
                        let sortParam = rowAttributes.find((attribute) => attribute.Name === param.field);
                        sortParam.IsGridSortable = true;
                        sortParam.GridSortLevel = index + 1;
                        sortParam.GridSortDirection = param.dir.toUpperCase();
                    });
                }
            }
        });
        draft.model.TotalLoadedResults = 0;
        draft.model.Shipments = [];
        draft.model.SearchResults = [];
        draft.type = 'filter';
        draft.shouldSubmitSearchShipment = true;
    });
};

const resetGridFilters = (filters) => {
    filters.forEach((f) => {
        if (f.Level === 'grid') {
            f.Value = undefined;
            f.RangeEnd = undefined;
            f.RangeStart = undefined;
        }
    });
};

const setGridFilters = (state, action) => {
    return produce(state, (draft) => {
        resetGridFilters(draft.model.Filters);
        if (action.gridFilter) {
            const gridFilters = action.gridFilter.filters.reduce(
                (accumulator, currentValue) => accumulator.concat(currentValue.filters),
                []
            );

            gridFilters.forEach((gf) => {
                if (gf.operator === 'gt') {
                    const filter = draft.model.Filters.find((f) => f.ControlName === gf.field && f.Level === 'grid');
                    filter.RangeStart = gf.value;
                } else if (gf.operator === 'lt') {
                    const filter = draft.model.Filters.find((f) => f.ControlName === gf.field && f.Level === 'grid');
                    filter.RangeEnd = gf.value;
                } else if (gf.operator === 'gte') {
                    const filter = draft.model.Filters.find((f) => f.ControlName === gf.field && f.Level === 'grid');
                    filter.RangeStart = moment(gf.value).startOf('day').format('YYYY-MM-DD');
                } else if (gf.operator === 'lte') {
                    const filter = draft.model.Filters.find((f) => f.ControlName === gf.field && f.Level === 'grid');
                    filter.RangeEnd = moment(gf.value).endOf('day').format('YYYY-MM-DDTHH:mm:ss');
                } else {
                    const filter = draft.model.Filters.find((f) => f.ControlName === gf.field && f.Level === 'grid');
                    filter.Value = gf.value;
                }
            });
        }
        draft.model.TotalLoadedResults = 0;
        draft.model.Shipments = [];
        draft.model.SearchResults = [];
        draft.type = 'filter';
        draft.shouldSubmitSearchShipment = true;
    });
};

const loadShipments = (state) => {
    return produce(state, (draft) => {
        draft.type = 'load';
        draft.shouldSubmitSearchShipment = true;
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_SEARCH_SHIPMENT:
            return setSearchShipment(state, action);
        case actionTypes.INIT_SEARCH_SHIPMENT_MODEL:
            return initSearchShipmentModel(state, action);
        case actionTypes.RESET_SUBMIT_SEARCH_SHIPMENT_FLAG:
            return resetSubmitSearchShipmentFlag(state);
        case actionTypes.SUBMIT_SEARCH_SHIPMENT:
            return afterSubmitSearchShipment(state, action);
        case actionTypes.RESET_UPDATE_DATA_SOURCE_FLAG:
            return resetUpdateDataSourceFlag(state);
        case actionTypes.RESET_INIT_SEARCH_SHIPMENT_FLAG:
            return resetInitSearchShipmentFlag(state);
        case actionTypes.UPDATE_SHIPMENT_CLIENT_SERVICES:
            return updateShipmentClientServices(state, action);
        case actionTypes.CLEAR_SHIPMENT_CLIENT_SERVICES:
            return clearShipmentClientServices(state);
        case actionTypes.CLEAR_SHIPMENT_FILTERS:
            return clearShipmentFilters(state);
        case actionTypes.CLEAR_SHIPMENT_DATE_FILTERS:
            return clearShipmentDateFilters(state);
        case actionTypes.SHIPMENT_FILTER_OPTION_SELECT:
            return shipmentFilterOptionSelect(state, action);
        case actionTypes.SHIPMENT_FILTER_VALUE_CHANGE:
            return shipmentFilterValueChange(state, action);
        case actionTypes.FILTER_SHIPMENTS:
            return filterShipments(state);
        case actionTypes.SET_SORT_PARAMS:
            return setSortParams(state, action);
        case actionTypes.SET_GRID_FILTERS:
            return setGridFilters(state, action);
        case actionTypes.LOAD_SHIPMENTS:
            return loadShipments(state);
        case actionTypes.RESET_STATE_SEARCH_SHIPMENT:
            return initialState;
        default:
            return state;
    }
};

export default reducer;
