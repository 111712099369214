import { useEffect, useRef, useState } from 'react';

const useElementDimensions = (element: undefined | null | string, delay: undefined | number = 200) => {
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
    const elementRef = useRef<any>(null);
    const throttleObserver = useRef<NodeJS.Timeout>();

    // On initial load set the element dimensions
    useEffect(() => {
        if (!element) return;
        elementRef.current = document.querySelector(element);
        setDimensions({ width: elementRef.current?.offsetWidth || 0, height: elementRef.current?.offsetHeight || 0 });
    }, [element]);

    // On element resize update dimensions
    useEffect(() => {
        if (!element) return;

        elementRef.current = document.querySelector(element);

        if (!elementRef.current) return;

        const throttle = (f: any, delay: number) => {
            return function (...args: any) {
                clearTimeout(throttleObserver.current);
                throttleObserver.current = setTimeout(() => f(...args), delay);
            };
        };

        const resizeObserver = new ResizeObserver(
            throttle((entries: ResizeObserverEntry[]) => {
                for (const entry of entries) {
                    setDimensions({ width: entry.contentRect.width, height: entry.contentRect.height });
                }
            }, delay)
        );

        resizeObserver.observe(elementRef.current);

        return () => {
            clearTimeout(throttleObserver.current);
            resizeObserver.disconnect();
        };
    }, [element, delay]);

    return { width: dimensions.width, height: dimensions.height };
};

export default useElementDimensions;
