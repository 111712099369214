export const GET_TRANSLATIONS = 'GET_TRANSLATIONS';
export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE';
export const CHANGE_LANGUAGE_HYDRATION = 'CHANGE_LANGUAGE_HYDRATION';
export const GET_USER_NOTICES = 'GET_USER_NOTICES';
export const GET_USER_DETAILS = 'GET_USER_DETAILS';
export const GET_CLIENT_SELECTION = 'GET_CLIENT_SELECTION';
export const GET_CLIENT_SEARCH = 'GET_CLIENT_SEARCH';
export const UPDATE_CLIENT_SELECTION = 'UPDATE_CLIENT_SELECTION';
export const GET_PORTAL_SOLUTIONS_MENU = 'GET_PORTAL_SOLUTIONS_MENU';
export const CHECK_DOWNLOAD_LOBBY = 'CHECK_DOWNLOAD_LOBBY';
export const GET_DOWNLOAD_LOBBY = 'GET_DOWNLOAD_LOBBY';
export const SAVE_PORTAL_SOLUTIONS_MENU = 'SAVE_PORTAL_SOLUTIONS_MENU';
export const GET_CUSTOMS_INFO_ID = 'GET_CUSTOMS_INFO_ID';
