import { faSearch, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { type ChangeEvent, useEffect, useRef } from 'react';
import { GetClientSelectionSearch } from '../../../../redux/actions/clientSelection';
import { usePortalDispatch, usePortalSelector } from '../../../../redux/hooks';
import { useDebounce } from '../../../hooks/useDebounce';
import useTranslation from '../../../hooks/useTranslation';
import { type ClientChooserRow } from '../ClientChooser';

interface SearchBarProps {
    isUnrestricted: boolean;
    searchQuery: string;
    setSearchQuery: React.Dispatch<React.SetStateAction<string>>;
    selectedCountryCode: ClientChooserRow['country'] | undefined;
}

export default function SearchBar({ isUnrestricted, searchQuery, setSearchQuery, selectedCountryCode }: SearchBarProps) {
    const translate = useTranslation();

    const dispatch = usePortalDispatch();

    const debouncedQuery = useDebounce(searchQuery, 1000);

    const searchInputRef = useRef<HTMLInputElement>(null);

    const { isLoadingSearch } = usePortalSelector((state) => state.clientSelection);

    useEffect(() => {
        if (!isUnrestricted || debouncedQuery.trim().length < 3) return;

        dispatch(
            GetClientSelectionSearch({
                countryCode: selectedCountryCode || 'ca',
                query: debouncedQuery
            })
        );
    }, [debouncedQuery, dispatch, isUnrestricted, selectedCountryCode]);

    const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;

        setSearchQuery(value);
    };

    return (
        <div className='border-top border-bottom bg-gray-200 flex-grow-0 px-4 py-3'>
            <div className='search-bar-wrapper align-self-center flex-md-grow-1'>
                <div className='search-bar'>
                    <div className='input-group form-group align-items-center position-relative m-0'>
                        <label htmlFor='search-clients' className='visually-hidden'>
                            {translate('SearchClients_Label')}
                        </label>
                        <input
                            type='text'
                            id='search-clients'
                            className='main-desktop-input form-control search-input rounded-sm'
                            placeholder={translate('SearchClients_Label')}
                            ref={searchInputRef}
                            value={searchQuery}
                            onChange={handleSearch}
                            readOnly={isLoadingSearch}
                        />
                        <div className='magnifying-glass-input-icon'>
                            <FontAwesomeIcon icon={faSearch} />
                        </div>
                        <div className='position-relative'>
                            <span className='main-desktop-form-clear form-clear d-none'>
                                <FontAwesomeIcon icon={faTimesCircle} />
                            </span>
                        </div>
                    </div>
                </div>
                {isUnrestricted && <p className='search-bar-subtext small'>{translate('UnrestrictedMessage_Label')}</p>}
            </div>
        </div>
    );
}
