import { VariationPlacement } from '@popperjs/core';
import { GridCellProps } from '@progress/kendo-react-grid';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { TabNavigationIds } from '../../../../../common/constants/constants-portal';
import ActionButtonElipsis from '../../../../../common/features/Grid/ActionButtonElipsis';
import useTranslation from '../../../../../common/hooks/useTranslation';
import { ClientSelectionSlice } from '../../../../../common/models/ReduxSlices';
import { ClearancesActionButtonOptions } from '../../../common/constants/constants-clearances';
import { configFilters } from '../../../common/functions/documentModelFunctions';
import { ShipmentListSlice } from '../../../common/models/ReduxSlices';
import { DownloadDocuments, ExportHeaderAndDetail } from '../../../redux/actions/clearanceDocuments';
import { RootState } from '../../../redux/store';

interface ActionButtonProps extends GridCellProps {
    disabled: boolean;
    placement?: VariationPlacement;
}

export const ActionButton: React.FC<ActionButtonProps> = ({ disabled, dataItem, className, style, placement, ...rest }) => {
    const { documentsIndex } = useSelector<RootState, ShipmentListSlice>((state) => state.shipmentsWithTotals);
    const { initialClientSelection } = useSelector<RootState, ClientSelectionSlice>((state) => state.clientSelection);

    const translate = useTranslation();
    const { push } = useHistory();
    const dispatch = useDispatch();

    const selectedCountryCodeInitial = initialClientSelection?.Countries.find((c) => c.IsSelected)?.Code;

    const displayOptions =
        selectedCountryCodeInitial === 'ca'
            ? ClearancesActionButtonOptions.filter((opt) => opt.id !== 'HEADER_LINE')
            : ClearancesActionButtonOptions;

    const handleAction = (optionId: (typeof ClearancesActionButtonOptions)[number]['id']) => {
        switch (optionId) {
            case 'VIEW_DETAILS': {
                localStorage.setItem('activeTab', TabNavigationIds.Clearances[0]);
                const customsInfoID = dataItem.CustomsInfoID;
                return push(`/Clearances/${customsInfoID}`);
            }
            case 'HEADER_LINE': {
                if (!documentsIndex) return;

                return dispatch(
                    ExportHeaderAndDetail({
                        shipments: [dataItem],
                        filters: configFilters(documentsIndex, [dataItem?.EntryNumber])!,
                        imageGroupings: documentsIndex?.ImageGroupings
                    })
                );
            }
            case 'DOCUMENTS': {
                if (!dataItem?.EntryNumber) return;

                return dispatch(
                    DownloadDocuments({
                        entryNumbers: { allEntries: [dataItem.EntryNumber] }
                    })
                );
            }
            default:
        }
    };

    return (
        <ActionButtonElipsis
            options={displayOptions.map((option) => ({ ...option, label: translate(option.label) }))}
            dangerOption={''}
            onAction={(option) => handleAction(option as (typeof ClearancesActionButtonOptions)[number]['id'])}
            disabled={disabled}
            dataItem={dataItem}
            className={className}
            style={style}
            placement={placement}
            {...rest}
        />
    );
};
