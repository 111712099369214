import { faFolderOpen, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { TabNavigationIds } from '../../../../../common/constants/constants-portal';
import Spinner from '../../../../../common/features/Spinner';
import Tooltip from '../../../../../common/features/Tooltip/Tooltip';
import useTranslation from '../../../../../common/hooks/useTranslation';
import type { ClientSelectionSlice } from '../../../../../common/models/ReduxSlices';
import { GetCustomsInfoID } from '../../../../../redux/actions/ellipsis';
import { RootState } from '../../../redux/store';
import { EllipsisSlice, ImagingModelSlice } from '../../models/ReduxSlices';
import EntryDocumentsTable from './EntryDocumentsTable';

interface DocumentDetailsModalProps {
    modalView: null | string;
    topPosition?: number | undefined;
    setModalView: (data: null | string) => void;
    downloadButtonClick: (value: string) => void;
    setDownloadModalData: (value: string[]) => void;
    setDocumentDetailsCompareView: () => void;
}

const DocumentDetailsModal: React.FC<DocumentDetailsModalProps> = ({
    modalView,
    topPosition,
    setModalView,
    downloadButtonClick,
    setDownloadModalData,
    setDocumentDetailsCompareView
}) => {
    const { ellipsisData } = useSelector<RootState, EllipsisSlice>((state) => state.ellipsis);
    const { model } = useSelector<RootState, ImagingModelSlice>((state) => state.imaging);
    const { initialClientSelection } = useSelector<RootState, ClientSelectionSlice>((state) => state.clientSelection);
    const country = initialClientSelection?.Countries.find((item) => item.IsSelected);
    const translate = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        const image = model.SearchResults.find((item) => item[modalView!]);
        const clientNo = country?.Code === 'ca' ? image![modalView!][0]._clientNumberAndCaption : image![modalView!][0].USBILLTO;

        dispatch(GetCustomsInfoID({ DocumentEntryNumber: modalView || '', ClientNumbers: String(clientNo) ? [String(clientNo)] : [] }));
    }, [dispatch, modalView]);

    const getDetailsPageLink = () => {
        localStorage.setItem('activeTab', TabNavigationIds.Clearances[0]);
        return `/Clearances/${ellipsisData.customsInfoID}?origin=documents`;
    };

    return (
        <aside
            className={`offcanvas large-off-canvas offcanvas-border-blue shadow-sm pb-5 overflow-hidden ${modalView ? 'show' : ''}`}
            id='EntryPanel'
            style={{ top: `${topPosition}px` }}
        >
            <div className={'d-flex flex-direction-row justify-content-between align-items-center bg-blue-200 p-3'}>
                <div className={'d-flex flex-direction-row align-items-center'}>
                    <FontAwesomeIcon icon={faFolderOpen} className={'mr-2'} size={'lg'} />
                    <div className={'flex-direction-column align-items-start'}>
                        {translate('EntryDocumentHeader_Label')}
                        <h3>
                            {translate('EntryNo_Label')} &nbsp;
                            <span className='text-primary font-weight-bold'>
                                {!ellipsisData.customsInfoID ? (
                                    <Tooltip label={translate('ViewEntryDisabledTooltip_Label')}>
                                        <span>{modalView}</span>
                                    </Tooltip>
                                ) : (
                                    <Link to={getDetailsPageLink()}>{modalView}</Link>
                                )}
                            </span>
                        </h3>
                    </div>
                </div>
                <div>
                    {!ellipsisData.customsInfoID ? (
                        <Tooltip label={translate('ViewEntryDisabledTooltip_Label')}>
                            <button type='button' className={`btn btn-tertiary mr-1 disabled`}>
                                {translate('ViewEntry_Label')}
                                {ellipsisData.isLoading && <Spinner size='small' className='ml-2' />}
                            </button>
                        </Tooltip>
                    ) : (
                        <Link to={getDetailsPageLink()}>
                            <button type='button' className={`btn btn-tertiary mr-1`}>
                                {translate('ViewEntry_Label')}
                            </button>
                        </Link>
                    )}

                    <button
                        type='button'
                        className='align-items-end modal-close btn btn-tertiary'
                        onClick={() => setModalView(null)}
                        aria-label='Close'
                    >
                        <FontAwesomeIcon icon={faTimes} />
                    </button>
                </div>
            </div>

            <EntryDocumentsTable
                entryNo={modalView!}
                downloadButtonClick={downloadButtonClick}
                setDownloadModalData={setDownloadModalData}
                setModalView={setModalView}
                setDocumentDetailsCompareView={setDocumentDetailsCompareView}
            />
        </aside>
    );
};

export default DocumentDetailsModal;
