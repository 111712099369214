import { GridCellProps } from '@progress/kendo-react-grid';
import { Checkbox } from '@progress/kendo-react-inputs';
import { useDispatch, useSelector } from 'react-redux';
import MilestoneDropdown from '../../../../../common/features/MilestoneDropdown';
import OnHoldToggle from '../../../../../common/features/OnHoldToggle';
import Tooltip from '../../../../../common/features/Tooltip/Tooltip';
import useTranslation from '../../../../../common/hooks/useTranslation';
import { Subscription } from '../../../../../common/models/ClearanceSubscriptions';
import { Notifications } from '../../../../../common/models/ResponseData';
import { ShipmentDetailsSlice } from '../../../common/models/ReduxSlices';
import { UpdateSubscription } from '../../../redux/actions/clearanceSubscribers';
import { setSubscriptionUpdateAction } from '../../../redux/reducers/clearanceSubscribersSlice';
import { RootState } from '../../../redux/store';
import ActionButton from './ActionButton';

interface DataItem extends Subscription {
    selected?: boolean;
}

interface FormattedGridCellProps extends GridCellProps {
    dataItem: DataItem;
    onRemoveSubscriber: (id?: null | number) => void;
    isLocked: boolean;
}

const FormattedGridCell: React.FC<FormattedGridCellProps> = ({
    field = '',
    dataItem,
    isLocked,
    onRemoveSubscriber,
    selectionChange,
    style,
    className
}) => {
    const { shipmentDetails } = useSelector<RootState, ShipmentDetailsSlice>((state) => state?.shipmentDetails);
    const translate = useTranslation();
    const dispatch = useDispatch();
    let cellContent;

    const handleSubscriptionUpdate = (
        action: string,
        customerNumber: null | string,
        subscriptionId: null | number,
        milestones: null | Notifications[]
    ) => {
        dispatch(setSubscriptionUpdateAction(action));
        if (shipmentDetails?.CustomsInfoID) {
            dispatch(
                UpdateSubscription({
                    customsInfoId: shipmentDetails.CustomsInfoID,
                    milestones,
                    requestModel: dataItem
                })
            );
        }
    };

    switch (field) {
        case 'FullName':
            cellContent = dataItem.FullName;
            break;
        case 'Email':
            cellContent = dataItem.Email;
            break;
        case 'Milestones':
            cellContent = (
                <MilestoneDropdown
                    {...dataItem}
                    updateMilestones={handleSubscriptionUpdate}
                    disabled={isLocked}
                    applyButtonLabel={translate('Apply_Label')}
                    cancelButtonLabel={translate('Cancel_Label')}
                />
            );
            break;
        case 'OnHoldNotifications':
            cellContent = (
                <OnHoldToggle
                    data={dataItem}
                    handleUpdate={(milestones) =>
                        handleSubscriptionUpdate('onhold', dataItem.CustomerNumber || null, dataItem.SubscriptionId || null, milestones)
                    }
                    disabled={isLocked}
                />
            );
            break;
        case 'Actions':
            cellContent = <ActionButton onClick={() => onRemoveSubscriber(dataItem.SubscriptionId)} disabled={isLocked} />;
            break;
        case 'Type':
            cellContent = dataItem.IsExternal ? 'External-user' : 'User';
            break;
        case 'Language':
            cellContent = dataItem.Language;
            break;
        case 'RoleName':
            cellContent = dataItem.RoleName ? translate(`${dataItem.RoleName}_Label`) : null;
            break;
        case 'Phone':
            cellContent = dataItem.Phone;
            break;
        case 'CreatedByEmail':
            cellContent = dataItem.CreatedByEmail;
            break;
        case 'ModifiedByEmail':
            cellContent = dataItem.ModifiedByEmail;
            break;
        case 'selected':
            cellContent = <Checkbox value={!!dataItem.selected} onChange={selectionChange} />;
            break;
        default:
            cellContent = null;
    }

    return (
        <td
            className={`${className} ${dataItem?.isNew ? 'new-row-highlight' : ''}`}
            style={{
                ...style,
                backgroundColor: dataItem?.selected ? '#DDE9F9' : '',
                cursor: 'default',
                textAlign: field === 'selected' ? 'center' : 'start'
            }}
        >
            {['Email', 'CreatedByEmail', 'ModifiedByEmail'].includes(field) ? (
                <Tooltip label={cellContent as string}>
                    <span>
                        {(cellContent! as string)?.length > 30
                            ? (cellContent! as string).substring(0, 30) + '...'
                            : (cellContent! as string)}
                    </span>
                </Tooltip>
            ) : (
                cellContent
            )}
        </td>
    );
};

export default FormattedGridCell;
