import { faFileDownload, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useRef } from 'react';
import ReactDOM from 'react-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import useDropdownToggle from '../../hooks/useDropdownToggle';
import useGTMEventTracking from '../../hooks/useGTMEventTracking';
import useTranslationHydration from '../../hooks/useTranslationHydration';
import { HydrationSlice } from '../../models/ReduxSlices';
import Button from '../Button';
import DropdownMenuButton from '../DropdownMenuButton';
import Spinner from '../Spinner';
import Tooltip from '../Tooltip/Tooltip';

interface UnsubscribeFooterProps {
    amountLabel: string;
    primaryButtonLabel?: string;
    secondaryButtonLabel?: string;
    selectedSubscribers?: number;
    disablePrimaryButton?: boolean;
    loadingPrimaryButton?: boolean;
    loadingSecondaryButton?: boolean;
    loadingDropdownButton?: boolean;
    download?: boolean;
    onPrimary?: () => void;
    onSecondary?: () => void;
    dropdownButtonLabel?: string;
    dropdownOptions?:
        | {
              text?: string;
              label?: string;
              onClick: () => void;
          }[]
        | false
        | null;
    isForClearances?: boolean;
}

const UnsubscribeFooter: React.FC<UnsubscribeFooterProps> = ({
    amountLabel,
    primaryButtonLabel,
    secondaryButtonLabel,
    selectedSubscribers,
    disablePrimaryButton,
    loadingPrimaryButton,
    download,
    loadingSecondaryButton,
    loadingDropdownButton,
    onPrimary,
    onSecondary,
    dropdownButtonLabel,
    dropdownOptions,
    isForClearances
}) => {
    const { user } = useSelector<RootState, HydrationSlice>((state) => state.hydration);
    const dropdownMenuRef = useRef(null);
    const parentContainer = document.querySelector('.navbar-and-stage-wrapper') ?? document.body;
    const { isOpen, handleToggle } = useDropdownToggle(dropdownMenuRef);
    const translate = useTranslationHydration();
    const downloadDocumentsCaRef = useRef(null);

    useGTMEventTracking('Doc_DNLD_Grid_CLR_CPEvt', { location: 'Clearances' }, downloadDocumentsCaRef);
    return ReactDOM.createPortal(
        <div id='FixedFooter' className='bg-white shadow-lg'>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col'>
                        <div className='d-flex flex-column flex-md-row align-items-md-center align-items-stretch justify-content-md-end fixed-bar-without-sidebar py-3'>
                            <div className='helper-text mr-md-auto mb-3 mb-md-0 mt-md-0'>{amountLabel}</div>
                            {selectedSubscribers! > user?.PortalProfileSubscriptionsBulkMaxCount! && !dropdownOptions && !download ? (
                                <div className='helper-text mr-md-auto mb-3 mb-md-0 mt-md-0' style={{ color: 'red' }}>
                                    {translate('LimitOfSelectedSubscribers_Label', user?.PortalProfileSubscriptionsBulkMaxCount)}
                                </div>
                            ) : user &&
                              user.PortalClearancesMaxItemDownloadNumber &&
                              selectedSubscribers! >= user?.PortalClearancesMaxItemDownloadNumber &&
                              download ? (
                                <div className='helper-text mr-md-auto mb-3 mb-md-0 mt-md-0' style={{ color: 'red' }}>
                                    {translate('MaxItemDownloadNumber_Label')}
                                </div>
                            ) : (
                                ''
                            )}

                            {primaryButtonLabel && onPrimary && !dropdownOptions && (
                                <div className='d-flex flex-column flex-sm-row flex-shrink-0 mb-2 mb-md-0'>
                                    {!user?.InternalUser || isForClearances ? (
                                        <Button
                                            disabled={
                                                disablePrimaryButton || selectedSubscribers! > user?.PortalProfileSubscriptionsBulkMaxCount!
                                            }
                                            variant='danger'
                                            size='medium'
                                            onClick={onPrimary}
                                        >
                                            <FontAwesomeIcon icon={faTimesCircle} className='mr-1' />
                                            {primaryButtonLabel}
                                        </Button>
                                    ) : (
                                        <Tooltip label={translate('LiiUsersTooltipMessage_Label')}>
                                            <Button
                                                disabled={
                                                    disablePrimaryButton ||
                                                    selectedSubscribers! > user?.PortalProfileSubscriptionsBulkMaxCount!
                                                }
                                                variant='danger'
                                                size='medium'
                                                onClick={onPrimary}
                                            >
                                                <FontAwesomeIcon icon={faTimesCircle} className='mr-1' />
                                                {primaryButtonLabel}
                                            </Button>
                                        </Tooltip>
                                    )}
                                </div>
                            )}
                            {secondaryButtonLabel && onSecondary && !dropdownOptions && (
                                <div className='d-flex flex-column flex-sm-row flex-shrink-0 ml-0 ml-md-2'>
                                    <Button
                                        variant='primary'
                                        size='medium'
                                        onClick={() => {
                                            onSecondary();
                                        }}
                                        ref={downloadDocumentsCaRef}
                                        disabled={
                                            download
                                                ? loadingDropdownButton ||
                                                  disablePrimaryButton ||
                                                  selectedSubscribers! > user?.PortalClearancesMaxItemDownloadNumber!
                                                : loadingSecondaryButton ||
                                                  (selectedSubscribers! > user?.PortalProfileSubscriptionsBulkMaxCount! ? true : false)
                                        }
                                    >
                                        {loadingSecondaryButton && (
                                            <Spinner color='white' size='small' style={{ marginRight: 6, marginBottom: 2 }} />
                                        )}

                                        {!user?.InternalUser || loadingSecondaryButton ? (
                                            secondaryButtonLabel
                                        ) : download ? (
                                            secondaryButtonLabel
                                        ) : (
                                            <Tooltip label={translate('LiiUsersTooltipMessage_Label')}>{secondaryButtonLabel}</Tooltip>
                                        )}
                                    </Button>
                                </div>
                            )}
                            {dropdownOptions && (
                                <section className='dropdown-examples'>
                                    <DropdownMenuButton
                                        label={
                                            <>
                                                {loadingDropdownButton && (
                                                    <Spinner color='white' size='small' style={{ marginRight: 6, marginBottom: 2 }} />
                                                )}
                                                {dropdownButtonLabel}
                                            </>
                                        }
                                        disabled={
                                            loadingDropdownButton ||
                                            disablePrimaryButton ||
                                            selectedSubscribers! > user?.PortalClearancesMaxItemDownloadNumber!
                                        }
                                        isOpen={isOpen}
                                        onClick={handleToggle}
                                        btnClassName=''
                                        btnVariant='primary'
                                        ref={dropdownMenuRef}
                                    >
                                        <div className='max-height-280'>
                                            <div className='card-body px-0 py-1 pt-3'>
                                                {dropdownOptions.map((opt, i) => (
                                                    <div
                                                        key={i}
                                                        className='dropdown-item d-flex justify-content-between align-items-center'
                                                        style={{ cursor: 'pointer' }}
                                                        onClick={() => {
                                                            handleToggle();
                                                            opt.onClick();
                                                        }}
                                                    >
                                                        {opt.text || translate(opt.label)}
                                                        <div className='icon ml-3'>
                                                            <FontAwesomeIcon icon={faFileDownload} />
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </DropdownMenuButton>
                                </section>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>,
        parentContainer
    );
};

export default UnsubscribeFooter;
