import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import type { StatusUserSlice } from '../../common/models/ReduxSlices';

const initialState: StatusUserSlice = {
    statusUserId: 0,
    isActive: false,
    isStatusChange: false,
    isStatusLoading: false,
    statusMessage: false,
    showStatusModal: false
};

const statusUserSlice = createSlice({
    name: 'statusUser',
    initialState,
    reducers: {
        setUserId(state, action: PayloadAction<number>) {
            state.statusUserId = action.payload;
        },
        statusChangeCheck(state, action: PayloadAction) {
            state.isStatusChange = !state.isStatusChange;
        },
        setStatusIsLoading(state, action: PayloadAction<boolean>) {
            state.isStatusLoading = action.payload;
        },
        setStatusMessage(state, action: PayloadAction<boolean>) {
            state.statusMessage = action.payload;
        },
        setIsActive(state, action: PayloadAction<boolean>) {
            state.isActive = action.payload;
        },
        openStatusModal: (state, action: PayloadAction) => {
            state.showStatusModal = true;
        },
        closeStatusModal: (state, action: PayloadAction) => {
            state.showStatusModal = false;
        }
    }
});

export const { setUserId, statusChangeCheck, setStatusIsLoading, setStatusMessage, setIsActive, openStatusModal, closeStatusModal } =
    statusUserSlice.actions;
export default statusUserSlice.reducer;
