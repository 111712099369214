import { faUsers } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ClientChooser from '../../../../common/features/ClientChooser/ClientChooser';
import ErrorPage from '../../../../common/features/ErrorPage';
import FilterBar from '../../../../common/features/FilterBar/FilterBar';
import StatusFilter from '../../../../common/features/FilterBar/StatusFilter';
import EmptyState from '../../../../common/features/Grid/EmptyState';
import HeaderBar from '../../../../common/features/HeaderBar/HeaderBar';
import ConfrimationModal from '../../../../common/features/Modal/ConfirmationModal';
import Spinner from '../../../../common/features/Spinner';
import useGTMEventTracking from '../../../../common/hooks/useGTMEventTracking';
import useTranslation from '../../../../common/hooks/useTranslation';
import { ClientSelectionSlice, HydrationSlice, SearchFilterSlice, StatusFilterSlice } from '../../../../common/models/ReduxSlices';
import { SubscribersModals } from '../../../../common/models/SubscribersModals';
import { StatusFilterOptions } from '../../common/constants/constants-subscribers';
import { SubscribersSlice } from '../../common/models/ReduxSlices';
import { TransformedSubscriber } from '../../common/models/Subscriber';
import { GetUserSubscribersData } from '../../redux/actions/subscribersManagement';
import { setPageSelected } from '../../redux/reducers/subscribersGridSettingsSlice';
import { setSelectedStatus } from '../../redux/reducers/subscribersStatusFilterSlice';
import { RootState } from '../../redux/store';
import AddSubscriberModal from './AddSubscribersModal/AddSubscribersModal';
import SubscribersSearchBox from './SearchBox/SubscribersSearchBox';
import SubscribersGridCaption from './SubscribersGridCaption';
import SubscribersTable from './SubscribersTable/SubscribersTable';
import SubscriptionStatusAlerts from './SubscriptionStatusAlerts';

const initialModals: SubscribersModals = {
    add: false,
    cancelAdd: false,
    remove: false
};

const SubscribersGrid = () => {
    const { searchTerm } = useSelector<RootState, SearchFilterSlice>((state) => state.subscribersSearchFilter);
    const { selectedStatus } = useSelector<RootState, StatusFilterSlice>((state) => state.subscribersStatusFilter);
    const { user } = useSelector<RootState, HydrationSlice>((state) => state.hydration);
    const [transformedSubscribers, setTransformedSubscribers] = useState<TransformedSubscriber[] | null>(null);
    const [filteredSubscribers, setFilteredSubscribers] = useState<TransformedSubscriber[]>([]);
    const { applyClicked } = useSelector<RootState, ClientSelectionSlice>((state) => state.clientSelection);
    const { subscribers, isLoading, subscriptionUpdate, unsubscribe, subscribe, error } = useSelector<RootState, SubscribersSlice>(
        (state) => state.subscribers
    );
    const [modal, setModal] = useState<SubscribersModals>(initialModals);
    const initialLoad = useRef(true);
    const dispatch = useDispatch();
    const translate = useTranslation();
    const addSubscribersSubscribersInitialRef = useRef(null);

    useGTMEventTracking('Add_Subscriber_SUB_CPEvt', { location: 'Subscribers' }, addSubscribersSubscribersInitialRef);
    // On page load get the subscribers data and translations
    useEffect(() => {
        dispatch(GetUserSubscribersData({ ProfileEmail: user?.Email, ProfileMobileNumber: user?.MobileNumber }));
    }, [dispatch, user?.Email, user?.MobileNumber]);

    useEffect(() => {
        if (subscribers && subscribers.length) {
            let transformedSubscriber: TransformedSubscriber[] = [...subscribers].map((subscriber) => {
                return {
                    ...subscriber,
                    Role: subscriber.RoleName,
                    MobileNumber: subscriber.MobileNumber,
                    MobileNumberOnlyNumbers: subscriber.MobileNumber ? subscriber.MobileNumber.replace(/\D/g, '') : null,
                    Type: subscriber.IsExternal ? translate('StatusExternal_Label') : translate('StatusPortalUser_Label'),
                    Language: !subscriber.Language?.includes('fr') ? 'EN' : 'FR'
                };
            });
            setTransformedSubscribers(transformedSubscriber);
        }
    }, [subscribers, translate, dispatch]);

    useEffect(() => {
        if (transformedSubscribers) {
            // Filter the Subscribers by selected type
            let filteredSubscribers = searchSubscribers(transformedSubscribers, searchTerm);

            //filteredSubscribers = filterSubscribersByType(filteredSubscribers, selectedStatus.id);

            setFilteredSubscribers(filteredSubscribers);
        }
    }, [selectedStatus.id, searchTerm, transformedSubscribers]);

    // Refresh the grid after each "subscriptionUpdate", "subscribe" and "unsubscribe"
    useEffect(() => {
        if (
            !subscriptionUpdate.isLoading &&
            !subscriptionUpdate.error &&
            !subscribe.isLoading &&
            !subscribe.error &&
            !unsubscribe.isLoading &&
            !unsubscribe.error &&
            !initialLoad.current &&
            applyClicked !== false
        ) {
            dispatch(GetUserSubscribersData({ ProfileEmail: user?.Email, ProfileMobileNumber: user?.MobileNumber }));
        }
        initialLoad.current = false;
    }, [
        subscriptionUpdate.isLoading,
        subscriptionUpdate.error,
        subscribe.isLoading,
        subscribe.error,
        unsubscribe.isLoading,
        unsubscribe.error,
        dispatch,
        user?.Email,
        user?.MobileNumber,
        applyClicked
    ]);

    const toggleModal = useCallback(
        (prop: keyof SubscribersModals | Array<keyof SubscribersModals>, next?: boolean) => {
            if (user?.InternalUser) return;
            if (typeof prop === 'string') {
                setModal({ ...modal, [prop]: next !== undefined ? next : !modal[prop] });
            } else {
                setModal(Object.assign({}, modal, ...prop.map((key) => ({ [key]: next }))));
            }
        },
        [modal, user]
    );

    const searchSubscribers = (subscribers: TransformedSubscriber[], searchTerm: string) => {
        if (!searchTerm.length) {
            return subscribers;
        }
        searchTerm = searchTerm.toLowerCase();
        let temp = subscribers?.filter(
            (subscriber) =>
                (subscriber.FullName && subscriber.FullName.toLowerCase().includes(searchTerm)) ||
                (subscriber.Email && subscriber.Email.toLowerCase().includes(searchTerm)) ||
                (subscriber.MobileNumber && subscriber.MobileNumber.toLowerCase().includes(searchTerm)) ||
                (subscriber.MobileNumberOnlyNumbers && subscriber.MobileNumberOnlyNumbers.toLowerCase().includes(searchTerm))
        );

        return temp;
    };

    // Filter Subscribers by Type (All, Portal user, External)
    const filterSubscribersByType = (subscribers: TransformedSubscriber[], status: string) => {
        if (status.toUpperCase() === 'PORTAL_USER') {
            return subscribers.filter((subscriber) => !subscriber?.IsExternal);
        } else if (status.toUpperCase() === 'EXTERNAL') {
            return subscribers.filter((subscriber) => subscriber?.IsExternal);
        } else {
            return subscribers;
        }
    };

    const handleStatusChange = (value: number) => {
        dispatch(setSelectedStatus(value));
        // Set the grid page to 1
        dispatch(setPageSelected(1));
    };

    return (
        <>
            {/* Header bar */}
            <HeaderBar>
                <div className={'d-flex align-items-center'}>
                    <span className='badge badge-circular badge-info mr-3'>
                        <FontAwesomeIcon icon={faUsers} />
                    </span>
                    <h1 className={'m-0 mr-2'}>{translate('Subscribers_Label')}</h1>
                </div>

                <ClientChooser />
            </HeaderBar>

            {/* Filter bar */}
            <FilterBar>
                <div
                    className='filter-and-search d-flex flex-fill justify-content-between px-0 '
                    style={{ display: 'flex', flexDirection: 'column-reverse' }}
                >
                    {/* Status filter pills */}
                    <div className='p-2'>
                        <StatusFilter
                            id='status-filters'
                            options={StatusFilterOptions}
                            selectedStatus={selectedStatus}
                            onChange={handleStatusChange}
                        />
                    </div>

                    {/* Search box */}
                    <SubscribersSearchBox />
                </div>
            </FilterBar>

            {/* Status: pending (only showing on initial load)*/}
            {(isLoading || subscribe.isLoading || unsubscribe.isLoading) && (!subscribers || subscribers.length === 0) && (
                <div className='w-100 h-100 d-flex align-items-center justify-content-center'>
                    <Spinner />
                </div>
            )}

            {/* Status: fulfilled */}
            {subscribers && subscribers.length > 0 && !error && (
                <>
                    <SubscribersGridCaption />
                    <SubscribersTable
                        subscribers={filterSubscribersByType(filteredSubscribers, selectedStatus.id)}
                        toggleModal={toggleModal}
                        modal={modal}
                    />
                </>
            )}

            {/* No subscribers */}
            {!isLoading && subscribers && subscribers.length === 0 && (
                <EmptyState
                    descriptionLabel={translate('NoSubscribers_Label')}
                    addButtonLabel={translate('AddSubscribersHeadline_Label')}
                    onAdd={() => toggleModal('add', true)}
                    ref={addSubscribersSubscribersInitialRef}
                />
            )}

            {modal.add && <AddSubscriberModal modal={modal} toggleModal={toggleModal} />}
            {modal.cancelAdd && (
                <ConfrimationModal
                    title={translate('CancelAddingSucribers_Label')}
                    description={translate('LostProgress_Label')}
                    primaryButtonLabel={translate('YesCancel_Label')}
                    secondaryButtonLabel={translate('NotificationsGoBack_Label')}
                    onPrimary={() => toggleModal(['add', 'cancelAdd'], false)}
                    onSecondary={() => {
                        toggleModal('cancelAdd', false);
                        const modalElement = document.querySelector('#SteppedProgressModal') as HTMLDivElement;
                        modalElement.focus();
                    }}
                />
            )}

            {/* Status: rejected */}
            {error && <ErrorPage errorCode={error} withTranslations />}

            {/* Status alerts for subscriptions */}
            <SubscriptionStatusAlerts />
        </>
    );
};

export default SubscribersGrid;
