import type { ParentServices } from '../models/TeamMember';

export const isRequiredFeatureSelected = ({
    parentServices,
    featureName
}: {
    parentServices: null | undefined | ParentServices[];
    featureName: string;
}) => {
    if (!parentServices?.length) return false;

    let hasAnyFeatureSelected = false;

    return (
        parentServices.every((parentService) => {
            if (!parentService.ClientServices) return false;

            return parentService.ClientServices.every((clientService) => {
                const selectedFeatures = clientService.Features?.filter((feature) => feature.IsSelected);

                if (!selectedFeatures || selectedFeatures.length === 0) return true;

                hasAnyFeatureSelected = true;

                return selectedFeatures.some((feature) => feature.Name?.toLowerCase() === featureName.toLowerCase());
            });
        }) && hasAnyFeatureSelected
    );
};
